import { Component, OnInit, DoCheck, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { Pet } from '../model/pet';
import { User } from '../model/user';
import { Cover } from '../model/cover';
import { Theme } from '../model/theme';
import { Promotion } from '../model/promotion';
import { Insurance } from '../model/insurance';
import { Frequency } from '../model/frequency.enum';
import { BrandDetails } from '../model/brand-details';
import { InsuranceCovers } from '../model/insurance-covers'; 
import { ProductQuestions } from '../model/productQuestions';

import { PremiumsHelper } from '../helpers/premiums.helper';

import { SubscriptionService } from '../services/subscription.service';
import { PromotionService } from '../services/promotion.service';
import { PremiumService } from '../services/premium.service';
import { BrandsService } from '../services/brands.service';
import { QuoteService } from '../services/quote.service';

import {
    SortInsurancesPipe,
    FilterShortListPipe
} from './insurances.pipe';

import { ToolsMethods } from '../shared/tools.methods';

//User story - 31124 - Choosi PDDO Pet STP
import { PDDOSelectedDetails } from '../model/pddo-selected-details';
import { PDDOService } from '../services/pddo.service';
import { CONFIGURATION } from 'app/environments/environment';

@Component({
    selector: 'compare',
    templateUrl: 'compare.component.html',
    providers: [PremiumService, QuoteService, BrandsService, PromotionService, PDDOService]
})
export class CompareComponent implements OnInit, DoCheck {

    FREQUENCY = Frequency;

    // dropdowns
    openFieldFilter = false;

    scrollDownBtn = true;
    scrollDownBtnCompute = false;

    // sorting
    sort = '';

    // filtering
    shortListOnly = false;

    // if it need to be processed like a new quote
    newQuote: boolean;

    // data sets
    coverFieldIdList = [];

    insuranceCovers: InsuranceCovers;
    subscriptionInsuranceCovers: BehaviorSubject<InsuranceCovers>;

    petCoverList: Insurance[];
    subscriptionPetCoverList: BehaviorSubject<Insurance[]>;

    cover: Cover;
    subscriptionCover: BehaviorSubject<Cover>;

    productQuestions: ProductQuestions;
    subscriptionProductQuestions: BehaviorSubject<ProductQuestions>;

    insurance: Insurance;
    subscriptionInsurance: BehaviorSubject<Insurance>;

    user: User;
    subscriptionUser: BehaviorSubject<User>;

    pets: Pet[];
    subscriptionPets: BehaviorSubject<Pet[]>;

    pet: Pet;
    subscriptionPet: BehaviorSubject<Pet>;

    theme: Theme;
    subscriptionTheme: BehaviorSubject<Theme>;

    showBrandDetails: boolean = false;
    brandDetailsLoading: boolean = false;
    brandDetails: BrandDetails = BrandDetails.getBlank();

    subscriptionHaveUsCallOpen: BehaviorSubject<boolean>;

    // scroll
    scrollPosition = 0;
    maxNb = 0;
    visibleNb = 0;
    leftMargin = 0;
    maxVisible = 3;
    columnSize = 0;
    scrollSize = ToolsMethods.getScrollBarWidth();

    rowsSize = {};

    // mobile variable
    mobileFilterPanelOpen: boolean = false;
    mobilePanelOpen: string = '';

    // promotion
    promoCode: string = '1CFREEMONTH';
    promoIsError: boolean = false;
    promoLoading: boolean = false;
    showPromoInput: boolean = true;
    promotionApplied: boolean = false;
    promotionTACOpen: boolean = false;
    promoIsNotApplied: boolean = false;
    promotionProductsLoading: boolean = false;

    // excess
    excessLoading: boolean = false;

    shortListedInsurances: Insurance[] = [];

    applyLoading: boolean = false;
    loadingRoutineCare: boolean = false;

    //User story - 31124 - Choosi PDDO Pet STP
    //PDDO lightbox - validation
    showPDDOSelectedDetails: boolean = false;
    pddoSelectedDetailsLoading: boolean = false;
    pddoSelectedDetails: PDDOSelectedDetails = PDDOSelectedDetails.getBlank();
    isFirstTermsAccepted: boolean = false;
    isSecondTermsAccepted: boolean = false;
    btnContinueClicked: boolean;
    selectedInsurance: Insurance;
    isMobileView: boolean = false;
    mobileTop = 0;
    @HostListener('window:resize', ['$event.target'])
    onResize() {
        this.updateMaxVisible();
    }
    isProdEnv: boolean = true; //set false for local debugging diagnostic trace #117737

    constructor(
        private subscriptionService: SubscriptionService,
        private promotionService: PromotionService,
        private premiumService: PremiumService,
        private brandsService: BrandsService,
        private quoteService: QuoteService,
        private route: ActivatedRoute,
        private router: Router,
        private pddoService: PDDOService
    ) {
        this.subscriptionUser = subscriptionService.getSubscriptions<User>('user');
        this.subscriptionUser.subscribe(
            user => {
                this.user = user;
            }
        );

        this.subscriptionPet = subscriptionService.getSubscriptions<Pet>('pet');
        this.subscriptionPet.subscribe(
            pet => {
                this.pet = pet;
            }
        );

        this.subscriptionPets = subscriptionService.getSubscriptions<Pet[]>('pets');
        this.subscriptionPets.subscribe(
            pets => {
                this.pets = pets;
            }
        );

        this.subscriptionInsurance = subscriptionService.getSubscriptions<Insurance>('insurance');
        this.subscriptionInsurance.subscribe(
            insurance => {
                this.insurance = insurance;
            }
        );

        this.subscriptionPetCoverList = subscriptionService.getSubscriptions<Insurance[]>('pet-covers');
        this.subscriptionPetCoverList.subscribe(
            coverList => {
                this.petCoverList = coverList;
            }
        );

        this.subscriptionCover = subscriptionService.getSubscriptions<Cover>('cover');
        this.subscriptionCover.subscribe(
            cover => {
                this.cover = cover;
            }
        );

        this.subscriptionProductQuestions = subscriptionService.getSubscriptions<ProductQuestions>('productQuestions');
        this.subscriptionProductQuestions.subscribe(
            productQuestionList => {
                this.productQuestions = productQuestionList;
            }
        );

        this.subscriptionInsuranceCovers = subscriptionService.getSubscriptions<InsuranceCovers>('insurance-covers');
        this.subscriptionInsuranceCovers.subscribe(
            insuranceCovers => {
                this.insuranceCovers = insuranceCovers;
            }
        );

        this.subscriptionTheme = subscriptionService.getSubscriptions<Theme>('theme');
        this.subscriptionTheme.subscribe(
            theme => {
                this.theme = theme;
            }
        );

        this.subscriptionHaveUsCallOpen = subscriptionService.getSubscriptions<boolean>('haveUsCallOpen');
    }

    ngOnInit(): void {
        this.updateMaxVisible();
        this.route.data.forEach((data: { premium: InsuranceCovers }) => {
            this.insuranceCovers = data.premium;
        });
        this.fillCoverFieldIdList();
        ToolsMethods.scrollToId('app-header');
        this.newQuote = true;
        this.collectPromotion(); //apply promotion by default
        this.promotionApplied = this.user.promotion.petsurePromotionId > 0;
        if (this.promotionApplied) {
            this.promotionTACOpen = false;
        }
        this.loadingRoutineCare = true;
        //User story - 31124 - Choosi PDDO Pet STP
        //PDDO lightbox - validation
        this.btnContinueClicked = false;
    }

    ngDoCheck(): void {
        this.updateScroll(0);
        this.recomputeRowsSize();
    }

    reLoadPremiums(): void {
        if (!this.insuranceCovers.coverFields) {
            return;
        }
        this.loadingRoutineCare = true;
        let products: string[] = PremiumsHelper.getProductCodes(1, this.user, this.getCurrentPet());
        let partners: string[] = PremiumsHelper.getPartnerCodes(1, this.user, this.getCurrentPet());
        let brandIdFilter: number = this.insuranceCovers.brandIdFilter;
        this.shortListedInsurances = this.insuranceCovers
                                            .getInsurances()
                                            .filter(ins => {
                                                return ins.shortListed;
                                            });
        this.premiumService
            .getAllPremiums(products, partners, this.theme.id, this.user, this.getCurrentPet(), this.insuranceCovers.coverFields)
            .then(
                (newInsuranceCovers) => {
                    newInsuranceCovers.petToUpdate = this.insuranceCovers.petToUpdate;
                    newInsuranceCovers.insurances = newInsuranceCovers.insurances.filter(
                        (insurance: Insurance) => {
                            return (brandIdFilter === null || insurance.brandId === brandIdFilter);
                        }
                    );
                    newInsuranceCovers
                        .getInsurances()
                        .forEach(
                            (insurance) => {
                                for (let shortListedInsurance of this.shortListedInsurances) {
                                    if (shortListedInsurance.productBenefitId === insurance.productBenefitId) {
                                        insurance.shortListed = true;
                                    }
                                }
                        });
                    // clear caching
                    newInsuranceCovers.brandIdFilter = brandIdFilter;
                    this.subscriptionInsuranceCovers.next(newInsuranceCovers);
                    this.loadMorePremiums();
                    this.updateScroll(0);
                    this.recomputeRowsSize();
                    this.promotionProductsLoading = false;
                    }
                )
                .catch(
                    (error) => {
                        console.log('cannot load more premiums');
                        console.log(error);
                    }
                );
    }

    loadMorePremiums(): void {
        if (!this.insuranceCovers.coverFields) {
            return;
        }
        let products: string[] = PremiumsHelper.getProductCodes(2, this.user, this.getCurrentPet());
        let partners: string[] = PremiumsHelper.getPartnerCodes(2, this.user, this.getCurrentPet());
        let brandIdFilter: number = this.insuranceCovers.brandIdFilter;
        this.premiumService
            .getAllPremiums(products, partners, this.theme.id, this.user, this.getCurrentPet(), this.insuranceCovers.coverFields)
            .then(
                (additionalInsuranceCovers) => {
                    additionalInsuranceCovers.petToUpdate = this.insuranceCovers.petToUpdate;
                    additionalInsuranceCovers.insurances = additionalInsuranceCovers.insurances.filter(
                        (insurance: Insurance) => (brandIdFilter === null || insurance.brandId === brandIdFilter)
                    );

                    this.insuranceCovers.getInsurances().forEach(
                        (insurance) => {
                            for (let shortListedInsurance of this.shortListedInsurances) {
                                if (shortListedInsurance.productBenefitId === insurance.productBenefitId) {
                                    insurance.shortListed = true;
                                }
                            }
                            if(brandIdFilter !== null || insurance.brandId !== brandIdFilter)
                            additionalInsuranceCovers.insurances.push(insurance);
                        });
                    additionalInsuranceCovers.brandIdFilter = brandIdFilter;
                    this.subscriptionInsuranceCovers.next(additionalInsuranceCovers);
                    this.loadRoutineCarePremiums();
                    this.recomputeRowsSize();
                })
                .catch((error) => {
                    console.log('cannot load more premiums');
                    console.log(error);
                });
    }

    updateRoutineCarePricesForProduct(insurance: Insurance) {
        // update the routine care price for all the insurances
        for (let i = 0; i < this.insuranceCovers.getInsurances().length; i++) {
            if (this.insuranceCovers.insurances[i].productBenefitId !== insurance.productBenefitId) {
                continue;
            }
            this.insuranceCovers.insurances[i]
                    .mergeInsurancePricings(insurance.cloneInsurancePricings());
            this.insuranceCovers.insurances[i]
                    .applyCurrentInsurancePricing();
        }
    }

    loadRoutineCarePremiums(): void {
        if (!this.insuranceCovers.coverFields) {
            return;
        }
        let products: string[] = PremiumsHelper.getProductCodes(3, this.user, this.getCurrentPet());
        let partners: string[] = PremiumsHelper.getPartnerCodes(3, this.user, this.getCurrentPet());
        let brandIdFilter: number = this.insuranceCovers.brandIdFilter;
        this.premiumService
            .getAllPremiums(products, partners, this.theme.id, this.user, this.getCurrentPet(), this.insuranceCovers.coverFields)
            .then(
                (additionalInsuranceCovers) => {
                    additionalInsuranceCovers.getInsurances().forEach(
                        (insurance) => {
                            if (brandIdFilter !== null && brandIdFilter !== insurance.brandId) {
                                return;
                            }
                            this.updateRoutineCarePricesForProduct(insurance);
                        });                    
                    this.insuranceCovers.brandIdFilter = brandIdFilter;
                    this.subscriptionInsuranceCovers.next(this.insuranceCovers);
                    this.loadingRoutineCare = false;
                },
            )
            .catch((error) => {
                //console.log('cannot load more premiums');
                console.log(error);
                this.loadingRoutineCare = false;
            });
    }

    get sortingTypes() {
        let sortingList =
            [
                {
                    'txt': 'Lowest to highest price',
                    'val': 'price'
                },
                {
                    'txt': 'Highest to lowest price',
                    'val': '-price'
                },
                {
                    'txt': 'Brand',
                    'val': 'brand'
                }
            ];
        if (this.user.promotion.petsurePromotionId > 0) {
            sortingList.push(
                {
                    'txt': 'Offer',
                    'val': 'offer'
                }
            );
        }
        return sortingList;
    }

    openMobilePanel(i: number) {
        this.mobilePanelOpen = (this.mobilePanelOpen === 'mobile-panel-details-' + i) ? '' : 'mobile-panel-details-' + i;
    }

    /**
     * Generated the field id list and the origin list to allow
     * fields filtering
     */
    fillCoverFieldIdList() {
        this.coverFieldIdList = [];
        if (this.insuranceCovers.coverFields) {
            let tmp = [];
            this.insuranceCovers.coverFields.forEach(function(element, index, array){
                if (element.isVisible) {
                    tmp.push(
                        {
                            'index': index,
                            'value': element.name,
                            'activated': true
                        }
                    );
                }
            });
            this.coverFieldIdList = tmp;
        }
    }

    updateRoutineCare(routineSelected: boolean) {
        for (let i = 0; i < this.insuranceCovers.getInsurances().length; i++) {
            this.insuranceCovers.insurances[i].setRoutineCare(routineSelected);
        }
    }

    // ---- Position computing methods ----

    updateMaxVisible() {
        if (document.body.offsetWidth < 992) {
            this.maxVisible = 2;
        } else {
            this.maxVisible = 3;
        }
    }

    updateScroll(move: number) {
        this.visibleNb = document.getElementsByClassName('insurancesDisplayed').length;
        if (this.visibleNb > this.maxVisible) {
            this.maxNb = this.visibleNb - this.maxVisible;
        } else {
            this.maxNb = 0;
        }
        let tmp = this.scrollPosition + move;
        this.scrollPosition = (tmp < 0) ? 0 : (tmp > this.maxNb) ? this.maxNb : tmp;
        this.updatePosition();        
    }

    updatePosition() {
        this.updateColumnSize();
        this.leftMargin = this.scrollPosition * -this.columnSize;
    }

    updateColumnSize() {
        let els = document.getElementsByClassName('insurancesDisplayed');
        if (els.length) {
            let propertyName = 'offsetWidth';
            this.columnSize = els[0][propertyName];
        }
    }

    // **** Position computing methods ****

    getCurrentPet() {
        if (this.insuranceCovers.petToUpdate !== null) {
            if (this.insuranceCovers.petToUpdate < this.pets.length) {
                return this.pets[this.insuranceCovers.petToUpdate];
            }
        }
        return this.pet;
    }

    // ---- Submit methods ----

    gatherInsuranceCovers(name: string) {
        this.petCoverList = [];
        for (let insurance of this.insuranceCovers.getInsurances()) {
            if (insurance.insuranceName === name) {
                this.petCoverList.push(insurance);
            }
        }
    }

    setPetCover(insurance: Insurance) {
        if (this.insuranceCovers.petToUpdate !== null) {
            if (this.insuranceCovers.petToUpdate < this.pets.length) {
                let quoteDetailsId: number = null;
                if (this.pets[this.insuranceCovers.petToUpdate].cover !== null) {
                    quoteDetailsId = this.pets[this.insuranceCovers.petToUpdate].cover.quoteDetailsId;
                }
                this.pets[this.insuranceCovers.petToUpdate].cover = Insurance.getCopy(insurance);
                this.pets[this.insuranceCovers.petToUpdate].cover.quoteDetailsId = quoteDetailsId;
                this.newQuote = false;
            }
        } else if (this.pets.length > 1) {
            let differentBrand = this.pets[0].cover.brandId !== insurance.brandId;
            if (differentBrand) {
                this.cover.quoteId = null;
            } else {
                this.newQuote = false;
            }
            for (let pet of this.pets) {
                let quoteDetailsId: number = null;
                if (pet.cover !== null) {
                    quoteDetailsId = pet.cover.quoteDetailsId;
                }
                pet.cover = Insurance.getCopy(insurance);
                if (differentBrand) {
                    pet.cover.quoteDetailsId = null;
                } else {
                    pet.cover.quoteDetailsId = quoteDetailsId;
                }
            }
        } else {
            let quoteDetailsId: number = null;
            if (this.pet.cover !== null && this.pet.cover.quoteDetailsId !== null) {
                if (this.pets[0].cover.brandId !== insurance.brandId) {
                    this.cover.quoteId = null;
                    this.pet.cover.quoteDetailsId = null;
                } else {
                    this.newQuote = false;
                    quoteDetailsId = this.pet.cover.quoteDetailsId;
                }
            }
            this.pet.cover = insurance;
            this.pet.cover.quoteDetailsId = quoteDetailsId;
            this.pets = [];
            this.pets.push(this.pet);
        }
    }

        applyTo(insurance: Insurance): void {
        this.applyLoading = true;
        this.promoIsNotApplied = false;
        if (!this.promoIsError && !this.promotionApplied && this.promoCode !== '') {
            this.promoIsNotApplied = true;
            this.applyLoading = false;
            return;
        }
        if (insurance.validate()) {
            this.user.excessSelected = insurance.excessSelected;
            this.setPetCover(insurance);
            this.gatherInsuranceCovers(insurance.insuranceName);
            this.subscriptionPets.next(this.pets);
            this.subscriptionUser.next(this.user);
            this.subscriptionCover.next(this.cover);
            this.subscriptionInsurance.next(insurance);
            this.subscriptionPetCoverList.next(this.petCoverList);
            this.subscriptionProductQuestions.next(ProductQuestions.getFromPets(this.pets, this.insuranceCovers));
            this.subscriptionInsuranceCovers.next(this.insuranceCovers);
            this.subscriptionHaveUsCallOpen.next(false);
            this.quoteService
                .saveQuote(this.user, insurance, this.pets, this.cover, this.theme, ToolsMethods.getCookie(document.cookie), this.newQuote)
                .then(
                    (response) => {
                        //Populate the Pets array with the QuoteDetailIds returned from the API
                        for(var index in this.pets){
                            //Attempt to find a matching pet in the response where name, breed and DOB are the same
                            let match = response.quoteDetails.find(o => 
                                o.PetName === this.pets[index].name
                                && o.BreedCode === this.pets[index].breed.breedCode
                                && o.PetDateOfBirth.toISOString() === this.pets[index].getDoB().toISOString());
                            //Use that match to set the quoteDetailsId and Created Date
                            if(match != null){
                                this.pets[index].cover.quoteDetailsId = match.QuoteDetailId;
                                this.pets[index].cover.createdDate = response.createdDate;
                            } else{ //Throw error if no match is found
                                throw new Error('No matching Pet Response found');
                            }
                        }

                        this.cover.createdDate = response.createdDate;
                        this.cover.quoteId = response.quoteId;
                        this.cover.token = response.token;
                        this.subscriptionPet.next(this.pet);
                        this.subscriptionPets.next(this.pets);
                        this.subscriptionCover.next(this.cover);
                        this.applyLoading = false;
                        this.router.navigate(['buy']);
                    },
                    (error) => {
                        console.error('An error occurred while saving user data');
                    }
                );
        }
    }

    // **** Submit methods ****

    //User story - 31124 - Choosi PDDO Pet STP
    // ---- PDDO Lightbox ----
    displayPDDOSelectedDetails(insurance: Insurance, isMobileView: boolean) {
        //35957 - Pet name not updating
        this.pet = this.getCurrentPet();

        if (isMobileView)
        {
            this.mobileTop = window.scrollY + 10;
        }

        this.isMobileView = isMobileView;
        this.selectedInsurance = insurance;
        this.isFirstTermsAccepted = false;
        this.isSecondTermsAccepted = false;
        this.btnContinueClicked = false;

        this.pddoSelectedDetailsLoading = true;
        this.pddoService
            .getPDDOSelectedDetails(insurance, this.pet.name)
            .then(
                (result) => {
                    this.pddoSelectedDetails = result;
                    this.showPDDOSelectedDetails = true;
                    this.pddoSelectedDetailsLoading = false;
                }
            );
    }
    btnContinueClick(): void {
        this.btnContinueClicked = true;
        //if (this.isFirstTermsAccepted && this.isSecondTermsAccepted)
        {
           this.pet.isDDOTermsAccepted=true;
            this.applyTo(this.selectedInsurance);
        }
    }
    // **** PDDO Lightbox ****

    // ---- Rows size computing methods ----

    addOrUpdateRowsSize(className: string) {
        if (!this.rowsSize.hasOwnProperty(className)) {
            this.rowsSize[className] = 0;
        }
    }

    recomputeRowsSize() {
        for (let el in this.rowsSize) {
            if (this.rowsSize.hasOwnProperty(el)) {
                this.computeSingleRowSize(el);
            }
        }
    }

    computeSingleRowSize(className: string) {
        let els = document.getElementsByClassName(className);
        let propertyName = 'offsetHeight';
        for (let i = 0; i < els.length; i++) {
            let elSize = els.item(i)[propertyName];
            if (this.rowsSize[className] < elSize) {
                this.rowsSize[className] = elSize;
            }
        }
    }

    getRowsSize(className: string): string {
        this.addOrUpdateRowsSize(className);
        return (this.rowsSize[className] === 0) ? 'auto' : this.rowsSize[className] + 'px';
    }

    displayBrandDetails(insurance: Insurance) {
        //console.log(insurance);
        this.brandDetailsLoading = true;
        this.brandsService
            .getBrandDetails(insurance)
            .then(
                (result) => {
                    this.brandDetails = result;
                    this.showBrandDetails = true;
                    this.brandDetailsLoading = false;
                }
            );
    }
    // **** Rows size computing methods ****

    // ---- Promotions ---
    applyPromotion() {
        this.promoLoading = true;
        this.promoIsError = false;
        this.promoIsNotApplied = false;
        this.promotionService
            .getPromotion(this.promoCode, this.theme.id)
            .then(
                (result) => {
                    if (result.length === 0) {
                        this.displayPromotionErrorMessage();
                        return;
                    }
                    this.user.promotion = result[0];
                    this.promoLoading = false;
                    this.promotionProductsLoading = true;
                    this.promotionApplied = true;
                    this.promotionTACOpen = false;
                    this.sort = 'offer';
                    this.reLoadPremiums();
                },
                (error) => {
                    this.displayPromotionErrorMessage();
                }
            );
    }

    collectPromotion() {
        this.promoLoading = true;
        this.promoIsError = false;
        this.promoIsNotApplied = false;
        this.promotionService
            .getPromotion(this.promoCode, this.theme.id)
            .then(
                (result) => {
                    if (result.length === 0) {
                        this.displayPromotionErrorMessage();
                        this.reLoadPremiums();
                        return;
                    }
                    this.user.promotion = result[0];
                    this.promoLoading = false;
                    this.promotionProductsLoading = true;
                    this.promotionApplied = true;
                    this.promotionTACOpen = false;                    
                    this.sort = '';                     
                    this.reLoadPremiums();
                }
            )
            .catch(
                (error) => {
                    this.displayPromotionErrorMessage();                    
                }
            );
    }

    displayPromotionErrorMessage() {
        this.promoLoading = false;
        this.promoIsError = true;
        this.recomputeRowsSize();
    }

    // **** Promotions ****

    // ---- Excess ---
    // update
    applyExcess(ins: Insurance, excess: number) {
        ins.setExcess(excess);
    }
    // **** Excess ****

    updateScrollDownBtn() {
        if (!this.scrollDownBtnCompute) {
            this.scrollDownBtnCompute = true;
            setTimeout(
                () => {
                    this.scrollDownBtn = !ToolsMethods.scrollIsBottom('.insurance-fields-container');
                    this.scrollDownBtnCompute = false;
                },
                50
            );
        }
    }

    get scrollDownBtnPositionLeft(): number {
        let visibleNb: number = document.getElementsByClassName('insurancesDisplayed').length;
        visibleNb = visibleNb > this.maxVisible  ? this.maxVisible : visibleNb;
        return ToolsMethods.getElWidth('.insurancesDisplayed') * visibleNb / 2;
    }

    get effectiveDate() {
        let effectiveDate = this.premiumService.getEffectiveDate();
        let monthDisplay: string = (effectiveDate.getMonth() + 1) + '';
        if ((effectiveDate.getMonth() + 1) < 10) {
            monthDisplay = '0' + monthDisplay;
        }
        let dayDisplay: string = effectiveDate.getDate() + '';
        if (effectiveDate.getDate() < 10) {
            dayDisplay = '0' + dayDisplay;
        }
        return dayDisplay + ' ' + monthDisplay + ' ' + effectiveDate.getFullYear();
    }

    get shortListVisible(): boolean {
        return ((new FilterShortListPipe).transform(this.insuranceCovers.getInsurances(), true).length > 0);
    }

    get isOnePremiumVisible(): boolean {
        let insurances: Insurance[] = this.insuranceCovers.getInsurances();
        insurances = new FilterShortListPipe().transform(insurances, this.shortListOnly);
        insurances = new SortInsurancesPipe().transform(insurances, this.sort, this.theme.id);
        return insurances.length > 0;
    }

    /**
     * Debug purpose
     *
     * TODO remove
     *
     * @returns {string}
     */
    get diagnostic() {
        return JSON.stringify(
            {
                'rowsSize': this.rowsSize,
                'scrollPosition': this.scrollPosition,
                'columnSize': this.columnSize,
                'maxVisible': this.maxVisible,
                'maxNb': this.maxNb,
                'visibleNb': this.visibleNb,
                'cover': this.cover,
                'sort': this.sort,
                'shortListOnly': this.shortListOnly,
                'insuranceCovers': this.insuranceCovers,
                'coverFieldIdList': this.coverFieldIdList
            },
            undefined,
            4
        );
    }
}
