import { Frequency } from './frequency.enum';

export class InsurancePricing {

    static getBlank(): InsurancePricing {
        return new InsurancePricing(
            null, '', '',
            false,
            '',
            null, null, null,
            null, null, null,
            null, null, null,
            null, null, null,
            null
        );
    }

    static getCopy(insurance: InsurancePricing): InsurancePricing {
        let insCopy: InsurancePricing = InsurancePricing.getBlank();
        insCopy.productBenefitId = insurance.productBenefitId;
        insCopy.productCode = insurance.productCode;
        insCopy.petSureProductCode = insurance.petSureProductCode;
        insCopy.routineCareAvailable = insurance.routineCareAvailable;
        insCopy.productName = insurance.productName;

        insCopy.priceFortnight = insurance.priceFortnight;
        insCopy.priceMonth = insurance.priceMonth;
        insCopy.priceYear = insurance.priceYear;

        insCopy.originalPriceFortnight = insurance.originalPriceFortnight;
        insCopy.originalPriceMonth = insurance.originalPriceMonth;
        insCopy.originalPriceYear = insurance.originalPriceYear;

        insCopy.routineCarePriceFortnight = insurance.routineCarePriceFortnight;
        insCopy.routineCarePriceMonth = insurance.routineCarePriceMonth;
        insCopy.routineCarePriceYear = insurance.routineCarePriceYear;

        insCopy.originalRoutineCarePriceFortnight = insurance.originalRoutineCarePriceFortnight;
        insCopy.originalRoutineCarePriceMonth = insurance.originalRoutineCarePriceMonth;
        insCopy.originalRoutineCarePriceYear = insurance.originalRoutineCarePriceYear;

        insCopy.seniorDiscountAmount = insurance.seniorDiscountAmount;
        insCopy.seniorDiscountAmountRoutineCare = insurance.seniorDiscountAmountRoutineCare;

        insCopy.multiPetDiscount = insurance.multiPetDiscount;
        insCopy.multiPetDiscountRoutineCare = insurance.multiPetDiscountRoutineCare;
        insCopy.multiPetDiscountCount = insurance.multiPetDiscountCount;

        insCopy.excess = insurance.excess;

        insCopy.displayOrder = insurance.displayOrder;
        insCopy.coolingOffDays = insurance.coolingOffDays;
        insCopy.minimumPetAgeForAccidentOnly = insurance.minimumPetAgeForAccidentOnly;
        insCopy.accidentBenefitCode = insurance.accidentBenefitCode;
        return insCopy;
    }

    static getCopyWithoutRoutineCare(insurance: InsurancePricing): InsurancePricing {
        let insCopy: InsurancePricing = InsurancePricing.getBlank();
        insCopy.productBenefitId = insurance.productBenefitId;
        insCopy.productCode = insurance.productCode;
        insCopy.petSureProductCode = insurance.petSureProductCode;
        insCopy.routineCareAvailable = insurance.routineCareAvailable;
        insCopy.productName = insurance.productName;

        insCopy.priceFortnight = insurance.priceFortnight;
        insCopy.priceMonth = insurance.priceMonth;
        insCopy.priceYear = insurance.priceYear;

        insCopy.originalPriceFortnight = insurance.originalPriceFortnight;
        insCopy.originalPriceMonth = insurance.originalPriceMonth;
        insCopy.originalPriceYear = insurance.originalPriceYear;

        insCopy.routineCarePriceFortnight = 0;
        insCopy.routineCarePriceMonth = 0;
        insCopy.routineCarePriceYear = 0;

        insCopy.originalRoutineCarePriceFortnight = 0;
        insCopy.originalRoutineCarePriceMonth = 0;
        insCopy.originalRoutineCarePriceYear = 0;

        insCopy.seniorDiscountAmount = insurance.seniorDiscountAmount;
        insCopy.seniorDiscountAmountRoutineCare = insurance.seniorDiscountAmountRoutineCare;

        insCopy.multiPetDiscount = insurance.multiPetDiscount;
        insCopy.multiPetDiscountRoutineCare = insurance.multiPetDiscountRoutineCare;
        insCopy.multiPetDiscountCount = insurance.multiPetDiscountCount;

        insCopy.excess = insurance.excess;

        insCopy.displayOrder = insurance.displayOrder;
        insCopy.coolingOffDays = insurance.coolingOffDays;
        insCopy.minimumPetAgeForAccidentOnly = insurance.minimumPetAgeForAccidentOnly;
        insCopy.accidentBenefitCode = insurance.accidentBenefitCode;
        return insCopy;
    }

    constructor(
        public productBenefitId: number,
        public productCode: string,
        public petSureProductCode: string,
        public routineCareAvailable: boolean,
        public productName: string,

        public priceFortnight: number,
        public priceMonth: number,
        public priceYear: number,

        public originalPriceFortnight: number,
        public originalPriceMonth: number,
        public originalPriceYear: number,

        public routineCarePriceFortnight: number,
        public routineCarePriceMonth: number,
        public routineCarePriceYear: number,

        public originalRoutineCarePriceFortnight: number,
        public originalRoutineCarePriceMonth: number,
        public originalRoutineCarePriceYear: number,

        public seniorDiscountAmount: number = 0,
        public seniorDiscountAmountRoutineCare: number = 0,

        public multiPetDiscount: number = 0,
        public multiPetDiscountRoutineCare: number = 0,
        public multiPetDiscountCount: number = 0,

        public excess: number = 0,

        public displayOrder: number = 0,
        public coolingOffDays: number = 30,
        public minimumPetAgeForAccidentOnly: number = 99,
        public accidentBenefitCode: string = '',
    ) {
    }

    mergeInsurancePricings(insurance: InsurancePricing) {
        this.productBenefitId = insurance.productBenefitId;
        this.productCode = insurance.productCode;
        this.petSureProductCode = insurance.petSureProductCode;
        this.routineCareAvailable = insurance.routineCareAvailable;
        this.productName = insurance.productName;

        this.priceFortnight = insurance.priceFortnight;
        this.priceMonth = insurance.priceMonth;
        this.priceYear = insurance.priceYear;

        this.originalPriceFortnight = insurance.originalPriceFortnight;
        this.originalPriceMonth = insurance.originalPriceMonth;
        this.originalPriceYear = insurance.originalPriceYear;

        this.routineCarePriceFortnight = insurance.routineCarePriceFortnight;
        this.routineCarePriceMonth = insurance.routineCarePriceMonth;
        this.routineCarePriceYear = insurance.routineCarePriceYear;

        this.originalRoutineCarePriceFortnight = insurance.originalRoutineCarePriceFortnight;
        this.originalRoutineCarePriceMonth = insurance.originalRoutineCarePriceMonth;
        this.originalRoutineCarePriceYear = insurance.originalRoutineCarePriceYear;

        this.seniorDiscountAmount = insurance.seniorDiscountAmount;
        this.seniorDiscountAmountRoutineCare = insurance.seniorDiscountAmountRoutineCare;

        this.multiPetDiscount = insurance.multiPetDiscount;
        this.multiPetDiscountRoutineCare = insurance.multiPetDiscountRoutineCare;
        this.multiPetDiscountCount = insurance.multiPetDiscountCount;

        this.excess = insurance.excess;

        this.displayOrder = insurance.displayOrder;
        this.coolingOffDays = insurance.coolingOffDays;
        this.minimumPetAgeForAccidentOnly = insurance.minimumPetAgeForAccidentOnly;
        this.accidentBenefitCode = insurance.accidentBenefitCode;
    }


    hasRoutineCare(): boolean {
        return this.routineCarePriceFortnight > 0;
    }

    getPrice(freq: Frequency, routine: boolean = null): number {
        let price = 0;
        switch (freq) {
            case Frequency.Fortnight:
                price = (routine && this.hasRoutineCare()) ?
                    this.routineCarePriceFortnight : this.priceFortnight;
                break;
            case Frequency.Month:
                price = (routine && this.hasRoutineCare()) ?
                    this.routineCarePriceMonth : this.priceMonth;
                break;
            case Frequency.Year:
                price = (routine && this.hasRoutineCare()) ?
                    this.routineCarePriceYear : this.priceYear;
                break;
            default:
                price = 0;
                break;
        }
        // discount is negative
        return price;
    }

    getOriginalPrice(freq: Frequency, routine: boolean = null): number {
        let price = 0;
        switch (freq) {
            case Frequency.Fortnight:
                price = ((routine && this.hasRoutineCare()) ?
                    this.originalRoutineCarePriceFortnight : this.originalPriceFortnight);
                break;
            case Frequency.Month:
                price = ((routine && this.hasRoutineCare()) ?
                    this.originalRoutineCarePriceMonth : this.originalPriceMonth);
                break;
            case Frequency.Year:
                price = ((routine && this.hasRoutineCare()) ?
                    this.originalRoutineCarePriceYear : this.originalPriceYear);
                break;
            default:
                price = 0;
                break;
        }
        return price;
    }

    getDiscountAmount(freq: Frequency, routine: boolean = null): number {
        if (this.hasDiscount(routine)) {
            let discount: number = this.getOriginalPrice(freq, routine) - this.getPrice(freq, routine);
            return discount > 0 ? discount : 0;
        }
        return 0;
    }

    hasDiscountNoRoutineCare(): boolean {
        return this.originalPriceYear > 0
            && this.getOriginalPrice(Frequency.Year, false) !== this.getPrice(Frequency.Year, false);
    }

    hasDiscountRoutineCare(): boolean {
        return this.originalRoutineCarePriceYear > 0
            && this.getOriginalPrice(Frequency.Year, true) !== this.getPrice(Frequency.Year, true);
    }

    hasDiscount(routine: boolean = null): boolean {
        return routine ? this.hasDiscountRoutineCare() : this.hasDiscountNoRoutineCare();
    }

    hasDiscountIfNoRoutine(): boolean {
        return this.hasDiscountRoutineCare() || this.hasDiscountNoRoutineCare();
    }

    getOriginalPriceIfNoRoutine(freq: Frequency, routine: boolean): number {
        if (routine && this.hasDiscount(true)) {
            return this.getOriginalPrice(freq, routine);
        } else {
            return this.getOriginalPrice(freq, false);
        }
    }

    convertPriceToFrequency(amount: number, freq: Frequency): number {
        let newValue = amount;
        switch (freq) {
            case Frequency.Fortnight:
                newValue = newValue / 26;
                break;
            case Frequency.Month:
                newValue = newValue / 12;
                break;
            default:
                break;
        }
        return newValue;
    }

    getSeniorDiscountAmount(freq: Frequency, routine: boolean): number {
        if (this.seniorDiscountAmount <= 0 && this.seniorDiscountAmountRoutineCare <= 0) {
            return 0;
        }
        let amountDiscount = this.seniorDiscountAmount;
        if (routine) {
            amountDiscount = this.seniorDiscountAmountRoutineCare;
        }
        return this.convertPriceToFrequency(amountDiscount, freq);
    }

    getMultipetDiscount(freq: Frequency, routine: boolean): number {
        if (this.multiPetDiscount <= 0 && this.multiPetDiscountRoutineCare <= 0) {
            return 0;
        }
        let amountDiscount = this.multiPetDiscount;
        if (routine) {
            amountDiscount = this.multiPetDiscountRoutineCare;
        }
        return this.convertPriceToFrequency(amountDiscount, freq);
    }
}
