import { HttpHeaders } from '@angular/common/http';
import { CONFIGURATION } from '../environments/environment';
import { Injectable } from "@angular/core";

@Injectable()
export class BaseService {

    protected headers: HttpHeaders;
    protected baseUrl: string;

    constructor() {
        this.headers = new HttpHeaders({'Content-Type': 'application/json'});
        this.baseUrl = CONFIGURATION.baseUrl;
    }

}
