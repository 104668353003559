import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { PaymentGatewayComponent } from './payment-gateway/payment-gateway.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { AboutYouComponent } from './about-you/about-you.component';
import { CompareComponent } from './compare/compare.component';
import { BuyComponent } from './buy/buy.component';

import { PaymentGatewayResolve } from './payment-gateway/payment-gateway.resolve';
import { AboutYouResolve } from './about-you/about-you.resolve';
import { ThankYouResolve } from './thank-you/thank-you.resolve';
import { PremiumResolve } from './compare/premium.resolve';
import { BuyRouteGuard } from './buy/buy-route.guard';

import { ApplicationService } from './services/application.service';
import { PromotionService } from './services/promotion.service';
import { DiscountService } from './services/discount.service';
import { PremiumService } from './services/premium.service';
import { BreedsService } from './services/breeds.service';
import { ReCAPTCHAService } from './services/recaptcha.service';
import { ClientService } from './services/client.service';
import { QuoteService } from './services/quote.service';

const appRoutes: Routes = [
    {
        path: '',
        component: AboutYouComponent,
        resolve: {
            premium: AboutYouResolve
        },
        data: { name: 'About' }
    },
    {
        path: 'compare',
        component: CompareComponent,
        resolve: {
            premium: PremiumResolve
        },
        data: { name: 'Compare' }
    },
    {
        path: 'buy',
        component: BuyComponent,
        canActivate: [BuyRouteGuard],
        data: { name: 'Buy' }
    },
    {
        path: 'payment-gateway',
        component: PaymentGatewayComponent,
        resolve: {
            insuredPets: PaymentGatewayResolve
        },
        data: {
            name: 'Payment gateway'
        }
    },
    {
        path: 'thank-you',
        component: ThankYouComponent,
        resolve: {
            insuredPets: ThankYouResolve
        },
        data: {
            name: 'Thank you',
            hasBefore: false
        }
    },
    {
        path: 'thank-you-s',
        component: ThankYouComponent,
        resolve: {
            insuredPets: ThankYouResolve
        },
        data: {
            name: 'Thank you',
            hasBefore: true
        }
    }
];

export const appRoutingProviders: any[] = [
    PaymentGatewayResolve,
    ApplicationService,
    PromotionService,
    AboutYouResolve,
    ThankYouResolve,
    DiscountService,
    PremiumResolve,
    PremiumService,
    BuyRouteGuard,
    BreedsService,
    ReCAPTCHAService,
    ClientService,
    QuoteService
];

export const routing: ModuleWithProviders<RouterModule> = RouterModule.forRoot(appRoutes);
