<div class="hidden-xs">
    <div class="container">
        <div class="row">
			<div class="col-sm-24 compare-page-title">
                <p *ngIf="theme.id==1">The products are sorted from lowest to highest price by default. Use the toggles on the left for other sorting options.<br>
                    No direct fees or charges means the price you pay is the same as the standard rates you would pay if you went directly to the insurer.
                </p>
                <p *ngIf="theme.id==2">The products are sorted from lowest to highest price by default. Use the toggles on the left for other sorting options.<br>
                    No direct fees or charges means the price you pay is the same as the standard rates you would pay if you went directly to the insurer.
                </p>
                <p *ngIf="theme.id==3">The products are sorted from lowest to highest price by default. Use the toggles on the left for other sorting options.<br>
                    No direct fees or charges means the price you pay is the same as the standard rates you would pay if you went directly to the insurer.    
                </p>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-8 col-md-5 z-10 shadow-bottom">
                <div class="compare-filter-desktop-panel compare-top-section-table" [style.height]="getRowsSize('compare-top-section-table')">
                    <div class="row">
                        <div class="col-xs-24">
                            <h4>{{visibleNb}} Product{{visibleNb > 1? 's' : ''}} found</h4>
                        </div>
                        <div class="col-xs-24">
                            <select class="form-control compare-sort-select" name="sortInsurances" [(ngModel)]="sort">
                                <option value="">Sort by</option>
                                <option *ngFor="let sorting of sortingTypes" [value]="sorting.val">{{sorting.txt}}</option>
                            </select>
                        </div>
                        <div class="col-xs-24">
                            <div class="dropdown">
                                <div class="dropdown-toggle adjust-view-btn" data-toggle="dropdown">
                                    Adjust view
                                    <span class="caret pull-right"></span>
                                </div>
                                <ul class="dropdown-menu keep-open filter-covered-field-list">
                                    <li *ngFor="let field of coverFieldIdList">
                                        <div *ngIf="field.index !== 'cover-field-clss-100'" class="checkbox">
                                            <label>
                                                <input type="checkbox" [(ngModel)]="field.activated"/>
                                                {{field.value}}
                                            </label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-xs-24 radio-group short-list-radio-group">
                            <div class="col-xs-24" [ngClass]="{ 'selected-radio': !shortListOnly }">
                                <label class="radio-inline radio-inline-right">
                                    View all
                                    <input type="radio" name="short-list-radio" class="short-list-radio-disable" [(ngModel)]="shortListOnly" [value]="false" (change)="scrollPosition = 0">
                                </label>
                            </div>
                            <div class="col-xs-24" [ngClass]="{ 'selected-radio': shortListOnly, 'disabled': (!shortListVisible) }">
                                <label class="radio-inline radio-inline-right">
                                    View shortlist
                                    <input type="radio" name="short-list-radio" class="short-list-radio-activate" [(ngModel)]="shortListOnly" [value]="true" (change)="scrollPosition = 0" [disabled]="!shortListVisible" [ngClass]="{ 'disabled': (!shortListVisible) }">
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row promotion-container-form">
                        <div class="col-xs-24" *ngIf="promoLoading" >
                            <div class="loading-container">
                                <div class="loading-container-table">
                                    <div>
                                        <span class="glyphicon glyphicon-refresh rotating"></span>
                                    </div>
                                    <div>
                                        Loading...
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-24" *ngIf="promoIsError && promoCode !== ''">
                            <div class="alert alert-danger">
                                Invalid offer code
                            </div>
                        </div>
                        <div class="col-xs-24 promotions-applied-container" *ngIf="promotionApplied">
                            <div class="promotions-applied-title">
                                Offer applied
                            </div>
                            <div class="promotions-applied-content">
                                <div class="promotion-item">
                                    <div class="promotion-title">
                                        <span class="glyphicon glyphicon-tag"></span>
                                        {{ user.promotion.offerTitle }}
                                    </div>
                                    <div class="promotion-desc">
                                        <a (click)="promotionTACOpen = true" class="tooltip-link btn link-btn">
                                            Terms and conditions
                                        </a>
                                    </div>
                                    <div class="promotion-overlay" *ngIf="promotionTACOpen">
                                        <div class="promotion-overlay-content">
                                            <div class="promotion-overlay-content-termsandcondition" [innerHTML]="user.promotion.termsAndConditions"></div>
                                            <span class="promotion-overlay-close" (click)="promotionTACOpen = false">X</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Originally:  *ngIf="promotionOnly && !isOnePremiumVisible" but promotionOnly does not exist in the component -->
            <div class="col-sm-15 col-sm-offset-1 col-md-18 col-md-offset-1 text-center" *ngIf="!isOnePremiumVisible">
                <div class="item-spacer-30"></div>
                <h4>
                    Unfortunately no special offers are currently available
                </h4>
            </div>
            <div class="col-sm-15 col-sm-offset-1 col-md-18 col-md-offset-1 text-center movable-table-container z-10" *ngIf="isOnePremiumVisible">
                <div class="move-control control-left"
                     (click)="updateScroll(-1)"
                     [hidden]="visibleNb < maxVisible"
                     [ngClass]="{ 'disable': (scrollPosition == 0)}">
                    <span class="glyphicon glyphicon-menu-left"></span>
                </div>
                <div class="move-control control-right"
                     (click)="updateScroll(1)"
                     [hidden]="visibleNb < maxVisible"
                     [ngClass]="{ 'disable': (scrollPosition == maxNb)}">
                    <span class="glyphicon glyphicon-menu-right"></span>
                </div>
                <div class="table-responsive desktop-compare-table">
                    <div class="shadow-bottom desktop-compare-table-shadow-container">
                        <table class="table-bordered movable-table compare-header-container-desktop compare-top-section-table"
                           [style.margin-left]="leftMargin + 'px'"
                           [style.height]="getRowsSize('compare-top-section-table')"
                           (change)="updateScroll(0)">
                            <tr>
                                <td *ngFor="let ins of insuranceCovers.getInsurances()
                                            | filterShortList:shortListOnly
                                            | sortInsurances:sort:theme.id;
                                            let i = index;
                                            let l = last;"
                                    class="insurancesDisplayed">
                                    {{ l ? updateScroll(0) : ''}}
                                    <div class="shortlist-toggle-btn" [ngClass]="{ 'shortlisted' : ins.shortListed }">
                                        <span *ngIf="ins.shortListed" (click)="ins.shortListed = false; shortListOnly = shortListOnly && shortListVisible">
                                            Shortlisted <span class="glyphicon glyphicon-remove pull-right"></span>
                                        </span>
                                        <span *ngIf="!ins.shortListed" (click)="ins.shortListed = true">
                                            Add to shortlist <span class="glyphicon glyphicon-plus pull-right"></span>
                                        </span>
                                    </div>
                                    <div class="row">
                                        <div class="col-xs-24 col-md-14">
                                            <img src="{{ins.brandLogo}}" alt="{{ins.insuranceName}}"/>
                                        </div>
                                        <div class="col-xs-24 col-md-9 col-lg-offset-1">
                                            <div class="compare-header-insurer-about" (click)="displayBrandDetails(ins)">
                                                <div class="compare-header-insurer-toggle-content" *ngIf="!brandDetailsLoading">
                                                    About brand
                                                    <span class="glyphicon glyphicon-info-sign"></span>
                                                </div>
                                                <div *ngIf="brandDetailsLoading" class="compare-head-insurer-loading">
                                                    <span class="glyphicon glyphicon-refresh rotating"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td *ngFor="let ins of insuranceCovers.getInsurances()
                                            | filterShortList:shortListOnly
                                            | sortInsurances:sort:theme.id;
                                            let i = index"
                                    class="compare-top-section-table-main-content">
                                    <div class="row">
                                        <div class="col-xs-24 col-sm-13 col-md-13">
                                            <h3>{{ins.coverName}}</h3>
                                        </div>
                                        <div class="col-xs-24 col-sm-11 col-md-11 text-right">
                                            <div>
                                                <h2>
                                                    <strong>
                                                        {{ins.getPrice(cover.frequency) | customCurrency:2:'$' }}
                                                    </strong>
                                                </h2>
                                            </div>
                                            <div class="dropdown price-freq-dropdown margin-0">
                                                <div class="dropdown-toggle" [id]="'price-dropdown-' + id" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                                    <small>
                                                        {{FREQUENCY[cover.frequency]}}ly
                                                        <span class="glyphicon glyphicon-menu-down"></span>
                                                    </small>
                                                </div>
                                                <ul class="dropdown-menu text-right" [attr.aria-labelledby]="'price-dropdown-' + id">
                                                    <li (click)="cover.frequency = FREQUENCY.Fortnight;">
                                                        Fortnightly
                                                    </li>
                                                    <li (click)="cover.frequency = FREQUENCY.Month;">
                                                        Monthly
                                                    </li>
                                                    <li (click)="cover.frequency = FREQUENCY.Year;">
                                                        Yearly
                                                    </li>
                                                </ul>
                                            </div>
                                            <div class="price-offer-details">
                                                <div *ngIf="ins.hasDiscountIfNoRoutine() && (user.promotion && user.promotion.offerTypeId === 3 && cover.frequency === FREQUENCY.Year)">
                                                    <small>
                                                        <s>
                                                            Was {{ins.getOriginalPriceIfNoRoutine(cover.frequency) | customCurrency:2:'$' }}
                                                        </s>
                                                    </small>
                                                </div>
                                                <div class="senior-discount-txt" *ngIf="ins.getSeniorDiscountAmount(cover.frequency) > 0">
                                                    <small>
                                                        Includes 10% Seniors Discount
                                                    </small>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td *ngFor="let ins of insuranceCovers.getInsurances()
                                            | filterShortList:shortListOnly
                                            | sortInsurances:sort:theme.id;
                                            let i = index">                                        
                                    <div class="main-content-bottom">
                                        <div class="row">
                                            <div class="col-xs-24 text-center">
                                                <div *ngIf="!user.promotion || user.promotion.petsurePromotionId <= 0">
                                                    No offer available
                                                </div>
                                                <div *ngIf="user.promotion.petsurePromotionId > 0">
                                                    <div *ngIf="ins.insurancePromotion && !promotionProductsLoading" class="insurance-promotion-description">
                                                        <span class="glyphicon glyphicon-tag"></span>
                                                        {{ins.insurancePromotion.discountName}} has been applied.
                                                    </div>
                                                    <div *ngIf="!ins.insurancePromotion && !promotionProductsLoading">
                                                        <span>
                                                            Offer not applicable
                                                        </span>
                                                    </div>
                                                    <div *ngIf="promotionProductsLoading">
                                                        <div class="loading-container">
                                                            <div class="loading-container-table">
                                                                <div>
                                                                    <span class="glyphicon glyphicon-refresh rotating"></span>
                                                                </div>
                                                                <div>
                                                                    Loading offer...
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td *ngFor="let ins of insuranceCovers.getInsurances()
                                            | filterShortList:shortListOnly
                                            | sortInsurances:sort:theme.id;
                                            let i = index">
                                    <div class="row" *ngIf="!loadingRoutineCare && ins.hasRoutineCare()">
                                        <div class="col-xs-24 col-sm-24 col-md-12 parent-relative">
                                            <div class="dropdown parent-relative">
                                                <div class="dropdown-toggle link-toggle"
                                                     data-toggle="dropdown"
                                                     aria-haspopup="true"
                                                     aria-expanded="true">
                                                    <div class="col-xs-16 col-sm-15 col-sm-offset-5 col-md-19 col-md-offset-0">
                                                        <div class="optional-routine-care">Optional Routine Care</div>
                                                    </div>
                                                    <div class="col-xs-8 col-sm-2 col-md-5">
                                                        <div class="optional-routine-care-help">
                                                            <span class="glyphicon glyphicon-menu-down"></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="dropdown-menu coloured-drop-menu column-sized-popup"
                                                    [style.margin-left]="(((columnSize * i) - (scrollPosition * columnSize)) + (columnSize / 20) + 5) + 'px'">
                                                    <h4>
                                                        Optional Routine Care
                                                    </h4>
                                                    <p>
                                                        <strong>
                                                            Routine Care Stated Benefits:<br/>
                                                            $50 annual benefit for ONE of the following:
                                                        </strong>
                                                    </p>
                                                    <ul class="no-style">
                                                        <li>De-sexing</li>
                                                        <li>Micro-chipping</li>
                                                        <li>Heartworm preventative medication</li>
                                                        <li>Teeth cleaning</li>
                                                        <li>Council registration fees</li>
                                                        <li>Alternative therapies</li>
                                                        <li>Prescription diets</li>
                                                        <li>Dewclaw removal</li>
                                                        <li>Cremation or burial</li>
                                                        <li>
                                                            Behavioural therapy (by a qualified animal behavioural therapist
                                                            of vet)
                                                        </li>
                                                        <li>Dental treatment</li>
                                                        <li>Obedience training (at a recognised training facility)</li>
                                                        <li>Flea/Tick/Worm control</li>
                                                        <li>Vaccinations or Health Check</li>
                                                    </ul>
                                                    <p>
                                                        <strong>
                                                            PLUS annual benefit of ALL of the following:
                                                        </strong>
                                                    </p>
                                                    <ul class="no-style">
                                                        <li>
                                                            $15 - Heartworm test of Blood screen
                                                        </li>
                                                        <li>
                                                            $15 - FeLv/Fiv test or urinalysis
                                                        </li>
                                                    </ul>
                                                    <h4>
                                                        <strong>
                                                            AnnualPremium = $60<br/>
                                                            Maximum benefit = $80
                                                        </strong>
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xs-24 col-sm-24 col-md-12">
                                            <div class="btn-group routine-care-radio-group" role="group" aria-label="Basic example">
                                                <button type="button" class="btn radio-btn routine-care-radio-btn-yes" tabindex="-1"
                                                        (click)="updateRoutineCare(true)"
                                                        [ngClass]="{ 'active': ins.routineCareSelected}">
                                                    Yes
                                                </button>
                                                <button type="button" class="btn radio-btn routine-care-radio-btn-no" tabindex="-1"
                                                        (click)="updateRoutineCare(false)"
                                                        [ngClass]="{ 'active': !ins.routineCareSelected}">
                                                    No
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="!loadingRoutineCare && !ins.hasRoutineCare()">
                                        Optional Routine Care is not available on this product
                                    </div>
                                    <div *ngIf="loadingRoutineCare">
                                        <div class="loading-container">
                                            <div class="loading-container-table">
                                                <div>
                                                    <span class="glyphicon glyphicon-refresh rotating"></span>
                                                </div>
                                                <div>
                                                    Loading routine care...
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td *ngFor="let ins of insuranceCovers.getInsurances()
                                            | filterShortList:shortListOnly
                                            | sortInsurances:sort:theme.id;
                                            let i = index">
                                    <div class="row">
                                        <div class="col-xs-24" *ngIf="excessLoading" >
                                            <div class="loading-container">
                                                <div class="loading-container-table">
                                                    <div>
                                                        <span class="glyphicon glyphicon-refresh rotating"></span>
                                                    </div>
                                                    <div>
                                                        Loading excess...
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="excess-wrapper row">
                                        <div class="col-xs-24 col-sm-24 col-md-24" *ngIf="!excessLoading">
                                            
                                                <span class="excess col-xs-18-excess">Excess
                                                    <a href="javascript:void(0);"   tooltipPlacement="right" tabindex="-1"
                                                    [tooltip]="'This is a contribution you\'re required to pay when you make a claim. Excess applies per condition per policy period.'">
                                                     <span class="help-icon-excess"></span>
                                                 </a>
                                                </span>
                                                <div class="btn-group excess-radio-group" role="group" aria-label="Basic example">
                                                    <button *ngFor="let excessOption of ins.excessOptions"
                                                            type="button" class="btn radio-btn excess-radio-btn" tabindex="-1"
                                                            (click)="applyExcess(ins, excessOption)"
                                                            [ngClass]="{ 'active': (ins.excessSelected === excessOption) }">
                                                        ${{excessOption}}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                       
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td *ngFor="let ins of insuranceCovers.getInsurances()
                                            | filterShortList:shortListOnly
                                            | sortInsurances:sort:theme.id;
                                            let i = index">
                                    <div class="row">
                                        <div class="col-xs-24 text-left" >                                     
                                            <a href="{{ins.policyBookletLink}}" target="_blank" tabindex="-1"> 
                                                <span class="glyphicon glyphicon-file"></span>Policy Booklet 
                                            </a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr class="top-apply-container-desktop">
                                <td *ngFor="let ins of insuranceCovers.getInsurances()
                                            | filterShortList:shortListOnly
                                            | sortInsurances:sort:theme.id;
                                            let i = index">
                                    <button type="button" class="btn submit-btn width-100" *ngIf="!pddoSelectedDetailsLoading" (click)="displayPDDOSelectedDetails(ins, false)" tabindex="-1">Choose</button>
                                    <div class="loading-container" *ngIf="pddoSelectedDetailsLoading">
                                        <div class="loading-container-table">
                                            <div>
                                                <span class="glyphicon glyphicon-refresh rotating"></span>
                                            </div>
                                            <div>
                                                Loading...
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="row insurance-fields-main-container" *ngIf="isOnePremiumVisible">
            <div class="insurance-fields-container" [style.margin-right]="(-scrollSize) + 'px'" (scroll)="updateScrollDownBtn()">
                <div class="col-sm-8 col-md-5 padding-0">
                    <table class="table-bordered insurance-fields-description-table">
                        <tr class="{{'insurance-fields-list-desktop-title'}}"
                            [style.height]="getRowsSize('insurance-fields-list-desktop-title')">
                            <th>
                                <h4>What's covered</h4>
                            </th>
                        </tr>
                        <tr *ngFor="let field of coverFieldIdList"
                            [hidden]="!field.activated"
                            class="{{'insurance-fields-list-desktop-'+field.index}}"
                            [style.height]="getRowsSize('insurance-fields-list-desktop-'+field.index)">
                            <td [style.height]="getRowsSize('insurance-fields-list-desktop-'+field.index)" style="padding-top: 20px; padding-bottom: 15px;">
                                <div class="field-description-wrapper">
                                    <div class="field-description {{'field-description-id-' + insuranceCovers.getCoverFields().get(field.index).id}}">
                                        <span><strong>{{insuranceCovers.getCoverFields().get(field.index).name}}</strong></span>
                                    </div>
                                    <div class="field-description-tooltip" *ngIf="insuranceCovers.getCoverFields().get(field.index).helpText">
                                        <a href="javascript:void(0);" class="tooltip-link" tooltipPlacement="right" tabindex="-1"
                                              tooltip="{{insuranceCovers.getCoverFields().get(field.index).helpText}}">
                                            <span class="help-icon"></span>
                                        </a>
                                    </div>
                                </div>

                            </td>
                        </tr>
                    </table>
                </div>
                <div class="col-sm-15 col-sm-offset-1 col-md-18 col-md-offset-1 movable-table-container">
                    <div class="table-responsive desktop-compare-table">
                        <table class="table-bordered movable-table insurance-fields-value-table"
                               [style.margin-left]="leftMargin + 'px'">
                            <tr class="{{'insurance-fields-list-desktop-title'}}"
                                [style.height]="getRowsSize('insurance-fields-list-desktop-title')">
                                <th *ngFor="let ins of insuranceCovers.getInsurances()
                                        | filterShortList:shortListOnly
                                        | sortInsurances:sort:theme.id;
                                        let i = index">
                                    <h4>What's covered</h4>
                                </th>
                            </tr>
                            <tr *ngFor="let field of coverFieldIdList" [hidden]="!field.activated"
                                class="{{'insurance-fields-list-desktop-'+field.index}}"
                                [style.height]="getRowsSize('insurance-fields-list-desktop-'+field.index)">
                                <td *ngFor="let ins of insuranceCovers.getInsurances()
                                        | filterShortList:shortListOnly
                                        | sortInsurances:sort:theme.id;
                                        let i = index"
                                    class="{{'insurance-field-id-' + ins.fields.get(field.index).id}}"
                                    [innerHTML]="ins.fields.get(field.index).html">
                                </td>
                            </tr>
                            <tfoot>
                                <tr>
                                    <td *ngFor="let ins of insuranceCovers.getInsurances()
                                            | filterShortList:shortListOnly
                                            | sortInsurances:sort:theme.id;
                                            let i = index">
                                        <button type="button" class="btn submit-btn width-100" *ngIf="!pddoSelectedDetailsLoading" (click)="displayPDDOSelectedDetails(ins, false)" tabindex="-1">Choose</button>
                                        <div class="loading-container" *ngIf="pddoSelectedDetailsLoading">
                                            <div class="loading-container-table">
                                                <div>
                                                    <span class="glyphicon glyphicon-refresh rotating"></span>
                                                </div>
                                                <div>
                                                    Loading...
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="row product-and-pricing-information">
            <div class="col-sm-8 col-md-5">
                <a class="btn btn-lg action-btn width-100 previous-btn" [routerLink]="['/']">
                    <span class="glyphicon glyphicon-menu-left"></span>
                    Previous step
                </a>
            </div>
            <div class="col-sm-15 col-sm-offset-1 col-md-18 col-md-offset-1">
                <div class="scroll-down-btn" *ngIf="scrollDownBtn && isOnePremiumVisible" [style.left]="scrollDownBtnPositionLeft + 'px'" >
                    Scroll down
                    <span class="glyphicon glyphicon-menu-down"></span>
                </div>
                <p>
                  <sup>1</sup> Unless specified, all benefit amounts are annual limits. 
                </p>
                    <p>
                            <sup>2</sup> Lifetime cover for as long as we continue to offer the product, provided there are no breaks in cover, and subject to the terms and conditions of the policy.
                        </p>
                        <p>
                                <sup>3</sup> Paid as part of consultation and/or treatment of an eligible vet bill and may be paid as part of any applicable annual condition limit or sub-limit.
                            </p>
                            <p>
                                    <sup>4</sup>  Please consult the relevant Policy Booklet for further information, including a full description of benefits, as well as exclusions.
                                </p>
                                <p>
                                    <sup>5</sup> If a condition exists or occurs before the commencement date of the first policy period or within an applicable waiting period, it may be excluded from cover as a pre-existing condition. Some conditions are eligible for review after an 18-month period – refer to your Policy Booklet for more information regarding pre-existing conditions.
                                </p>
                                <p>
                                    <sup>6</sup> Hereditary conditions and congenital conditions will not be considered to be a pre-existing condition if they have not shown noticeable signs, symptoms or an abnormality at any time before the commencement date of the first policy period or during any applicable waiting period.
                                </p>
                                <p>
                                    <sup>7</sup> A Chronic Condition is long-lasting or recurring in its effects and affects (or is present in) a Pet over a period greater than three months in total. Chronic Conditions may also be intermittent or managed with medication and/or prescription diets with intervals of remission. 
                                </p>
                                <p>
                                    Product information up-to-date as at {{ effectiveDate }}. This page contains general information and there may
                                    be additional features to some products that are not compared. Please refer to the Product Disclosure Statement for full information on cover.
                               </p>
            </div>
        </div>
    </div>
</div>
<div class="hidden-sm hidden-md hidden-lg">
    <div class="container">        
        <div class="row">
			<div class="col-sm-20 compare-page-title">
                <p class="xs" *ngIf="theme.id==1">The products are sorted from lowest to highest price by default. You can ‘Filter results’ below for other sorting options.<br>
                    No direct fees or charges means the price you pay is the same as the standard rates you would pay if you went directly to the insurer.
                </p>
                <p class="xs" *ngIf="theme.id==2">The products are sorted from lowest to highest price by default. You can ‘Filter results’ below for other sorting options.<br>
                    No direct fees or charges means the price you pay is the same as the standard rates you would pay if you went directly to the insurer.
                </p>
                <p class="xs" *ngIf="theme.id==3">The products are sorted from lowest to highest price by default. You can ‘Filter results’ below for other sorting options.<br>
                    No direct fees or charges means the price you pay is the same as the standard rates you would pay if you went directly to the insurer.    
                </p>
            </div>
        </div>
        <div class="compare-filter-btn" [ngClass]="{ 'filter-open': mobileFilterPanelOpen }" (click)="mobileFilterPanelOpen = !mobileFilterPanelOpen">
            <div class="row">
                <div class="col-xs-20">
                    Filter results
                </div>
                <div class="col-xs-4">
                    <span class="glyphicon glyphicon-search"></span>
                </div>
            </div>
        </div>
        <div class="mobile-promotion-container">
            <div class="promotion-container-form">
                <div class="row">
                    <div class="col-xs-24" *ngIf="promoLoading" >
                        <div class="loading-container">
                            <div class="loading-container-table">
                                <div>
                                    <span class="glyphicon glyphicon-refresh rotating"></span>
                                </div>
                                <div>
                                    Loading...
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-24 promotions-applied-container" *ngIf="promotionApplied">
                        <div class="promotions-applied-title">
                            Offer applied
                        </div>
                        <div class="promotions-applied-content">
                            <div class="promotion-item">
                                <div class="promotion-title">
                                    <span class="glyphicon glyphicon-tag"></span>
                                    {{ user.promotion.offerTitle }}
                                </div>
                                <div class="promotion-desc">
                                    <a (click)="promotionTACOpen = true" class="tooltip-link btn link-btn">
                                        Terms and conditions
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="promotion-overlay" *ngIf="promotionTACOpen">
                            <div class="promotion-overlay-content">
                                <div [innerHTML]="user.promotion.termsAndConditions"></div>
                                <span class="promotion-overlay-close" (click)="promotionTACOpen = false">X</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr/>
    <div class="compare-filter-mobile-panel" *ngIf="mobileFilterPanelOpen">
        <div class="container">
            <div class="row">
                <div class="col-xs-24">
                    <select class="form-control compare-sort-select" name="sortInsurances" [(ngModel)]="sort">
                        <option value="">Sort by</option>
                        <option *ngFor="let sorting of sortingTypes" [value]="sorting.val">{{sorting.txt}}</option>
                    </select>
                </div>
                <div class="col-xs-24 radio-group">
                    <div class="col-xs-12" [ngClass]="{ 'selected-radio': !shortListOnly }">
                        <label class="radio-inline radio-inline-right">
                            View all
                            <input type="radio" name="short-list-radio-mobile" class="short-list-radio-disable" [(ngModel)]="shortListOnly" [value]="false" (change)="scrollPosition = 0">
                        </label>
                    </div>
                    <div class="col-xs-12" [ngClass]="{ 'selected-radio': shortListOnly, 'disabled': (!shortListVisible) }">
                        <label class="radio-inline radio-inline-right">
                            View shortlist
                            <input type="radio" name="short-list-radio-mobile" class="short-list-radio-activate" [(ngModel)]="shortListOnly" [value]="true" (change)="scrollPosition = 0" [disabled]="!shortListVisible" [ngClass]="{ 'disabled': (!shortListVisible) }">
                        </label>
                    </div>
                </div>
                <div class="col-xs-24">
                    <button class="btn update-search-btn width-100" type="button" (click)="mobileFilterPanelOpen = !mobileFilterPanelOpen">
                        UPDATE SEARCH
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="compare-list-mobile" *ngIf="!mobileFilterPanelOpen">
        <table class="table-bordered col-xs-24"
               *ngFor="let ins of insuranceCovers.getInsurances()
                            | filterShortList:shortListOnly
                            | sortInsurances:sort:theme.id;
                            let i = index;">
            <tr class="compare-list-mobile-header">
                <td colspan="3" class="col-xs-20">
                    <img src="{{ins.brandLogo}}" alt="{{ins.insuranceName}}"/>
                </td>
                <td colspan="1" class="col-xs-4 padding-0-xs">
                    <table>
                        <tr>
                            <td class="padding-0-xs">
                                <div class="shortlist-toggle-btn" [ngClass]="{ 'shortlisted' : ins.shortListed }">
                                    <span *ngIf="ins.shortListed" (click)="ins.shortListed = false; shortListOnly = shortListOnly && shortListVisible">
                                        <span class="col-xs-16">
                                            Shortlisted
                                        </span>
                                        <span class="col-xs-8">
                                            <span class="glyphicon glyphicon-remove"></span>
                                        </span>
                                    </span>
                                    <span *ngIf="!ins.shortListed" (click)="ins.shortListed = true">
                                        <span class="col-xs-16">
                                            Add to shortlist
                                        </span>
                                        <span class="col-xs-8">
                                            <span class="glyphicon glyphicon-plus"></span>
                                        </span>
                                    </span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="padding-0-xs">
                                <div class="compare-header-insurer-about" (click)="displayBrandDetails(ins)">
                                    <div class="compare-header-insurer-toggle-content" *ngIf="!brandDetailsLoading">
                                        About insurer
                                        <span class="glyphicon glyphicon-info-sign"></span>
                                    </div>
                                    <div *ngIf="brandDetailsLoading" class="compare-head-insurer-loading">
                                        <span class="glyphicon glyphicon-refresh rotating"></span>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr class="compare-insurer-mobile-pricing">
                <td colspan="4" class="col-xs-24">
                    <div class="col-xs-12">
                        <h3>{{ins.coverName}}</h3>
                        <div *ngIf="!user.promotion || user.promotion.petsurePromotionId <= 0">
                            No offer available
                        </div>
                        <div *ngIf="user.promotion.petsurePromotionId > 0">
                            <div *ngIf="ins.insurancePromotion && !promotionProductsLoading" class="insurance-promotion-description">
                                <span class="glyphicon glyphicon-tag"></span>
                                {{ins.insurancePromotion.discountName}} has been applied.
                            </div>
                            <div *ngIf="!ins.insurancePromotion && !promotionProductsLoading">
                                <span>
                                    Offer not applicable
                                </span>
                            </div>
                            <div *ngIf="promotionProductsLoading" >
                                <div class="loading-container">
                                    <div class="loading-container-table">
                                        <div>
                                            <span class="glyphicon glyphicon-refresh rotating"></span>
                                        </div>
                                        <div>
                                            Loading offer...
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12 text-right">
                        <div>
                            <h2>
                                {{ins.getPrice(cover.frequency) | customCurrency:2:'$' }}
                            </h2>
                        </div>
                        <div>
                            <span   class="price-freq first-freq"
                                    (click)="cover.frequency = FREQUENCY.Fortnight"
                                    [ngClass]="{ 'active': cover.frequency === FREQUENCY.Fortnight }">
                                Fort
                            </span>
                            <span   class="price-freq"
                                    (click)="cover.frequency = FREQUENCY.Month"
                                    [ngClass]="{ 'active': cover.frequency === FREQUENCY.Month }">
                                Month
                            </span>
                            <span   class="price-freq"
                                    (click)="cover.frequency = FREQUENCY.Year"
                                    [ngClass]="{ 'active': cover.frequency === FREQUENCY.Year }">
                                Year
                            </span>
                        </div>
                        <div *ngIf="ins.hasDiscountIfNoRoutine() && (user.promotion && user.promotion.offerTypeId === 3 && cover.frequency === FREQUENCY.Year)">
                            <s>
                                Was {{ins.getOriginalPriceIfNoRoutine(cover.frequency) | customCurrency:2:'$' }}
                            </s>
                        </div>
                        <div class="senior-discount-txt" *ngIf="ins.getSeniorDiscountAmount(cover.frequency) > 0">
                            <small>
                                Includes 10% Seniors Discount
                            </small>
                        </div>
                    </div>
                </td>
            </tr>            
            <tr>
                <td colspan="4" class="col-xs-24">
                    <div *ngIf="excessLoading" >
                        <div class="loading-container">
                            <div class="loading-container-table">
                                <div>
                                    <span class="glyphicon glyphicon-refresh rotating"></span>
                                </div>
                                <div>
                                    Loading excess...
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-12" *ngIf="!excessLoading">
                        <div class="excess-wrapper">
                            <span class="excess">Excess</span>
                            <a href="javascript:void(0);" class="tooltip-link" tooltipPlacement="top" tabindex="-1"
                               [tooltip]="'Excess is deducted per condition, per policy period*.'">
                                <span class="help-icon"></span>
                            </a>
                        </div>
                    </div>
                    <div class="col-xs-12 text-right" *ngIf="!excessLoading">
                        <div class="btn-group excess-radio-group" role="group" aria-label="Basic example">
                            <button *ngFor="let excessOption of ins.excessOptions"
                                    type="button" class="btn radio-btn excess-radio-btn" tabindex="-1"
                                    (click)="applyExcess(ins, excessOption)"
                                    [ngClass]="{ 'active': (ins.excessSelected === excessOption) }">
                                ${{excessOption}}
                            </button>
                        </div>
                    </div>
                </td>
            </tr>
            <tr class="compare-insurer-mobile-optional-routine-care">
                <td colspan="4" class="col-xs-24">
                    <div class="row relative" *ngIf="!loadingRoutineCare && ins.hasRoutineCare()">
                        <div class="col-xs-14 parent-relative">
                            <div class="dropdown parent-relative">
                                <div class="dropdown-toggle link-toggle"
                                     data-toggle="dropdown"
                                     aria-haspopup="true"
                                     aria-expanded="true">
                                    <div class="optional-routine-care-wrapper">
                                        <div class="optional-routine-care">Optional Routine Care</div>
                                        <div class="optional-routine-care-tooltip">
                                            <span class="glyphicon glyphicon-menu-down"></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="dropdown-menu coloured-drop-menu">
                                    <h4>
                                        Optional Routine Care
                                    </h4>
                                    <p>
                                        <strong>
                                            Routine Care Stated Benefits:<br/>
                                            $50 annual benefit for ONE of the following:
                                        </strong>
                                    </p>
                                    <ul class="no-style">
                                        <li>De-sexing</li>
                                        <li>Micro-chipping</li>
                                        <li>Heartworm preventative medication</li>
                                        <li>Teeth cleaning</li>
                                        <li>Council registration fees</li>
                                        <li>Alternative therapies</li>
                                        <li>Prescription diets</li>
                                        <li>Dewclaw removal</li>
                                        <li>Cremation or burial</li>
                                        <li>
                                            Behavioural therapy (by a qualified animal behavioural therapist
                                            of vet)
                                        </li>
                                        <li>Dental treatment</li>
                                        <li>Obedience training (at a recognised training facility)</li>
                                        <li>Flea/Tick/Worm control</li>
                                        <li>Vaccinations or Health Check</li>
                                    </ul>
                                    <p>
                                        <strong>
                                            PLUS annual benefit of ALL of the following:
                                        </strong>
                                    </p>
                                    <ul class="no-style">
                                        <li>
                                            $15 - Heartworm test of Blood screen
                                        </li>
                                        <li>
                                            $15 - FeLv/Fiv test or urinalysis
                                        </li>
                                    </ul>
                                    <h4>
                                        <strong>
                                            AnnualPremium = $60;<br/>
                                            Maximum benefit = $80
                                        </strong>
                                    </h4>
                                </div>
                            </div>
                        </div>
                        <div class="col-xs-10 text-right">
                            <div class="btn-group routine-care-radio-group" role="group" aria-label="Basic example">
                                <button type="button" class="btn radio-btn routine-care-radio-btn-yes"
                                        (click)="updateRoutineCare(true)"
                                        [ngClass]="{ 'active': ins.routineCareSelected}">
                                    Yes
                                </button>
                                <button type="button" class="btn radio-btn routine-care-radio-btn-no"
                                        (click)="updateRoutineCare(false)"
                                        [ngClass]="{ 'active': !ins.routineCareSelected}">
                                    No
                                </button>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="!loadingRoutineCare && !ins.hasRoutineCare()">
                        Optional Routine Care is not available on this product
                    </div>
                    <div *ngIf="loadingRoutineCare">
                        <div class="loading-container">
                            <div class="loading-container-table">
                                <div>
                                    <span class="glyphicon glyphicon-refresh rotating"></span>
                                </div>
                                <div>
                                    Loading routine care...
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr class="compare-insurer-mobile-buttons">
                <td colspan="4" class="col-xs-24">                                
                    <a href="{{ins.policyBookletLink}}" target="_blank" tabindex="-1"> 
                            <span class="glyphicon glyphicon-file"></span>Policy Booklet 
                    </a>                    
                </td>
            </tr>
            <tr class="compare-insurer-mobile-buttons">
                <td colspan="4" class="col-xs-24">
                    <div class="row">
                        <div class="col-xs-12">
                            <button type="button" class="btn action-btn open-btn-mobile width-100"
                                    [ngClass]="{ 'open' : mobilePanelOpen === 'mobile-panel-details-' + i}"
                                    (click)="openMobilePanel(i)">
                                Show Details
                                <span class="glyphicon glyphicon-menu-down"></span>
                            </button>
                        </div>
                        <div class="col-xs-12">
                            <button type="button" class="btn submit-btn width-100" *ngIf="!pddoSelectedDetailsLoading" (click)="displayPDDOSelectedDetails(ins, true)" tabindex="-1">Choose</button>
                            <div class="loading-container" *ngIf="pddoSelectedDetailsLoading">
                                <div class="loading-container-table">
                                    <div>
                                        <span class="glyphicon glyphicon-refresh rotating"></span>
                                    </div>
                                    <div>
                                        Loading...
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
            <tr [hidden]="mobilePanelOpen !== 'mobile-panel-details-' + i">
                <td colspan="4" class="col-xs-24 compare-details-fields-mobile">
                    <div>
                        <table class="col-xs-24">
                            <tr *ngFor="let field of coverFieldIdList">
                                <td class="col-xs-12">
                                    {{insuranceCovers.getCoverFields().get(field.index).name}}
                                    <div class="field-description-tooltip" *ngIf="insuranceCovers.getCoverFields().get(field.index).helpText">
                                        <a href="javascript:void(0);" class="tooltip-link" tooltipPlacement="right"
                                           tooltip="{{insuranceCovers.getCoverFields().get(field.index).helpText}}">
                                            <span class="help-icon"></span>
                                        </a>
                                    </div>
                                </td>
                                <td class="col-xs-12 {{'insurance-field-id-' + ins.fields.get(field.index).id}}"
                                    [innerHTML]="ins.fields.get(field.index).html"></td>
                            </tr>
                        </table>
                    </div>
                </td>
            </tr>
        </table>
        <div class="item-spacer-30 clearfix"></div>
        <div class="item-spacer-30 clearfix"></div>
        <div>
            <p>
                <sup>1</sup> Unless specified, all benefit amounts are annual limits. 
              </p>
                  <p>
                          <sup>2</sup> Provided there's no break in cover and subject to the terms and conditions of the renewal policy.
                      </p>
                      <p>
                              <sup>3</sup> Paid as part of consultation and/or treatment of an eligible vet bill and may be paid as part of any applicable annual condition limit or sub-limit.
                          </p>
                          <p>
                                  <sup>4</sup>  Please consult the relevant Policy Booklet for further information, including a full description of benefits, as well as exclusions.
                              </p>
                              <p>
                                  <sup>5</sup> If a condition exists or occurs before the commencement date of the first policy period or within an applicable waiting period, it may be excluded from cover as a pre-existing condition. Some conditions are eligible for review after an 18-month period – refer to your Policy Booklet for more information regarding pre-existing conditions.
                              </p>
                              <p>
                                  <sup>6</sup> Hereditary conditions and congenital conditions will not be considered to be a pre-existing condition if they have not shown noticeable signs, symptoms or an abnormality at any time before the commencement date of the first policy period or during any applicable waiting period.
                              </p>
                              <p>
                                <sup>7</sup> A Chronic Condition is long-lasting or recurring in its effects and affects (or is present in) a pet over a period greater than three months in total. Chronic Conditions may also be intermittent or managed with medication and/or prescription diets with intervals of remission. 
                            </p>
            <p>
                Product information up-to-date as at {{ effectiveDate }}. This page contains general information and there may
                be additional features to some products that are not compared. Please refer to the Product Disclosure Statement for full information on cover.
           </p>
        </div>
        <div class="item-spacer-30 clearfix"></div>
        <div class="col-xs-20 col-xs-offset-2">
            <a class="btn btn-lg action-btn width-100 previous-btn" [routerLink]="['/']">
                <span class="glyphicon glyphicon-menu-left"></span>
                Previous step
            </a>
        </div>
        <div class="item-spacer-20 clearfix"></div>
        <div class="item-spacer-20 clearfix"></div>
    </div>
</div>
<div *ngIf="showBrandDetails" class="custom-popup-windows" [ngClass]="{ 'activated': showBrandDetails }">
    <div class="custom-popup-shadow" (click)="showBrandDetails = false"></div>
    <div class="custom-popup-container">
        <div class="custom-popup-header">
            <div class="custom-popup-close-btn" (click)="showBrandDetails = false">
                Close
                <span class="glyphicon glyphicon-remove"></span>
            </div>
        </div>
        <div class="custom-popup-content">
            <div class="custom-popup-img-container">
                <img src="{{brandDetails.brandLogoLarge}}" alt="{{brandDetails.brandName}}"/>
            </div>
            <div class="brand-detail-content" [innerHTML]="brandDetails.brandContent"></div>
        </div>
    </div>
</div>
<div *ngIf="showPDDOSelectedDetails" class="custom-popup-windows pddo-popup-window" [ngClass]="{ 'activated': showPDDOSelectedDetails }">
    <div class="custom-popup-shadow" (click)="applyLoading ? false : showPDDOSelectedDetails = false"></div>
    <div class="custom-popup-container" [ngStyle]="{'top':(isMobileView ? mobileTop + 'px' : '10%'), 'position':(isMobileView ? 'relative' : 'sticky')}">
        <div class="custom-popup-header">
            <div class="custom-popup-close-btn" (click)="applyLoading ? false : showPDDOSelectedDetails = false">
                Close
                <span class="glyphicon glyphicon-remove"></span>
            </div>
        </div>
        <div class="custom-popup-content">
            <div class="brand-detail-content pddo">
                <div class="pddo-title">
                    <p><b>{{pddoSelectedDetails.title}}</b></p>
                </div>
                <div class="pddo-content">
                    <!-- <div class="checkbox">
                        <label class="control-label checkbox-double-size" [ngClass]="{ 'check-required': !isFirstTermsAccepted && btnContinueClicked }">
                            <input type="checkbox" [checked]="isFirstTermsAccepted" (change)="isFirstTermsAccepted = !isFirstTermsAccepted"/>
                            {{pddoSelectedDetails.firstTerms}}
                        </label>
                    </div> -->
                    <!-- <div class="item-spacer-10 clearfix"></div> -->
                    
                    <ul [innerHTML]="pddoSelectedDetails.benefits">
                     </ul>   
                     <div>
                        <p><b>You are comfortable and agree that you can afford to pay:</b></p>
                    </div>                  
                       
                        <ul>
                        <li>the applicable premium, which will increase each year;</li>
                        <li>the vet invoice in full and upfront before submitting a claim for eligible vet expenses unless you use GapOnly®, in which case you will only need to pay the gap upfront;
                            <span class="tooltip-link">
                                <span class="help-icon-excess"></span>
                             </span>
                             <span class="tool-tip">
                                <p>The ‘gap’ means the difference between the vet’s invoice for eligible expenses and the claim benefit calculated under the policy terms and conditions, if any.</p>                               
                            </span>                          
                          
                        </li>
                        <li>any vet expenses above the accepted claim amount. This includes the per condition excess (if any) and any amount above the benefit percentage, annual benefit limit and any applicable sub limits that may apply for some conditions, treatments or benefits as outlined in the table.</li>
                    </ul>    
                                

                    <div class="item-spacer-10 clearfix"></div>     

                    <p>
                        <b>Lastly, you acknowledge that this product does not provide cover for chronic pre-existing conditions or for general exclusions listed in the PDS</b>
                        <span class="tooltip-link">
                           <span class="help-icon-excess"></span>
                        </span>
                        <span class="tool-tip">
                        <span>Pre-Existing Conditions <br/></span><br/>
                        <span>A pre-existing condition is:<br/></span>
                        <span>A condition that existed or occurred prior to the commencement date of your first policy period or within any applicable waiting period.<br/></span>
                        <span>A condition that you or your vet were aware of, or a reasonable person in your circumstances would have been aware of.<br/></span>
                        <span>This is irrespective of whether the underlying or causative condition was diagnosed at the time.<br/></span>                       
                        <span>Whether it is a pre-existing condition will depend on its nature and experience. If your pet has a temporary condition that has not existed, occurred or shown noticeable signs, symptoms or an abnormality in the 18-month period immediately prior to your claim treatment date, it will no longer be excluded from cover as a pre-existing condition. Chronic Conditions and several other specified Conditions that are pre-existing conditions cannot fall within this category. For more information please refer to the relevant PDS.</span>
                    <br/> </span>
                        </p>                   
               
                    <!-- <p>{{pddoSelectedDetails.note}}</p> -->
                    <!-- <div class="checkbox">
                        <label class="control-label checkbox-double-size" [ngClass]="{ 'check-required': !isSecondTermsAccepted && btnContinueClicked }">
                            <input type="checkbox" [checked]="isSecondTermsAccepted" (change)="isSecondTermsAccepted = !isSecondTermsAccepted"/>
                            {{pddoSelectedDetails.secondTerms}}
                        </label>
                    </div> -->
                    <!-- <div class="row" *ngIf="btnContinueClicked && (!isFirstTermsAccepted || !isSecondTermsAccepted)">
                        <div class="col-xs-24">
                            <div class="alert alert-danger">
                                {{pddoSelectedDetails.errorMessage}}
                            </div>
                        </div>
                    </div>  -->
                    <div class="item-spacer-20 clearfix"></div>
                    <div class="col-sm-offset-8 col-sm-8 text-center">
                        <button type="button" class="btn submit-btn width-100" *ngIf="!applyLoading" (click)="btnContinueClick()" tabindex="-1">Yes</button>
                        <div class="loading-container" *ngIf="applyLoading">
                            <div class="loading-container-table">
                                <div>
                                    <span class="glyphicon glyphicon-refresh rotating"></span>
                                </div>
                                <div>
                                    Loading...
                                </div>
                            </div>
                        </div>
                        <div class="item-spacer-10 clearfix"></div>
                        <div>
                            <button type="button" class="btn submit-btn width-100" (click)="applyLoading ? false : showPDDOSelectedDetails = false" tabindex="-1">No, let me review</button>
                            <!-- <a (click)="applyLoading ? false : showPDDOSelectedDetails = false" class="tooltip-link btn link-btn">No, let me review</a> -->
                        </div>
                    </div>
                    <div class="item-spacer-30 clearfix"></div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- build:dev -->
<div *ngIf="!isProdEnv" class="container">
    <div class="row">
        <div class="col-sm-24 text-center" style="word-wrap: break-word;">
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <textarea id="diagnostic" cols="50" rows="25" style="width:100%" [innerHTML]="diagnostic"></textarea>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>
    </div>
</div>
<!-- endbuild -->
