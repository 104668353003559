import { Injectable }    from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';

import 'rxjs/add/operator/toPromise';

import { Promotion } from '../model/promotion';

@Injectable()
export class PromotionService extends BaseService {

    public static mapGetPromotions(data: any): Promotion[] {
        let promotions: Promotion[] = [];
        data.forEach(
            (promo) => {
                promotions.push(
                    new Promotion(
                        promo.OfferId,
                        promo.OfferTypeId,
                        promo.OfferVariantId,
                        promo.OfferCriteriaId,
                        promo.OfferTitle,
                        promo.OfferTypeName,
                        promo.OfferDescription,
                        promo.PartnerCode,
                        promo.PetsurePromotionId,
                        promo.ProductCode,
                        promo.PromoCode,
                        promo.TermsAndConditions
                    )
                );
            }
        );
        return promotions;
    }

    constructor(private http: HttpClient) {
        super();
    }

    getPromotion(code: string, partnerId: number): Promise<any> {
        let url = this.baseUrl + 'api/PetSure/GetSalesOffers/' + code + '/' + partnerId;
        return this.http.get(url, {headers: this.headers})
            .toPromise()
            .then((response) => {
                return PromotionService.mapGetPromotions(response);
            })
            .catch(this.handleError);
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }
}
