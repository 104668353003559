export class Theme {

    static getBlank(): Theme {
        return new Theme(0, '', '', '', '', '', '', '', '','', false);
    }

    constructor(
        public id: number,
        public partnerCode: string,
        public name: string,
        public homeUrl: string,
        public mainWebSiteUrl: string,
        public companyName: string,
        public contactPhoneNumber: string,
        public termsCondition: string,
        public firstStepTerms: string,
        public antiHawking: string,
        public firstStepTermsCheckbox: boolean,
        public showInterestedIn: boolean = false,
        public userId: string = 'WebUser',
        public source: string = 'choosipet',
        public automated: string = 'false'
    ) {}

    setNewData(theme: Theme) {
        this.id = theme.id;
        this.partnerCode = theme.partnerCode;
        this.name = theme.name;
        this.homeUrl = theme.homeUrl;
        this.mainWebSiteUrl = theme.mainWebSiteUrl;
        this.companyName = theme.companyName;
        this.contactPhoneNumber = theme.contactPhoneNumber;
        this.termsCondition = theme.termsCondition;
        this.firstStepTerms = theme.firstStepTerms;
        this.antiHawking = theme.antiHawking;
        this.firstStepTermsCheckbox = theme.firstStepTermsCheckbox;
        this.showInterestedIn = theme.showInterestedIn;
    }

    getFormattedPhoneNumber(): string {
        let newPhone = this.contactPhoneNumber;
        if (this.contactPhoneNumber.length === 10) {
            newPhone = this.contactPhoneNumber.slice(0, 4)
                + ' ' + this.contactPhoneNumber.slice(4, 7)
                + ' ' + this.contactPhoneNumber.slice(7, 10);
        }
        return newPhone;
    }
}
