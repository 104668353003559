import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject } from 'rxjs';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';

import 'rxjs/add/operator/toPromise';

import { Species } from '../model/species.enum';
import { Breed } from '../model/breed';

@Injectable()
export class BreedsService extends BaseService {

    private observables: ReplaySubject<Breed[]>[] = [
        new ReplaySubject<Breed[]>(1), // feline
        new ReplaySubject<Breed[]>(1)  // canine
    ];

    private static mapBreedResponse(data: any): Breed[] {
        let breeds: Breed[] = [];
        for (let breed of data) {
            breeds.push(
                new Breed(breed.BreedCode, breed.Breed, data.CrossBreed)
            );
        }
        return breeds;
    }

    constructor(private http: HttpClient) {
        super();
    }

    getBreeds(species: Species): Observable<Breed[]> {
        let url = this.baseUrl + 'api/PetSure/GetBreed/';
        url += (species === Species.FELINE) ? 'FELINE' : 'CANINE';

        let observable: ReplaySubject<Breed[]> = (species === Species.FELINE)
            ? this.observables[0] : this.observables[1];

        if (!observable.observers.length) {
            this.http.get(url).subscribe(
                response => observable.next(BreedsService.mapBreedResponse(response)),
                error => observable.error(error)
            );
        }
        return observable;
    }
}
