import { ModelValidator } from './model-validator';

import { BrandsEnum } from './brands.enum';
import { Species } from './species.enum';
import { Desexed } from './desexed.enum';
import { Insurance } from './insurance';
import { Gender } from './gender.enum';
import { Breed } from './breed';
import { Vet } from './vet';

export class Pet extends ModelValidator {

    static getBlank(id: number = -1, cover: Insurance = null): Pet {
        return new Pet(id, '', null, Species.CANINE, Breed.getBlank(), Gender.Male, Desexed.Unknown, null, null, false,
                       ((cover === null) ? Insurance.getBlank() : cover),
                       Vet.getBlank(), false, false);
    }

    static getCopy(pet: Pet): Pet {
        let newPet = Pet.getBlank();
        newPet.id = pet.id;
        newPet.name = pet.name;
        newPet.petType = pet.petType;
        newPet.breed = pet.breed;
        newPet.gender = pet.gender;
        newPet.isDesexed = pet.isDesexed;

        newPet.bornMonth = pet.bornMonth;
        newPet.bornYear = pet.bornYear;
        newPet.isOver8WeeksOldDeclaration = pet.isOver8WeeksOldDeclaration;
        if (pet.cover) {
            newPet.cover = Insurance.getCopy(pet.cover);
        }
        if (pet.vet) {
            newPet.vet = Vet.getCopy(pet.vet);
        }
        newPet.expanded = pet.expanded;
        newPet.hasVet = pet.hasVet;
        newPet.isGuideDog = pet.isGuideDog;
        newPet.microchipNumber = pet.microchipNumber;

       //newPet.isFirstTermsAccepted = pet.isFirstTermsAccepted;
        //newPet.isSecondTermsAccepted = pet.isSecondTermsAccepted;
        newPet.isDDOTermsAccepted= pet.isDDOTermsAccepted;
        return newPet;
    }

    constructor(
        public id: number,
        public name: string,
        public clientConsent: boolean,
        public petType: Species,
        public breed: Breed,
        public gender: Gender,
        public isDesexed: Desexed,
        // 1 based month, 1 = January
        public bornMonth: number,
        public bornYear: number,
        public isOver8WeeksOldDeclaration: boolean,
        public cover: Insurance,
        public vet: Vet,
        public expanded: boolean = true,
        public hasVet: boolean = false,
        public isGuideDog: boolean = false,
        public microchipNumber: string = '', 
        
        //User story - 31124 - Choosi PDDO Pet STP
        //Step 3 - PDDO inline validation
        //public isFirstTermsAccepted: boolean = false,
        //public isSecondTermsAccepted: boolean = false
         public isDDOTermsAccepted:boolean=false
    ) {
        super();
    }

    getAge(date: Date = null): number {
        let months: number = null;
        if (date !== null && date.getTime() > new Date().getTime()) {
            months = this.getMonthsOld(date.getFullYear(), date.getMonth(), date.getDate());
        } else {
            months = this.getMonthsOld();
        }
        return (months !== null) ? Math.floor(months / 12) : null;
    }

    getMonthsOld(year: number = -1, month: number = -1, date: number = -1): number {
        if (this.bornYear === null || this.bornMonth === null) {
            return null;
        }

        if (year === -1) {
            year = new Date().getFullYear();
        }
        if (month === -1) {
            month = new Date().getMonth();
        }
        if (date === -1) {
            date = new Date().getDate();
        } else {
            date = 1;
        }

        let now = new Date(year, month, date);
        let yearMonthNow = new Date(now.getFullYear(), now.getMonth(), date);
        let yearMonthSelected = new Date(this.bornYear, this.bornMonth - 1, 1);

        return ((yearMonthNow.getFullYear() * 12) + yearMonthNow.getMonth())
                - ((yearMonthSelected.getFullYear() * 12) + yearMonthSelected.getMonth());
    }

    getDoB(): Date {
        return new Date(this.bornYear, this.bornMonth - 1, 1);
    }

    isOver9(): boolean {
        let minDate =  new Date(new Date().getFullYear() - 9, new Date().getMonth(), new Date().getDate());
        return minDate >= this.getDoB();
    }
    willHaveYearBirthdayBeforeOrOnDate(year: number, date: Date ): boolean {
        let yrsAgoTodayDate =  new Date(new Date().getFullYear() - year, new Date().getMonth(), new Date().getDate());
        let yrsBeforeDate =  new Date(date.getFullYear() - year, date.getMonth(), date.getDate());
        let bday=this.getDoB();
        return (( yrsAgoTodayDate < bday) && (yrsBeforeDate >= bday));
    }

    proceedValidation(step: number = 0): boolean {
        if (this.name === null || this.name.trim().length < 1) {
            this.setError('name', 'Please enter your pet\'s name');
        }

        if (this.name !== null && this.name.length > 50) {
            this.setError('name', 'Your pet\'s name may not be more than 50 characters');
        }

        if (this.name !== null && this.name.trim().length > 0 && !this.name.match(/^[a-z0-9'\-\s]+$/i)) {
            this.setError('name', 'Your pet\'s name may only contain letters and numbers');
        }

        if (this.petType !== Species.FELINE && this.petType !== Species.CANINE) {
            this.setError('petType', 'Please select if your pet is a dog or a cat');
        }

        if (!this.bornMonth) {
            this.setError('bornMonth', 'Please enter your pet\'s month of birth');
        }

        if (this.bornMonth && this.bornMonth < 1 || this.bornMonth > 12) {
            this.setError('bornMonth', 'Month of birth must be between 1-12');
        }

        if (!this.bornYear) {
            this.setError('bornYear', 'Please enter your pet\'s year of birth');
        }

        let nowDate: Date = new Date();
        if (this.bornYear && this.bornYear < (nowDate.getFullYear() - 40) || this.bornYear > nowDate.getFullYear()) {
            this.setError('bornYear', 'Year of birth must be between ' + (nowDate.getFullYear() - 40) + '-' + nowDate.getFullYear());
        }
        let currentYear = new Date().getFullYear()
        if (this.isPropertyValid('bornMonth')
            && this.isPropertyValid('bornYear')
            && this.bornMonth
            && this.getMonthsOld() <= 1
        ) {
            this.setError('bornDate', 'Unfortunately we do not insure pets under the age of 8 weeks');
        }

        if (this.isPropertyValid('bornMonth')
            && this.isPropertyValid('bornYear')
            && this.getMonthsOld() === 2
            && !this.isOver8WeeksOldDeclaration
        ) {
            this.setError('isOver8WeeksOldDeclaration', 'Please confirm your pet is over 8 weeks old');
        }


        if (this.gender !== Gender.Male && this.gender !== Gender.Female) {
            this.setError('gender', 'Please select your pet\'s gender');
        }

        if (this.isDesexed !== Desexed.Yes && this.isDesexed !== Desexed.No && this.isDesexed !== Desexed.Unknown) {
            this.setError('isDesexed', 'Please confirm whether your pet is desexed');
        }

        if (this.petType !== Species.CANINE) {
            if (this.isGuideDog) {
                this.setError('isGuideDog', 'Only dogs can be guide dogs');
            }
            if (this.microchipNumber !== null && this.microchipNumber.trim().length > 0) {
                this.setError('microchipNumber', 'Only Guide Dogs need a microchip number');
            }
        } else if (this.isGuideDog && step === 3) {

            if (this.cover !== null && this.cover.brandId === BrandsEnum.GuideDogs) {
                if (this.microchipNumber == null || this.microchipNumber.trim().length < 1) {
                    this.setError('microchipNumber', 'Please enter your pet\'s microchip number');
                } else if (this.microchipNumber.trim().length > 15 || !this.microchipNumber.match(/^[0-9]{15}$/i)) {
                    this.setError('microchipNumber', 'Please enter your pet\'s microchip number');
                }
            }
        }

        //User story - 31124 - Choosi PDDO Pet STP
        //Step 3 - PDDO inline validation
        // if ((!this.isFirstTermsAccepted || !this.isSecondTermsAccepted) && step === 3)
        // {
        //     this.setError('isFirstTermsAccepted', 'You must agree with the above statements to continue.');
        // }

        if ((!this.isDDOTermsAccepted || !this.isDDOTermsAccepted) && step === 3)
         {
             this.setError("isDDOTermsAccepted", "You must select 'Yes' to Proceed.");
         }
        this.cover.validate(step);
        if (this.hasVet) {
            this.vet.validate(step);
        } else {
            this.vet.clearErrors();
        }
        this.breed.validate(step);

        return this.isValid();
    }

    isValid(): boolean {
        return this.cover.isValid() && this.vet.isValid() && this.breed.isValid() && super.isValid();
    }

}
