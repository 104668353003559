<form class="form-horizontal reverse-color have-us-call-form" novalidate #haveCallForm="ngForm" (ngSubmit)="onSubmit()">
    <div class="container" [hidden]="finalSubmit">
        <div class="row">
            <div class="relative text-center">
                <h2>Before we call you, please confirm the following</h2>
                <div class="have-call-close-cross hidden-xs" (click)="close()">
                    X
                </div>
            </div>
            <div class="col-sm-20 col-sm-offset-2 m-t-1">
                <div class="form-group">
                    <label for="haveCallFirstName" class="col-sm-8 control-label">First name</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" id="haveCallFirstName" name="haveCallFirstName" placeholder="First name"
                               [(ngModel)]="user.firstName" #haveCallFirstName="ngModel"
                               [fieldValidation]="user"
                               [fieldName]="'firstName'"
                               [fieldGuard]="submitted"
                               [validationStep]="20"
                               (keyup)="(($event.key == 8 || $event.key == 46)? (user.firstName=$event.target.value):'')"
                               required />
                        <div [hidden]="haveCallFirstName.valid || haveCallFirstName.pristine || submitted" class="alert alert-danger">
                            Please enter your first name
                        </div>
                        <div [model-validated]="user" [property-name]="'firstName'" model-validation-display></div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="haveCallLastName" class="col-sm-8 control-label">Last name</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" id="haveCallLastName" name="haveCallLastName" placeholder="Last name"
                               [(ngModel)]="user.lastName" #haveCallLastName="ngModel"
                               [fieldValidation]="user"
                               [fieldName]="'lastName'"
                               [fieldGuard]="submitted"
                               [validationStep]="20"
                               (keyup)="(($event.key == 8 || $event.key == 46)? (user.lastName=$event.target.value):'')"
                               required />
                        <div [hidden]="haveCallLastName.valid || haveCallLastName.pristine || submitted" class="alert alert-danger">
                            Please enter you last name
                        </div>
                        <div [model-validated]="user" [property-name]="'lastName'" model-validation-display></div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="haveCallContactNumber" class="col-sm-8 control-label">
                        Primary contact number
                    </label>
                    <div class="col-sm-10 col-xs-24">
                        <input type="tel" class="form-control" id="haveCallContactNumber" name="haveCallContactNumber" placeholder="Mobile or landline number with area code"
                               [(ngModel)]="user.contactNumber" #haveCallContactNumber="ngModel"
                               [fieldValidation]="user"
                               [fieldName]="'contactNumber'"
                               [fieldGuard]="submitted"
                               [validationStep]="20"
                               (ngModelChange)="updatePhoneNumber($event)"
                               (keyup)="updateNumberOnDel($event)"
                               maxlength="{{maxPhoneLength}}" pattern="(04)|(\(0[023578]\)\s)([0-9\s]{9,10})"
                               required />
                        <div [hidden]="haveCallContactNumber.valid || haveCallContactNumber.pristine || submitted" class="alert alert-danger">
                            Please enter a valid Australian phone number including the area code
                        </div>
                        <div [model-validated]="user" [property-name]="'contactNumber'" model-validation-display></div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="haveCallState" class="col-xs-24 col-sm-8 control-label">
                        State you live in
                    </label>
                    <div class="col-xs-12 col-sm-5">
                        <select id="haveCallState" class="form-control" name="haveCallState"
                                [(ngModel)]="user.address.state"
                                [fieldValidation]="user.address"
                                [fieldName]="'state'"
                                [fieldGuard]="submitted"
                                [validationStep]="20"
                                #userState="ngModel"
                                (keyup)="(($event.key == 8 || $event.key == 46)? (user.address.state=$event.target.value):'')"
                                required>
                            <option [value]="null">Select State</option>
                            <option *ngFor="let state of STATES | keys" [value]="STATES[state.value]">{{state.value}}</option>
                        </select>
                        <div [model-validated]="user.address" [property-name]="'state'" model-validation-display></div>
                    </div>
                </div>
                <div class="item-spacer-30 clearfix"></div>
                <div class="col-sm-offset-8 col-sm-10 text-center">
                    <button type="submit" class="btn submit-btn btn-lg width-100 mobile-not-full">Have us Call you</button>
                </div>
                <div class="item-spacer-20 clearfix"></div>
                <div class="col-sm-offset-8 col-sm-10 text-center">
                    <button type="button" class="btn action-btn btn-lg width-100 mobile-not-full" (click)="close()">Close</button>
                </div>
                <div class="item-spacer-20 clearfix"></div>
                <div class="col-sm-offset-8 col-sm-10 text-center alert alert-danger" *ngIf="errorMsg">
                    {{ errorMsg }}
                </div>
                <div class="item-spacer-30 clearfix"></div>
            </div>
        </div>
    </div>
    <div class="container" [hidden]="!finalSubmit">
        <div class="row">
            <div class="relative text-center">
                <h2>Thank you</h2>
                <div class="have-call-close-cross hidden-xs" (click)="close()">
                    X
                </div>
            </div>
            <div class="col-sm-20 col-sm-offset-2 m-t-1">
                <div class="form-group">
                    <p class="col-sm-20 col-sm-offset-2 text-center have-us-call-thx-txt">
                        Thanks <strong>{{user.firstName}}</strong>. One of {{theme.companyName}}’s team of Choosers will be in touch with
                        you soon to answer any questions that you may have.
                    </p>
                </div>
                <div class="item-spacer-20 clearfix"></div>
                <div class="have-us-call-note text-center">
                    <strong>Please note:</strong> We will only attempt to contact you weekdays between 8:00am-8:00pm (AEST).
                </div>
                <div class="item-spacer-20 clearfix"></div>
                <div class="col-sm-offset-8 col-sm-8 text-center">
                    <button type="button" class="btn action-btn btn-lg width-100 mobile-not-full" (click)="close()">Close</button>
                </div>
                <div class="item-spacer-30 clearfix"></div>
            </div>
        </div>
    </div>
</form>



