import { PaymentMethod } from './payment-method';

export class Account extends PaymentMethod {

    static getBlank(): Account {
        return new Account('', '', '');
    }

    constructor(
        public name: string,
        public BSB: string,
        public accountNumber: string
    ) {
        super();
    }

    getExpiryDateOneWord(): string {
        return '0000';
    }

    proceedValidation(step: number = 0): boolean {
        if (this.name !== null && !this.name.match(/^[a-z\s\-]+$/i)) {
            this.setError('name', 'The account name may only contain letters');
        }

        if (this.name !== null && this.name.length > 100) {
            this.setError('name', 'The account name must be between 1-100 characters');
        }

        if (this.name === null || this.name.trim().length < 1) {
            this.setError('name', 'Please enter an accout name');
        }

        if (this.BSB !== null && !this.BSB.match(/^[0-9]{6}$/i)) {
            this.setError('BSB', 'The BSB number must be exactly 6 digits');
        }

        if (this.BSB !== null && this.BSB.length !== 6) {
            this.setError('BSB', 'The BSB number must be exactly 6 digits');
        }

        if (this.BSB === null || this.BSB.length < 1) {
            this.setError('BSB', 'Please enter a 6-digit BSB number');
        }

        if (this.accountNumber !== null && !this.accountNumber.match(/^[0-9]{1,9}$/i)) {
            this.setError('accountNumber', 'The account number may only contain numbers');
        }

        if (this.accountNumber !== null && this.accountNumber.length > 9) {
            this.setError('accountNumber', 'The account number may not be more than 9 digits');
        }

        if (this.accountNumber === null || this.accountNumber.trim().length < 1) {
            this.setError('accountNumber', 'Please enter an account number');
        }
        return this.isValid();
    }
}
