import { Pet } from '../model/pet';
import { InsuranceCovers } from '../model/insurance-covers';
import { ProductQuestion } from '../model/productQuestion';
import { CoverFieldValue } from '../model/cover-field-value';

import { StringHelper } from '../helpers/string.helpers';
import { ToolsMethods } from '../shared/tools.methods';
import { ModelValidator } from './model-validator';
export class ProductQuestions extends ModelValidator {
    constructor(
        public questions: ProductQuestion[] = [],
        public productQuestionTermsValid: boolean = false
    ) {
        super();
    }
    static getBlank(): ProductQuestions {
        return new ProductQuestions();
    }
    static getFromPets(pets: Pet[], covers: InsuranceCovers): ProductQuestions {
        if(!covers) return;
        let productQuestions = new  ProductQuestions([],false);
        productQuestions.questions = [];
        for (let pet of pets) {
            let coverName = pet.cover.coverName;
            if(pet.cover.routineCareSelected)
            {coverName = coverName.replace(' + Routine', '') + ' + Routine Care';}

            if (coverName) {
                let foundCover = productQuestions.questions.find(i=>i.cover === coverName);
                if(foundCover){
                    foundCover.productQuestionsDeclaration = false;
                    foundCover.petNames.push(pet.name)
                    foundCover.petNamesString = ToolsMethods.smartJoinTexts(foundCover.petNames);
                }
                else{
                    
                    let coverFields =covers.getCoverFields();
                    let benefitLimitCoverField = Array.from(coverFields).find(p=>p[1].name === 'Benefit limit¹')[1];
                    let benefitPercentageCoverField = Array.from(coverFields).find(p=>p[1].name === 'Benefit percentage reimbursed for eligible vet expenses')[1];
                    let coverFieldValues = Array.from(pet.cover.fields.values()) as CoverFieldValue[]; 
                    let benefitLimit = '';
                    let benefitPercentage = '';
                    if(benefitLimitCoverField && benefitPercentageCoverField)
                    {
                        var benefitLimithtml =  coverFieldValues.find(c=>c.id===benefitLimitCoverField.id).html;
                        var benefitPercentagehtml =  coverFieldValues.find(c=>c.id===benefitPercentageCoverField.id).html; 
                        benefitLimit = StringHelper.FormatCurrency(StringHelper.stripHtml(benefitLimithtml)); 
                        benefitPercentage = StringHelper.ReplaceCaseInsensitive(StringHelper.stripHtml(benefitPercentagehtml), "up to","");  
                    }
                    let newPetNames = [];
                    newPetNames.push('you');
                    newPetNames.push(pet.name);
                   
                    let newCover = new  ProductQuestion(coverName, newPetNames, ToolsMethods.smartJoinTexts(newPetNames),benefitLimit, benefitPercentage);
                    productQuestions.questions.push(newCover)
                }
            }
        }
        return  productQuestions;
    }

    proceedValidation(step: number = 0): boolean {       
        if(!this.productQuestionTermsValid) {
            this.setError('productQuestionTermsValid', 'Please indicate you understand and accept the statement');
   
        }
    return this.isValid();
}}