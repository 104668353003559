
export class InsuredPet {

    static getBlank(): InsuredPet {
        return new InsuredPet('', '');
    }

    constructor(
        public referenceNumber: string,
        public petDetails: string
    ) {}

}
