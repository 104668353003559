import { PaymentMethod } from './payment-method';

export class CreditCard extends PaymentMethod {

    static getBlank(): CreditCard {
        return new CreditCard('', '', null, null);
    }

    constructor(
        public cardOwnerName: string,
        public cardNumber: string,
        public cardExpiryMonth: number,
        public cardExpiryYear: number
    ) {
        super();
    }

    getExpirationFullYear(): number {
        return Number((new Date().getFullYear() / 100).toFixed(0) + '' + this.cardExpiryYear);
    }

    getExpirationDate(): Date {
        return new Date(this.getExpirationFullYear(), this.cardExpiryMonth);
    }

    getExpiryDateOneWord(): string {
        return '' + this.cardExpiryMonth + this.cardExpiryYear;
    }

    proceedValidation(step: number = 0): boolean {
        let now = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

        if (this.cardOwnerName !== null && !this.cardOwnerName.match(/^[a-z\s\-]+$/i)) {
            this.setError('cardOwnerName', 'The card owner\'s name may only contain letters');
        }

        if (this.cardOwnerName == null || this.cardOwnerName.trim().length < 1) {
            this.setError('cardOwnerName', 'Please enter the card owner\'s name');
        }

        if (this.cardNumber !== null && this.cardNumber.trim().length < 13) {
            this.setError('cardNumber', 'The card number may not be less than 13 digits');
        }

        if (this.cardNumber !== null && this.cardNumber.trim().length > 16) {
            this.setError('cardNumber', 'The card number may not be more than 16 digits');
        }

        if (this.cardNumber === null || this.cardNumber.trim() === '') {
            this.setError('cardNumber', 'Please enter a valid MasterCard or VISA credit card number');
        }

        if (this.isPropertyValid('cardNumber')) {
            if (!this.cardNumber.match(/^((5[1-5])|4|34|37|6011)/)) {
                this.setError('cardNumber', 'Sorry we only accept MasterCard or VISA credit cards');
            }

            if (!this.cardNumber.match(/^[0-9]{13,16}$/i)) {
                this.setError('cardNumber', 'The card number may only contain numbers');
            }

            if (!this.lunhCheck()) {
                this.setError('cardNumber', 'Please enter a valid MasterCard or VISA credit card number');
            }
        }

        if (this.cardExpiryMonth < 1 || this.cardExpiryMonth > 12) {
            this.setError('cardExpiryMonth', 'The month of expiry must be between 1-12');
        }

        if (!this.cardExpiryMonth) {
            this.setError('cardExpiryMonth', 'Please enter a month of expiry');
        }

        if (this.cardExpiryYear < 1 || this.cardExpiryYear > 50) {
            this.setError('cardExpiryYear', 'The year of expiry must be between 1-50');
        }

        if (!this.cardExpiryYear) {
            this.setError('cardExpiryYear', 'Please enter a year of expiry');
        }

        if (now.getTime() > this.getExpirationDate().getTime()) {
            this.setError('cardExpiryDate', 'The card\'s date of expiry must be in the future');
        }

        return this.isValid();
    }

    lunhCheck(): boolean {
        let luhnChk = (function (arr) {
            return function (ccNum) {
                let
                    len = ccNum.length,
                    bit = 1,
                    sum = 0,
                    val;

                while (len) {
                    val = parseInt(ccNum.charAt(--len), 10);
                    sum += (bit ^= 1) ? arr[val] : val; // tslint:disable-line no-bitwise
                }

                return sum && sum % 10 === 0;
            };
        }([0, 2, 4, 6, 8, 1, 3, 5, 7, 9]));

        return luhnChk(this.cardNumber);
    }
}
