import { ModelValidator } from './model-validator';

export class Loading extends ModelValidator {

    static getBlank(): Loading {
        return new Loading('Loading', false);
    }

    constructor(
        public loadingText: string,
        public activated: boolean
    ) {
        super();
    }

    proceedValidation(step: number): boolean {
        return true;
    }

}
