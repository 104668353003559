import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';

import 'rxjs/add/operator/toPromise';
import { ToolsMethods } from '../shared/tools.methods';
import { PremiumsHelper } from '../helpers/premiums.helper';

import { InsuranceCovers } from '../model/insurance-covers';
import { Insurance } from '../model/insurance';

import { Pet } from '../model/pet';
import { User } from '../model/user';
import { Cover } from '../model/cover';
import { State } from '../model/state.enum';
import { BrandsEnum } from '../model/brands.enum';
import { CoverField } from '../model/cover-field';
import { Frequency } from '../model/frequency.enum';
import { CoverFieldValue } from '../model/cover-field-value';
import { InsurancePromotion } from '../model/insurance-promotion';
import { InsurancePricing } from '../model/insurance-pricing';
import { PremiumCoverDateRequest} from '../model/premiumCoverDateRequest';
import { Promotion } from '../model/promotion';
import { PremiumCoverDateResponse } from '../model/premiumCoverDateResponse';

@Injectable()
export class PremiumService extends BaseService {

    public static getLogoPath(brandId: number): string {
        switch (brandId) {
            case BrandsEnum.Real:
                return 'assets/img/logo/real.png';
            case BrandsEnum.Asia:
                return 'assets/img/logo/asia.png';
            case BrandsEnum.Guardian:
                return 'assets/img/logo/guardian.png';
            case BrandsEnum.RSPCA:
                return 'assets/img/logo/rspca.png';
            case BrandsEnum.Prime:
                return 'assets/img/logo/prime.png';
            case BrandsEnum.GuideDogs:
                return 'assets/img/logo/guide-dogs.png';
            default:
                return '';
        }
    }
    public static getLargeLogoPath(brandId: number): string {
        switch (brandId) {
            case BrandsEnum.Real:
                return 'assets/img/logo/real-lg.png';
            case BrandsEnum.Asia:
                return 'assets/img/logo/asia-lg.png';
            case BrandsEnum.Guardian:
                return 'assets/img/logo/guardian-lg.png';
            case BrandsEnum.RSPCA:
                return 'assets/img/logo/rspca-lg.png';
            case BrandsEnum.Prime:
                return 'assets/img/logo/prime-lg.png';
            case BrandsEnum.GuideDogs:
                return 'assets/img/logo/guide-dogs-lg.png';
            default:
                return '';
        }
    }

    private static mapAllPremiumsRequest(productCodes: string[], partnerCodes: string[], partnerId: number, user: User, pet: Pet) {
        let dobString = ToolsMethods.getDateAsString(pet.getDoB());
        return {
            'ProductCodes': productCodes,
            'PartnerCodes': partnerCodes,
            'Name': pet.name,
            'Species': pet.petType,
            'IsGuideDog': pet.isGuideDog,
            'BreedCode': pet.breed.breedCode,
            'CrossBreed': pet.breed.crossBreed,
            'State': State[user.address.state],
            'DateOfBirth': dobString,
            'Age': pet.getAge(),
            'IsEligibleForSeniorsDiscount': user.eligibleForSenior,
            'Discount': 0,
            'Gender': pet.gender,
            'ClientId': user.id,
            'EncryptedClientId': user.encryptedClientId,       // Mehedi
            'PartnerId': partnerId,
            'Desexed': pet.isDesexed,
            'PromoCode': user.promotion.promoCode,
            'PetsurePromotionId': user.promotion.petsurePromotionId,
            'OfferTypeId': user.promotion.offerTypeId
        };
    }

    private static mapInsurancePromotion(insurancePromotionsData: any) {
        if (!insurancePromotionsData || insurancePromotionsData.length === 0) {
            return null;
        }
        let insurancePromotionData: any = insurancePromotionsData[0];
        return new InsurancePromotion(
            insurancePromotionData.LoadingNo,
            insurancePromotionData.LoadingCode,
            insurancePromotionData.LoadingName,
            insurancePromotionData.LoadingBase,
            (insurancePromotionData.LoadingBase / 12),
            (insurancePromotionData.LoadingBase / 26),
            insurancePromotionData.LoadingGst,
            insurancePromotionData.LoadingStampDuty,
            insurancePromotionData.PremiumFreeValue,
            insurancePromotionData.WebDisplayValue

        );
    }

    private static mapPremiumPricingResponse(premiumPricingData: any, hasFreeMonthOffer: boolean): InsurancePricing {
        if (!premiumPricingData) {
            return null;
        }
        let routinePriceFortnight: number = 0;
        let routinePriceMonth: number = 0;
        let routinePriceYear: number = 0;

        if (premiumPricingData.RoutineCareAvailable) {
            routinePriceFortnight = premiumPricingData.FortnightlyAmountWithRoutineCare;
            routinePriceMonth = premiumPricingData.MonthlyAmountWithRoutineCare;
            routinePriceYear = premiumPricingData.AnnualAmountWithRoutineCare;
        }

        let originalPriceFortnight: number = premiumPricingData.FortnightlyAmountDiscount
            + premiumPricingData.FortnightlyAmount;
        let originalPriceMonth: number = premiumPricingData.MonthlyAmountDiscount
            + premiumPricingData.MonthlyAmount;
        let originalPriceYear: number = premiumPricingData.AnnualAmountDiscount
            + premiumPricingData.AnnualAmount;

        let originalRoutinePriceFortnight: number = 0;
        let originalRoutinePriceMonth: number = 0;
        let originalRoutinePriceYear: number = 0;

        if (premiumPricingData.RoutineCareAvailable) {
            originalRoutinePriceFortnight = routinePriceFortnight
                + premiumPricingData.FortnightlyAmountWithRoutineCareDiscount;
            originalRoutinePriceMonth = routinePriceMonth
                + premiumPricingData.MonthlyAmountWithRoutineCareDiscount;
            originalRoutinePriceYear = routinePriceYear
                + premiumPricingData.AnnualAmountWithRoutineCareDiscount;
        }

        let excessAmount: number = 0;
        if (premiumPricingData.ExcessAmount) {
            excessAmount = premiumPricingData.ExcessAmount;
        }

        let filledInsurancePricing = InsurancePricing.getBlank();
        filledInsurancePricing.productBenefitId = premiumPricingData.ProductBenefitId;
        filledInsurancePricing.productCode = premiumPricingData.ProductCode;
        filledInsurancePricing.petSureProductCode = premiumPricingData.PetsureProductCode;
        filledInsurancePricing.routineCareAvailable = premiumPricingData.RoutineCareAvailable;
        filledInsurancePricing.productName = premiumPricingData.ProductName;

        filledInsurancePricing.priceFortnight = hasFreeMonthOffer ? originalPriceFortnight: premiumPricingData.FortnightlyAmount;
        filledInsurancePricing.priceMonth = hasFreeMonthOffer ? originalPriceMonth: premiumPricingData.MonthlyAmount;
        filledInsurancePricing.priceYear = premiumPricingData.AnnualAmount;

        filledInsurancePricing.originalPriceFortnight = originalPriceFortnight;
        filledInsurancePricing.originalPriceMonth = originalPriceMonth;
        filledInsurancePricing.originalPriceYear = originalPriceYear;

        filledInsurancePricing.routineCarePriceFortnight = hasFreeMonthOffer ? originalRoutinePriceFortnight : routinePriceFortnight;
        filledInsurancePricing.routineCarePriceMonth = hasFreeMonthOffer ?  originalRoutinePriceMonth : routinePriceMonth;
        filledInsurancePricing.routineCarePriceYear = routinePriceYear;

        filledInsurancePricing.originalRoutineCarePriceFortnight = originalRoutinePriceFortnight;
        filledInsurancePricing.originalRoutineCarePriceMonth = originalRoutinePriceMonth;
        filledInsurancePricing.originalRoutineCarePriceYear = originalRoutinePriceYear;

        filledInsurancePricing.seniorDiscountAmount = premiumPricingData.AnnualAmountSeniorsDiscount;
        filledInsurancePricing.seniorDiscountAmountRoutineCare = premiumPricingData
            .AnnualAmountSeniorsWithRoutineCareDiscount;

        filledInsurancePricing.multiPetDiscount = premiumPricingData.AnnualAmountMultipetDiscount;
        filledInsurancePricing.multiPetDiscountRoutineCare = premiumPricingData
            .AnnualAmountMultipetWithRoutineCareDiscount;
        filledInsurancePricing.multiPetDiscountCount = premiumPricingData.MultiPetDiscountCount;

        filledInsurancePricing.excess = excessAmount;

        filledInsurancePricing.displayOrder = premiumPricingData.DisplayOrder;
        filledInsurancePricing.coolingOffDays = premiumPricingData.CoolingOffDays;
        filledInsurancePricing.minimumPetAgeForAccidentOnly = premiumPricingData.MinimumPetAgeForAccidentOnly;
        filledInsurancePricing.accidentBenefitCode = premiumPricingData.AccidentBenefitCode;
        return filledInsurancePricing;
    }

    private static mapPremiumPricingsResponse(premiumsResponseObject, hasFreeMonthOffer: boolean): InsurancePricing[] {
        let pricings: InsurancePricing[] = [];

        if (!premiumsResponseObject || premiumsResponseObject.length <= 0) {
            return pricings;
        }
        premiumsResponseObject.forEach((premiumPricingData: any) => {
            let result: InsurancePricing = PremiumService.mapPremiumPricingResponse(premiumPricingData, hasFreeMonthOffer);
            if (result) {
                pricings.push(
                    PremiumService.mapPremiumPricingResponse(premiumPricingData, hasFreeMonthOffer)
                );
            }
        });
        return pricings;
    }

    private static mapAllPremiumsResponse(
        coverFieldMap: Map<string, CoverField>,
        clientResponseObject,
        promotion: Promotion
    ): InsuranceCovers {
        let insurances: Array<Insurance> = [];
        clientResponseObject.forEach((partnerContext: any) => {
            if (partnerContext === null || partnerContext.Options.length === 0) {
                return;
            }

            let premiumPrice = partnerContext.Premium;
            let hasRoutine: boolean = (partnerContext.IsRoutineCareOffered);

            let routinePriceFortnight: number = 0;
            let routinePriceMonth: number = 0;
            let routinePriceYear: number = 0;

            let hasFreeMonthOffer: boolean = promotion.offerTypeId === 3 && 
                (partnerContext.PetsurePartnerCode == "RS456" || partnerContext.PetsurePartnerCode == "GU456");

            if (hasRoutine) {
                routinePriceFortnight = premiumPrice.FortnightlyAmountWithRoutineCare;
                routinePriceMonth = premiumPrice.MonthlyAmountWithRoutineCare;
                routinePriceYear = premiumPrice.AnnualAmountWithRoutineCare;
            }

            let originalPriceFortnight: number = premiumPrice.FortnightlyAmountDiscount
                + premiumPrice.FortnightlyAmount;
            let originalPriceMonth: number = premiumPrice.MonthlyAmountDiscount
                + premiumPrice.MonthlyAmount;
            let originalPriceYear: number = premiumPrice.AnnualAmountDiscount
                + premiumPrice.AnnualAmount;

            let originalRoutinePriceFortnight: number = 0;
            let originalRoutinePriceMonth: number = 0;
            let originalRoutinePriceYear: number = 0;

            if (hasRoutine) {
                originalRoutinePriceFortnight = routinePriceFortnight
                    + premiumPrice.FortnightlyAmountWithRoutineCareDiscount;
                originalRoutinePriceMonth = routinePriceMonth
                    + premiumPrice.MonthlyAmountWithRoutineCareDiscount;
                originalRoutinePriceYear = routinePriceYear
                    + premiumPrice.AnnualAmountWithRoutineCareDiscount;
            }

            let coverFields: Map<string, CoverFieldValue> = PremiumService
                .getCoverFields(
                    partnerContext.Options,
                    partnerContext.Product.PdsLocation,
                    coverFieldMap
                );

            let pricings: InsurancePricing[] = PremiumService.mapPremiumPricingsResponse(partnerContext.Premiums, hasFreeMonthOffer);

            let excessOptions: number[] = PremiumsHelper
                .getExcessOptions(partnerContext.Product.BrandId, partnerContext.Id);
            if (pricings && pricings.length > 0) {
                excessOptions = [];
                for (let pricing of pricings) {
                    excessOptions.push(pricing.excess);
                }
                excessOptions = excessOptions.sort((a, b) => {
                    return b - a;
                });
            }


            let excessDefault: number = PremiumsHelper
                .getDefaultExcess(partnerContext.Product.BrandId, partnerContext.Id);


            // to now what field we fill it is easier than using the constructor
            let filledInsurance = Insurance.getBlank();
            filledInsurance.policyBookletLink = partnerContext.Product.PdsLocation;
            filledInsurance.id = partnerContext.Id;
            filledInsurance.quoteDetailsId = null;
            filledInsurance.productBenefitId = partnerContext.Id;
            filledInsurance.productCode = partnerContext.Product.Code;
            filledInsurance.petSureProductCode = partnerContext.PetsureProductCode;
            filledInsurance.petSurePartnerCode = partnerContext.PetsurePartnerCode;
            filledInsurance.coverName = premiumPrice.ProductName;
            filledInsurance.routineCareAvailable = partnerContext.RoutineCareAvailable;

            filledInsurance.brandId = partnerContext.Product.BrandId;
            filledInsurance.brandCode = partnerContext.Code;
            filledInsurance.insuranceName = partnerContext.Product.Name;
            filledInsurance.brandLogo = PremiumService.getLogoPath(partnerContext.Product.BrandId);
            filledInsurance.brandLogoLarge = PremiumService.getLargeLogoPath(partnerContext.Product.BrandId);
            filledInsurance.aboutBrand = partnerContext.Product.Description;
            filledInsurance.brandPhone = partnerContext.Product.PhoneNumber;

            filledInsurance.priceFortnight = hasFreeMonthOffer ? originalPriceFortnight : premiumPrice.FortnightlyAmount;
            filledInsurance.priceMonth = hasFreeMonthOffer ? originalPriceMonth: premiumPrice.MonthlyAmount;
            filledInsurance.priceYear = premiumPrice.AnnualAmount;

            filledInsurance.originalPriceFortnight = originalPriceFortnight;
            filledInsurance.originalPriceMonth = originalPriceMonth;
            filledInsurance.originalPriceYear = originalPriceYear;
            filledInsurance.originalPriceText = ((partnerContext.SpecialOfferText === null) ?
                '' : partnerContext.SpecialOfferText);

            filledInsurance.routineCarePriceFortnight = hasFreeMonthOffer ? originalRoutinePriceFortnight : routinePriceFortnight;
            filledInsurance.routineCarePriceMonth = hasFreeMonthOffer ? originalRoutinePriceMonth : routinePriceMonth;
            filledInsurance.routineCarePriceYear = routinePriceYear;

            filledInsurance.originalRoutineCarePriceFortnight = originalRoutinePriceFortnight;
            filledInsurance.originalRoutineCarePriceMonth = originalRoutinePriceMonth;
            filledInsurance.originalRoutineCarePriceYear = originalRoutinePriceYear;

            filledInsurance.fields = coverFields;
            filledInsurance.routineCareSelected = false;
            filledInsurance.shortListed = false;
            filledInsurance.displayOrder = partnerContext.DisplayOrder;

            filledInsurance.seniorDiscount = partnerContext.Product.SeniorDiscount;
            filledInsurance.seniorDiscountAmount = premiumPrice.AnnualAmountSeniorsDiscount;
            filledInsurance.seniorDiscountAmountRoutineCare = premiumPrice.AnnualAmountSeniorsWithRoutineCareDiscount;

            filledInsurance.multiPetDiscount = premiumPrice.AnnualAmountMultipetDiscount;
            filledInsurance.multiPetDiscountRoutineCare = premiumPrice.AnnualAmountMultipetWithRoutineCareDiscount;
            filledInsurance.multiPetDiscountCount = partnerContext.Product.MultiPetDiscountCount;

            filledInsurance.coolingOffDays = partnerContext.Product.CoolingOffDays;
            filledInsurance.minimumPetAgeForAccidentOnly = partnerContext.MinimumPetAgeForAccidentOnly;
            filledInsurance.accidentBenefitCode = partnerContext.AccidentBenefitCode;
            filledInsurance.createdDate = '';
            filledInsurance.insurancePromotion = this.mapInsurancePromotion(partnerContext.Loadings);

            filledInsurance.excessOptions = excessOptions;
            filledInsurance.excessDefault = excessDefault;
            filledInsurance.excessSelected = excessDefault;

            filledInsurance.pricings = pricings;
            insurances.push(filledInsurance);
        });

        return new InsuranceCovers(
            coverFieldMap,
            insurances
        );
    }

    private static mapPremiumsRequest(user: User, pets: Pet[], insurance: Insurance, cover: Cover) {
        let quoteDetails = [];
        for (let pet of pets) {
            if (!pet.isValid()) {
                continue;
            }
            if (!pet.validate()) {
                pet.clearErrors();
                continue;
            }
            quoteDetails.push(
                {
                    'AnnualPremiumAmount': pet.cover.priceYear,
                    'BreedCode': pet.breed.breedCode,
                    'DateCreated': pet.cover.createdDate,
                    'PetsureProductCode': PremiumsHelper.getProductCode(
                        pet.cover.petSureProductCode,
                        pet.cover.routineCareSelected),
                    'PartnerCode': pet.cover.petSurePartnerCode,
                    'IsGuideDog': pet.isGuideDog,
                    'DateOfBirth': ToolsMethods.getDateAsString(pet.getDoB()),
                    'DiscountAmount': pet.cover.getDiscountAmount(cover.frequency),
                    'DiscountPercent': pet.cover.seniorDiscount,
                    'ExcessOption': pet.cover.excessSelected,
                    'Gender': pet.gender,
                    'HasRoutineCare': pet.cover.hasRoutineCare() ? pet.cover.routineCareSelected : false,
                    'Id': pet.cover.quoteDetailsId,
                    'IsEligibleForSeniorsDiscount': user.eligibleForSenior,
                    'IsValid': true,
                    'Desexed': pet.isDesexed,
                    'Name': pet.name,
                    'PaymentFrequency': Frequency[cover.frequency].toUpperCase().substring(0, 1),
                    'PetAgeConfirmed': pet.isOver8WeeksOldDeclaration,
                    'ProductBenefitId': pet.cover.productBenefitId,
                    'QuoteId': cover.quoteId,
                    'Species': pet.petType,
                    'State': State[user.address.state],
                    'VetPractice': pet.vet.vet,
                    'VetPracticeCode': pet.vet.practiceCode
                }
            );
        }
        return {
            'ClientId': user.id,
            'EncryptedClientId': user.encryptedClientId,
            'ClientToken': user.token,
            'PetsureProductCode': PremiumsHelper.getProductCode(
                insurance.petSureProductCode,
                insurance.routineCareSelected),
            'CoverStartDate': ToolsMethods.getDateAsString(cover.coverStartDate),
            'Id': null,
            'IsEligibleForSeniorsDiscount': user.eligibleForSenior,
            'PaymentFrequency': Frequency[cover.frequency].toUpperCase().substring(0, 1),
            'PremiumStartDate': ToolsMethods.getDateAsString(cover.premiumStartDate),
            'ProductCode': insurance.productCode,
            'PartnerCode': insurance.petSurePartnerCode,
            'QuoteDetails': quoteDetails,
            'ReferToUnderwriting': cover.hadPriorPolicyCancelled,
            'State': State[user.address.state],
            'Token': cover.token,
            'PromoCode': user.promotion.promoCode,
            'PromotionId': user.promotion.petsurePromotionId
        };
    }

    private static mapPremiumsResponse(insurances: Insurance[], clientResponseObject, promotion: Promotion): Insurance[] {
        if (!clientResponseObject || clientResponseObject.length <= 0) {
            return [];
        }
        let fallBackInsurance: Insurance = null;
        if (insurances && insurances.length > 0) {
            fallBackInsurance = Insurance.getCopy(insurances[0]);
            fallBackInsurance.pricings = [];
            fallBackInsurance.excessOptions = [];
            fallBackInsurance.excessDefault = 0;
            fallBackInsurance.excessSelected = 0;
            fallBackInsurance.routineCareSelected = false;
        }

        let insurancesMap: Map<number, Insurance> = new Map<number, Insurance>();
        for (let i = 0; i < clientResponseObject.length; i++) {
            let insurance: Insurance = insurancesMap.get(clientResponseObject[i].ProductBenefitId);
            if (!insurance) {
                for (let insuranceEl of insurances) {
                    if (clientResponseObject[i].ProductBenefitId === insuranceEl.productBenefitId) {
                        insurance = Insurance.getCopy(insuranceEl);
                        break;
                    }
                }
                if (!insurance) {
                    insurance = Insurance.getCopy(fallBackInsurance);
                }
            }
            if (insurance === null) {
                continue;
            }

            let hasFreeMonthOffer: boolean = promotion.promoCode === "1CFREEMONTH" && 
                (insurance.petSurePartnerCode == "RS456" || insurance.petSurePartnerCode == "GU456");

            let insurancePricing: InsurancePricing = PremiumService.mapPremiumPricingResponse(clientResponseObject[i], hasFreeMonthOffer);
            if (!insurancePricing) {
                continue;
            }
            let toMerge = [insurancePricing];
            let insuranceNoRoutineCare: InsurancePricing = null;
            if (insurancePricing.hasRoutineCare()) {
                insuranceNoRoutineCare = InsurancePricing.getCopyWithoutRoutineCare(insurancePricing);
                toMerge.push(insuranceNoRoutineCare);
            }
            insurance.mergeInsurancePricings(toMerge);
            insurance.applyCurrentInsurancePricing();
            insurancesMap.set(insurance.productBenefitId, insurance);
        }
        return Array.from(
            insurancesMap.values()
        );
    }

    private static getCoverFields(
        coverFieldsData: any,
        pdsLocation: string,
        coverFieldMap: Map<string, CoverField>
    ): Map<string, CoverFieldValue> {
        let coverFields: Map<string, CoverFieldValue> = new Map<string, CoverFieldValue>();
        for (let data of coverFieldsData) {
            let fieldClass = 'cover-field-clss-' + data.DisplayOrder;
            let fieldId: number = -1;
            let name: string = "";
            if (coverFieldMap.has(fieldClass)) {
                fieldId = coverFieldMap.get(fieldClass).id;
                name = coverFieldMap.get(fieldClass).name;
            }
            coverFields.set(
                fieldClass,
                new CoverFieldValue(
                    fieldId,
                    data.DisplayOrder,
                    PremiumService.generateHtmlElement(data),
                    name
                )
            );
        }
        coverFields.set(
            'cover-field-clss-100',
            new CoverFieldValue(
                9999,
                100,
                '<a href="' + pdsLocation + '" target="_blank" tabindex="-1">'
                + '<span class="glyphicon glyphicon-file"></span> Policy Booklet'
                + '</a>',
                ''
            )
        );
        return coverFields;
    }

    private static generateHtmlElement(value: any): string {
        let html: string = '';
        if (value.DisplayText !== null && value.DisplayText !== '') {
            html = value.DisplayText;
        } else if (value.IsIncluded) {
            html = '<span class="glyphicon glyphicon-ok"></span>';
        } else {
            html = '<span class="glyphicon glyphicon-remove"></span>';
        }
        return html;
    }

    private static mapBenefitResponse(data: any): Map<string, CoverField> {
        let coverFields: Map<string, CoverField> = new Map<string, CoverField>();
        for (let coverField of data) {
            coverFields.set(
                'cover-field-clss-' + coverField.DisplayOrder,
                new CoverField(
                    coverField.Id,
                    coverField.DisplayOrder,
                    coverField.Name,
                    coverField.HelpText,
                    coverField.IsVisible
                )
            );
        }
        coverFields.set(
            'cover-field-clss-100',
            new CoverField(
                100,
                100,
                'Policy Booklet',
                '',
                true
            )
        );
        return coverFields;
    }

    constructor(private http: HttpClient) {
        super();
    }

    getAllBenefitsAndPremiums(step: number, partnerId: number, user: User, pet: Pet): Promise<InsuranceCovers> {
        let products: string[] = PremiumsHelper.getProductCodes(step, user, pet);
        let partners: string[] = PremiumsHelper.getPartnerCodes(step, user, pet);
        return this.getAllBenefits(partnerId, user)
            .then(
                (coverFields) => {
                    return this.getAllPremiums(products, partners, partnerId, user, pet, coverFields);
                },
                (error) => {
                    return error;
                }
            );
    }

    getAllBenefits(partnerId: number, user: User): Promise<Map<string, CoverField>> {
        let url = this.baseUrl + 'api/Petsure/GetAllBenefitData/' + partnerId + '/' + user.eligibleForSenior;
        return this.http.get(url)
            .toPromise()
            .then((response) => {
                return PremiumService.mapBenefitResponse(response);
            })
            .catch(this.handleError);
    }

    getAllPremiums(
        productCodes: string[],
        partnerCodes: string[],
        partnerId: number,
        user: User,
        pet: Pet,
        coverFields: Map<string, CoverField> = new Map<string, CoverField>()
    ): Promise<InsuranceCovers> {
        let url = this.baseUrl + 'api/Petsure/GetAllPremiums';
        let clientRequestObject = PremiumService.mapAllPremiumsRequest(productCodes, partnerCodes, partnerId, user, pet);
        // console.log( JSON.stringify(clientRequestObject));
        return this.http.post(url, JSON.stringify(clientRequestObject), { headers: this.headers })
            .toPromise()
            .then((response) => {
                return PremiumService.mapAllPremiumsResponse(coverFields, response, user.promotion);
            })
            .catch(this.handleError);
    }

    getPremiums(user: User, pet: Pet, pets: Pet[], insurances: Insurance[], selected: Insurance, cover: Cover): Promise<Insurance[]> {
        let url = this.baseUrl + 'api/Petsure/GetPremiums?petName=' + pet.name;
        let clientRequestObject = PremiumService.mapPremiumsRequest(user, pets, selected, cover);
        // console.log(JSON.stringify(clientRequestObject));
        return this.http.post(url, JSON.stringify(clientRequestObject), { headers: this.headers })
            .toPromise()
            .then((response) => {
                return PremiumService.mapPremiumsResponse(insurances, response, user.promotion);
            })
            .catch(this.handleError);
    }

    getEffectiveDate(): Date {
        return new Date(Date.now());
    }
    /**
     * Update the cover Dates
     * Calls upm Wrapper service 
     * @param userId 
     * @param coverDates 
     * @returns 
     */
    updatePremiumCoverDetails(userId: string, coverDates: PremiumCoverDateRequest) {
        let url = this.baseUrl + 'api/Petsure/UpdateCoverDetails/' + userId;  
           return this.http.post<PremiumCoverDateResponse>(url, JSON.stringify(coverDates), {headers: this.headers})
            .toPromise()
            .then((response: PremiumCoverDateResponse) => {          
            return response;        
        })
         .catch(this.handleError);
    }


    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }

}
