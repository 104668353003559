import { Directive, ElementRef, Input, DoCheck, OnInit, ChangeDetectorRef, Renderer2 } from '@angular/core';

import { ModelValidator } from '../model/model-validator';

@Directive({
    selector: '[fieldValidation]'
})
export class ValidationDirective implements DoCheck, OnInit {

    @Input('fieldValidation') model: ModelValidator;

    @Input('fieldName') field: string;

    @Input('validationStep') step: number = 0;

    /**
     * When true it will trigger the validation on change, otherwise it will not do anything.
     *
     * @type {boolean}
     */
    @Input('fieldGuard') guard: boolean = true;

    process: any;

    oldValue: any = '';

    constructor(private el: ElementRef, private renderer: Renderer2, private ref: ChangeDetectorRef) { }

    ngOnInit(): void {
        this.oldValue = this.model[this.field];
    }

    ngDoCheck(): void {
        if (this.oldValue !== this.model[this.field]) {
            this.oldValue = this.model[this.field];
            if (this.guard) {
                clearTimeout(this.process);
                this.process = setTimeout(
                    () => {
                        this.validateModel();
                    },
                    400
                );
            }
        }
        !this.model.isPropertyValid(this.field) ? this.renderer.addClass(this.el.nativeElement, 'form-invalid') : this.renderer.removeClass(this.el.nativeElement, 'form-invalid');
    }

    validateModel() {
        this.model.validate(this.step);
        this.ref.detectChanges();
    }
}
