/**
 * Created by sdolier on 7/10/2016.
 */

export enum State {
    ACT = 1,
    NSW = 2,
    VIC = 3,
    QLD = 4,
    TAS = 5,
    SA  = 6,
    WA  = 7,
    NT  = 8
}
