
export class InsurancePromotion {

    static getCopy(insurancePromotion: InsurancePromotion): InsurancePromotion {
        if (!insurancePromotion) {
            return insurancePromotion;
        }
        return new InsurancePromotion(
            insurancePromotion.discountNo,
            insurancePromotion.discountCode,
            insurancePromotion.discountName,
            insurancePromotion.discountYear,
            insurancePromotion.discountMonth,
            insurancePromotion.discountFortnight,
            insurancePromotion.discountGst,
            insurancePromotion.discountStampDuty,
            insurancePromotion.premiumFreeValue,
            insurancePromotion.webDisplayValue
        );
    }

    constructor(
        public discountNo: number,
        public discountCode: string,
        public discountName: string,
        // discount are negative
        public discountYear: number,
        public discountMonth: number,
        public discountFortnight: number,
        public discountGst: number,
        public discountStampDuty: number,
        public premiumFreeValue: number,
        public webDisplayValue: boolean
    ) {
    }
}
