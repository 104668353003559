import { Injectable }    from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BaseService } from './base.service';

import 'rxjs/add/operator/toPromise';


@Injectable()
export class FileService extends BaseService {
    constructor(private http: HttpClient) {
        super();
    }

    private getFileContentAsString(filePath:string):Promise<string>{
        var headers = new HttpHeaders({'Content-Type': 'text/plain'});       
        return this.http.get(filePath, {headers, responseType: "text"})
        .toPromise()
        .then((response) => { 
            return response })
        .catch(this.handleError);
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }

    renderLoadScript(paymentType:string, userId:string, quoteId:string){
        var paymentGatewayLoadScript = document.createElement("script");
        paymentGatewayLoadScript.id = "showHidePaymentGateWayCredit";
        
        if(document.getElementById("showHidePaymentGateWayCredit"))
        {
            document.body.removeChild(document.getElementById("showHidePaymentGateWayCredit"));
        }
        
        //Set payment-gateway web-component properties explicitly, because it is not applied from html
        let paymentGatewayLoadString :string = `
        document.querySelector('payment-register')["applicationname"] = "choosipet";
        document.querySelector('payment-register')["bankinfoname"]="petsure_qvalent";
        document.querySelector('payment-register')["cdcrn"]="`+userId+`-`+quoteId+`"; 
        `;

        this.getFileContentAsString("assets/js/payment-gateway-load.js").then((response)=>{
            paymentGatewayLoadString += response;
            if(paymentType === "CC"){
                paymentGatewayLoadString += `
                document.getElementsByTagName('payment-register')[0].showcreditcard = true;
                document.getElementsByTagName('payment-register')[0].disablevalidation = false;
                `            
            }
            else{
                paymentGatewayLoadString += `
                document.getElementsByTagName('payment-register')[0].showcreditcard = false;
                document.getElementsByTagName('payment-register')[0].disablevalidation = false;
                `;
            }         
            paymentGatewayLoadScript.innerHTML = paymentGatewayLoadString;
            document.body.appendChild(paymentGatewayLoadScript);
        });
    }

    renderOnPaymentTypeChange()
    {
        var paymentGatewayPaymentTypeChangeScript = document.createElement("script");
        paymentGatewayPaymentTypeChangeScript.id = "paymentGatewayPaymentTypeChange";
        
        if(document.getElementById("paymentGatewayPaymentTypeChange"))
        {
            document.body.removeChild(document.getElementById("paymentGatewayPaymentTypeChange"));
        }
        
        //Set payment-gateway web-component properties explicitly, because it is not applied from html
        let paymentGatewayPaymentTypeChangeString :string;

        this.getFileContentAsString("assets/js/payment-gateway-pament-type-switch.js").then((response)=>{            
            paymentGatewayPaymentTypeChangeScript.innerHTML = response;
            document.body.appendChild(paymentGatewayPaymentTypeChangeScript);
        });
    }
}