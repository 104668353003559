//User story - 31124 - Choosi PDDO Pet STP
export class PDDOSelectedDetails {

    static getBlank(): PDDOSelectedDetails {
        return new PDDOSelectedDetails('','');
        //return new PDDOSelectedDetails('','', '', '', '', '');
    }

    constructor(
        public title: string,
       // public firstTerms: string,       
        public benefits: string,
      //  public note: string,
        //public secondTerms: string,
        //public errorMessage: string
    ) {}
}
