<div class="top-strap-resume-container">
    <div class="container">
        <div class="row row-height sm-row-height">
            <div class="col-xs-24 col-md-10 col-height col-top top-strap-title-container">
                <h3>
                    You're almost there
                </h3>
                Applying for {{insurance.insuranceName}}
            </div>
            <div class="col-xs-24 col-md-9 padding-0 col-height col-top">
                <div class="col-xs-24 resume-container">
                    <div class="col-xs-12 resume-container-details">
                        <div class="resume-container-logo">
                            <img src="{{insurance.brandLogo}}" alt="{{insurance.insuranceName}}" />
                        </div>
                        <div class="pet-number">
                            {{pets.length}} Pet{{ (pets.length > 1)? 's' : ''}}
                        </div>
                        <div>
                            <button type="button" class="btn action-btn pets-details-dropdown-btn" (click)="topDetailsVisible = !topDetailsVisible">
                                {{ topDetailsVisible? 'Close Details' : 'Show Details' }}
                                <span class="glyphicon" [ngClass]="{ 'glyphicon-menu-up': topDetailsVisible, 'glyphicon-menu-down': !topDetailsVisible }"></span>
                            </button>
                        </div>
                    </div>
                    <div class="col-xs-12 resume-container-price-container">
                        <div>
                            <strong>Total price:</strong>
                        </div>
                        <h3>{{ totalPrice | customCurrency:2:'$' }}</h3>
                        <span   class="price-freq first-freq"
                                (click)="cover.frequency = FREQUENCY.Fortnight"
                                [ngClass]="{ 'active': cover.frequency === FREQUENCY.Fortnight }">
                            Fort
                        </span>
                        <span   class="price-freq"
                                (click)="cover.frequency = FREQUENCY.Month"
                                [ngClass]="{ 'active': cover.frequency === FREQUENCY.Month }">
                            Month
                        </span>
                        <span   class="price-freq"
                                (click)="cover.frequency = FREQUENCY.Year"
                                [ngClass]="{ 'active': cover.frequency === FREQUENCY.Year }">
                            Year
                        </span>
                        <div class="price-container-bottom" *ngIf="multiPetDiscount > 0">
                            Multi-pet discount {{ multiPetDiscount | customCurrency:2:'$' }}
                        </div>
                        <div class="price-container-bottom" *ngIf="multiPetDiscount <= 0 && seniorPetDiscount">
                            <small>
                                Includes 10% Seniors Discount
                            </small>
                        </div> 
                        <div class="promotions-applied-container"  *ngIf="showPromotionTitle">                                                     
                                <div class="promotions-applied-content">                     
                                        <div class="promotion-item">
                                            <div class="promotion-title" >
                                                <span class="glyphicon glyphicon-tag" ></span>
                                               <span class= "promotion-title-offer" 
                                               *ngIf= "insurance.insurancePromotion.discountName !== null">{{ insurance.insurancePromotion.discountName}}</span>                       
                                            </div>
                                            <div class="promotion-desc">
                                                <a (click)="promotionTACOpen = true" class="tooltip-link btn link-btn">
                                                    Terms and conditions
                                                </a>
                                            </div>
                                            <div class="promotion-description" *ngIf="promotionTACOpen">                                                    
                                                <span class="promotion-overlay-close" (click)="promotionTACOpen = false">X</span>                                                
                                                    <div  [innerHTML]="user.promotion.termsAndConditions"></div>
                                                </div>
                                            </div>            
                                        </div>     
                                 </div>
                    </div>
                </div>
                <ul class="col-xs-24 resume-container-details-dropdown" [hidden]="!topDetailsVisible">
                    <li *ngFor="let pet of pets; let i = index" class="pet-resume">
                        <div class="row align-items-start">
                            <div class="col-xs-11 col-lg-9">
                                <h4>Pet {{i+1}}:</h4>
                            </div>
                            <div class="col-xs-13 col-lg-15">
                                <h4><strong>{{pet.name}}</strong></h4>
                            </div>
                        </div>
                        <div class="row align-items-start">
                            <div class="col-xs-11 col-lg-9">
                                Pet name: 
                            </div>
                            <div class="col-xs-13 col-lg-15">
                                <strong>{{pet.name}}</strong>
                            </div>
                        </div>
                        <div class="row align-items-start">
                            <div class="col-xs-11 col-lg-9">
                                {{FREQUENCY[cover.frequency]}} premium: 
                            </div>
                            <div class="col-xs-13 col-lg-15">
                                <strong><span>{{ pet.cover.getPrice(cover.frequency) | customCurrency:2:'$' }}</span></strong>
                            </div>
                        </div>
                        <div class="row align-items-start">
                            <div class="col-xs-11 col-lg-9">
                                Excess:
                            </div>
                            <div class="col-xs-13 col-lg-15">
                                <strong>{{pet.cover.excessSelected  | customCurrency:2:'$'}}</strong>
                            </div>
                        </div>
                        <div class="row align-items-start">
                            <div class="col-xs-11 col-lg-9">
                                Routine care:
                            </div>
                            <div class="col-xs-13 col-lg-15">
                                <strong>{{ (pet.cover.routineCareSelected) ? 'Yes' : 'No' }}</strong>
                            </div>
                        </div>
                        <div class="row align-items-start">
                            <div class="col-xs-11 col-lg-9">
                                Species:
                            </div>
                            <div class="col-xs-13 col-lg-15">
                                <strong>{{ (pet.petType === SPECIES.FELINE) ? 'Cat' : 'Dog' }}</strong>
                            </div>
                        </div>
                        
                        
                        
                        <div class="row align-items-start">
                            <div class="col-xs-11 col-lg-9">
                                Breed:
                            </div>
                            <div class="col-xs-13 col-lg-15">
                                <strong>{{pet.breed.breed}}</strong>
                            </div>
                        </div>
                        <div class="row align-items-start">
                            <div class="col-xs-11 col-lg-9">
                                Cover:
                            </div>
                            <div class="col-xs-13 col-lg-15">
                                <strong>{{pet.cover.insuranceName}} {{pet.cover.coverName}}</strong>
                            </div>
                        </div>
                        <div class="text-right">
                            <button class="btn action-btn" type="button"
                                    *ngIf="pets.length > 1"
                                    (click)="onRemove(i)">
                                Remove X
                            </button>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="hidden-xs hidden-sm col-md-5  col-height col-top top-resume-additional-information">
                <div>
                    <span class="icon-large calendar-light-blue"></span>
                    <p>
                        This cover has a <strong>{{insurance.coolingOffDays}} days</strong> cooling off period
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="item-spacer-30"></div>
<div class="container form-horizontal padding-0-xs">
    <div class="panel-group" id="buy-form-group">
        <div id="about-your-pet-panel" class="panel panel-default">
            <div class="panel-heading">
                <div class="row panel-title"
                     data-toggle="collapse"
                     data-parent="#buy-form-group"
                     data-target="#about-your-pet-form"
                     (click)="checkPreviousAndGo($event, 'about-your-pet-panel')">
                    <div class="col-sm-24 text-center">
                        <h2>About your pet(s)</h2>
                        <small><em>Please confirm your details below</em></small>
                        <span class="glyphicon glyphicon-menu-up pull-right panel-arrow"></span>
                        <div class="validation-group">
                            <div class="valid" *ngIf="aboutPetValid === true">
                                <span class="glyphicon glyphicon-ok"></span>
                                Completed
                            </div>
                            <div class="invalid" *ngIf="aboutPetValid === false">
                                <span class="glyphicon glyphicon-warning-sign"></span>
                                Details required
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="about-your-pet-form" class="panel-collapse collapse in">
                <div class="panel-body">
                    <div class="row">
                        <div class="col-sm-24 m-t-1">
                            <div class="panel-group" id="pet-form">
                                <div class="panel panel-default"
                                     *ngFor="let pet of pets; let i = index;"
                                     (onRemove)="onRemove($event)"
                                     (changeFreq)="changeFreq($event)"
                                     (onRoute)="goToBrandCompare($event)"
                                     [pet]="pet"
                                     [pets]="pets"
                                     [user]="user"
                                     [cover]="cover"
                                     [frequency]="cover.frequency"
                                     [pet-covers]="petCoverList"
                                     [cover-start-date]="cover.coverStartDate"
                                     [cover-start-date-change-age]="petAgeChanged"
                                     [pet-nb]="pets.length"
                                     [id]="i"
                                     [breedsService]="breedsService"
                                     [vetsService]="vetsService"
                                     [submitted]="aboutPetSubmit"
                                     [loading]="loading"
                                     [loadingRoute]="loadingRoute"
                                     pet-form>
                                </div>
                            </div>
                            <div class="clearfix item-spacer-10"></div>
                            <div class="col-sm-offset-7 col-sm-10 text-center" *ngIf="pets.length < 15">
                                <button type="button" (click)="addPet()"
                                    class="btn action-btn btn-lg width-100 mobile-not-full add-pet-btn">
                                    <span class="plus-icon"></span> Add Another Pet
                                </button>
                            </div>
                            <div class="clearfix item-spacer-20"></div>
                            <div class="col-sm-offset-7 col-sm-10 text-center">
                                <button id="about-your-pet-submit-btn" type="button"
                                    class="btn submit-btn btn-lg width-100 mobile-not-full" data-toggle="collapse"
                                    data-parent="#buy-form-group" data-target="#about-you-form" *ngIf="!loadingRoute"
                                        (click)="validateAboutPet($event)">
                                    Next
                                </button>
                                <div class="loading-container" *ngIf="loadingRoute">
                                    <div class="loading-container-table">
                                        <div>
                                            <span class="glyphicon glyphicon-refresh rotating"></span>
                                        </div>
                                        <div>
                                            Loading plans...
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="clearfix item-spacer-20"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="about-you-panel" class="panel panel-default">
            <div class="panel-heading">
                <div class="row panel-title collapsed"
                     data-toggle="collapse"
                     data-parent="#buy-form-group"
                     data-target="#about-you-form"
                     (click)="checkPreviousAndGo($event, 'about-you-panel')">
                    <div class="col-sm-24 text-center">
                        <h2>About you</h2>
                        <small><em>Please confirm your details below</em></small>
                        <span class="glyphicon glyphicon-menu-up pull-right panel-arrow"></span>
                        <div class="validation-group">
                            <div class="valid" *ngIf="aboutYouValid === true">
                                <span class="glyphicon glyphicon-ok"></span>
                                Completed
                            </div>
                            <div class="invalid" *ngIf="aboutYouValid === false">
                                <span class="glyphicon glyphicon-warning-sign"></span>
                                Details required
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="about-you-form" class="panel-collapse collapse">
                <div class="panel-body">
                    <div class="row">
                        <div class="col-sm-24 m-t-1">
                            <div class="form-group">
                                <label for="userTitle" class="col-xs-24 col-sm-8 control-label">Title</label>
                                <div class="col-xs-12 col-sm-3">
                                    <select id="userTitle" class="form-control" name="petCover"
                                            [(ngModel)]="user.title"
                                            [fieldValidation]="user"
                                            [fieldName]="'title'"
                                            [fieldGuard]="aboutYouSubmit"
                                            [validationStep]="2"
                                            (change)="updateTitle($event.target.value)"
                                            #userTitle="ngModel"
                                            required>
                                        <option value="">Select</option>
                                        <option value="Mr">Mr</option>
                                        <option value="Ms">Ms</option>
                                        <option value="Mrs">Mrs</option>
                                        <option value="Miss">Miss</option>
                                        <option value="Dr">Dr</option>
                                    </select>
                                    <div [model-validated]="user" [property-name]="'title'" model-validation-display></div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="userFirstName" class="col-sm-8 control-label">First name</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control" id="userFirstName" name="userFirstName" placeholder="First name"
                                           [(ngModel)]="user.firstName" #userFirstName="ngModel"
                                           [fieldValidation]="user"
                                           [fieldName]="'firstName'"
                                           [fieldGuard]="aboutYouSubmit"
                                           [validationStep]="2"
                                           (keyup)="(($event.key == 8 || $event.key == 46)? (user.firstName=$event.target.value):'')" required />
                                    <div [hidden]="userFirstName.valid || userFirstName.pristine || aboutYouSubmit" class="alert alert-danger">
                                        Please enter your first name
                                    </div>
                                    <div [model-validated]="user" [property-name]="'firstName'" model-validation-display></div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="userLastName" class="col-sm-8 control-label">Last name</label>
                                <div class="col-sm-10">
                                    <input type="text" class="form-control" id="userLastName" name="userLastName" placeholder="Last name"
                                           [(ngModel)]="user.lastName" #userLastName="ngModel"
                                           [fieldValidation]="user"
                                           [fieldName]="'lastName'"
                                           [fieldGuard]="aboutYouSubmit"
                                           [validationStep]="2"
                                           (keyup)="(($event.key == 8 || $event.key == 46)? (user.lastName=$event.target.value):'')" required />
                                    <div [hidden]="userLastName.valid || userLastName.pristine || aboutYouSubmit" class="alert alert-danger">
                                        Please enter you last name
                                    </div>
                                    <div [model-validated]="user" [property-name]="'lastName'" model-validation-display></div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-sm-8 control-label">What is your gender?</label>
                                <div class="col-sm-10">
                                    <div class="row">
                                        <div class="col-xs-12">
                                            <button type="button" class="btn radio-btn col-xs-24"
                                                    (click)="user.gender = GENDER.Male"
                                                    id="userGenderMale"
                                                    [ngClass]="{ 'active': user.gender === GENDER.Male }">
                                                Male
                                            </button>
                                        </div>
                                        <div class="col-xs-12">
                                            <button type="button" class="btn radio-btn col-xs-24"
                                                    (click)="user.gender = GENDER.Female"
                                                    id="userGenderFemale"
                                                    [ngClass]="{ 'active': user.gender === GENDER.Female }">
                                                Female
                                            </button>
                                        </div>
                                        <div class="col-xs-24" [model-validated]="user" [property-name]="'gender'" model-validation-display></div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group last">
                                <label for="userBornDay" class="col-sm-8 control-label">Your Date of Birth</label>
                                <div class="col-sm-10">
                                    <div class="row">
                                        <div class="col-xs-8">
                                            <input type="tel" class="form-control text-center" id="userBornDay" name="userBornDay" placeholder="DD" disabled
                                                   [(ngModel)]="user.bornDay" #userBornDay="ngModel"
                                                   [fieldValidation]="user"
                                                   [fieldName]="'bornDay'"
                                                   [fieldGuard]="aboutYouSubmit"
                                                   [validationStep]="2"
                                                   (keyup)="(($event.key == 8 || $event.key == 46)? (user.bornDay=$event.target.value):'')"
                                                   maxlength="2" pattern="([1-9]|[12][0-9]|3[01]|0[1-9])"
                                                   required/>
                                            <div [hidden]="userBornDay.valid || userBornDay.pristine || aboutYouSubmit" class="alert alert-danger">
                                                Please enter a day
                                            </div>
                                            <div [model-validated]="user" [property-name]="'bornDay'" model-validation-display></div>
                                        </div>
                                        <div class="col-xs-8">
                                            <input type="tel" class="form-control text-center" id="userBornMonth" name="userBornMonth" placeholder="MM" disabled
                                                   [(ngModel)]="user.bornMonth" #userBornMonth="ngModel"
                                                   [fieldValidation]="user"
                                                   [fieldName]="'bornMonth'"
                                                   [fieldGuard]="aboutYouSubmit"
                                                   [validationStep]="2"
                                                   (keyup)="(($event.key == 8 || $event.key == 46)? (user.bornMonth=$event.target.value):'')"
                                                   maxlength="2" pattern="([1-9]|[1][0-2]|0[1-9])"
                                                   required/>
                                            <div [hidden]="userBornMonth.valid || userBornMonth.pristine || aboutYouSubmit" class="alert alert-danger">
                                                Please enter a month
                                            </div>
                                            <div [model-validated]="user" [property-name]="'bornMonth'" model-validation-display></div>
                                        </div>
                                        <div class="col-xs-8">
                                            <input type="tel" class="form-control text-center" id="userBornYear" name="userBornYear" placeholder="YYYY" disabled
                                                   [(ngModel)]="user.bornYear" #userBornYear="ngModel"
                                                   [fieldValidation]="user"
                                                   [fieldName]="'bornYear'"
                                                   [fieldGuard]="aboutYouSubmit"
                                                   [validationStep]="2"
                                                   (keyup)="(($event.key == 8 || $event.key == 46)? (user.bornYear=$event.target.value):'')"
                                                   maxlength="4" pattern="(19[0-9]{2}|20[0-2][0-9])"
                                                   required/>
                                            <div [hidden]="userBornYear.valid || userBornYear.pristine || aboutYouSubmit" class="alert alert-danger">
                                                Year of birth must be between {{nowDate.getFullYear() - 112}}-{{nowDate.getFullYear() - 18}}
                                            </div>
                                            <div [model-validated]="user" [property-name]="'bornYear'" model-validation-display></div>
                                        </div>
                                        <div class="col-xs-24">
                                            <div [model-validated]="user" [property-name]="'bornDate'" model-validation-display></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="clearfix item-spacer-20"></div>
                            <div class="col-sm-offset-8 col-sm-10 text-center">
                                <button id="about-you-submit-btn" type="button" class="btn submit-btn btn-lg width-100 mobile-not-full"
                                        data-toggle="collapse" data-parent="#buy-form-group" data-target="#contact-details-form"
                                        (click)="validateAboutYou($event)">
                                    Next
                                </button>
                            </div>
                            <div class="clearfix item-spacer-20"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="contact-details-panel" class="panel panel-default">
            <div class="panel-heading">
                <div class="row panel-title collapsed"
                     data-toggle="collapse"
                     data-parent="#buy-form-group"
                     data-target="#contact-details-form"
                     (click)="checkPreviousAndGo($event, 'contact-details-panel')">
                    <div class="col-sm-24 text-center">
                        <h2>Your contact details</h2>
                        <small><em>Please confirm your details below</em></small>
                        <span class="glyphicon glyphicon-menu-up pull-right panel-arrow"></span>
                        <div class="validation-group">
                            <div class="valid" *ngIf="aboutContactDetailsValid === true">
                                <span class="glyphicon glyphicon-ok"></span>
                                Completed
                            </div>
                            <div class="invalid" *ngIf="aboutContactDetailsValid === false">
                                <span class="glyphicon glyphicon-warning-sign"></span>
                                Details required
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="contact-details-form" class="panel-collapse collapse">
                <div class="panel-body">
                    <div class="row">
                        <div class="col-sm-24 m-t-1">
                            <div class="form-group" *ngIf="!manualAddress">
                                <label for="formatterUserAddress" class="col-sm-8 control-label">
                                    Address
                                    <div class="tooltip-xs">
                                        <a href="javascript:void(0);" class="tooltip-link" tooltipPlacement="bottom" tabindex="999"
                                            tooltip="Enter Address">
                                            <span class="help-icon"></span>
                                        </a>
                                    </div>
                                </label>
                                <div class="col-xs-24 col-sm-10 typeahead-container">
                                    <input id="formatterUserAddress" name="formatterUserAddress" placeholder="Start typing to begin search"
                                        class="form-control" type="text" autocomplete="off" #formatterUserAddress="ngModel"
                                        [fieldValidation]="user.address" [fieldName]="'address'" [fieldGuard]="aboutContactDetailsSubmit"
                                        [validationStep]="3"
                                        [ngClass]="{ 'form-invalid': ((!user.address.address.pristine || submitted) && user.address.address === '') }"
                                        [(ngModel)]="user.address.address" [typeahead]="addressSuggestions$" [typeaheadAsync]="true"
                                        (typeaheadLoading)="changeAddressTypeaheadLoading($event)"
                                        (typeaheadNoResults)="changeAddressTypeaheadNoResults($event)"
                                        (typeaheadOnSelect)="addressTypeaheadOnSelect($event)"
                                        [typeaheadWaitMs]="50" [typeaheadOptionField]="'formatterUserAddress'" (keyup)="addressChanged($event)"
                                        required>
                                    <div *ngIf="addressTypeaheadLoading">
                                        <i class="glyphicon glyphicon-refresh ng-hide rotating"></i>
                                    </div>
                                    <div *ngIf="addressTypeaheadNoResults">
                                        <i class="glyphicon glyphicon-remove"></i> No Results Found
                                        <span style="padding-left: 190px; cursor: pointer;">
                                            <a (click)="showManualAddress()">Enter Address</a>
                                        </span>
                                    </div>
                                    <div *ngIf="!isAddressSelected" class="alert alert-danger">
                                        <i class="glyphicon glyphicon-remove"></i> Please select the Address
                                    </div>
                                    <div [model-validated]="user.address" [property-name]="'address'" model-validation-display></div>
                                </div>
                                <div class="col-sm-6 hidden-xs">
                                    <a href="javascript:void(0);" class="tooltip-link" tooltipPlacement="bottom" tabindex="999"
                                        tooltip="Please enter address">
                                        <span class="help-icon"></span>
                                    </a>
                                </div>
                            </div>
                            <!-- Manual address-->
                            <div class="form-group" *ngIf="manualAddress">
                                <label for="userAddress" class="col-sm-8 col-xs-24 control-label">
                                    Address
                                </label>
                                <div class="col-sm-10">
                                    <input class="form-control" id="userAddress" name="userAddress" [(ngModel)]="user.address.address"
                                        #userAddress="ngModel" [fieldValidation]="user.address" [fieldName]="'address'"
                                        [fieldGuard]="aboutContactDetailsSubmit" [validationStep]="3" required />
                                    <div [hidden]="userAddress.valid || userAddress.pristine || aboutContactDetailsSubmit"
                                        class="alert alert-danger">
                                        Please enter Address
                                    </div>
                                    <div [model-validated]="user.address" [property-name]="'address'" model-validation-display></div>
                                </div>
                            </div>

                            <div class="form-group" *ngIf="manualAddress">
                                <label for="userSuburb" class="col-sm-8 col-xs-24 control-label">
                                    Suburb
                                </label>
                                <div class="col-sm-10">
                                    <input class="form-control" id="userSuburb" name="userSuburb" 
                                           [(ngModel)]="user.address.suburb" #userSuburb="ngModel"
                                           [fieldValidation]="user.address"
                                           [fieldName]="'suburb'"
                                           [fieldGuard]="aboutContactDetailsSubmit"
                                           [validationStep]="3"                                           
                                           required />
                                    <div [hidden]="userSuburb.valid || userSuburb.pristine || aboutContactDetailsSubmit" class="alert alert-danger">
                                        Please enter Suburb
                                    </div>
                                    <div [model-validated]="user.address" [property-name]="'suburb'" model-validation-display></div>
                                </div>
                            </div>
                            <!-- end manual address -->    

                            <div class="form-group" *ngIf="!manualAddress">
                                <label for="formatterUserSuburb" class="col-sm-8 control-label">
                                    Suburb
                                </label>
                                <div class="col-xs-24 col-sm-10">
                                    <select id="formatterUserSuburb" class="form-control" name="formatterUserSuburb" disabled [(ngModel)]="user.address.suburb"
                                        #formatterUserSuburb="ngModel" [fieldValidation]="user.address" [fieldName]="'suburb'"
                                        [fieldGuard]="aboutContactDetailsSubmit" [validationStep]="3" required>
                                        <option value="">Select Suburb</option>
                                        <option *ngFor="let suburb of suburbList" [value]="suburb">{{suburb}}</option>
                                    </select>
                                    <div [model-validated]="user.address" [property-name]="'suburb'" model-validation-display></div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="userPostcode" class="col-xs-24 col-sm-8 control-label">
                                    Postcode
                                </label>
                                <div class="col-xs-12 col-sm-3">
                                    <input type="tel" class="form-control" id="userPostcode" name="userPostcode"  placeholder="Postcode"
                                           maxlength="4"
                                           [ngModel]="user.address.postcode" #userPostcode="ngModel" disabled required />
                                    <div [hidden]="userPostcode.valid || userPostcode.pristine || aboutContactDetailsSubmit" class="alert alert-danger">
                                        Please enter your postcode
                                    </div>
                                    <div [model-validated]="user.address" [property-name]="'postcode'" model-validation-display></div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="userState" class="col-xs-24 col-sm-8 control-label">
                                    State you live in
                                </label>
                                <div class="col-xs-12 col-sm-3">
                                    <select id="userState" class="form-control" name="userState" disabled
                                            [(ngModel)]="user.address.state" #userState="ngModel"
                                            [fieldValidation]="user.address"
                                            [fieldName]="'state'"
                                            [fieldGuard]="aboutContactDetailsSubmit"
                                            [validationStep]="3"
                                            required>
                                        <option value="">Select State</option>
                                        <option *ngFor="let state of STATES | keys" [value]="STATES[state.value]">{{state.value}}</option>
                                    </select>
                                    <div [model-validated]="user.address" [property-name]="'state'" model-validation-display></div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="userContactNumber" class="col-sm-8 control-label">
                                    Primary contact number
                                </label>
                                <div class="col-sm-10">
                                    <input type="tel" class="form-control" id="userContactNumber" name="userContactNumber" placeholder="Mobile or landline number with area code"
                                           [(ngModel)]="user.contactNumber" #userContactNumber="ngModel"
                                           [fieldValidation]="user"
                                           [fieldName]="'contactNumber'"
                                           [fieldGuard]="aboutContactDetailsSubmit"
                                           [validationStep]="3"
                                           (ngModelChange)="updatePhoneNumber($event)"
                                           (keyup)="updateNumberOnDel($event)"
                                           maxlength="{{maxPhoneLength}}" pattern="(04)|(\(0[023578]\)\s)([0-9\s]{9,10})"
                                           required />
                                    <div [hidden]="userContactNumber.valid || userContactNumber.pristine || aboutContactDetailsSubmit" class="alert alert-danger">
                                        Please enter a valid Australian phone number including the area code
                                    </div>
                                    <div [model-validated]="user" [property-name]="'contactNumber'" model-validation-display></div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="userMail" class="col-sm-8 col-xs-24 control-label">
                                    Email address
                                </label>
                                <div class="col-sm-10">
                                    <input type="email" class="form-control" id="userMail" name="userMail" placeholder="Your email address"
                                           [(ngModel)]="user.email" #userMail="ngModel"
                                           [fieldValidation]="user"
                                           [fieldName]="'email'"
                                           [fieldGuard]="aboutContactDetailsSubmit"
                                           [validationStep]="3"
                                           (ngModelChange)="updateDeliveryMethod()"
                                           (keyup)="(($event.key == 8 || $event.key == 46)? (user.email=$event.target.value):'')" required />
                                    <div [hidden]="userMail.valid || userMail.pristine || aboutContactDetailsSubmit" class="alert alert-danger">
                                        Please enter a valid email address
                                    </div>
                                    <div [model-validated]="user" [property-name]="'email'" model-validation-display></div>
                                </div>
                            </div>
                            <div class="form-group last">
                                <label class="col-xs-24 col-sm-8 control-label">
                                    Documentation delivery method
                                </label>
                                <div class="col-sm-10">
                                    <div class="row">
                                        <div class="col-xs-24">
                                            By clicking 'Email' you agree to receive all insurance documentation relating
                                            to your {{ insurance.insuranceName }} policy electronically,
                                            to the nominated email address above. You may amend this to postal delivery
                                            at any time by calling {{ insurance.brandPhone }}.
                                            <div class="item-spacer-10"></div>
                                        </div>
                                        <div class="col-xs-12">
                                            <button type="button" class="btn radio-btn col-xs-24"
                                                    (click)="cover.eDeliveryMethod = true"
                                                    [ngClass]="{ 'active': cover.eDeliveryMethod}"
                                                    [disabled]="user.email === EMAIL_GREENSTONE">
                                                Email
                                            </button>
                                        </div>
                                        <div class="col-xs-12">
                                            <button type="button" class="btn radio-btn col-xs-24"
                                                    (click)="cover.eDeliveryMethod = false"
                                                    [ngClass]="{ 'active': !cover.eDeliveryMethod }">
                                                Post
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="clearfix item-spacer-20"></div>
                            <div class="col-sm-offset-8 col-sm-10 text-center">
                                <button id="contact-details-submit-btn" type="button" class="btn submit-btn btn-lg width-100 mobile-not-full"
                                        data-toggle="collapse" data-parent="#buy-form-group" data-target="#pet-cover-form"
                                        (click)="validateAboutContactDetails($event)">
                                    Next
                                </button>
                            </div>
                            <div class="clearfix item-spacer-20"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="pet-cover-panel" class="panel panel-default">
            <div class="panel-heading">
                <div class="row panel-title collapsed" data-toggle="collapse" data-parent="#buy-form-group"
                    data-target="#pet-cover-form" (click)="checkPreviousAndGo($event, 'pet-cover-panel')">
                    <div class="col-sm-24 text-center">
                        <h2>Your pet's cover</h2>
                        <span class="glyphicon glyphicon-menu-up pull-right panel-arrow"></span>
                        <div class="validation-group">
                            <div class="valid" *ngIf="petCoverValid === true">
                                <span class="glyphicon glyphicon-ok"></span>
                                Completed
                            </div>
                            <div class="invalid" *ngIf="petCoverValid === false">
                                <span class="glyphicon glyphicon-warning-sign"></span>
                                Details required
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="pet-cover-form" class="panel-collapse collapse">
                <div class="panel-body">
                    <div class="row">
                        <div class="col-sm-24 m-t-1">
                            <div class="form-group">
                                <label class="col-sm-8 control-label">Your Pet Insurance Premium is</label>
                                <div class="col-sm-10">
                                    <h2>{{ totalPrice | customCurrency:2:'$'
                                        }}<span>{{FREQUENCY[cover.frequency]}}ly</span></h2>
                                    <div style="font-size:15.2px">Due to rounding, your first premium amount may be
                                        slightly different with the remaining instalments being a consistent amount.
                                    </div>
                                    <div class="alert alert-info" *ngIf="petAgeChanged">
                                        Due to the policy start date being after {{moreThanOnePetAgeChanged? 'your pets'
                                        : petNameAgeChanged + "'s"}} birthday, the premium amount has been updated.
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label for="coverBegin" class="col-sm-8 control-label">
                                    When would you like your cover to begin?
                                    <div class="tooltip-xs">
                                        <a href="javascript:void(0);" class="tooltip-link" tooltipPlacement="bottom"
                                            tabindex="999"
                                            tooltip="Your policy starts at one minute to midnight (11:59pm) on the policy commencement date">
                                            <span class="help-icon"></span>
                                        </a>
                                    </div>
                                </label>
                                <div class="col-sm-10 col-xs-24">
                                    <select id="coverBegin" class="form-control" name="cardType"
                                        [(ngModel)]="cover.coverStartDate" [fieldValidation]="cover"
                                        [fieldName]="'coverStartDate'" [fieldGuard]="petCoverSubmit"
                                        #coverBegin="ngModel" required>
                                        <option *ngFor="let coverDateOption of coverDateList"
                                            [ngValue]="coverDateOption">{{coverDateOption.toDateString()}}</option>
                                    </select>
                                    <div [model-validated]="cover" [property-name]="'coverStartDate'"
                                        model-validation-display></div>
                                </div>
                                <div class="col-sm-6 hidden-xs">
                                    <a href="javascript:void(0);" class="tooltip-link" tooltipPlacement="bottom"
                                        tabindex="999"
                                        tooltip="Your policy starts at one minute to midnight (11:59pm) on the policy commencement date">
                                        <span class="help-icon"></span>
                                    </a>
                                </div>
                            </div>
                            <div class="form-group last">
                                <label for="premiumStart" class="col-sm-8 control-label">
                                    What date would you like to start paying your premium?
                                    <div class="tooltip-xs">
                                        <a href="javascript:void(0);" class="tooltip-link" tooltipPlacement="bottom"
                                            tabindex="999"
                                            tooltip="Please note that your first payment date can only be on a weekday, and not on a public holiday.">
                                            <span class="help-icon"></span>
                                        </a>
                                    </div>
                                </label>
                                <div class="col-sm-10 col-xs-22">
                                    <select id="premiumStart" class="form-control" name="cardType"
                                        [(ngModel)]="cover.premiumStartDate" [fieldValidation]="cover"
                                        [fieldName]="'premiumStartDate'" [fieldGuard]="petCoverSubmit"
                                        #premiumStart="ngModel" required>
                                        <option *ngFor="let premiumDateOption of premiumDateList"
                                            [ngValue]="premiumDateOption">{{premiumDateOption.toDateString()}}</option>
                                    </select>
                                    <div [model-validated]="cover" [property-name]="'premiumStartDate'"
                                        model-validation-display></div>
                                </div>
                                <div class="col-sm-6 hidden-xs">
                                    <a href="javascript:void(0);" class="tooltip-link" tooltipPlacement="bottom"
                                        tabindex="999"
                                        tooltip="Please note that your first payment date can only be on a weekday, and not on a public holiday.">
                                        <span class="help-icon"></span>
                                    </a>
                                </div>
                                <div class="col-sm-10 col-xs-22" *ngIf="hasFreeMonthOffer && cover.frequency !== FREQUENCY.Year"
                                    style="font-size: 15.2px; margin-top: 15px;">
                                    Please note as part of the one month free offer your first payment date will be one month from your selected payment date.
                                </div>
                            </div>
                            <div class="clearfix item-spacer-20"></div>
                            <div class="col-sm-offset-8 col-sm-10 text-center">
                                <button [disabled]="!this.enablePetCoverNextButton" id="pet-cover-submit-btn"
                                type="button" class="btn submit-btn btn-lg width-100 mobile-not-full"
                                data-toggle="collapse" data-parent="#buy-form-group" data-target="#payment-form"
                                (click)="validatePetCover($event)">
                                Next
                            </button>
                            </div>
                            <div class="clearfix item-spacer-20"></div>
                            <div class="col-sm-offset-8 col-sm-10 text-center">
                                <div *ngIf="!updatingCoverDates && this.isAnyPetCoverDateUpdateError"
                                    class="alert alert-danger">
                                    {{this.message}}
                                </div>
                                <div *ngIf="updatingCoverDates">
                                    <div class="loading-container">
                                        <div class="loading-container-table">
                                            <div>
                                                <span class="glyphicon glyphicon-refresh rotating"></span>
                                            </div>
                                            <div>
                                                {{this.message}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div id="payment-details-panel" class="panel panel-default">
            <div class="panel-heading">
                <div class="row panel-title collapsed"
                     data-toggle="collapse"
                     data-parent="#buy-form-group"
                     data-target="#payment-form"
                     (click)="checkPreviousAndGo($event, 'payment-details-panel')">
                    <div class="col-sm-24 text-center">
                        <h2>Payment details</h2>
                        <small><em>All fields are mandatory</em></small>
                        <span class="glyphicon glyphicon-menu-up pull-right panel-arrow"></span>
                        <div class="validation-group">
                            <div class="valid" *ngIf="paymentValid === true">
                                <span class="glyphicon glyphicon-ok"></span>
                                Completed
                            </div>
                            <div class="invalid" *ngIf="paymentValid === false">
                                <span class="glyphicon glyphicon-warning-sign"></span>
                                Details required
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="payment-form" class="panel-collapse collapse">
                <div class="panel-body">
                    <form id="credit-card-payment-form" class="form-horizontal padding-0-xs" method="{{paymentGatewaySubmitMethod}}" autocomplete="off" autofill="off"
                          action="{{payment.postUrl}}">
                        <input name="token" type="hidden" [value]="payment.token"/>
                        <input name="dt_expiry" type="hidden" [value]="payment.paymentMethod.getExpiryDateOneWord()"/>
                        <input name="cd_community" type="hidden" [value]="payment.community"/>
                        <input name="action" type="hidden" value="RegisterAccount"/>
                        <input name="cd_supplier_business" type="hidden" [value]="payment.supplierBusiness"/>
                        <input name="cd_crn" type="hidden" [value]="payment.crn"/>
                        <input name="returnURL" type="hidden" [value]="returnUrl"/>
                        <input name="serviceReturnURL" type="hidden" [value]="returnUrl"/>
                        <input name="mode" type="hidden" value="Safe"/>
                        <input name="accountType" type="hidden" value="{{ (payment.type === PAYMENTTYPE.ACCOUNT) ? 'DD' : 'CC' }}"/>
                        <input name="http_response_type" type="hidden" [value]="payment.responseType"/>
                        <input name="fl_ddr_agree" type="hidden" value="{{ (payment.type === PAYMENTTYPE.ACCOUNT) ? true : '' }}"/>
                        <div class="row">
                            <div class="col-sm-24 m-t-1">
                                <div class="form-group">
                                    <label class="col-sm-8 col-xs-24 control-label">
                                        Choose a payment method
                                    </label>
                                    <div class="col-xs-24 col-sm-10">
                                        <div id="paymentType" class="row">
                                            <div class="col-xs-12">
                                                <button type="button" class="btn radio-btn col-xs-24 account-card-btn"
                                                        (click)="payment.selectPaymentType(PAYMENTTYPE.ACCOUNT)"
                                                        [ngClass]="{ 'active': payment.type === PAYMENTTYPE.ACCOUNT }">
                                                    Direct debit
                                                </button>
                                            </div>
                                            <div class="col-xs-12">
                                                <button type="button" class="btn radio-btn col-xs-24 credit-card-btn"
                                                        (click)="payment.selectPaymentType(PAYMENTTYPE.CARD)"
                                                        [ngClass]="{ 'active': payment.type === PAYMENTTYPE.CARD }">
                                                    Credit card
                                                </button>
                                            </div>
                                            <div class="col-xs-24 item-spacer-10"></div>
                                            <div class="col-xs-24 form-info">
                                                <div class="row row-height sm-row-height">
                                                    <div class="col-xs-4 text-center col-height col-middle lh-1">
                                                        <span class="lock-icon"></span>
                                                    </div>
                                                    <div class="col-xs-20 col-height col-middle">
                                                        Our payment forms are secured with 256bit encryption
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="payment.type === PAYMENTTYPE.ACCOUNT">
                                    <div class="form-group">
                                        <label for="accountName" class="col-xs-24 col-sm-8 control-label">
                                            Account name
                                        </label>
                                        <div class="col-sm-10">
                                            <input type="text" class="form-control" id="accountName" name="nm_account" placeholder="John Smith"
                                                   [(ngModel)]="payment.paymentMethod.name" #accountName="ngModel"
                                                   [fieldValidation]="payment.paymentMethod"
                                                   [fieldName]="'name'"
                                                   [fieldGuard]="paymentSubmit"
                                                   (keyup)="(($event.key == 8 || $event.key == 46)? (payment.paymentMethod.name=$event.target.value):'')"
                                                   pattern="[a-zA-Z\s\-]+"
                                                   required/>
                                            <div [hidden]="accountName.valid || accountName.pristine || paymentSubmit" class="alert alert-danger">
                                                Please enter the account owner name
                                            </div>
                                            <div [model-validated]="payment.paymentMethod" [property-name]="'name'" model-validation-display></div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="accountName" class="col-xs-24 col-sm-8 control-label">
                                            BSB
                                        </label>
                                        <div class="col-sm-10">
                                            <input type="tel" class="form-control" id="accountBSB" name="no_bsb" placeholder="123456"
                                                   [(ngModel)]="payment.paymentMethod.BSB" #accountBSB="ngModel"
                                                   [fieldValidation]="payment.paymentMethod"
                                                   [fieldName]="'BSB'"
                                                   [fieldGuard]="paymentSubmit"
                                                   (keyup)="(($event.key == 8 || $event.key == 46)? (payment.paymentMethod.BSB=$event.target.value):'')"
                                                   pattern="[0-9]+"
                                                   maxlength="6"
                                                   required />
                                            <div [hidden]="accountBSB.valid || accountBSB.pristine || paymentSubmit" class="alert alert-danger">
                                                Please enter the account BSB
                                            </div>
                                            <div [model-validated]="payment.paymentMethod" [property-name]="'BSB'" model-validation-display></div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="accountNumber" class="col-xs-24 col-sm-8 control-label">
                                            Account number
                                        </label>
                                        <div class="col-sm-10">
                                            <input type="tel" class="form-control" id="accountNumber" name="no_account" placeholder="123456789"
                                                   [(ngModel)]="payment.paymentMethod.accountNumber" #accountNumber="ngModel"
                                                   [fieldValidation]="payment.paymentMethod"
                                                   [fieldName]="'accountNumber'"
                                                   [fieldGuard]="paymentSubmit"
                                                   (keyup)="(($event.key == 8 || $event.key == 46)? (payment.paymentMethod.accountNumber=$event.target.value):'')"
                                                   pattern="[0-9]{1,9}"
                                                   maxlength="9"
                                                   required />
                                            <div [hidden]="accountNumber.valid || accountNumber.pristine || paymentSubmit" class="alert alert-danger">
                                                Please enter the account number
                                            </div>
                                            <div [model-validated]="payment.paymentMethod" [property-name]="'accountNumber'" model-validation-display></div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="payment.type === PAYMENTTYPE.CARD">
                                    <div class="form-group">
                                        <label for="cardName" class="col-xs-24 col-sm-8 control-label">
                                            Name on card
                                        </label>
                                        <div class="col-sm-10">
                                            <input type="text" class="form-control" id="cardName" name="nm_card_holder" placeholder="John Smith"
                                                   [(ngModel)]="payment.paymentMethod.cardOwnerName" #cardName="ngModel"
                                                   [fieldValidation]="payment.paymentMethod"
                                                   [fieldName]="'cardOwnerName'"
                                                   [fieldGuard]="paymentSubmit"
                                                   (keyup)="(($event.key == 8 || $event.key == 46)? (payment.paymentMethod.cardOwnerName=$event.target.value):'')"
                                                   pattern="[a-zA-Z\s\-]+"
                                                   required />
                                            <div [hidden]="cardName.valid || cardName.pristine || paymentSubmit" class="alert alert-danger">
                                                Please enter the card owner name
                                            </div>
                                            <div [model-validated]="payment.paymentMethod" [property-name]="'cardOwnerName'" model-validation-display></div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="cardNumber" class="col-xs-24 col-sm-8 control-label">
                                            Card number
                                        </label>
                                        <div class="col-sm-10">
                                            <input type="tel" class="form-control" id="cardNumber" name="no_credit_card" placeholder="123456789"
                                                   [(ngModel)]="payment.paymentMethod.cardNumber" #cardNumber="ngModel"
                                                   [fieldValidation]="payment.paymentMethod"
                                                   [fieldName]="'cardNumber'"
                                                   [fieldGuard]="paymentSubmit"
                                                   (keyup)="(($event.key == 8 || $event.key == 46)? (payment.paymentMethod.cardNumber=$event.target.value):'')"
                                                   pattern="[0-9]+"
                                                   maxlength="16"
                                                   required />
                                            <div [hidden]="cardNumber.valid || cardNumber.pristine || paymentSubmit" class="alert alert-danger">
                                                Please enter the card number
                                            </div>
                                            <div [model-validated]="payment.paymentMethod" [property-name]="'cardNumber'" model-validation-display></div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="cardExpiryMonth" class="col-sm-8 control-label">Card expiry date</label>
                                        <div class="col-sm-10">
                                            <div class="row">
                                                <div class="col-xs-8">
                                                    <input type="tel" class="form-control text-center" id="cardExpiryMonth" name="dt_expiry_month" placeholder="MM"
                                                           [(ngModel)]="payment.paymentMethod.cardExpiryMonth" #cardExpiryMonth="ngModel"
                                                           [fieldValidation]="payment.paymentMethod"
                                                           [fieldName]="'cardExpiryMonth'"
                                                           [fieldGuard]="paymentSubmit"
                                                           (keyup)="(($event.key == 8 || $event.key == 46)? (payment.paymentMethod.cardExpiryMonth=$event.target.value):'')"
                                                           maxlength="2" pattern="([1-9]|[1][0-2]|0[1-9])"
                                                           required/>
                                                    <div [hidden]="cardExpiryMonth.valid || cardExpiryMonth.pristine || paymentSubmit" class="alert alert-danger">
                                                        Please enter the expiration month
                                                    </div>
                                                    <div [model-validated]="payment.paymentMethod" [property-name]="'cardExpiryMonth'" model-validation-display></div>
                                                </div>
                                                <div class="col-xs-8">
                                                    <input type="tel" class="form-control text-center" id="cardExpiryYear" name="dt_expiry_year" placeholder="YY"
                                                           [(ngModel)]="payment.paymentMethod.cardExpiryYear" #cardExpiryYear="ngModel"
                                                           [fieldValidation]="payment.paymentMethod"
                                                           [fieldName]="'cardExpiryYear'"
                                                           [fieldGuard]="paymentSubmit"
                                                           (keyup)="(($event.key == 8 || $event.key == 46)? (payment.paymentMethod.cardExpiryYear=$event.target.value):'')"
                                                           maxlength="2" pattern="[1-3][0-9]"
                                                           required/>
                                                    <div [hidden]="cardExpiryYear.valid || cardExpiryYear.pristine || paymentSubmit" class="alert alert-danger">
                                                        Please enter the expiration year
                                                    </div>
                                                    <div [model-validated]="payment.paymentMethod" [property-name]="'cardExpiryYear'" model-validation-display></div>
                                                </div>
                                                <div class="col-xs-16">
                                                    <div [model-validated]="payment.paymentMethod" [property-name]="'cardExpiryDate'" model-validation-display></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="clearfix item-spacer-20"></div>
                                <div class="col-sm-offset-8 col-sm-10 text-center">
                                    <button id="payment-details-submit-btn" type="button" class="btn submit-btn btn-lg width-100 mobile-not-full"
                                            data-toggle="collapse" data-parent="#buy-form-group" data-target="#declaration-form"
                                            (click)="validatePayment($event)">
                                        Next
                                    </button>
                                    <div class="clearfix item-spacer-20"></div>
                                    <div [model-validated]="loading" [property-name]="'paymentProcessError'" model-validation-display></div>
                                </div>
                                <div class="clearfix item-spacer-20"></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div> -->        
        <div id="payment-details-panel" class="panel panel-default">
            <div class="panel-heading">
                <div class="row panel-title collapsed"
                     data-toggle="collapse"
                     data-parent="#buy-form-group"
                     data-target="#payment-form"
                     (click)="checkPreviousAndGo($event, 'payment-details-panel')">
                    <div class="col-sm-24 text-center">
                        <h2>Payment details</h2>
                        <small><em>All fields are mandatory</em></small>
                        <span class="glyphicon glyphicon-menu-up pull-right panel-arrow"></span>
                        <div class="validation-group">
                            <div class="valid" *ngIf="paymentValid === true">
                                <span class="glyphicon glyphicon-ok"></span>
                                Completed
                            </div>
                            <div class="invalid" *ngIf="paymentValid === false">
                                <span class="glyphicon glyphicon-warning-sign"></span>
                                Details required
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="payment-form" class="panel-collapse collapse">
                <div class="panel-body">
                    <form id="credit-card-payment-form" class="form-horizontal padding-0-xs" method="{{paymentGatewaySubmitMethod}}" autocomplete="off" autofill="off"
                          action="{{payment.postUrl}}">
                        <input name="hdnPaymentValid" id="hdnPaymentValid" [(ngModel)]="paymentValid" type="text" style="display:none" />
                        <input name="token" type="hidden" [value]="payment.token"/>
                        <input name="dt_expiry" type="hidden" [value]="payment.paymentMethod.getExpiryDateOneWord()"/>
                        <input name="cd_community" type="hidden" [value]="payment.community"/>
                        <input name="action" type="hidden" value="RegisterAccount"/>
                        <input name="cd_supplier_business" type="hidden" [value]="payment.supplierBusiness"/>
                        <input name="cd_crn" type="hidden" [value]="payment.crn"/>
                        <input name="returnURL" type="hidden" [value]="returnUrl"/>
                        <input name="serviceReturnURL" type="hidden" [value]="returnUrl"/>
                        <input name="mode" type="hidden" value="Safe"/>
                        <input name="accountType" type="hidden" value="{{ (payment.type === PAYMENTTYPE.ACCOUNT) ? 'DD' : 'CC' }}"/>
                        <input name="http_response_type" type="hidden" [value]="payment.responseType"/>
                        <input name="fl_ddr_agree" type="hidden" value="{{ (payment.type === PAYMENTTYPE.ACCOUNT) ? true : '' }}"/>
                        <div class="row">
                            <div class="col-sm-24 m-t-1">
                                <div class="form-group">
                                    <label class="col-sm-8 col-xs-24 control-label">
                                        Choose a payment method
                                    </label>
                                    <div class="col-xs-24 col-sm-10">
                                        <div id="paymentType" class="row">
                                            <div class="col-xs-12">
                                                <button type="button" class="btn radio-btn col-xs-24 account-card-btn"
                                                        (click)="SelectPaymentType(PAYMENTTYPE.ACCOUNT)"
                                                        [ngClass]="{ 'active': payment.type === PAYMENTTYPE.ACCOUNT }">
                                                    Direct debit
                                                </button>
                                            </div>
                                            <div class="col-xs-12">
                                                <button type="button" class="btn radio-btn col-xs-24 credit-card-btn"
                                                        (click)="SelectPaymentType(PAYMENTTYPE.CARD)"
                                                        [ngClass]="{ 'active': payment.type === PAYMENTTYPE.CARD }">
                                                    Credit card
                                                </button>
                                            </div>
                                            <div class="col-xs-24 item-spacer-10"></div>
                                            <div class="col-xs-24 form-info">
                                                <div class="row row-height sm-row-height">
                                                    <div class="col-xs-4 text-center col-height col-middle lh-1">
                                                        <span class="lock-icon"></span>
                                                    </div>
                                                    <div class="col-xs-20 col-height col-middle">
                                                        Our payment forms are secured with 256bit encryption
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <payment-register                                     
                                    applicationname='choosipet' 
                                    [attr.showcreditcard]='true'
                                    [attr.gfsauthtoken]="bearerToken"
                                    [attr.hidebutton]='true'
                                    [attr.environment]="environment"
                                    [attr.cdcrn]="payment.crn"
                                    [attr.clientid]="user.id"
                                    [attr.quoteid]="cover.quoteId"
                                    bankinfoname="petsure_qvalent"
                                    [attr.actionurl]="actionUrl"
                                    [attr.paymentapiurl]="paymentGatewayUrl"
                                    [attr.tokenizedd]="true"
                                    [attr.disableserverpost]="true"
                                    [attr.ispetstp]="true"
                                    ></payment-register>
                                    <!-- <script src="PaymentGatewayJSFile"></script> -->                                    
                                </div>
                                
                                <div class="clearfix item-spacer-20"></div>
                                <div class="col-sm-offset-8 col-sm-10 text-center">
                                    <button id="payment-details-submit-btn" type="button" class="btn submit-btn btn-lg width-100 mobile-not-full"
                                            data-toggle="collapse" data-parent="#buy-form-group" data-target="#declaration-form"
                                            (click)="validatePayment($event)">
                                        Next
                                    </button>
                                    <div class="clearfix item-spacer-20"></div>
                                    <div [model-validated]="loading" [property-name]="'paymentProcessError'" model-validation-display></div>
                                </div>
                                <div class="clearfix item-spacer-20"></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div id="your-declaration-panel" class="panel panel-default">
            <div class="panel-heading">
                <div class="row panel-title collapsed"
                     data-toggle="collapse"
                     data-parent="#buy-form-group"
                     data-target="#declaration-form"
                     (click)="checkPreviousAndGo($event, 'your-declaration-panel')">
                    <div class="col-sm-24 text-center">
                        <h2>Your declaration</h2>
                        <span class="glyphicon glyphicon-menu-up pull-right panel-arrow"></span>
                        <div class="validation-group">
                            <div class="valid" *ngIf="declarationValid === true">
                                <span class="glyphicon glyphicon-ok"></span>
                                Completed
                            </div>
                            <div class="invalid" *ngIf="declarationValid === false">
                                <span class="glyphicon glyphicon-warning-sign"></span>
                                Details required
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div id="declaration-form" class="panel-collapse collapse">
                <div class="panel-body">
                    <div class="row">
                        <div class="panel-body">
                            <div class="row">
                                <div class="col-xs-24">
                                    <div id="confirmation-txt" class="declaration-text pre-scrollable" [innerHTML]="disclaimerDisplay">
                                    </div>
                                    <div class="item-spacer-10"></div>
                                    <div class="checkbox  text-center form-group">
                                        <label class="control-label checkbox-double-size" [ngClass]="{ 'check-required': !cover.preExistingConditionsDeclaration && declarationSubmit }">
                                            <input type="checkbox" id="isTermsAccepted"
                                            [(ngModel)]="cover.preExistingConditionsDeclaration"
                                            #isTermsAccepted="ngModel"
                                            [fieldValidation]="cover"
                                            [fieldName]="'preExistingConditionsDeclaration'"
                                            [fieldGuard]="declarationSubmit"
                                            [validationStep]="4"/>
                                            I understand and accept
                                        </label>
                                        <div class="item-spacer-10 clearfix"></div>
                                        <div class="col-xs-24 col-sm-offset-6 col-sm-12">
                                            <div [model-validated]="cover" [property-name]="'preExistingConditionsDeclaration'" model-validation-display></div>
                                        </div>
                                    </div>
                                    <div class="form-group last">
                                        <label class="col-sm-8 control-label">Have you ever had an insurance policy cancelled by an insurer?</label>
                                        <div class="col-sm-10">
                                            <div class="row">
                                                <div class="col-xs-12">
                                                    <button type="button" class="btn radio-btn col-xs-24"
                                                            (click)="cover.hadPriorPolicyCancelled = true"
                                                            [ngClass]="{ 'active': cover.hadPriorPolicyCancelled }">
                                                        Yes
                                                    </button>
                                                </div>
                                                <div class="col-xs-12">
                                                    <button type="button" class="btn radio-btn col-xs-24"
                                                            (click)="cover.hadPriorPolicyCancelled = false"
                                                            [ngClass]="{ 'active': !cover.hadPriorPolicyCancelled }">
                                                        No
                                                    </button>
                                                </div>
                                            </div>
                                            <div [model-validated]="cover" [property-name]="'hadPriorPolicyCancelled'" model-validation-display></div>
                                        </div>
                                    </div>
                                    <div class="clearfix item-spacer-30"></div>
                                    <div class="col-sm-offset-8 col-sm-10 text-center">
                                        <button id="your-declaration-submit-btn" type="button" class="btn submit-btn btn-lg width-100 mobile-not-full"
                                                *ngIf="!loading.activated"
                                                (click)="validateDeclaration()">
                                            Confirm Purchase
                                        </button>
                                        <div *ngIf="loading.activated" class="loading-container">
                                            <div class="loading-container-table">
                                                <div>
                                                    <h3>
                                                        <span class="glyphicon glyphicon-refresh rotating"></span>
                                                    </h3>
                                                </div>
                                                <div>
                                                    <h3>
                                                        {{loading.loadingText}}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="clearfix item-spacer-20"></div>
                                        <div [hidden]="!globalError" class="alert alert-danger">
                                            Please fill all the previous form correctly
                                        </div>
                                        <div [model-validated]="loading" [property-name]="'applicationProcessError'" model-validation-display></div>
                                    </div>
                                    <div class="clearfix item-spacer-20"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
<div class="container">
    <div class="row">
        <div class="col-xs-20 col-xs-offset-2 col-sm-8 col-sm-offset-0 col-md-5">
            <div class="item-spacer-10"></div>
            <button type="button" class="btn btn-lg action-btn width-100"
                    [ngClass]="{ 'disabled': loading.activated }"
                    (click)="goToRoute('/compare')">
                <span class="glyphicon glyphicon-menu-left"></span>
                Previous step
            </button>
            <div class="item-spacer-30 visible-xs"></div>
        </div>
    </div>
</div>
<div *ngIf="processRunning" class="process-overlay">
    <div class="loading-container">
        <div class="loading-container-table">
            <div>
                <h3>
                    <span class="glyphicon glyphicon-refresh rotating"></span>
                </h3>
            </div>
            <div>
                <h3>
                    {{loading.loadingText}}
                </h3>
            </div>
        </div>
    </div>
</div>
<div *ngIf="removePetPopupEnabled" class="custom-popup-windows" [ngClass]="{ 'activated': removePetPopupEnabled }">
    <div class="custom-popup-shadow" (click)="disableRemovePetPopup()"></div>
    <div class="custom-popup-container">
        <div class="custom-popup-content">
            <div class="row">
                <div class="col-xs-24 text-center">
                    <h3>
                        Are you sure that you want delete this pet ?
                    </h3>
                    <p>
                                You will lose all the information about this pet.
                    </p>
                </div>
                <div class="col-xs-11">
                    <button type="button" (click)="confirmRemovePet()" class="btn action-btn width-100 confirm-remove-pet-btn">
                        <h3 class="margin-0">Yes</h3>
                    </button>
                </div>
                <div class="col-xs-offset-2 col-xs-11">
                    <button type="button" (click)="disableRemovePetPopup()" class="btn action-btn width-100 decline-remove-pet-btn">
                        <h3 class="margin-0">No</h3>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- build:dev -->
<div *ngIf="!isProdEnv" class="container" style="word-wrap: break-word;">
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <textarea id="diagnostic" cols="50" rows="25" style="width:100%" [innerHTML]="diagnostic"></textarea>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
</div>
<!-- endbuild -->
