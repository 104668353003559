import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * Subscription service to share variable across the whole application via an id.
 */
@Injectable()
export class SubscriptionService {

    private subscriptions = new Map<string, BehaviorSubject<any>>();

    /**
     * Create a new Observable variable associated with the key.
     *
     * @param id The key which will be associated with the variable.
     * @param value The observable variable which will be shared across the application as a BehaviorSubject object.
     * @returns {BehaviorSubject<T>} The observable variable created, throw an error if the variable already exist.
     */
    newObservable<T>(id: string, value: T): BehaviorSubject<T> {
        if (!this.subscriptions.has(id)) {
            this.subscriptions.set(id, new BehaviorSubject(value));
            return this.subscriptions.get(id);
        } else {
            throw new Error('The observable "' + id + '" already exist');
        }
    }

    /**
     * Remove the variable from the application sharing pool.
     * @param id The id of the shared variable.
     */
    removeObservable(id: string): void {
        if (this.subscriptions.has(id)) {
            this.subscriptions.get(id).unsubscribe();
            this.subscriptions.delete(id);
        }
    }

    /**
     * Get the shared variable if it exist, throw an error otherwise.
     * todo maybe replace by null instead of throwing an error.
     *
     * @param id The id of the variable which will be gathered.
     * @returns {BehaviorSubject<any>} The observable variable shared across the application or an Error if it does not exist.
     */
    getSubscriptions<T>(id: string): BehaviorSubject<T> {
        if (this.subscriptions.has(id)) {
            return this.subscriptions.get(id);
        } else {
            throw new Error('The observable "' + id + '" does not exist');
        }
    }
}
