import { Injectable }             from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';

import { Pet } from '../model/pet';
import { User } from '../model/user';
import { Theme } from '../model/theme';
import { Loading } from '../model/loading';
import { Insurance } from '../model/insurance';
import { InsuranceCovers } from '../model/insurance-covers';

import { SubscriptionService } from '../services/subscription.service';
import { DiscountService } from '../services/discount.service';
import { PremiumService } from '../services/premium.service';
import { ClientService } from '../services/client.service';


@Injectable()
export class PremiumResolve implements Resolve<InsuranceCovers> {

    loading: Loading;
    activeRoute: ActivatedRouteSnapshot;

    constructor(
        private subscriptionService: SubscriptionService,
        private discountService: DiscountService,
        private clientService: ClientService,
        private ps: PremiumService,
        private router: Router
    ) {}

    redirectError(): InsuranceCovers {
        this.router.navigate(['']);
        return null;
    }

    disableLoading(error: string = '') {
        this.subscriptionService
            .getSubscriptions<Loading>('loading')
            .subscribe(
                loading => {
                    this.loading = loading;
                    this.loading.activated = false;
                    // to clear the errors
                    this.loading.validate();
                    if (error !== '') {
                        this.loading.setError('searchingPremiumError', error);
                    }
                }
            );
    }

    resolve(route: ActivatedRouteSnapshot): Promise<InsuranceCovers>|InsuranceCovers {
        this.activeRoute = route;
        try {
            let pet = this.getPet();
            let user = this.subscriptionService.getSubscriptions<User>('user').getValue();
            let theme = this.subscriptionService.getSubscriptions<Theme>('theme').getValue();

            let petValid = pet !== null && pet.validate();
            let userValid = user !== null && user.validate();

            if (petValid && userValid) {
                return this.discountService
                    .isCustomerEligibleForSeniorsDiscount(user)
                    .then(
                        (eligibleForSenior) => {
                            user.eligibleForSenior = eligibleForSenior;
                            this.clientService.updatePageForClient(theme,  user, pet, 2);
                            return this.getAllPremiums(theme.id, pet, user);
                        },
                        error => {
                            this.disableLoading(error.message);
                            return this.redirectError();
                        }
                    );
            }
        } catch (e) {
            console.error('Cannot access to this page there is missing elements: ' + e.toString());
        }
        return this.redirectError();
    }

    private getAllPremiums(partnerId: number, pet: Pet, user: User): Promise<any>|boolean {
        return this.ps.getAllBenefitsAndPremiums(1, partnerId, user, pet)
            .then(
                premiums => {
                    this.disableLoading();
                    return this.filterBrandAndSetPet(premiums);
                },
                error => {
                    this.disableLoading(error.message);
                    return this.redirectError();
                }
            );
    }

    private filterBrandAndSetPet(premiums: InsuranceCovers): InsuranceCovers|boolean {
        let brandFilterParam = 'brandId';
        if (this.activeRoute.queryParams.hasOwnProperty(brandFilterParam)) {
            let brandId: number = Number(this.activeRoute.queryParams[brandFilterParam]);
            premiums.brandIdFilter = brandId;
            premiums.insurances = premiums.insurances.filter(
                (insurance: Insurance) => {
                    return insurance.brandId === brandId;
                }
            );
        }
        let petIndexParam = 'petIndex';
        if (this.activeRoute.queryParams.hasOwnProperty(petIndexParam)) {
            premiums.petToUpdate = this.activeRoute.queryParams[petIndexParam];
        }
        return premiums;
    }

    private getPet(): Pet {
        let pet = this.subscriptionService.getSubscriptions<Pet>('pet').getValue();
        let petIndexParam = 'petIndex';
        if (this.activeRoute.queryParams.hasOwnProperty(petIndexParam)) {
            let pets = this.subscriptionService.getSubscriptions<Pet[]>('pets').getValue();
            let index = this.activeRoute.queryParams[petIndexParam];
            if (pets.length >= index) {
                pet = pets[index];
            }
        }
        return pet;
    }
}
