export class RecommendedProduct {
    constructor(
        public id: number,
        public title: string,
        public mainText: string,
        public subText: string,
        public quoteUrl: string,
        public imgPath: string
    ) {}

    getIconClass(): string {
        return this.title.toLowerCase().replace(new RegExp(' ', 'g'), '-');
    }
}
