import { ModelValidator } from './model-validator';

export class Breed extends ModelValidator {

    static getBlank(): Breed {
        return new Breed('', '', false);
    }

    constructor(
        public breedCode: string,
        public breed: string,
        public crossBreed: boolean
    ) {
        super();
    }

    protected proceedValidation(step: number): boolean {
        if (this.breedCode.trim() === '') {
            this.setError('breedCode', 'Please select the breed of your pet');
        }
        if (this.breed.trim() === '') {
            this.setError('breed', 'Please select the breed of your pet');
        }
        return true;
    }
}
