import { ModelValidator } from './model-validator';

import { Frequency } from './frequency.enum';
import { CoverFieldValue } from './cover-field-value';
import { InsurancePromotion } from './insurance-promotion';
import { InsurancePricing } from './insurance-pricing';
import { StringHelper } from '../helpers/string.helpers';

export class Insurance extends ModelValidator {

    static getBlank(): Insurance {
        return new Insurance('',
            0, null, null, '', '', '', '', false,
            0, '', '', '', '', '', '',
            null, null, null,
            null, null, null,
            '',
            null, null, null,
            null, null, null,
            null,
            false, false, 0, 0
        );
    }

    static getCopy(insurance: Insurance): Insurance {
        let insCopy: Insurance = Insurance.getBlank();
        insCopy.policyBookletLink = insurance.policyBookletLink;
        insCopy.id = insurance.id;
        insCopy.productBenefitId = insurance.productBenefitId;
        insCopy.productCode = insurance.productCode;
        insCopy.petSureProductCode = insurance.petSureProductCode;
        insCopy.petSurePartnerCode = insurance.petSurePartnerCode;
        insCopy.coverName = insurance.coverName;
        insCopy.routineCareAvailable = insurance.routineCareAvailable;

        insCopy.brandId = insurance.brandId;
        insCopy.brandCode = insurance.brandCode;
        insCopy.insuranceName = insurance.insuranceName;
        insCopy.brandLogo = insurance.brandLogo;
        insCopy.brandLogoLarge = insurance.brandLogoLarge;
        insCopy.aboutBrand = insurance.aboutBrand;
        insCopy.brandPhone = insurance.brandPhone;

        insCopy.priceFortnight = insurance.priceFortnight;
        insCopy.priceMonth = insurance.priceMonth;
        insCopy.priceYear = insurance.priceYear;

        insCopy.originalPriceFortnight = insurance.originalPriceFortnight;
        insCopy.originalPriceMonth = insurance.originalPriceMonth;
        insCopy.originalPriceYear = insurance.originalPriceYear;
        insCopy.originalPriceText = insurance.originalPriceText;

        insCopy.routineCarePriceFortnight = insurance.routineCarePriceFortnight;
        insCopy.routineCarePriceMonth = insurance.routineCarePriceMonth;
        insCopy.routineCarePriceYear = insurance.routineCarePriceYear;

        insCopy.originalRoutineCarePriceFortnight = insurance.originalRoutineCarePriceFortnight;
        insCopy.originalRoutineCarePriceMonth = insurance.originalRoutineCarePriceMonth;
        insCopy.originalRoutineCarePriceYear = insurance.originalRoutineCarePriceYear;

        insCopy.routineCareSelected = insurance.routineCareSelected;

        insCopy.seniorDiscount = insurance.seniorDiscount;
        insCopy.seniorDiscountAmount = insurance.seniorDiscountAmount;
        insCopy.seniorDiscountAmountRoutineCare = insurance.seniorDiscountAmountRoutineCare;

        insCopy.multiPetDiscount = insurance.multiPetDiscount;
        insCopy.multiPetDiscountRoutineCare = insurance.multiPetDiscountRoutineCare;
        insCopy.multiPetDiscountCount = insurance.multiPetDiscountCount;

        insCopy.coolingOffDays = insurance.coolingOffDays;
        insCopy.minimumPetAgeForAccidentOnly = insurance.minimumPetAgeForAccidentOnly;
        insCopy.accidentBenefitCode = insurance.accidentBenefitCode;
        insCopy.createdDate = insurance.createdDate;
        insCopy.insurancePromotion = InsurancePromotion.getCopy(insurance.insurancePromotion);

        insCopy.excessOptions = insurance.excessOptions;
        insCopy.excessDefault = insurance.excessDefault;
        insCopy.excessSelected = insurance.excessSelected;
        insCopy.pricings = insurance.cloneInsurancePricings();
        insCopy.fields = insurance.fields;
        return insCopy;
    }

    public static isFrequency(value: string, frequency: Frequency): boolean {
        switch (frequency) {
            case Frequency.Fortnight:
                return value.toLowerCase().startsWith('fortnight');
            case Frequency.Month:
                return value.toLowerCase().startsWith('month');
            case Frequency.Year:
                return value.toLowerCase().startsWith('annual');
            default:
                return false;
        }
    }

    constructor(
        public policyBookletLink: string,
        public id: number,
        public quoteDetailsId: number,
        public productBenefitId: number,
        public productCode: string,
        public petSureProductCode: string,
        public petSurePartnerCode: string,
        public coverName: string,
        public routineCareAvailable: boolean,

        public brandId: number,
        public brandCode: string,
        public insuranceName: string,
        public brandLogo: string,
        public brandLogoLarge: string,
        public aboutBrand: string,
        public brandPhone: string,

        public priceFortnight: number,
        public priceMonth: number,
        public priceYear: number,

        public originalPriceFortnight: number,
        public originalPriceMonth: number,
        public originalPriceYear: number,
        public originalPriceText: string,

        public routineCarePriceFortnight: number,
        public routineCarePriceMonth: number,
        public routineCarePriceYear: number,

        public originalRoutineCarePriceFortnight: number,
        public originalRoutineCarePriceMonth: number,
        public originalRoutineCarePriceYear: number,

        public fields: Map<string, CoverFieldValue>,
        public routineCareSelected: boolean = false,
        public shortListed: boolean = false,
        public displayOrder: number = 0,

        public seniorDiscount: number = 0,
        public seniorDiscountAmount: number = 0,
        public seniorDiscountAmountRoutineCare: number = 0,

        public multiPetDiscount: number = 0,
        public multiPetDiscountRoutineCare: number = 0,
        public multiPetDiscountCount: number = 0,

        public coolingOffDays: number = 30,
        public minimumPetAgeForAccidentOnly: number = 99,
        public accidentBenefitCode: string = '',
        public createdDate: string = '',
        public insurancePromotion: InsurancePromotion = null,

        public excessOptions: number[] = [ 0 ],
        public excessDefault: number = 0,
        public excessSelected: number = 0,
        public pricings: InsurancePricing[] = []
    ) {
        super();
    }

    getFields() {
        if (this.fields) {
            return this.fields;
        } else {
            return new Map();
        }
    }

    hasExcess(): boolean {
         return this.excessOptions.length>1;
    }


    hasRoutineCare(): boolean {
        if (!this.pricings || this.pricings.length <= 0) {
            return this.routineCareAvailable;
        }
        for (let pricing of this.pricings) {
            if (pricing.hasRoutineCare()) {
                return true;
            }
        }
        return false;
    }

    hasDiscountNoRoutineCare(): boolean {
        return this.originalPriceYear > 0
            && this.getOriginalPrice(Frequency.Year, false) !== this.getPrice(Frequency.Year, false);
    }

    hasDiscountRoutineCare(): boolean {
        return this.originalRoutineCarePriceYear > 0
            && this.getOriginalPrice(Frequency.Year, true) !== this.getPrice(Frequency.Year, true);
    }

    hasDiscount(routine: boolean = null): boolean {
        let forRoutine: boolean = (routine !== null) ? routine : this.routineCareSelected;
        return forRoutine ? this.hasDiscountRoutineCare() : this.hasDiscountNoRoutineCare();
    }

    hasDiscountIfNoRoutine(): boolean {
        return this.hasDiscountRoutineCare() || this.hasDiscountNoRoutineCare();
    }

    applyInsurancePricing(pricing: InsurancePricing) {
        if (!pricing) {
            return;
        }
        this.productBenefitId = pricing.productBenefitId;
        this.productCode = pricing.productCode;
        this.petSureProductCode = pricing.petSureProductCode;
        this.routineCareAvailable = pricing.routineCareAvailable;
        this.coverName = pricing.productName;

        this.priceFortnight = pricing.priceFortnight;
        this.priceMonth = pricing.priceMonth;
        this.priceYear = pricing.priceYear;

        this.originalPriceFortnight = pricing.originalPriceFortnight;
        this.originalPriceMonth = pricing.originalPriceMonth;
        this.originalPriceYear = pricing.originalPriceYear;

        this.routineCarePriceFortnight = pricing.routineCarePriceFortnight;
        this.routineCarePriceMonth = pricing.routineCarePriceMonth;
        this.routineCarePriceYear = pricing.routineCarePriceYear;

        this.originalRoutineCarePriceFortnight = pricing.originalRoutineCarePriceFortnight;
        this.originalRoutineCarePriceMonth = pricing.originalRoutineCarePriceMonth;
        this.originalRoutineCarePriceYear = pricing.originalRoutineCarePriceYear;

        this.seniorDiscountAmount = pricing.seniorDiscountAmount;
        this.seniorDiscountAmountRoutineCare = pricing.seniorDiscountAmountRoutineCare;

        this.multiPetDiscount = pricing.multiPetDiscount;
        this.multiPetDiscountRoutineCare = pricing.multiPetDiscountRoutineCare;
        this.multiPetDiscountCount = pricing.multiPetDiscountCount;

        this.displayOrder = pricing.displayOrder;
        this.coolingOffDays = pricing.coolingOffDays;
        this.minimumPetAgeForAccidentOnly = pricing.minimumPetAgeForAccidentOnly;
        this.accidentBenefitCode = pricing.accidentBenefitCode;
    }

    applyCurrentInsurancePricing() {
        this.applyInsurancePricing(this.getCurrentInsurancePricing());
    }

    setExcess(excessAmount: number) {
        let insurancePricing: InsurancePricing = this.getInsurancePricing(excessAmount, this.routineCareSelected);
        if (!insurancePricing) {
            return;
        }
        this.excessSelected = excessAmount;
        this.applyInsurancePricing(insurancePricing);
    }

    setRoutineCare(routineCare: boolean) {
        if (!this.hasRoutineCare()) {
            return;
        }
        let insurancePricing: InsurancePricing = this.getInsurancePricing(this.excessSelected, routineCare);
        if (!insurancePricing) {
            return;
        }
        this.routineCareSelected = routineCare;
        this.applyInsurancePricing(insurancePricing);
    }

    /**
     * No fallback on insurance pricing, get the one which match
     *
     * @param {number} excess
     * @param {boolean} routineCare
     * @returns {any}
     */
    getStrictInsurancePricing(excess: number, routineCare: boolean) {
        if (!this.pricings || this.pricings.length <= 0) {
            return null;
        }
        for (let pricing of this.pricings) {
            if (pricing.excess === excess && routineCare === pricing.hasRoutineCare()) {
                return pricing;
            }
        }
        return null;
    }

    /**
     * No fallback on insurance pricing, get the one which match
     *
     * @param {number} excess
     * @param {boolean} routineCare
     * @returns {any}
     */
    getInsurancePricingWithExcess(excess: number) {
        if (!this.pricings || this.pricings.length <= 0) {
            return null;
        }
        for (let pricing of this.pricings) {
            if (pricing.excess === excess) {
                return pricing;
            }
        }
        return null;
    }

    /**
     * get the insurance pricing which match or fallback to the closest one
     *
     * @param {number} excess
     * @param {boolean} routineCare
     * @returns {InsurancePricing}
     */
    getInsurancePricing(excess: number, routineCare: boolean): InsurancePricing {
        if (!this.pricings || this.pricings.length <= 0) {
            return null;
        }
        let pricing = this.getStrictInsurancePricing(excess, routineCare);
        if (pricing === null) {
            return this.getInsurancePricingWithExcess(excess);
        }
        return pricing;
    }

    cloneInsurancePricings(): InsurancePricing[] {
        let newPricings: InsurancePricing[] = [];
        if (!this.pricings || this.pricings.length <= 0) {
            return newPricings;
        }
        for (let pricing of this.pricings) {
            newPricings.push(InsurancePricing.getCopy(pricing));
        }
        return newPricings;
    }

    updateExcessOptions() {
        if (this.pricings && this.pricings.length > 0) {
            this.excessOptions = [];
            for (let pricing of this.pricings) {
                this.excessOptions.push(pricing.excess);
            }
            this.excessOptions = this.excessOptions.sort((a, b) => {
                return b -  a;
            });
            let seen = {};
            this.excessOptions = this.excessOptions.filter(function(item) {
                return seen.hasOwnProperty(item) ? false : (seen[item] = true);
            });
        }
    }

    mergeInsurancePricings(pricingsToMerge: InsurancePricing[]) {
        if (!this.pricings || this.pricings.length <= 0) {
            this.pricings = pricingsToMerge;
            return;
        }
        for (let pricing of pricingsToMerge) {
            let pricingToOverride = this.getStrictInsurancePricing(pricing.excess, pricing.hasRoutineCare());
            if (pricingToOverride) {
                pricingToOverride.mergeInsurancePricings(pricing);
                continue;
            }
            this.pricings.push(pricing);
        }
        this.updateExcessOptions();
    }

    getCurrentInsurancePricing(): InsurancePricing {
        return this.getInsurancePricing(this.excessSelected, this.routineCareSelected);
    }

    getFallBackPrice(freq: Frequency, routine: boolean = null): number {
        let forRoutine: boolean = (routine !== null) ? routine : this.routineCareSelected;
        let price = 0;
        switch (freq) {
            case Frequency.Fortnight:
                price = (forRoutine && this.hasRoutineCare()) ?
                    this.routineCarePriceFortnight : this.priceFortnight;
                break;
            case Frequency.Month:
                price = (forRoutine && this.hasRoutineCare()) ?
                    this.routineCarePriceMonth : this.priceMonth;
                break;
            case Frequency.Year:
                price = (forRoutine && this.hasRoutineCare()) ?
                    this.routineCarePriceYear : this.priceYear;
                break;
            default:
                price = 0;
                break;
        }
        // discount is negative
        return price;
    }

    getPrice(freq: Frequency, routine: boolean = null): number {
        let insurancePricing: InsurancePricing = this.getCurrentInsurancePricing();
        if (!insurancePricing) {
            return this.getFallBackPrice(freq, routine);
        }
        let forRoutine: boolean = (routine !== null) ? routine : this.routineCareSelected;
        return insurancePricing.getPrice(freq, forRoutine);
    }

    getFallBackOriginalPrice(freq: Frequency, routine: boolean = null): number {
        let forRoutine: boolean = (routine !== null) ? routine : this.routineCareSelected;
        let price = 0;
        switch (freq) {
            case Frequency.Fortnight:
                price = ((forRoutine && this.hasRoutineCare()) ?
                    this.originalRoutineCarePriceFortnight : this.originalPriceFortnight);
                break;
            case Frequency.Month:
                price = ((forRoutine && this.hasRoutineCare()) ?
                    this.originalRoutineCarePriceMonth : this.originalPriceMonth);
                break;
            case Frequency.Year:
                price = ((forRoutine && this.hasRoutineCare()) ?
                    this.originalRoutineCarePriceYear : this.originalPriceYear);
                break;
            default:
                price = 0;
                break;
        }
        return price;
    }

    getOriginalPrice(freq: Frequency, routine: boolean = null): number {
        let insurancePricing: InsurancePricing = this.getCurrentInsurancePricing();
        if (!insurancePricing) {
            return this.getFallBackOriginalPrice(freq, routine);
        }
        let forRoutine: boolean = (routine !== null) ? routine : this.routineCareSelected;
        return insurancePricing.getOriginalPrice(freq, forRoutine);
    }

    getFallBackDiscountAmount(freq: Frequency, routine: boolean = null): number {
        let forRoutine: boolean = (routine !== null) ? routine : this.routineCareSelected;
        if (this.hasDiscount(forRoutine)) {
            let discount: number = this.getOriginalPrice(freq, forRoutine) - this.getPrice(freq, forRoutine);
            return discount > 0 ? discount : 0;
        }
        return 0;
    }

    getDiscountAmount(freq: Frequency, routine: boolean = null): number {
        let insurancePricing: InsurancePricing = this.getCurrentInsurancePricing();
        if (!insurancePricing) {
            return this.getFallBackDiscountAmount(freq, routine);
        }
        let forRoutine: boolean = (routine !== null) ? routine : this.routineCareSelected;
        return insurancePricing.getDiscountAmount(freq, forRoutine);
    }

    getFallBackOriginalPriceIfNoRoutine(freq: Frequency): number {
        if (this.routineCareSelected && this.hasDiscount(true)) {
            return this.getOriginalPrice(freq, this.routineCareSelected);
        } else {
            return this.getOriginalPrice(freq, false);
        }
    }

    getOriginalPriceIfNoRoutine(freq: Frequency): number {
        let insurancePricing: InsurancePricing = this.getCurrentInsurancePricing();
        if (!insurancePricing) {
            return this.getFallBackOriginalPriceIfNoRoutine(freq);
        }
        return insurancePricing.getOriginalPriceIfNoRoutine(freq, this.routineCareSelected);
    }

    convertPriceToFrequency(amount: number, freq: Frequency): number {
        let newValue = amount;
        switch (freq) {
            case Frequency.Fortnight:
                newValue = newValue / 26;
                break;
            case Frequency.Month:
                newValue = newValue / 12;
                break;
            default:
                break;
        }
        return newValue;
    }

    getFallBackSeniorDiscountAmount(freq: Frequency): number {
        if (this.seniorDiscountAmount <= 0 && this.seniorDiscountAmountRoutineCare <= 0) {
            return 0;
        }
        let amountDiscount = this.seniorDiscountAmount;
        if (this.routineCareSelected) {
            amountDiscount = this.seniorDiscountAmountRoutineCare;
        }
        return this.convertPriceToFrequency(amountDiscount, freq);
    }

    getSeniorDiscountAmount(freq: Frequency): number {
        let insurancePricing: InsurancePricing = this.getCurrentInsurancePricing();
        if (!insurancePricing) {
            return this.getFallBackSeniorDiscountAmount(freq);
        }
        return insurancePricing.getSeniorDiscountAmount(freq, this.routineCareSelected);
    }

    getFallBackMultipetDiscount(freq: Frequency): number {
        if (this.multiPetDiscount <= 0 && this.multiPetDiscountRoutineCare <= 0) {
            return 0;
        }
        let amountDiscount = this.multiPetDiscount;
        if (this.routineCareSelected) {
            amountDiscount = this.multiPetDiscountRoutineCare;
        }
        return this.convertPriceToFrequency(amountDiscount, freq);
    }

    getMultipetDiscount(freq: Frequency): number {
        let insurancePricing: InsurancePricing = this.getCurrentInsurancePricing();
        if (!insurancePricing) {
            return this.getFallBackMultipetDiscount(freq);
        }
        return insurancePricing.getMultipetDiscount(freq, this.routineCareSelected);
    }

    proceedValidation(step: number = 0): boolean {
        if (step === 3) {
            if (this.coverName === null || this.coverName.length < 1) {
                this.setError('coverName', 'Please select your level of cover');
            }
            if (this.productBenefitId === null || this.productBenefitId === 0) {
                this.setError('coverName', 'Please select your level of cover');
            }
        }
        return this.isValid();
    }

    //User story - 31124 - Choosi PDDO Pet STP
    //Step2 & Step 3 - get benefit Percentage
    getBenefitPercentage(): string {
        if (this.fields) {
            let benefitPercentageCoverField = Array.from(this.fields).find(p=>p[1].name === 'Benefit percentage reimbursed for eligible vet expenses')[1];
            let coverFieldValues = Array.from(this.fields.values()) as CoverFieldValue[]; 
            if(benefitPercentageCoverField)
            {
                var benefitPercentagehtml =  coverFieldValues.find(c=>c.id===benefitPercentageCoverField.id).html; 
                return StringHelper.ReplaceCaseInsensitive(StringHelper.stripHtml(benefitPercentagehtml), "up to","");  
            }
            else {
                return '';
            }
        } else {
            return '';
        }
    }
    //User story - 31124 - Choosi PDDO Pet STP
    //Step2 & Step 3 - get benefit limit
    getBenefitLimit(): string {
        if (this.fields) {
            let benefitLimitCoverField = Array.from(this.fields).find(p=>p[1].name === 'Benefit limit¹')[1];
            let coverFieldValues = Array.from(this.fields.values()) as CoverFieldValue[]; 
            if(benefitLimitCoverField)
            {
                var benefitLimithtml =  coverFieldValues.find(c=>c.id===benefitLimitCoverField.id).html;
                return StringHelper.FormatCurrency(StringHelper.stripHtml(benefitLimithtml));
            }
            else {
                return '';
            }
        } else {
            return '';
        }
    }
    //User story - 31124 - Choosi PDDO Pet STP
    //Step2 & Step 3 - get annual limit
    getAnnualLimit(): string {
        if (this.fields) {
            let annualLimitCoverField = Array.from(this.fields).find(p=>p[1].name === 'Annual condition limit. This limit applies to each condition you claim, in each policy period. We won\'t pay more than the annual condition limit for any one condition.')[1];
            let coverFieldValues = Array.from(this.fields.values()) as CoverFieldValue[]; 
            if(annualLimitCoverField)
            {
                return coverFieldValues.find(c=>c.id===annualLimitCoverField.id).html;
            }
            else {
                return '';
            }
        } else {
            return '';
        }
    }
}
