import { Injectable }    from '@angular/core';
import { BaseService } from './base.service';

import 'rxjs/add/operator/toPromise';

import { Insurance } from '../model/insurance';
import { BrandsEnum } from '../model/brands.enum';
import { BrandDetails } from '../model/brand-details';

@Injectable()
export class BrandsService extends BaseService {

    getBrandDetails(insurance: Insurance): Promise<BrandDetails> {
        return Promise.resolve(
            new BrandDetails(
                insurance.brandId,
                insurance.brandLogo,
                insurance.brandLogoLarge,
                insurance.insuranceName,
                this.getBrandHtml(insurance.brandId)
            )
        );
    }

    private getBrandHtml(brandId: number): string {
        let htmlContent: string = '';
        switch (brandId) {
            case BrandsEnum.Real:
                htmlContent = `
                    <p>
                        If your four-legged friend becomes ill or has an accident, you want to be able to give them the   
                        care they deserve, and as most pet owners know, this can be very expensive.
                    </p>
                    <p>
                        Real Pet Insurance has your beloved cat or dog covered by providing you with financial support  
                        if your pet needs veterinary treatment. You can claim up to 80% of eligible vet bills* up to a 
                        maximum of $12,000 each year (depending on your cover).
                    </p>
                    <p>
                        <strong>Key benefits of Real Pet Insurance</strong>
                    </p>
                    <ul>
                        <li>
                        	Up to 80% of eligible vet bills reimbursed*.
                        </li>
                        <li>
                            Individual claims to a maximum of $12,000 a year.
                        </li>
                        <li>
                        	No excess to pay for each claim.
                        </li>
                        <li>
                            Choose from three different cover options to suit your pet’s needs – and your budget.
                        </li>
                        <li>
                             Payment towards vaccinations, health checks and micro-chipping with the Routine Care Benefit option.
                        </li>
                        <li>
                             You will get back 10% of premiums you have paid after the first 12 months with The Real Reward™.
                        </li>
                    </ul>
                    <p>
                    <span>
                    * Some pre-existing conditions and certain illnesses are excluded. Terms, conditions, exclusions, 
                    benefit limits, sub limits and annual condition limits may apply. Eligible vet bills only, claim for
                     reimbursement. Benefit Percentage varies based on the level of cover selected.
                    </span>
                     </p>
                `;
                break;
            case BrandsEnum.Asia:
                htmlContent = `
                    <p>
                        From injury and sickness to everyday treatments, Seniors Pet Insurance has your pet's health care 
                        covered – and a 10% discount for the over 50s.
                    </p>
                    <p>
                     Whether you’re after essential care or comprehensive protection, Seniors Pet
                     Insurance offers three levels of cover for you to choose from, providing cover for up to 
                     80% of your four-legged friend's eligible vet bills*. You also get cover for home visits
                     included at no extra cost and, if you are your pet's sole carer, emergency boarding for
                     your pet if you are hospitalised for 5 or more days in a row.
                    </p>
                    <p>
                        <strong>
                            Provide your four-legged friend with the best of care
                        </strong>
                    </p>
                    <ul>
                        <li>
                            Cover for up to 80% of eligible vet bills to a maximum of $12,000 a year*. 
                        </li>
                        <li>
                            Choose from three levels of cover to get the right protection for your pet's needs. 
                        </li>
                        <li>
                            Pay fortnightly, monthly or yearly – at no extra cost.
                        </li>
                        <li>
                            Enjoy extra convenience, with home visits included in your cover.
                        </li>
                        <li>
                            Benefit from a hassle-free claims process.
                        </li>
                    </ul>
                    <p>
                        Plus save with a 10% Seniors Discount if you are over 50.
                    </p>
                    <p>
                     <span>
                     * Some pre-existing conditions and certain illnesses are excluded. Terms, conditions, exclusions,
                     benefit limits, sub limits and annual condition limits may apply. Eligible vet bills only, claim for
                      reimbursement. Benefit Percentage varies based on the level of cover selected.
                     </span>
                      </p>                 
                `;
                break;
            case BrandsEnum.Guardian:
                htmlContent = `
                    <p>
                    Guardian Pet Insurance provides peace of mind, knowing your four-legged family
                    members can get the best veterinary care, without the worry of a big vet bill. With three
                    cover options to choose from, Guardian Pet Insurance provides flexible cover for your cat or dog.
                    </p>
                    <p>
                        <strong>
                            Key benefits of Guardian Pet Insurance
                        </strong>
                    </p>
                    <ul>
                        <li>
                        	Covers up to 80% of eligible vet bills*. 
                        </li>
                        <li>
                            Claim up to $12,000 per year.
                        </li>
                        <li>
                            You can use any registered vet in Australia.
                        </li>
                        <li>
                            No excess to pay.
                        </li>
                    </ul>
                    <p>
                        Your cat or dog is an important part of your life and a source of endless joy and companionship 
                        for you<br> and your family. So nothing should stop you giving them the best of care, especially when 
                        they’re sick<br> or injured. 
                    </p>
                    <p>
                    <span>
                    * Some pre-existing conditions and certain illnesses are excluded. Terms, conditions, exclusions,
                     benefit limits, sub limits and annual condition limits may apply. Eligible vet bills only, claim for
                     reimbursement. Benefit Percentage varies based on the level of cover selected.
                    </span>
                     </p>
                `;
                break;
            case BrandsEnum.RSPCA:
                htmlContent = `
                    <p>
                    RSPCA Pet Insurance offers peace of mind for dog and cat owners all across Australia.
                    Our pet insurance plans cover up to 80% of the cost of eligible vet bills* when your dog 
                    or cat is sick or injured. Plus, there’s no excess to pay, making vet bills even more manageable.                    
                    </p>
                    <p>
                        <strong>
                            Help your pet get the very best care
                        </strong>
                    </p>
                    <ul>
                        <li>
                        	Claim up to $20,000 per year with no excess.
                        </li>
                        <li>
                        	Covers up to 80% of eligible vet bills*.
                        </li>
                        <li>
                        	Use any registered vet in Australia.
                        </li>
                        <li>
                            Receive a 10% discount on each additional dog or cat you insure.
                        </li>
                    </ul>
                    <p>
                    Plus, by taking out a policy with RSPCA Pet Insurance a portion of first-year premiums
                     help support the RSPCA. So you’ll be assisting other Australian animals in need, not
                     just your best friend.
                    </p>
                    <p>
                    <span>
                    * Some pre-existing conditions and certain illnesses are excluded. Terms, conditions, exclusions,
                     benefit limits, sub limits and annual condition limits may apply. Eligible vet bills only, claim for
                     reimbursement. Benefit Percentage varies based on the level of cover selected.
                     </span>
                     </p>

                `;
                break;
            case BrandsEnum.Prime:
                htmlContent = `
                <p>
                   Prime Pet Insurance is the ideal way to keep that special dog or cat in prime condition.
                </p>
                <p>
                  We offer an exceptionally high standard of care if your pet becomes ill or injured.
                  Receive superior cover for up to 85% of eligible vet bills* to a maximum of $14,000 a
                  year, so there’s no need to worry – even if you’re faced with a king-sized vet bill!
                </p>
                <p>
                    <strong>When only the finest will do</strong>
                </p>
                <ul>
                    <li>
                    	Your choice of three regal cover options.
                    </li>
                    <li>
                    	First class care, with cover up to 85% of eligible treatment costs*.
                    </li>
                    <li>
                    	Receive back up to $14,000 a year.
                    </li>
                    <li>
                    	No additional excess for greater peace of mind.
                    </li>
                </ul>
                <p>
                 You’ll also receive complimentary cover for a lavish range of extras like cover when
                 travelling to Norfolk Island and New Zealand, essential euthanasia, and emergency boarding.  
                </p>
                <p>
                <span>
                * Some pre-existing conditions and certain illnesses are excluded. Terms, conditions, exclusions, 
                benefit limits, sub limits and annual condition limits may apply. Eligible vet bills only, claim for 
                reimbursement. Benefit Percentage varies based on the level of cover selected.
                </span>
                </p>
                `;
                break;
            case BrandsEnum.GuideDogs:
                htmlContent = `
                <p>
                Playing, exploring and just being themselves can sometimes get your dog or cat in a bit
                of trouble! An unforeseen illness or injury can not only be hard on your pet but can also 
                lead to unexpected vet bills. 
                </p>
                <p>
                 Guide Dogs Pet Insurance can help protect your dog or cat if they get sick or injured, so
                 you can give them the best care possible without worrying about the cost.You can get
                 back up to 85% of eligible vet bills*, to a maximum of $20,000 per year.
                </p>
                <p>
                <strong>Give your pet the care they deserve</strong>
                </p>                
                <ul>
                    <li>
                       Great value cover options to choose from, including an accident and illness cover for pets 9 years<br>
                       and older. 
                    </li>
                    <li>
                        Easy and quick application over the phone or online.
                    </li>
                    <li>
                        Get back up to 85% of eligible vet bills*, to a maximum of $20,000 per year.
                    </li>
                    <li>
                    	Receive a 10% multi-pet discount when you insure more than one dog or cat.
                    </li>
                    <li>
                        Choose an excess to lower your premium.
                    </li>
                    <li>
                    	Visit any registered vet in Australia.
                    </li>
                    <li>
                        30 day cooling off period, so you can make sure it is the right cover for you.
                    </li>
                </ul>
                    
                <p>
                    Plus when you get Guide Dogs Pet Insurance you will be helping support Guide Dogs Australia provide 
                    vital services to people who need them.
                </p>
                <p>
                <span>
                * Some pre-existing conditions and certain illnesses are excluded. Terms, conditions, exclusions,
                  benefit limits, sub limits and annual condition limits may apply. Eligible vet bills only, claim for
                  reimbursement. Benefit Percentage varies based on the level of cover selected.
                </span>
                </p>
                `;
                break;
            default:
                break;
        }
        return htmlContent;
    }
}
