export class BrandDetails {

    static getBlank(): BrandDetails {
        return new BrandDetails(0, '', '', '', '');
    }

    constructor(
        public brandId: number,
        public brandLogo: string,
        public brandLogoLarge: string,
        public brandName: string,
        public brandContent: string
    ) {}
}
