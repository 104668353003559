import { Injectable }    from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';

import 'rxjs/add/operator/toPromise';

import { Vet } from '../model/vet';

@Injectable()
export class VetsService extends BaseService {

    constructor(private http: HttpClient) {
        super();
    }

    getVets(searchText: string): Promise<Vet[]> {
        let url = this.baseUrl + 'api/Petsure/GetVetList/' + searchText;
        return this.http.get<{ServiceProviderNumber: string, ServiceProvider: string}[]>(url)
            .toPromise()
            .then((response: {ServiceProviderNumber: string, ServiceProvider: string}[]) => {
                const vets: Vet[] = [];
                response.forEach(x => {
                    vets.push(new Vet(x.ServiceProviderNumber, x.ServiceProvider));
                })
                return vets;
            })
            .catch(this.handleError);
    }



    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }
}
