import { Pet } from '../model/pet';
import { User } from '../model/user';
import { BrandsEnum } from '../model/brands.enum';

export class PremiumsHelper {

    // ----- EXCESS
    public static getDefaultExcess(brandId: number, productBenefitId: number): number {
        if (BrandsEnum.GuideDogs !== brandId) {
            return 0;
        }
        switch (productBenefitId) {
            case 198:
                return 100;
            case 199:
            case 200:
                return 50;
            default:
                return 0;
        }
    }

    public static getExcessOptions(brandId: number, productBenefitId: number): number[] {
        if (BrandsEnum.GuideDogs !== brandId) {
            return [ 0 ];
        }
        switch (productBenefitId) {
            case 198:
                return [ 100, 0 ];
            case 199:
            case 200:
                return [ 50, 0 ];
            default:
                return [ 0 ];
        }
    }

    // ----- ROUTINE CARES PRODUCT CODES
    public static getRoutineCareProductCode(productCode: string): string {
        switch (productCode) {
            case 'PR102':
                return 'PR103';
            case 'PR111':
                return 'PR110';
            case 'PR131':
                return 'PR132';
            default:
                return productCode;
        }
    }

    public static getProductCode(productCode: string, hasRoutine: boolean): string {
        if (!hasRoutine) {
            return productCode;
        }
        return PremiumsHelper.getRoutineCareProductCode(productCode);
    }

    // ----- PRODUCT CODES
    public static getProductCodes(step: number, user: User, pet: Pet): string[] {
        switch (step) {
            case 1:
                return PremiumsHelper.getFirstStepProductCodes(user, pet);
            case 2:
                return PremiumsHelper.getSecondProductCodes(user, pet);
            case 3:
                return PremiumsHelper.getThirdStepProductCodes(user, pet);
            default:
                return [];
        }
    }

    public static getPartnerCodes(step: number, user: User, pet: Pet): string[] {
        switch (step) {
            case 1:
                return PremiumsHelper.getFirstStepPartnerCodes(user, pet);
            case 2:
                return PremiumsHelper.getSecondPartnerCodes(user, pet);
            case 3:
                return PremiumsHelper.getThirdStepPartnerCodes(user, pet);
            default:
                return [];
        }
    }

    public static collectProductCodes(pets: Pet[]): string[] {
        let products: Set<string> = new Set<string>();
        for (let pet of pets) {
            if (pet.cover) {
                products.add(pet.cover.petSureProductCode);
            }
        }
        return Array.from(products.values());
    }

    public static collectPartnerCodes(pets: Pet[]): string[] {
        let partners: Set<string> = new Set<string>();
        for (let pet of pets) {
            if (pet.cover) {
                partners.add(pet.cover.petSurePartnerCode);
            }
        }
        return Array.from(partners.values());
    }

    private static getFirstStepProductCodes(user: User, pet: Pet): string[] {
        if (pet.isOver9()) {
            return ['PR122'];
        }
        if (user.isOver50()) {
            return ['PR102', 'PR122','PR131'];
        }
        return ['PR102', 'PR111','PR122','PR131'];
    }

    private static getSecondProductCodes(user: User, pet: Pet): string[] {
        if (pet.isOver9()) {
            return ['PR101','PR122'];
        }
        if (user.isOver50()) {
            return ['PR102', 'PR111', 'PR101','PR122'];
        }
        return ['PR102', 'PR101','PR122'];
    }

    private static getThirdStepProductCodes(user: User, pet: Pet): string[] {
        if (pet.isOver9()) {
            return [];
        }
        if (user.isOver50()) {
            return ['PR101', 'PR103', 'PR110','PR122','PR132'];
        }
        return ['PR101', 'PR103', 'PR110','PR122','PR132'];
    }
 
    // ----- PARTNER CODES

    private static getFirstStepPartnerCodes(user: User, pet: Pet): string[] {
        if (pet.isOver9()) {
            return ['RS456', 'PM456', 'GU456'];
        }
        if (user.isOver50()) {
            return ['RS456', 'AH456', 'GU456'];
        }
        return ['RS456', 'PM456', 'GU456'];
    }

    private static getSecondPartnerCodes(user: User, pet: Pet): string[] {
        if (pet.isOver9()) {
            return ['GD456', 'RL456', 'AH456'];
        }
        if (user.isOver50()) {
            return ['GD456', 'RL456', 'PM456'];
        }
        return ['GD456', 'RL456', 'AH456'];
    }

    private static getThirdStepPartnerCodes(user: User, pet: Pet): string[] {
        return [];
    }
}
