import { ModelValidator } from './model-validator';
import { PaymentType } from './payment-type.enum';
import { PaymentMethod } from './payment-method';
import { Account } from './account';
import { CreditCard } from './credit-card';

export class Payment extends ModelValidator {

    static getBlank(): Payment {
        return new Payment(
            PaymentType.ACCOUNT, Account.getBlank(),
            '', '', '', '', '', ''
        );
    }

    constructor(
        public type: PaymentType,
        public paymentMethod: PaymentMethod,
        public token: string,
        public community: string,
        public supplierBusiness: string,
        public crn: string,
        public responseType: string,
        public postUrl: string
    ) {
        super();
    }

    selectPaymentType(type: PaymentType): void {
        if (type === PaymentType.ACCOUNT) {
            this.paymentMethod = Account.getBlank();
        } else if (type === PaymentType.CARD) {
            this.paymentMethod = CreditCard.getBlank();
        }
        this.type = type;
    }

    proceedValidation(step: number = 0): boolean {
        this.paymentMethod.validate(step);
        return this.isValid();
    }

    isValid(): boolean {
        return this.paymentMethod.isValid() && super.isValid();
    }
}
