import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';

import 'rxjs/add/operator/toPromise';

import { User } from '../model/user';
import { State } from '../model/state.enum';

@Injectable()
export class CallService extends BaseService {

    private static mapCallRequest(partnerId: number, user: User, cookies: any) {
        let stateName = State[user.address.state];
        return {
            'EmailAddress': user.email,
            'FirstName':  user.firstName,
            'Surname':  user.lastName,
            'State': stateName,
            'PhoneNo': user.getFormattedPhoneNumber(),
            'PartnerId': partnerId,
            'Cookies': cookies
        };
    }

    private static mapCallAnswer(data: any): string {
        return data.WebLeadId.toString();
    }

    constructor(private http: HttpClient) {
        super();
    }

    haveUsCall(partnerId: number, user: User, cookies: any): Promise<string> {
        let url = this.baseUrl + 'api/Petsure/CallMeNow';
        let clientRequestObject = CallService.mapCallRequest(partnerId, user, cookies);
        return this.http.post(url, JSON.stringify(clientRequestObject), {headers: this.headers})
            .toPromise()
            .then((response) => {
                return CallService.mapCallAnswer(response);
            })
            .catch(this.handleError);
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }
}
