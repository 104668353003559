import { Injectable }       from '@angular/core';
import {
    CanActivate, Router,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
}                           from '@angular/router';

import { Pet } from '../model/pet';
import { User } from '../model/user';
import { Theme } from '../model/theme';
import { Insurance } from '../model/insurance';

import { SubscriptionService } from '../services/subscription.service';
import { ClientService } from '../services/client.service';

@Injectable()
export class BuyRouteGuard implements CanActivate {


    constructor(
        private subscriptionService: SubscriptionService,
        private clientService: ClientService,
        private router: Router
    ) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        try {
            let user = this.subscriptionService.getSubscriptions<User>('user').getValue();
            let pets = this.subscriptionService.getSubscriptions<Pet[]>('pets').getValue();
            let theme = this.subscriptionService.getSubscriptions<Theme>('theme').getValue();
            let insurance = this.subscriptionService.getSubscriptions<Insurance>('insurance').getValue();
            let petCoverList = this.subscriptionService.getSubscriptions<Insurance[]>('pet-covers').getValue();

            let petValid = true;
            if (pets.length > 0) {
                for (let pet of pets) {
                    if (pet === null && !pet.validate()) {
                        petValid = false;
                    }
                }
            } else {
                petValid = false;
            }

            let userValid = user !== null && user.validate();
            let petCoverListValid = petCoverList !== null && petCoverList.length > 0;
            let insuranceValid = insurance !== null && insurance.validate();

            if (petValid && userValid && petCoverListValid && insuranceValid) {
                this.clientService.updatePageForClient(theme,  user, pets[0], 3);
                return true;
            }
        } catch (e) {
            console.error('Cannot access to this page there is missing elements: ' + e.toString());
        }

        // navigate to the default page
        this.router.navigate(['']);
        return false;
    }
}
