import { Component, OnInit, DoCheck, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { BehaviorSubject, Observable, Observer, of, noop, from } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { CONFIGURATION } from '../environments/environment';

import { Pet } from '../model/pet';
import { User } from '../model/user';
import { Cover } from '../model/cover';
import { Theme } from '../model/theme';
import { Loading } from '../model/loading';
import { Payment } from '../model/payment';
import { Address } from '../model/address';
import { State } from '../model/state.enum';
import { Gender } from '../model/gender.enum';
import { Insurance } from '../model/insurance';
import { Species } from '../model/species.enum';
import { BrandsEnum } from '../model/brands.enum';
import { Frequency } from '../model/frequency.enum';
import { PaymentType } from '../model/payment-type.enum';
import { InsuranceCovers } from '../model/insurance-covers';

import { SubscriptionService } from '../services/subscription.service';
import { LocationService } from '../services/location.service';
import { PaymentService } from '../services/payment.service';
import { PromotionService } from '../services/promotion.service';
import { ClientService } from '../services/client.service';
import { BreedsService } from '../services/breeds.service';
import { QuoteService } from '../services/quote.service';
import { ThemeService } from '../services/theme.service';
import { VetsService } from '../services/vets.service';
import { ApplicationService } from '../services/application.service';
import { PremiumService } from '../services/premium.service';

import { FileService } from '../services/file.service';

import { ToolsMethods } from '../shared/tools.methods';
import { CustomCurrencyPipe } from '../shared/tools.pipe';
import { ExtendedAuthToken } from '../model/extended-auth-token';

import { StringHelper } from '../helpers/string.helpers';
import { PremiumCoverDateResponse } from '../model/premiumCoverDateResponse';
import { PremiumCoverDateRequest } from '../model/premiumCoverDateRequest';

@Component({
    selector: 'buy',
    templateUrl: 'buy.component.html',
    providers: [BreedsService, VetsService, PaymentService, PromotionService, ClientService, LocationService]
})

export class BuyComponent implements DoCheck, OnInit {

    PAYMENTTYPE = PaymentType;
    FREQUENCY = Frequency;
    SPECIES = Species;
    GENDER = Gender;
    STATES = State;
    EMAIL_GREENSTONE: string = 'noemail@greenstone.com.au';

    paymentGatewaySubmitMethod: string = CONFIGURATION.paymentSubmitMode;

    // model
    pet: Pet;
    pets: Pet[] = [];
    user: User;
    theme: Theme;
    cover: Cover;
    loading: Loading;
    insurance: Insurance;
    payment: Payment = Payment.getBlank();
    bearerToken: string;
    token: ExtendedAuthToken;
    actionUrl: String;
    paymentGatewayUrl: string;
    PaymentGatewayJSFile: string;
    environment: string;
    validatePaymentForm: boolean = false;

    //user Address
    addressDataSource: Array<string>;
    addressTypeaheadLoading: boolean = false;
    addressTypeaheadNoResults: boolean = false;
    isAddressSelected: boolean = true;
    manualAddress = false;
    auPostToken: string = '';

    // cover list with price and id
    petCoverList: Insurance[] = [];


    coverDateList: Date[] = [];
    premiumDateList: Date[] = [];

    publicHolidayList: Date[] = [];

    suburbList: string[] = [];



    insuranceCovers: InsuranceCovers;
    subscriptionInsuranceCovers: BehaviorSubject<InsuranceCovers>;

    // subscriptions
    subscriptionPet: BehaviorSubject<Pet>;
    subscriptionPets: BehaviorSubject<Pet[]>;
    subscriptionUser: BehaviorSubject<User>;
    subscriptionTheme: BehaviorSubject<Theme>;
    subscriptionCover: BehaviorSubject<Cover>;
    subscriptionLoading: BehaviorSubject<Loading>;
    subscriptionInsurance: BehaviorSubject<Insurance>;
    subscriptionPetCover: BehaviorSubject<Insurance[]>;

    breeds;
    submitted;

    removePetPopup: number = -1;
    removePetPopupEnabled: boolean = false;

    topDetailsVisible = false;
    directDebit: boolean = true;
    globalError: boolean = false;
    processRunning: boolean = false;

    // validation
    aboutPetSubmit: boolean = false;
    aboutPetValid: boolean = null;

    aboutYouSubmit: boolean = false;
    aboutYouValid: boolean = null;

    aboutContactDetailsSubmit: boolean = false;
    aboutContactDetailsValid: boolean = null;

    petCoverSubmit: boolean = false;
    petCoverValid: boolean = null;

    declarationSubmit: boolean = false;
    declarationValid: boolean = null;

    paymentSubmit: boolean = false;
    paymentValid: boolean = null;

    productQuestionTermsValid: boolean = null;
    oldCoverStartDate: Date;

    nowDate = new Date();

    disclaimerText = '';

    petAgeChanged: boolean = false;
    petNameAgeChanged: string = '';
    moreThanOnePetAgeChanged: boolean = false;

    submitApplicationSaveQuoteFinish: boolean = false;
    submitApplicationSaveClientFinish: boolean = false;
    submitApplicationGetPaymentTokenFinish: boolean = false;

    savingPets: boolean = false;

    loadingRoute: boolean = false;
    premiumCoverDateResponse: PremiumCoverDateResponse
    updatingCoverDates: boolean = false;
    isAnyPetCoverDateUpdateError: boolean = false;
    enablePetCoverNextButton: boolean = true;
    message: string = '';

    addressSearch?: string;
    addressSuggestions$?: Observable<string[]>;
    errorMessage?: string;
    hasFreeMonthOffer: boolean = false;
    isProdEnv: boolean = true; //set false for local debugging diagnostic trace #117737

    constructor(
        private subscriptionService: SubscriptionService,
        private locationService: LocationService,
        private paymentService: PaymentService,
        private promotionService: PromotionService,
        private breedsService: BreedsService,
        private clientService: ClientService,
        private quoteService: QuoteService,
        private themeService: ThemeService,
        private vetsService: VetsService,
        private location: LocationStrategy,
        private route: ActivatedRoute,
        private router: Router,
        private ref: ChangeDetectorRef,
        private applicationService: ApplicationService,
        private premiumService: PremiumService,
        private fileService: FileService
    ) {
        this.subscriptionLoading = subscriptionService.getSubscriptions<Loading>('loading');
        this.subscriptionLoading.subscribe(
            loading => {
                this.loading = loading;
            }
        );

        this.subscriptionTheme = subscriptionService.getSubscriptions<Theme>('theme');
        this.subscriptionTheme.subscribe(
            theme => {
                this.theme = theme;
            }
        );

        this.subscriptionPet = subscriptionService.getSubscriptions<Pet>('pet');
        this.subscriptionPet.subscribe(
            pet => {
                this.pet = pet;
            }
        );

        this.subscriptionPets = subscriptionService.getSubscriptions<Pet[]>('pets');
        this.subscriptionPets.subscribe(
            pets => {
                this.pets = pets;
            }
        );



        this.subscriptionInsurance = subscriptionService.getSubscriptions<Insurance>('insurance');
        this.subscriptionInsurance.subscribe(
            insurance => {
                this.insurance = insurance;
            }
        );

        this.subscriptionUser = subscriptionService.getSubscriptions<User>('user');
        this.subscriptionUser.subscribe(
            user => {
                this.user = user;
            }
        );

        this.subscriptionCover = subscriptionService.getSubscriptions<Cover>('cover');
        this.subscriptionCover.subscribe(
            cover => {
                this.cover = cover;
            }
        );

        this.subscriptionPetCover = subscriptionService.getSubscriptions<Insurance[]>('pet-covers');
        this.subscriptionPetCover.subscribe(
            coverList => {
                this.petCoverList = coverList;
            }
        );
        this.subscriptionInsuranceCovers = subscriptionService.getSubscriptions<InsuranceCovers>('insurance-covers');
        this.subscriptionInsuranceCovers.subscribe(
            insuranceCovers => {
                this.insuranceCovers = insuranceCovers;
            }
        );

    }

    ngOnInit(): void {
        this.goTo('app-header');
        if (this.loading.getError('paymentProcessError') !== '') {
            ToolsMethods.simulateClickOn('#payment-details-panel .panel-title');
            setTimeout(
                () => {
                    this.validatePayment();
                },
                1500
            );
        }
        this.locationService
            .getPublicHoliday(this.user.address.state)
            .then(
                (publicHolidays) => {
                    this.publicHolidayList = publicHolidays;
                    this.initDates();
                }
            );
        this.locationService
            .getSuburbs(this.user.address.postcode)
            .then(
                (suburbs) => {
                    this.suburbList = suburbs;
                }
            );
        this.disclaimerText = this.themeService.getDisclaimer(this.theme.id);
        this.updateDeliveryMethod();
        this.updateUserPromotion();

        this.paymentService.getAuthToken()
            .then(
                (response) => {
                    this.token = response;
                    this.bearerToken = this.token.access_token;
                    this.actionUrl = this.thankyouUrl();
                    this.paymentGatewayUrl = this.token.PaymentGatewayUrl;
                    this.PaymentGatewayJSFile = this.token.PaymentGatewayJSFile;
                    this.environment = this.token.Environment;

                    this.AddPaymentGatewayScript();
                    // console.log("bearerToken: " + this.bearerToken);
                    // console.log("actionUrl: " + this.actionUrl);
                    // console.log("paymentGatewayUrl: " + this.paymentGatewayUrl);
                    // console.log("PaymentGatewayJSFile: " + this.PaymentGatewayJSFile);
                    // console.log("environment: " + this.environment);

                }
            );

        this.addressSuggestions$ = new Observable<string | undefined>((observer: Observer<string | undefined>) => {
            observer.next(this.addressSearch);
        }).pipe(
            switchMap((query: string) => {
                if (this.auPostToken.length <= 0) {
                    this.locationService.getAuPostToken()
                        .then(result => {
                            this.auPostToken = result;
                        });
                }
                if (query && this.auPostToken.length > 0) {
                    return from(
                        this.locationService.findAllAddresses(this.user.address.postcode, encodeURIComponent(this.user.address.address), this.auPostToken))
                    .pipe(
                        map((data: string[]) => data || []),
                        tap(() => noop, err => {
                            // in case of http error
                            console.error(err && err.message || 'Something goes wrong');
                        })
                    );
                }

                return of([]);
            })
        );
    }

    AddPaymentGatewayScript() {
        let accountType: string = "CC";
        if (this.payment.type === PaymentType.CARD) {
            accountType = "CC";
        }
        else {
            accountType = "DD";
        }

        this.fileService.renderLoadScript(accountType, this.user.id.toString(), this.cover.quoteId.toString());
    }

    SelectPaymentType(typeOfPayment: PaymentType) {
        this.payment.selectPaymentType(typeOfPayment);
        this.AddPaymentGatewayScript();
        this.fileService.renderOnPaymentTypeChange();
    }


    ngDoCheck() {
        if (this.oldCoverStartDate !== this.cover.coverStartDate) {
            this.updatePremiumStartDate();
            this.updatePetCover();
            this.oldCoverStartDate = this.cover.coverStartDate;
        }
    }

    get totalPrice(): number {
        let price: number = 0;
        for (let pet of this.pets) {
            if (pet.cover !== null) {
                price += pet.cover.getPrice(this.cover.frequency);
            }
        }
        return price;
    }

    get multiPetDiscount(): number {
        let discount: number = 0;
        let multiPetDiscountCount: number = this.pets[0].cover.multiPetDiscountCount;
        if (multiPetDiscountCount > 0 && multiPetDiscountCount <= this.pets.length) {
            for (let i = (multiPetDiscountCount - 1); i < this.pets.length; i++) {
                if (this.pets[i].cover !== null) {
                    discount += this.pets[i].cover.getMultipetDiscount(this.cover.frequency);
                }
            }
        }
        return discount;
    }

    get seniorPetDiscount(): boolean {
        if (this.user.eligibleForSenior) {
            for (let i = 0; i < this.pets.length; i++) {
                if (this.pets[i].cover.getSeniorDiscountAmount(this.cover.frequency) > 0) {
                    return true;
                }
            }
        }
        return false;
    }

    get displayPromotionTitle(): boolean {
        if (this.user.promotion === null || this.user.promotion.petsurePromotionId <= 0) {
            return false;
        }
        if (this.pets === null || this.pets.length <= 0 || this.pets[0].cover === null) {
            return false;
        }

        return this.pets[0].cover.petSurePartnerCode === this.user.promotion.partnerCode;
    }

    showPromotionTitle: boolean = false;

    updateUserPromotion(): void {
        this.applicationService
            .getApplication(this.cover.token)
            .then(
                (result) => {
                    if (this.insurance.insurancePromotion !== null && this.insurance.insurancePromotion.discountCode.toString().trim() !== "") {
                        this.showPromotionTitle = true;
                        this.hasFreeMonthOffer = (this.insurance.petSurePartnerCode == "RS456" || this.insurance.petSurePartnerCode == "GU456") && this.user.promotion.promoCode == "1CFREEMONTH";
                        if (result.user.promotion.promoCode != null && result.user.promotion.promoCode.toString().trim() != "") {
                            this.promotionService.getPromotion(result.user.promotion.promoCode, this.theme.id)
                                .then(
                                    (results) => {
                                        if (results.length > 0) {
                                            this.user.promotion.offerTitle = results[0].offerTitle;
                                            this.user.promotion.termsAndConditions = results[0].termsAndConditions;
                                        }
                                    }
                                    ,
                                    (error) => {
                                        this.loading.setError('promotionProcessError', 'An error occurred please retry');

                                    });
                        }
                    }
                    else {
                        this.showPromotionTitle = false;
                    }
                },
                (error) => {
                    this.loading.setError('applicationProcessError', 'An error occurred please retry');
                }
            );
    }
    updateTitle(title: string) {
        switch (title) {
            case 'Dr':
                break;
            case 'Mr':
                this.user.gender = Gender.Male;
                break;
            default:
                this.user.gender = Gender.Female;
        }
    }

    updateDeliveryMethod(email: any = '') {
        if (!this.cover) {
            return;
        }
        if (!email) {
            email = this.user.email;
        }
        this.cover.eDeliveryMethod = email !== this.EMAIL_GREENSTONE;
    }

    updatePhoneNumber($event: any) {
        let currentPosition: number = ToolsMethods.getCaretPosition('userContactNumber');
        let currentSize: number = this.user.contactNumber.length;
        this.user.contactNumber = ToolsMethods.formatPhoneNumber($event);
        let finalSize: number = this.user.contactNumber.length;
        let diff: number = finalSize - currentSize;
        setTimeout(() => { ToolsMethods.setCaretPosition('userContactNumber', currentPosition + diff); }, 5);
    }

    updateNumberOnDel($event: any) {
        if ($event.keyCode === 8 || $event.keyCode === 46) {
            this.user.contactNumber = ToolsMethods.formatPhoneNumber($event.target.value);
        }
    }

    get maxPhoneLength(): number {
        return ToolsMethods.maxPhoneLength(this.user.contactNumber);
    }


    // ---- Address typeahead control method ----

    getAddress(place) {
        this.user.address.mismatch = false;
        if (!place || !place.address_components) {
            return;
        }
        let newAddress: Address = Address.getBlank();
        let placeName: string = place.name;
        newAddress.state = this.user.address.state;
        newAddress.postcode = this.user.address.postcode;

        place.address_components.forEach((component) => {
            component.types.forEach((type) => {
                if (type === 'locality') {
                    newAddress.suburb = this.isInSuburbList(component.long_name);
                }
                if (type === 'postal_code') {
                    newAddress.postcode = component.long_name;
                }
                if (type === 'route') {
                    if (placeName.toLowerCase().lastIndexOf(component.short_name.toLowerCase()) >= 0
                        && placeName.toLowerCase().lastIndexOf(component.long_name.toLowerCase()) === -1) {
                        placeName = placeName.replace(component.short_name, component.long_name);
                    }
                }
            });
        });
        newAddress.address = placeName;
        if (this.user.address.postcode !== newAddress.postcode) {
            this.user.address.address = newAddress.address;
            this.user.address.suburb = '';
            this.user.address.mismatch = true;
            this.user.address.validate(3);
        } else {
            this.user.address = newAddress;
            this.user.address.mismatch = false;
            this.user.address.validate(3);
        }
        this.ref.detectChanges();
    }

    isInSuburbList(suburb): string {
        for (let suburbEl of this.suburbList) {
            if (suburbEl.toLowerCase() === suburb.toLowerCase()) {
                return suburbEl;
            }
        }
        return '';
    }

    // **** Address typeahead control method ****
    // ---- Pet control methods ----

    addPet() {
        for (let i = 0; i < this.pets.length; i++) {
            this.pets[i].expanded = false;
        }
        let newId = this.pets[this.pets.length - 1].id + 1;
        let pet = Pet.getBlank(newId, Insurance.getBlank());
        pet.cover.routineCareSelected = this.insurance.routineCareSelected;
        pet.cover.excessSelected = this.insurance.excessSelected;
        pet.expanded = true;

        //US79543 - do not pre-populating details before anything has been selected
        pet.petType = null;
        pet.gender = null;
        pet.isDesexed = null;

        this.pets.push(pet);
    }

    onRemove(i: number) {
        this.removePetPopup = i;
        this.removePetPopupEnabled = true;
        ToolsMethods.scrollToId('app-header');
    }

    confirmRemovePet() {
        let petToRemove: number = this.removePetPopup;
        this.removePetQuoteDetails(petToRemove);
        this.disableRemovePetPopup();
    }

    disableRemovePetPopup() {
        this.removePetPopup = -1;
        this.removePetPopupEnabled = false;
    }

    changeFreq(frequency: Frequency) {
        this.cover.frequency = frequency;
    }

    goToBrandCompare(args: { route: string, index: number }) {
        if (!this.loading.activated) {

            //Azure 54876: When going back to compare, we must validate all pets except for the pet we are attempting to compare
            this.aboutPetValid = true;
            this.pets.forEach((pet, petIndex) => {
                if (petIndex !== args.index && !pet.validate(3)) {
                    this.aboutPetValid = false;
                }
            });
            //If any pets are invalid, go to that section and return
            if (!this.aboutPetValid) {
                this.goTo('about-your-pet-panel');
                this.loading.activated = false;
                return;
            }

            this.loadingRoute = true;
            this.loading.activated = true;
            this.loading.loadingText = 'Loading back compare data';
            let navigationExtras: NavigationExtras = {
                queryParams: {
                    'brandId': this.insurance.brandId,
                    'petIndex': args.index
                }
            };
            this.router.navigate([args.route], navigationExtras);
        }
    }

    // **** Pet control methods ****
    // ---- Datepicker control methods ----

    initDates() {
        let checkForBorderlinePets = true;
        let initDate = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
        this.coverDateList = this.getDateList(initDate, 14, false, checkForBorderlinePets);
        this.cover.coverStartDate = this.coverDateList[0];
        this.oldCoverStartDate = this.cover.coverStartDate;
        this.updatePremiumStartDate();
    }

    getDateList(initDate: Date, maximumDays: number, skipWeekends: boolean, checkForBorderlinePets: boolean): Date[] {
        let dateList = [];
        let initMonth = initDate.getMonth();
        for (let days = 0; days < maximumDays; days++) {
            let newDate = new Date(initDate.getFullYear(), initDate.getMonth(), initDate.getDate() + days);
            //only do the skip weekends logic if skipweekends is true
            if (skipWeekends) {
                if (newDate.getDay() === 6) {
                    days += 2;
                    if (days >= maximumDays) {
                        break;
                    }
                    newDate.setDate(newDate.getDate() + 2);
                } else if (newDate.getDay() === 0) {
                    days++;
                    if (days >= maximumDays) {
                        break;
                    }
                    newDate.setDate(newDate.getDate() + 1);
                }
            }
            //if skipweekends is true we also skip public holidays
            //if checkForBorderlinePets is true we need to check each pet for the following
            // -will they turn 9 years old between now and the prospective start date, applicable to all brands
            // -will they turn 16 years old between now and the prospective start date, applicable to all brands except guide dogs
            //if there are any borderline pets as above, only add the prospective start date if it falls within the current month
            if (!this.isPublicHoliday(newDate) || skipWeekends) {
                let isBorderlinePet = false;
                if (checkForBorderlinePets) {
                    isBorderlinePet = !this.pets.every(c => !c.willHaveYearBirthdayBeforeOrOnDate(9, newDate));
                    isBorderlinePet = isBorderlinePet || ((this.insurance.brandId !== BrandsEnum.GuideDogs) && (!this.pets.every(c => !c.willHaveYearBirthdayBeforeOrOnDate(16, newDate))));
                }
                if (!isBorderlinePet || (newDate.getMonth() === initMonth))
                    dateList.push(newDate);
            }
        }
        return dateList;
    }

    isPublicHoliday(date: Date): boolean {
        for (let holiday of this.publicHolidayList) {
            if (holiday.getDate() === date.getDate()
                && holiday.getFullYear() === date.getFullYear()
                && holiday.getMonth() === date.getMonth()
            ) {
                return true;
            }
        }
        return false;
    }

    updatePremiumStartDate() {
        let initDate = new Date(
            this.cover.coverStartDate.getFullYear(),
            this.cover.coverStartDate.getMonth(),
            this.cover.coverStartDate.getDate()
        );
        initDate.setDate(initDate.getDate() + 2);
        this.premiumDateList = this.getDateList(initDate, 12, true, false);
        this.cover.premiumStartDate = this.premiumDateList[0];
    }

    updatePetCover() {
        let ageChanged: boolean = false;
        let nbPetAgeChanged: number = 0;
        for (let pet of this.pets) {
            if (pet.getAge() !== pet.getAge(this.cover.coverStartDate)) {
                this.petNameAgeChanged = pet.name;
                ageChanged = true;
                nbPetAgeChanged++;
            }
        }
        this.moreThanOnePetAgeChanged = nbPetAgeChanged > 1;
        this.petAgeChanged = ageChanged;
    }

    // **** Datepicker control methods ****
    // ---- Validation methods ----

    checkAboutPet(): number {
        this.aboutPetValid = true;
        this.aboutPetSubmit = true;
        let id = -1;
        for (let pet of this.pets) {
            //console.log('isFirstTermsAccepted - ' +  pet.isFirstTermsAccepted);
            if (!pet.validate(3)) {
                this.aboutPetValid = false;
                if (id === -1) {
                    id = pet.id;
                }
            }
        }
        return id;
    }

    validateAboutPet(event: any = null) {
        let id = this.checkAboutPet();
        if (!this.aboutPetValid && event !== null) {
            event.stopPropagation();
        }
        if (this.savingPets) {
            event.stopPropagation();
            return;
        }
        if (id !== -1) {
            this.goTo('pet-form-header-' + id);
        } else {
            this.goTo('about-your-pet-panel');
        }
        this.saveClient(this.aboutPetValid);
        this.saveQuoteDetails(this.aboutPetValid);
    }

    checkAboutYou() {
        this.aboutYouValid = this.user.validate(2);
        this.aboutYouSubmit = true;
    }

    validateAboutYou(event: any = null) {
        this.checkAboutYou();
        if (!this.aboutYouValid && event !== null) {
            event.stopPropagation();
        }
        this.goTo('about-you-panel');
        this.saveClient(this.aboutYouValid);
    }

    checkAboutContactDetails() {
        this.aboutContactDetailsValid = this.user.validate(3);
        this.aboutContactDetailsSubmit = true;
    }

    validateAboutContactDetails(event: any = null) {
        if (!this.isAddressSelected && !this.manualAddress) {
            event.stopPropagation();
            return false;
        }

        this.checkAboutContactDetails();
        if (!this.aboutContactDetailsValid && event !== null) {
            event.stopPropagation();
        }
        this.goTo('contact-details-panel');
        this.saveClient(this.aboutContactDetailsValid);
    }

    checkPetCover() {
        this.petCoverValid = this.cover.validate(2);
        this.petCoverSubmit = true;
    }
    /**
     * This will validate the pet cover details and update the pet cover dates
     * @param event 
     */
    validatePetCover(event: any = null) {
        this.message = "Updating Premium Cover Dates..."
        this.enablePetCoverNextButton = false;
        this.checkPetCover();
        if (!this.petCoverValid && event !== null) {
            event.stopPropagation();
        }
        // checking cover dates and quote id is valid to update the datews in the database
        if (this.cover.coverStartDate !== null && this.cover.premiumStartDate !== null && this.cover.quoteId !== 0) {
            // if the coverStartDate and premiumStartDate are same  sytem should not allow to proceed further 
            if (this.cover.coverStartDate == this.cover.premiumStartDate) {
                this.isAnyPetCoverDateUpdateError = true;
                this.updatingCoverDates = false;
                this.message = "Invalid Cover Dates, Premium start date should be a least 2 days after cover start date";
                this.enablePetCoverNextButton = true;
            }
            //if the cover dates are valid call the api to update the database
            else {
                let premiumCoverDates: PremiumCoverDateRequest = {
                    coverStartDate: this.cover.coverStartDate.toDateString(),
                    premiumStartDate: this.cover.premiumStartDate.toDateString(),
                    petQuoteId: this.cover.quoteId
                }
                this.updatingCoverDates = true;
                this.premiumService.updatePremiumCoverDetails(this.theme.userId, premiumCoverDates)
                    .then((updatedCover: PremiumCoverDateResponse) => {
                        if (updatedCover == null || updatedCover == undefined) {
                            this.isAnyPetCoverDateUpdateError = true;
                            setTimeout(() => { this.message = "Application has encountered an error, you will be returned to the first page shortly"; }, 5000);// wait for 5 seconds to show the message to user                           
                            this.router.navigate(['']);// navigate to first page if the response has any issue
                        }
                        else 
                        {
                            this.premiumCoverDateResponse = updatedCover
                            this.premiumCoverDateResponse.isCoverDatesSaved = true;
                            this.updatingCoverDates = false;
                            this.enablePetCoverNextButton = true;
                            this.message = "Invalid Cover Dates, Premium start date should be a least 2 days after cover start date"
                            ToolsMethods.simulateClickOn('#payment-details-panel .panel-title');

                            return;
                        }
                    },
                        error => {
                            this.updatingCoverDates = false;
                            this.enablePetCoverNextButton = false;
                            this.isAnyPetCoverDateUpdateError = true;
                            this.message = "Application has encountered an error, you will be returned to the first page shortly";
                            setTimeout(() => {
                                this.router.navigate(['']);// if any error occured system should navigate to first page. Implemented as per the requirement
                            }, 5000);//                           

                        }
                    );
            }
            this.goTo('pet-cover-panel');
            this.saveClient(this.petCoverValid);
        }
    }

    checkPayment() {
        //this.paymentValid =  this.payment.validate();
        this.paymentSubmit = true;
    }

    clientVlidatePaymentGateway() {
        let validatePaymentScript = `
        function ValidatePaymentGatewayForm(){
            document.querySelector('payment-register').validateform = true;
        }
        ValidatePaymentGatewayForm();
        `
        var validatePaymentScriptTag = document.createElement("script");
        validatePaymentScriptTag.id = "validatePaymentScriptTag"
        validatePaymentScriptTag.innerHTML = validatePaymentScript;
        if (document.getElementById("validatePaymentScriptTag")) {
            document.body.removeChild(document.getElementById("validatePaymentScriptTag"));
        }
        document.body.appendChild(validatePaymentScriptTag);
    }

    validatePayment(event: any = null) {
        // this.validatePaymentForm = true;
        // setTimeout(() => {        
        //     if(this.paymentValid){                
        //         this.goTo('payment-details-panel');
        //         this.saveClient(this.paymentValid);
        //     }
        // }, 2000);

        this.clientVlidatePaymentGateway();
        //this.goTo('payment-details-panel');

        //this.paymentValid = false;
        setTimeout(() => {
            let isPaymentValid: string = document.getElementById("hdnPaymentValid")["value"];
            if (isPaymentValid == "true") {
                this.paymentValid = true;
                this.saveClient(this.petCoverValid);
                //this.saveClient(this.paymentValid);
            }
            else {
                this.paymentValid = false;
                this.goTo('payment-details-panel');
                event.stopPropagation();
            }
        }, 100);
        event.stopPropagation();

        //this.checkPayment();
        // if (!this.paymentValid && event !== null) {
        //     event.stopPropagation();
        // }
        // this.goTo('payment-details-panel');
        // this.saveClient(this.paymentValid);
    }

    validateAll() {
        this.aboutPetValid = true;
        for (let pet of this.pets) {
            if (!pet.validate(3)) {
                this.aboutPetValid = false;
            }
        }
        this.aboutYouValid = this.user.validate(2);
        this.aboutContactDetailsValid = this.user.validate(3);
        this.petCoverValid = this.cover.validate(2);
        this.declarationValid = this.cover.validate(4);
        return this.aboutPetValid
            && this.aboutYouValid
            && this.aboutContactDetailsValid
            && this.petCoverValid
            && this.paymentValid
            && this.declarationValid;
    }



    validateDeclaration() {
        //If the coverStartDate  and  premiumStartDate are same user will navigate back to pet cover panel      
        if (this.cover.coverStartDate == this.cover.premiumStartDate) {
            this.isAnyPetCoverDateUpdateError = true;
            this.updatingCoverDates = false;
            this.message = "Invalid Cover Dates, Premium start date should be a least 2 days after cover start date";
            ToolsMethods.simulateClickOn('#pet-cover-panel .panel-title');
            return;
        }
        this.declarationValid = this.cover.validate(4);
        this.declarationSubmit = true;

        this.globalError = false;
        if (this.declarationSubmit) {
            if (this.validateAll()) {
                // reseting the errors
                this.processRunning = true;
                this.loading.validate();
                this.loading.activated = true;
                this.loading.loadingText = 'Processing your application';

                this.submitApplicationSaveQuoteFinish = false;
                this.submitApplicationSaveClientFinish = false;
                this.submitApplicationGetPaymentTokenFinish = false;
                this.saveClient()
                    .then((result) => {
                        if (result) {
                            this.submitApplicationSaveClientFinish = true;
                            this.submitApplication();
                            return;
                        }
                        this.processRunning = false;
                        this.loading.activated = false;
                        this.loading.setError('applicationProcessError', 'An error occurred please retry');
                    });
                this.saveQuote()
                    .then((result) => {
                        if (result) {
                            this.submitApplicationSaveQuoteFinish = true;
                            this.submitApplication();
                            return;
                        }
                        this.processRunning = false;
                        this.loading.activated = false;
                        this.loading.setError('applicationProcessError', 'An error occurred please retry');
                    });
                // this.paymentService
                //     .getPaymentToken(this.user, this.cover, this.pets[0].cover, this.payment, this.theme)
                //     .then(
                //         result => {
                //             this.payment = result;
                //             this.submitApplicationGetPaymentTokenFinish = true;
                //             this.submitApplication();
                //         },
                //         error => {
                //             this.processRunning = false;
                //             this.loading.activated = false;
                //             this.loading.setError('applicationProcessError', 'An error occurred please retry');
                //         }
                //     );

                let submitPaymentScriptString: string = `document.getElementById('btn_ac_submit').click();`;
                let submitPaymentScript = document.createElement("script");
                submitPaymentScript.id = "submitPaymentScript"
                submitPaymentScript.innerHTML = submitPaymentScriptString;
                if (document.getElementById("submitPaymentScript")) {
                    document.body.removeChild(document.getElementById("submitPaymentScript"));
                }
                document.body.appendChild(submitPaymentScript);

                // setTimeout(() => {
                //     this.submitApplicationGetPaymentTokenFinish = true;
                //     this.submitApplication();
                // }, 200);
            } else {
                this.globalError = true;
                if (!this.aboutPetValid) {
                    this.goTo('about-your-pet-panel');
                    return;
                }
                if (!this.aboutYouValid) {
                    this.goTo('about-you-panel');
                    return;
                }
                if (!this.aboutContactDetailsValid) {
                    this.goTo('contact-details-panel');
                    return;
                }
                if (!this.petCoverValid) {
                    this.goTo('pet-cover-panel');
                    return;
                }
                if (!this.paymentValid) {
                    this.goTo('payment-details-panel');
                    return;
                }
                /*  if (!this.productQuestionsValid) {
                    this.goTo('your-declaration-panel');
                    return;
                }  */
                if (!this.declarationValid) {
                    this.goTo('your-declaration-panel');
                    return;
                }
            }
        } else {
            this.goTo('your-declaration-panel');
        }
    }

    checkPreviousAndGo(event: any, name: string) {
        if (this.loadingRoute) {
            event.stopPropagation();
            return;
        }
        let last: boolean = false;

        last = last || (name === 'about-your-pet-panel');
        this.checkAboutPet();
        let stop = !this.aboutPetValid;

        last = last || (name === 'about-you-panel');
        if (!last && !stop) {
            this.checkAboutYou();
            stop = !this.aboutYouValid;
        }

        last = last || (name === 'contact-details-panel');
        if (!last && !stop) {
            this.checkAboutContactDetails();
            stop = !this.aboutContactDetailsValid;
        }

        last = last || (name === 'pet-cover-panel');
        if (!last && !stop) {
            this.checkPetCover();
            stop = !this.petCoverValid;
        }

        last = last || (name === 'payment-details-panel');
        if (!last && !stop) {
            this.checkPayment();
            stop = !this.paymentValid;
        }

        if (stop || this.savingPets) {
            event.stopPropagation();
        } else {
            this.saveClient(this.aboutPetValid);
            this.saveQuoteDetails(this.aboutPetValid);
            this.goTo(name, 300);
        }
    }

    // **** Validation methods  ****

    // ---- Saving methods ----

    saveClient(guard: boolean = true): Promise<boolean> {
        if (guard) {
            return this.clientService
                .saveClient(this.theme, this.user, this.pets[0], 3)
                .then(
                    () => {
                        return true;
                    },
                    () => {
                        return false;
                    });
        }
        return Promise.resolve(true);
    }

    saveQuoteDetails(guard: boolean = true) {
        if (guard) {
            this.savingPets = true;
            let promises: Promise<void>[] = [];
            this.pets.forEach((pet, index) => {
                promises.push(this.quoteService.saveQuoteDetails(this.user, pet, this.cover, index + 1));
            });
            Promise
                .all(promises)
                .then(() => {
                    this.savingPets = false;
                });
        }
    }

    saveQuote(guard: boolean = true): Promise<boolean> {
        if (guard) {
            return this.quoteService
                .saveQuote(this.user, this.insurance, this.pets, this.cover, this.theme, ToolsMethods.getCookie(document.cookie))
                .then(
                    () => {
                        return true;
                    },
                    () => {
                        return false;
                    });
        }
        return Promise.resolve(true);
    }

    removePetQuoteDetails(petNumber: number) {
        if (petNumber >= this.pets.length) {
            return;
        }
        if (this.pets.length > 1) {
            let petCover: Insurance = this.pets[petNumber].cover;
            if (petCover !== null && petCover.quoteDetailsId !== null && petCover.quoteDetailsId !== 0) {
                this.quoteService
                    .invalidateQuoteDetail(this.cover.quoteId, petCover.quoteDetailsId)
                    .then(() => {
                        this.pets.splice(petNumber, 1);
                        if (petNumber === 0) {
                            this.pet = this.pets[0];
                            this.subscriptionPet.next(this.pets[0]);
                        }
                    });
            } else {
                this.pets.splice(petNumber, 1);
            }
        }

    }

    // **** Saving methods  ****

    // ---- Submit methods ----

    submitApplication() {
        if (this.submitApplicationSaveQuoteFinish
            && this.submitApplicationSaveClientFinish
            && this.submitApplicationGetPaymentTokenFinish) {
            this.processPayment();
        }
    }

    processPayment() {
        if (this.validateAll()) {
            switch (this.payment.type) {
                case PaymentType.ACCOUNT:
                    setTimeout(() => { this.processAccountPayment(); }, 100);
                    break;
                case PaymentType.CARD:
                    setTimeout(() => { this.processCreditCardPayment(); }, 100);
                    break;
                default:
                    break;
            }
        }
        // todo remove
        setTimeout(() => { this.loading.activated = false; this.processRunning = false; }, 3000);
    }

    processAccountPayment() {
        if (document.forms.hasOwnProperty('credit-card-payment-form')) {
            document.forms['credit-card-payment-form'].submit();
        }
    }

    processCreditCardPayment() {
        if (document.forms.hasOwnProperty('credit-card-payment-form')) {
            document.forms['credit-card-payment-form'].submit();
        }
    }

    thankyouUrl(): string {
        let url = ToolsMethods.getBaseAbsoluteUrl(this.router.url, false);
        let referredToUnderWriter: boolean = this.cover.hadPriorPolicyCancelled;
        if (!referredToUnderWriter && this.insurance.brandId === BrandsEnum.GuideDogs) {
            for (let pet of this.pets) {
                if (pet.petType === this.SPECIES.CANINE && pet.isGuideDog && pet.microchipNumber.length > 0) {
                    referredToUnderWriter = true;
                }
            }
        }
        if (referredToUnderWriter) {
            url += this.location.prepareExternalUrl('/thank-you-s');
        } else {
            url += this.location.prepareExternalUrl('/thank-you');
        }
        url +=
            '?TokenID=' + this.cover.token
            + '&PetCriteriaID=' + this.user.petCriteriaID
            + '&Source=' + this.theme.source
            + '&UserID=' + this.theme.userId + '&';
        return url;
    }

    get returnUrl(): string {
        return this.thankyouUrl();
    }

    // **** Submit methods ****

    goToRoute(route: string) {
        if (!this.loading.activated) {
            this.loading.activated = true;
            this.loading.loadingText = 'Loading back compare data';

            //When going back to compare, we must validate all pets
            this.aboutPetValid = true;
            for (let pet of this.pets) {
                if (!pet.validate(3)) {
                    this.aboutPetValid = false;
                }
            }

            //If any pets are invalid, go to that section and return
            if (!this.aboutPetValid) {
                this.goTo('about-your-pet-panel');
                this.loading.activated = false;
                return;
            }

            this.router.navigate([route]);
        }
    }

    goTo(location: string, delay: number = 0) {
        if (location) {
            setTimeout(
                () => {
                    ToolsMethods.scrollToId(location, 500);
                },
                delay
            );
        }
    }

    get disclaimerDisplay() {
        let petExcessTexts: string[] = [];
        let petNames: string[] = [];
        let dentalPrimeText: string = '';
        for (let pet of this.pets) {
            petNames.push(pet.name);
            if (pet.cover.brandId == BrandsEnum.Prime && (pet.cover.productBenefitId == 125 || pet.cover.productBenefitId == 126)) {
                dentalPrimeText = "Where applicable, cover for dental illness begins 2 years after the commencement date of the first policy period.";
            }

            if (!pet.cover || pet.cover.excessSelected <= 0) {
                continue;
            }
            petExcessTexts.push(`$${pet.cover.excessSelected} for ${pet.name}`);
        }
        let excessText: string = '';
        if (petExcessTexts && petExcessTexts.length > 0) {

            excessText = `You've selected an excess of ${ToolsMethods.smartJoinTexts(petExcessTexts)} which will be deducted after the 
            calculation of your benefit for any eligible claim you make. `;
        }
        let petNameText: string = '';
        if (petNames && petNames.length > 0) {
            petNameText = ToolsMethods.smartJoinTexts(petNames);
            if (petNames.length > 1) {
                petNameText = petNameText + ' are';
            }
            else {
                petNameText = petNameText + ' is';
            }

        }
        if (this.pet.isGuideDog) {
            excessText = excessText + 'Once cover for your Registered Guide Dog has been accepted, Third Party Liability cover will commence on your policy and any claim for third party property damage will be subject to an Excess of $500.'
        }

        return this.disclaimerText
            .replace(/\{\{userName}}/gi, this.user.firstName)
            .replace(/\{\{insuranceName}}/gi, this.insurance.insuranceName)
            .replace(/\{\{totalPrice}}/gi, new CustomCurrencyPipe().transform(this.totalPrice, 2, '$'))
            .replace(/\{\{frequency}}/gi, this.FREQUENCY[this.cover.frequency].toLowerCase())
            .replace(/\{\{contactPhoneNumber}}/gi, this.theme.contactPhoneNumber)
            .replace(/\{\{coolingOffDays}}/gi, this.insurance.coolingOffDays + '')
            .replace(/\{\{brandPhone}}/gi, this.insurance.brandPhone)
            .replace(/\{\{petName}}/gi, petNameText)
            .replace(/\{\{dentalPrimeText}}/gi, dentalPrimeText)
            .replace(/\{\{excessText}}/gi, excessText);
    }

    get diagnostic() {
        return JSON.stringify(
            {
                'frequency': Frequency[this.cover.frequency],
                'cover': this.cover,
                'insurance': this.insurance,
                'pets': this.pets,
                'user': this.user,
                'payment': this.payment,
                'submitted': this.submitted
            },
            undefined,
            4
        );
    }

    changeAddressTypeaheadLoading(e: boolean): void {
        this.addressTypeaheadLoading = e;
    }

    changeAddressTypeaheadNoResults(e: boolean): void {
        this.addressTypeaheadNoResults = e;
    }

    addressTypeaheadOnSelect(e: any): void {
        if (this.auPostToken.length <= 0) {
            this.locationService.getAuPostToken()
                .then(result => {
                    this.auPostToken = result;
                });
        }
        if (this.auPostToken.length > 0) {
            this.locationService.getAddress(encodeURIComponent(e.item), this.auPostToken).then((result) => {
                let addr: any = result;
                let address1: string = '';

                if (this.user.address.postcode == addr['postcode']) {
                    if (addr['flatOrUnitType'] == 'U') {
                        address1 = addr['flatOrUnitType'] + ' ' + addr['flatOrUnitNumber'] + '/';
                    }
                    if (addr['postalType'] !== '') {
                        address1 = address1 + addr['postalType'] + ' ' + addr['postalNumber'];
                    }
                    else {
                        address1 = address1 + addr['streetNumber1'];
                        if (addr['streetNumber1Suffix'] !== '') {
                            address1 = address1 + addr['streetNumber1Suffix'];
                        }
                        if (addr['streetNumber2'] != '') {
                            address1 = address1 + '-' + addr['streetNumber2']
                        }
                        if (addr['streetNumber2Suffix'] !== '') {
                            address1 = address1 + addr['streetNumber2Suffix'];
                        }
                        address1 = address1 + ' ' + addr['streetName'] + ' ' + addr['streetType'];
                    }
                    this.user.address.suburb = addr['localityName'];
                    this.user.address.address = address1;
                    this.isAddressSelected = true;
                }
                else {
                    this.user.address.address = '';
                }
            });
        }
    }

    resetAddress() {
        this.user.address.address = '';
    }

    addressChanged(): void {
        this.isAddressSelected = false;
        if (this.user.address.address) {
            if (this.auPostToken.length <= 0) {
                this.locationService.getAuPostToken()
                    .then(result => {
                        this.auPostToken = result;
                    });
            }
            if (this.auPostToken.length > 0) {
                this.addressSuggestions$ = from(
                    this.locationService.findAllAddresses(
                        this.user.address.postcode,
                        encodeURIComponent(this.user.address.address), this.auPostToken
                    )
                )
            }
        }
    }

    showManualAddress(): void {
        this.manualAddress = true;
    }
}
