<div id="app-header" class="container header">
    <div class="row row-height xs-row-height">
        <div class="col-xs-12 col-sm-7 col-md-8 col-height col-middle">
            <div class="header-img">
                <a href="{{theme.mainWebSiteUrl | protocol }}" class="header-main-picture">
                    <span class="header-brand-logo"></span>
                </a>
            </div>
        </div>
        <div class="col-xs-12 col-sm-17 col-md-16 col-height col-middle">
            <div class="header-container">
                <div class="hidden-xs">
                    <span>Need help?</span>
                    <a (click)="haveUsCallOpen = !haveUsCallOpen" class="box">
                        <span class="icon-small receive-call-white"></span>
                        Have us call you
                    </a>
                </div>
                <div *ngIf="theme.contactPhoneNumber" class="hidden-xs">
                    <a href="tel:{{theme.contactPhoneNumber | removeSpaces}}" target="_blank" class="box">
                        <span class="icon-small call-white"></span>
                        Call us <strong>{{theme.getFormattedPhoneNumber()}}</strong>
                    </a>
                    <small>
                        Mon - Fri 8am to 8pm (AEST)
                    </small>
                </div>
                <div *ngIf="theme.contactPhoneNumber" class="visible-xs">
                    <span>Call us </span>
                    <a href="tel:{{theme.contactPhoneNumber | removeSpaces}}" target="_blank" class="box">
                        <span class="icon-small phone-light-blue"></span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="haveUsCallOpen" [@slideDown]="haveUsCallOpen" (closeHaveCall)="closeHaveCall($event)" have-us-call class="no-overflow"></div>
<div class="breadcrumbs-container">
    <div class="container padding-0-xs">
        <div class="row row-height xs-row-height margin-0-xs">
            <div class="col-xs-8 col-height col-top"
                 (click)="goToRoute('/')"
                 [ngClass]="{ 'active': router.url === '/', 'can-route': linkActivated('/') }">
                <div class="col-sm-6 col-md-5 col-lg-4 hidden-xs text-center">
                    <span *ngIf="!loading.activated" class="breadcrumb-number">1</span>
                    <span *ngIf="loading.activated" class="glyphicon glyphicon-refresh rotating"></span>
                </div>
                <div class="col-xs-24 col-sm-16">
                    About you &amp; your pet
                </div>
            </div>
            <div class="col-xs-8 col-height col-top"
                 (click)="goToRoute('/compare')"
                 [ngClass]="{ 'active': router.url === '/compare', 'can-route': linkActivated('/compare') }">
                <div class="col-sm-6 col-md-5 col-lg-4 hidden-xs text-center">
                    <span *ngIf="!loading.activated" class="breadcrumb-number">2</span>
                    <span *ngIf="loading.activated" class="glyphicon glyphicon-refresh rotating"></span>
                </div>
                <div class="col-xs-24 col-sm-16">
                    Compare &amp; choose
                </div>
            </div>
            <div class="col-xs-8 col-height col-top"
                 (click)="goToRoute('/buy')"
                 [ngClass]="{ 'active': router.url === '/buy', 'can-route': linkActivated('/buy') }">
                <div class="col-sm-6 col-md-5 col-lg-4 hidden-xs text-center">
                    <span *ngIf="!loading.activated" class="breadcrumb-number">3</span>
                    <span *ngIf="loading.activated" class="glyphicon glyphicon-refresh rotating"></span>
                </div>
                <div class="col-xs-24 col-sm-16">
                    Buy now
                </div>
            </div>
        </div>
    </div>
</div>
<router-outlet></router-outlet>
<div class="container">
    <div class="back-to-top-btn pull-right">
        <span (click)="goTop()">
            Back to Top <span class="glyphicon glyphicon-menu-up pull-right"></span>
        </span>
    </div>
</div>
<div class="footer">
    <div class="footer-txt-container">
        <div class="container">
            <div class="row" [innerHTML]="theme.termsCondition"></div>

       
    </div>
</div>
<!-- <div *ngIf="recaptchaPassed"  [innerHTML]="hideRecaptchaBadge"></div> -->