import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot, NavigationExtras } from '@angular/router';

import { InsuredPet } from '../model/insured-pet';
import { Insurance } from '../model/insurance';
import { Loading } from '../model/loading';
import { Theme } from '../model/theme';
import { Cover } from '../model/cover';
import { Breed } from '../model/breed';
import { User } from '../model/user';
import { Pet } from '../model/pet';

import { SubscriptionService } from '../services/subscription.service';
import { ApplicationService } from '../services/application.service';
import { PremiumService } from '../services/premium.service';
import { BreedsService } from '../services/breeds.service';
import { QuoteService } from '../services/quote.service';

@Injectable()
export class ThankYouResolve implements Resolve<InsuredPet[]> {

    private theme: Theme = Theme.getBlank();

    constructor(
        private subscriptionService: SubscriptionService,
        private applicationService: ApplicationService,
        private premiumService: PremiumService,
        private breedsService: BreedsService,
        private quoteService: QuoteService,
        private router: Router
    ) {}

    redirectError(): InsuredPet[] {
        this.router.navigate(['']);
        return null;
    }

    resolve(route: ActivatedRouteSnapshot): Promise<InsuredPet[]>|InsuredPet[] {
        let loading = this.subscriptionService.getSubscriptions<Loading>('loading').getValue();
        loading.activated = true;
        try {
            this.initTheme(route);
            let queryParams: any = {};
            for (let key in route.queryParams) {
                if (route.queryParams.hasOwnProperty(key)) {
                    if (key.startsWith('?')) {
                        let newKey = key.substring(1);
                        queryParams[newKey] = route.queryParams[key];
                    } else {
                        queryParams[key] = route.queryParams[key];
                    }
                }
            }

            let tokenParam: string = 'TokenID';
            let petCriteriaIDParam: string = 'PetCriteriaID';

            let customerPreRegistrationCodeParam: string = 'cd_prerego';
            let customerReferenceNumberParam: string = 'cd_crn';
            let cardHolderNameParam: string = 'nm_card_holder';
            let accountAliasParam: string = 'nm_account_alias';
            let accountNameParam:string = 'nm_account';
            let actionProcessedParam: string = 'action';
            let expiryDateAliasParam: string = 'dt_expiry';
            let accountTypeParam: string = 'accountType';

            // todo unused for now
            // let errorContextParam: string = 'tx_response';

            let cdSummaryParam: string = 'cd_summary';

            if (!queryParams.hasOwnProperty(tokenParam)
                || !queryParams.hasOwnProperty(petCriteriaIDParam)
            ) {
                return this.redirectError();
            }

            let token: string = queryParams[tokenParam];
            let petCriteriaId: string = queryParams[petCriteriaIDParam];

            if (queryParams.hasOwnProperty(cdSummaryParam)
                || queryParams.hasOwnProperty(actionProcessedParam)) {
                if (queryParams[cdSummaryParam] === '1'
                    || queryParams[actionProcessedParam].toLowerCase() === 'failed') {
                    let navigationExtras: NavigationExtras = {
                        queryParams: {
                            'TokenID': token,
                            'UserID': this.theme.userId,
                            'Source': this.theme.source,
                            'error': 1
                        }
                    };
                    this.router.navigate([''], navigationExtras);
                    return null;
                }
            }

            if (queryParams.hasOwnProperty(customerReferenceNumberParam)
                && queryParams.hasOwnProperty(customerPreRegistrationCodeParam)
                && queryParams.hasOwnProperty(actionProcessedParam)
                && queryParams.hasOwnProperty(accountTypeParam)
            ) {      
                let customerPreRegistrationCode: string = queryParams[customerPreRegistrationCodeParam];
                let customerReferenceNumber: string = queryParams[customerReferenceNumberParam];
                let actionProcessed: string = queryParams[actionProcessedParam];
                let accountAlias: string = queryParams[accountAliasParam];
                let accountType: string = queryParams[accountTypeParam];                
                let accountName: string = "";
                
                if(queryParams[accountNameParam]){
                    accountName = queryParams[accountNameParam].split("+").join(" ");
                }
                let accountNumber: string = '';
                let bsb: string = '';
                let response: string = "";
                if(queryParams["tx_response"]){
                    response = queryParams["tx_response"].split("+").join(" ");
                }
                let cardHolderName: string = '';
                let expiryDateAlias: string = '';

                
                if(accountAlias && accountType.toLowerCase() === 'dd'){
                    var delimiter = "+"
                    if(accountAlias.indexOf('+') == -1 && accountAlias.indexOf(' ') > 0) {
                        delimiter = " "
                    } 
                    else 
                    {
                        console.error("Invalid account format received");
                        return this.redirectError();
                    }
                
                    bsb = accountAlias.split(delimiter)[0];
                    accountNumber = accountAlias.split(delimiter)[1];    
                
                    if(delimiter == " ") {
                    accountAlias = accountAlias.split(" ").join("+");
                    }
            
                }

                if (accountType && accountType.toLowerCase() === 'cc') {
                    if (queryParams.hasOwnProperty(cardHolderNameParam)
                        && queryParams.hasOwnProperty(expiryDateAliasParam)
                    ) {
                        cardHolderName = queryParams[cardHolderNameParam].split("+").join(" ");
                        expiryDateAlias = queryParams[expiryDateAliasParam];
                    } else {
                        return this.redirectError();
                    }
                } else if (accountType.toLowerCase() === 'dd') {
                    // fallback for DD
                    expiryDateAlias = '0000';
                }


                // todo get real msg
                // let errorContext: string = queryParams[errorContextParam];
                // if (errorContext === null) {
                let errorContext = '';
                // }                
                return this.applicationService
                    .getApplication(token)
                    .then(
                        (result) => {
                            result.user.petCriteriaID = petCriteriaId;
                            this.handleUpdateCoverResponse(result); 
                            return this.applicationService.saveToken(accountType.toLowerCase(),
                                                                    accountNumber,
                                                                    bsb,
                                                                    cardHolderName,
                                                                    accountAlias,
                                                                    customerPreRegistrationCode,
                                                                    queryParams[actionProcessedParam],
                                                                    customerReferenceNumber,
                                                                    expiryDateAlias,
                                                                    accountName,
                                                                    response)
                                        .then((response)=>{                                
                                            return this.applicationService
                                            .submitApplication(
                                                result.cover.quoteId,
                                                result.user.id,
                                                customerReferenceNumber,
                                                customerPreRegistrationCode,
                                                cardHolderName,
                                                accountAlias,
                                                actionProcessed,
                                                expiryDateAlias,
                                                errorContext,
                                                this.theme.userId
                                            )
                                            .then(
                                                (insuredPets) => {
                                                    let loadingFinal = this.subscriptionService.getSubscriptions<Loading>('loading').getValue();
                                                    loadingFinal.activated = false;
                                                    this.subscriptionService.getSubscriptions<Loading>('loading').next(loadingFinal);
                                                    return insuredPets;
                                                },
                                                (error) => {
                                                    console.error(error);

                                                    let navigationExtras: NavigationExtras = {
                                                        queryParams: {
                                                            'TokenID': token,
                                                            'UserID': this.theme.userId,
                                                            'Source': this.theme.source,
                                                            'error': 2
                                                        }
                                                    };
                                                    this.router.navigate([''], navigationExtras);
                                                    return this.redirectError();
                                                });
                                            },
                                            () => {
                                                return this.redirectError();
                                            });                            
                        },
                        () => {
                            return this.redirectError();
                        }
                    );

            }
        } catch (e) {
            console.error(e);
        }
        return this.redirectError();
    }

    private initTheme(route: ActivatedRouteSnapshot) {
        this.theme = this.subscriptionService.getSubscriptions<Theme>('theme').getValue();
        let userIdParamName = 'UserID';
        if (route.queryParams.hasOwnProperty(userIdParamName)) {
            this.theme.userId = route.queryParams[userIdParamName];
        }
        let sourceParamName = 'Source';
        if (route.queryParams.hasOwnProperty(sourceParamName)) {
            this.theme.source = route.queryParams[sourceParamName];
        }
    }

    private handleUpdateCoverResponse(result: any) {
        this.subscriptionService.getSubscriptions<Pet[]>('pets').next(result.pets);
        let insurance: Insurance = Insurance.getBlank();
        if (result.pets.length > 0) {
            insurance = result.pets[0].cover;
            this.subscriptionService.getSubscriptions<Pet>('pet').next(result.pets[0]);
        }
        this.subscriptionService.getSubscriptions<Insurance>('insurance').next(insurance);
        this.subscriptionService.getSubscriptions<Cover>('cover').next(result.cover);
        this.subscriptionService.getSubscriptions<User>('user').next(result.user);
        this.findProducts(result.pets, insurance, result.user);
    }

    private findProducts(pets: Pet[], ins: Insurance, user: User) {
        this.quoteService
            .findProduct(ins)
            .then(
                (insurance) => {
                    for (let pet of pets) {
                        this.findBreed(pet);
                        pet.cover.brandId = insurance.brandId;
                        pet.cover.brandCode = insurance.brandCode;
                        pet.cover.insuranceName = insurance.insuranceName;
                        pet.cover.aboutBrand = insurance.aboutBrand;
                        pet.cover.brandLogo = insurance.brandLogo;
                    }
                    this.subscriptionService.getSubscriptions<Pet[]>('pets').next(pets);
                    this.subscriptionService.getSubscriptions<Pet>('pet').next(pets[0]);
                },
                (error) => {
                    this.handleError('Error while retrieving the brand', error);
                }
            );
    }

    private findBreed(pet: Pet) {
        this.breedsService
            .getBreeds(pet.petType)
            .subscribe(
                breeds => {
                    let breedsFiltered: Breed[] = breeds.filter(
                        breed => {
                            return breed.breedCode === pet.breed.breedCode;
                        }
                    );
                    pet.breed.breed = 'UNKNOWN';
                    if (breedsFiltered.length > 0) {
                        pet.breed.breed = breedsFiltered[0].breed;
                    }
                },
                error => {
                    pet.breed.breed = 'UNKNOWN';
                }
            );
    }

    private handleError(message: string, error: any) {
        console.error(message);
        console.error(error);
    }

}
