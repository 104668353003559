<div class="panel-heading pet-form-panel-heading" [id]="'pet-form-header-' + id">
    <div class="panel-title row" data-toggle="collapse" data-parent="#pet-form"
        [attr.data-target]="'#pet-form-' + id"
        [ngClass]="{ 'collapsed': !pet.expanded }">
        <div class="col-xs-12 col-sm-8">
            <h4>
                Pet {{id + 1}}: {{pet.name | emptyValue:'Your pet'}}
            </h4>
        </div>
        <div class="col-xs-12 col-sm-16 text-right">
            <div class="validation-group inline-block with-menu hidden-xs">
                <div class="invalid" *ngIf="!pet.isValid()">
                    <span class="glyphicon glyphicon-warning-sign"></span>
                    Details required
                </div>
            </div>
            <button type="button" *ngIf="petNb > 1" (click)="remove($event)" class="btn action-btn link-more hidden-xs remove-pet-btn">
                Remove x
            </button>

            <button type="button" class="btn action-btn hidden-xs">
                <span class="show-more">
                    More
                </span>
                <span class="show-less">
                    Less
                </span>
                <span class="glyphicon glyphicon-menu-up"></span>
            </button>

            <div class="mobile-pet-menu-buttons visible-xs">
                <div class="validation-group">
                    <div class="invalid" *ngIf="!pet.isValid()">
                        <span class="glyphicon glyphicon-warning-sign"></span>
                        Details required
                    </div>
                </div>
                <a *ngIf="petNb > 1" (click)="remove()" class="remove">
                    Remove
                </a>
                <a>
                    <span class="show-more">
                        More
                    </span>
                        <span class="show-less">
                        Less
                    </span>
                    <span class="glyphicon glyphicon-menu-up"></span>
                </a>
            </div>
        </div>
    </div>
</div>
<div [id]="'pet-form-' + id" class="panel-collapse collapse" [ngClass]="{ 'in': pet.expanded }">
    <div class="panel-body">
        <div class="row">
            <div class="col-sm-24 m-t-1">
                <div class="form-group">
                    <label for="petName" class="col-sm-8 control-label">
                        Your pet's name
                    </label>
                    <div class="col-xs-24 col-sm-10">
                        <input type="text" id="petName" class="form-control" name="petName" placeholder="Pet name"
                               [(ngModel)]="pet.name" #petName="ngModel"
                               [fieldValidation]="pet"
                               [fieldName]="'name'"
                               [fieldGuard]="submitted"
                               [validationStep]="3"
                               (keyup)="(($event.key == 8 || $event.key == 46)? (pet.name= $event.target.value):'')"
                               maxlength="50" required />
                        <div [hidden]="petName.valid || petName.pristine || submitted" class="alert alert-danger">
                            Please enter your pet’s name
                        </div>
                        <div [model-validated]="pet" [property-name]="'name'" model-validation-display></div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-sm-8 control-label">{{pet.name | emptyValue:'Your pet'}} is a</label>
                    <div class="col-sm-10">
                        <div class="row">
                            <div class="col-xs-12">
                                <button type="button" class="btn radio-btn col-xs-24"
                                        (click)="updateSpecies(SPECIES.FELINE)"
                                        [ngClass]="{ 'active': pet.petType === SPECIES.FELINE }">
                                    Cat
                                </button>
                            </div>
                            <div class="col-xs-12">
                                <button type="button" class="btn radio-btn col-xs-24"
                                        (click)="updateSpecies(SPECIES.CANINE); pet.isGuideDog = null;"
                                        [ngClass]="{ 'active': pet.petType === SPECIES.CANINE }">
                                    Dog
                                </button>
                            </div>
                            <div class="col-xs-24" [model-validated]="pet" [property-name]="'petType'" model-validation-display></div>
                        </div>
                    </div>
                </div>
                <div class="form-group" *ngIf="pet.petType == SPECIES.CANINE">
                    <label class="col-sm-8 control-label">
                        Is {{pet.name | emptyValue:'Your pet'}} a Registered Guide Dog
                        <div class="tooltip-xs">
                            <a href="javascript:void(0);" class="tooltip-link" tooltipPlacement="bottom" tabindex="999"
                               [tooltip]="'Registered Guide Dog means a dog supplied by a Guide Dogs Australia state member organisation to a person for the purpose of mobility support.'">
                                <span class="help-icon"></span>
                            </a>
                        </div>
                    </label>
                    <div class="col-sm-10">
                        <div id="isGuideDog" class="row">
                            <div class="col-xs-12">
                                <button type="button" class="btn radio-btn col-xs-24"
                                        (click)="pet.isGuideDog = true"
                                        [ngClass]="{ 'active': pet.isGuideDog }">
                                    Yes
                                </button>
                            </div>
                            <div class="col-xs-12">
                                <button type="button" class="btn radio-btn col-xs-24"
                                        (click)="pet.isGuideDog = false"
                                        [ngClass]="{ 'active': pet.isGuideDog !== null && !pet.isGuideDog }">
                                    No
                                </button>
                            </div>
                            <div class="col-xs-24" [model-validated]="pet" [property-name]="'isGuideDog'" model-validation-display></div>
                        </div>
                    </div>
                    <div class="hidden-xs col-sm-6">
                        <a href="javascript:void(0);" class="tooltip-link" tooltipPlacement="bottom" tabindex="999"
                           [tooltip]="'Registered Guide Dog means a dog supplied by a Guide Dogs Australia state member organisation to a person for the purpose of mobility support.'">
                            <span class="help-icon"></span>
                        </a>
                    </div>
                </div>
                <div class="form-group" *ngIf="pet.isGuideDog && pet.cover && isGuideDogInsurance && pet.petType == SPECIES.CANINE">
                    <label for="petMicrochipNumber" class="col-sm-8 control-label">
                        What is your Registered {{pet.name}} microchip number?
                        <div class="tooltip-xs">
                            <a href="javascript:void(0);" class="tooltip-link" tooltipPlacement="bottom" tabindex="999"
                               [tooltip]="'By providing the microchip number, you authorise us to disclose your personal information to Guide Dogs Australia state member organisations to verify your Guide Dogs’ registration status and obtain vet history.'">
                                <span class="help-icon"></span>
                            </a>
                        </div>
                    </label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" id="petMicrochipNumber" name="petMicrochipNumber" placeholder="0123456789"
                               [(ngModel)]="pet.microchipNumber" #userContactNumber="ngModel"
                               [fieldValidation]="pet"
                               [fieldName]="'microchipNumber'"
                               [fieldGuard]="submitted"
                               [validationStep]="3"
                               (keyup)="(($event.key == 8 || $event.key == 46)? (pet.microchipNumber=$event.target.value):'')"
                               #petMicrochipNumber="ngModel"
                               maxlength="15" pattern="[0-9]{15}"
                               required />
                        <div [hidden]="petMicrochipNumber.valid || petMicrochipNumber.pristine || submitted" class="alert alert-danger">
                            Please enter your pet's microchip number
                        </div>
                        <div [model-validated]="pet" [property-name]="'microchipNumber'" model-validation-display></div>
                    </div>
                    <div class="col-sm-6 hidden-xs">
                        <a href="javascript:void(0);" class="tooltip-link" tooltipPlacement="bottom" tabindex="999"
                           [tooltip]="'By providing the microchip number, you authorise us to disclose your personal information to Guide Dogs Australia state member organisations to verify your Guide Dogs’ registration status and obtain vet history.'">
                            <span class="help-icon"></span>
                        </a>
                    </div>
                </div>
                <div class="form-group">
                    <label for="petBreed" class="col-sm-8 control-label">
                        What breed is {{pet.name | emptyValue:'your pet'}}?
                        <div class="tooltip-xs">
                            <a href="javascript:void(0);" class="tooltip-link" tooltipPlacement="bottom" tabindex="999"
                                  tooltip="If your pet is a cross breed that is not listed, please choose the dominant breed plus the word 'cross'. e.g. If the dominant breed is Beagle, choose 'Beagle Cross'. If the breed is not known, please select 'Unknown'.">
                                <span class="help-icon"></span>
                            </a>
                        </div>
                    </label>
                    <div class="col-xs-24 col-sm-10 typeahead-container">
                        <input
                                id="petBreed" name="petBreed"
                                placeholder="Start typing to begin search"
                                class="form-control"
                                type="text"
                                autocomplete="off"
                                #petBreed="ngModel"
                                [ngClass]="{ 'form-invalid': ((!petBreed.pristine || submitted) && pet.breed.breedCode === '') }"
                                [(ngModel)]="breedName"
                                [typeahead]="breedDataSource"
                                (typeaheadLoading)="changeBreedTypeaheadLoading($event)"
                                (typeaheadNoResults)="changeBreedTypeaheadNoResults($event)"
                                (typeaheadOnSelect)="breedTypeaheadOnSelect($event)"
                                [typeaheadOptionsLimit]="7"
                                [typeaheadMinLength]="1"
                                [typeaheadWaitMs]="50"
                                [typeaheadOptionField]="'breed'"
                                (ngModelChange)="checkAndUpdatePricing()"
                                (keyup)="(($event.key == 8 || $event.key == 46)? (resetPetBreed()):'')"
                                required>
                        <div *ngIf="breedTypeaheadLoading">
                            <i class="glyphicon glyphicon-refresh ng-hide rotating"></i>
                        </div>
                        <div *ngIf="breedTypeaheadNoResults" class="" style="">
                            <i class="glyphicon glyphicon-remove"></i> No Results Found
                        </div>
                        <div [hidden]="(petBreed.pristine && !submitted) || pet.breed.breed !== ''" class="alert alert-danger">
                            Please choose your pet's breed
                        </div>
                    </div>
                    <div class="col-sm-6 hidden-xs">
                        <a href="javascript:void(0);" class="tooltip-link" tooltipPlacement="bottom" tabindex="999"
                              tooltip="If your pet is a cross breed that is not listed, please choose the dominant breed plus the word 'cross'. e.g. If the dominant breed is Beagle, choose 'Beagle Cross'. If the breed is not known, please select 'Unknown'.">
                            <span class="help-icon"></span>
                        </a>
                    </div>
                </div>
                <div class="form-group">
                    <label for="petBornMonth" class="col-sm-8 control-label">
                        {{pet.name | emptyValue:'Your pet'}} was born in
                        <div class="tooltip-xs">
                            <a href="javascript:void(0);" class="tooltip-link" tooltipPlacement="bottom" tabindex="999"
                                  tooltip="In order to qualify for any of the pet insurance policies, your pet needs to be older than 8 weeks of age. Please enter the month and year your pet was born.">
                                <span class="help-icon"></span>
                            </a>
                        </div>
                    </label>
                    <div class="col-xs-24 col-sm-10">
                        <div class="row">
                            <div class="col-xs-12">
                                <input type="tel" class="form-control text-center" id="petBornMonth" name="petBornMonth" placeholder="MM"
                                       [(ngModel)]="pet.bornMonth" #petBornMonth="ngModel"
                                       [ngClass]="{ 'form-invalid': !pet.isPropertyValid('bornMonth') }"
                                       [fieldValidation]="pet"
                                       [fieldName]="'bornMonth'"
                                       [fieldGuard]="submitted"
                                       [validationStep]="3"
                                       (ngModelChange)="checkAndUpdatePricing()"
                                       (keyup)="(($event.key == 8 || $event.key == 46)? (pet.bornMonth= $event.target.value):'')"
                                       (change)="updatePetAgeControl()"
                                       maxlength="2" pattern="([1-9]|[1][0-2]|0[1-9])"
                                       required/>
                                <div [hidden]="petBornMonth.valid || petBornMonth.pristine || submitted" class="alert alert-danger">
                                    Please enter a month
                                </div>
                                <div [model-validated]="pet" [property-name]="'bornMonth'" model-validation-display></div>
                            </div>
                            <div class="col-xs-12">
                                <input type="tel" class="form-control text-center" id="petBornYear" name="petBornYear" placeholder="YYYY"
                                       [(ngModel)]="pet.bornYear" #petBornYear="ngModel"
                                       [fieldValidation]="pet"
                                       [fieldName]="'bornYear'"
                                       [fieldGuard]="submitted"
                                       [validationStep]="3"
                                       (ngModelChange)="checkAndUpdatePricing()"
                                       (keyup)="(($event.key == 8 || $event.key == 46)? (pet.bornYear= $event.target.value):'')"
                                       (change)="updatePetAgeControl()"
                                       maxlength="4" pattern="(19[0-9]{2}|20[0-2][0-9])"
                                       required/>
                                <div [hidden]="petBornYear.valid || petBornYear.pristine || submitted" class="alert alert-danger">
                                    Please enter a year
                                </div>
                                <div [model-validated]="pet" [property-name]="'bornYear'" model-validation-display></div>
                            </div>
                            <div class="col-xs-24">
                                <div [model-validated]="pet" [property-name]="'bornDate'" model-validation-display></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 hidden-xs">
                        <a href="javascript:void(0);" class="tooltip-link" tooltipPlacement="bottom" tabindex="999"
                              tooltip="In order to qualify for any of the pet insurance policies, your pet needs to be older than 8 weeks of age. Please enter the month and year your pet was born.">
                            <span class="help-icon"></span>
                        </a>
                    </div>
                    <div class="col-xs-24 col-sm-10 col-sm-offset-8 checkbox-confirm-container" *ngIf="petAgeControl">
                        <div class="item-spacer-10 clearfix"></div>
                        <div class="checkbox checkbox-confirm">
                            <label [ngClass]="{ 'check-required': !pet.isOver8WeeksOldDeclaration && submitted }">
                                <input type="checkbox"
                                       name="isOver8Weeks"
                                       [(ngModel)]="pet.isOver8WeeksOldDeclaration"
                                       [fieldValidation]="pet"
                                       [fieldName]="'isOver8WeeksOldDeclaration'"
                                       [fieldGuard]="submitted"
                                       [validationStep]="3"
                                       (ngModelChange)="checkAndUpdatePricing()"
                                       #isOver8Weeks="ngModel"
                                       required/>
                                I confirm that <strong>{{pet.name | emptyValue:'my pet'}}</strong> is older than 8 weeks
                            </label>
                        </div>
                        <div [model-validated]="pet" [property-name]="'isOver8WeeksOldDeclaration'" model-validation-display></div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-sm-8 control-label">Is {{pet.name | emptyValue:'your pet'}} male or female?</label>
                    <div class="col-sm-10">
                        <div class="row">
                            <div class="col-xs-12">
                                <button type="button" class="btn radio-btn col-xs-24"
                                        (click)="pet.gender = GENDER.Male; checkAndUpdatePricing();"
                                        [ngClass]="{ 'active': pet.gender === GENDER.Male }">
                                    Male
                                </button>
                            </div>
                            <div class="col-xs-12">
                                <button type="button" class="btn radio-btn col-xs-24"
                                        (click)="pet.gender = GENDER.Female; checkAndUpdatePricing();"
                                        [ngClass]="{ 'active': pet.gender === GENDER.Female }">
                                    Female
                                </button>
                            </div>
                            <div class="col-xs-24" [model-validated]="pet" [property-name]="'gender'" model-validation-display></div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-sm-8 control-label">
                        Is {{pet.name | emptyValue:'your pet'}} desexed?
                    </label>
                    <div class="col-xs-24 col-sm-10">
                        <div class="row">
                            <div class="col-xs-8">
                                <button type="button" class="btn radio-btn col-xs-24"
                                        (click)="pet.isDesexed = DESEXED.Yes; checkAndUpdatePricing();"
                                        [ngClass]="{ 'active': pet.isDesexed === DESEXED.Yes }">
                                    Yes
                                </button>
                            </div>
                            <div class="col-xs-8">
                                <button type="button" class="btn radio-btn col-xs-24"
                                        (click)="pet.isDesexed = DESEXED.No; checkAndUpdatePricing();"
                                        [ngClass]="{ 'active': pet.isDesexed === DESEXED.No }">
                                    No
                                </button>
                            </div>
                            <div class="col-xs-8">
                                <button type="button" class="btn radio-btn col-xs-24"
                                        (click)="pet.isDesexed = DESEXED.Unknown; checkAndUpdatePricing();"
                                        [ngClass]="{ 'active': pet.isDesexed === DESEXED.Unknown }">
                                    Unknown
                                </button>
                            </div>
                            <div class="col-xs-24" [model-validated]="pet" [property-name]="'isDesexed'" model-validation-display></div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-sm-8 control-label">Do you have a regular Vet practice?</label>
                    <div class="col-sm-10">
                        <div class="row">
                            <div class="col-xs-12">
                                <button type="button" class="btn radio-btn col-xs-24"
                                        (click)="updateHasVet(true)"
                                        [ngClass]="{ 'active': pet.hasVet }">
                                    Yes
                                </button>
                            </div>
                            <div class="col-xs-12">
                                <button type="button" class="btn radio-btn col-xs-24"
                                        (click)="updateHasVet(false)"
                                        [ngClass]="{ 'active': !pet.hasVet }">
                                    No
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group" *ngIf="pet.hasVet">
                    <label class="col-sm-8 control-label">
                        Select your Vet practice
                        <div class="tooltip-xs">
                            <a href="javascript:void(0);" class="tooltip-link" tooltipPlacement="bottom" tabindex="999"
                                  tooltip="Please enter the first three letters in the name of the vet practice and the list will populate with options. To select a vet from the list, simply click on the vet. If the vet practice is not listed, please select 'Unknown'">
                                <span class="help-icon"></span>
                            </a>
                        </div>
                    </label>
                    <div class="col-xs-24 col-sm-10 typeahead-container">
                        <input
                                placeholder="Type your vet name"
                                class="form-control"
                                type="text"
                                autocomplete="off"
                                #petVet="ngModel"
                                [ngClass]="{ 'form-invalid': (!petVet.pristine && pet.vet.practiceCode === '') }"
                                [(ngModel)]="vetName"
                                [typeahead]="dataSource"
                                (typeaheadLoading)="changeTypeaheadLoading($event)"
                                (typeaheadNoResults)="changeTypeaheadNoResults($event)"
                                (typeaheadOnSelect)="typeaheadOnSelect($event)"
                                [typeaheadOptionsLimit]="7"
                                [typeaheadMinLength]="3"
                                [typeaheadWaitMs]="500"
                                [typeaheadOptionField]="'vet'"
                                (keyup)="(($event.key == 8 || $event.key == 46)? (resetPetVet()):'')"
                                required>
                        <div *ngIf="typeaheadLoading">
                            <i class="glyphicon glyphicon-refresh ng-hide rotating" style=""></i>
                        </div>
                        <div *ngIf="typeaheadNoResults" class="" style="">
                            <i class="glyphicon glyphicon-remove"></i> No Results Found
                        </div>
                        <div [hidden]="petVet.pristine || submitted || (pet.vet.practiceCode !== '')" class="alert alert-danger">
                            Please enter your pet's vet practice
                        </div>
                        <div [model-validated]="pet.vet" [property-name]="'practiceCode'" model-validation-display></div>
                    </div>
                    <div class="col-sm-6 hidden-xs">
                        <a href="javascript:void(0);" class="tooltip-link" tooltipPlacement="bottom" tabindex="999"
                              tooltip="Please enter the first three letters in the name of the vet practice and the list will populate with options. To select a vet from the list, simply click on the vet. If the vet practice is not listed, please select 'Unknown'">
                            <span class="help-icon"></span>
                        </a>
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-sm-8 control-label">Your chosen plan</label>
                    <div class="col-sm-10">
                        <select class="form-control pet-cover-name-select" name="petCoverName"
                                [disabled]="selectDisabled"
                                [ngModel]="pet.cover"
                                [fieldValidation]="pet.cover"
                                [fieldName]="'coverName'"
                                [fieldGuard]="submitted"
                                [validationStep]="3"
                                #petCoverName="ngModel"
                                (ngModelChange)="handleCoverChange($event)"
                                required>
                            <option [ngValue]="blankCover">
                                Please select your cover
                            </option>
                            <option *ngFor="let p of insurancesDisplayed"
                                    [ngValue]="p">
                                {{p.coverName}}
                            </option>
                        </select>
                        <div [hidden]="petCoverName.valid || petCoverName.pristine || submitted || petCoverName !== null" class="alert alert-danger">
                            Please choose your pet cover
                        </div>
                        <div [model-validated]="pet.cover" [property-name]="'coverName'" model-validation-display></div>
                    </div>
                    <div class="col-sm-4">
                        <button type="button" class="btn link-btn text-small compare-brand-link"
                                *ngIf="!loadingRoute"
                                [disabled]="selectDisabled"
                                [ngClass]="{ 'disabled': selectDisabled }"
                                (click)="selectDisabled ? '' : goToRoute('/compare')">
                            Compare plan
                        </button>
                        <div class="loading-container" *ngIf="loadingRoute">
                            <div class="loading-container-table">
                                <div>
                                    <span class="glyphicon glyphicon-refresh rotating"></span>
                                </div>
                                <div>
                                    Loading plans...
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group" *ngIf="pet.cover.hasExcess()">
                    <label class="col-sm-8 control-label">
                        Please select an excess
                    </label>
                    <div class="col-sm-10">
                        <div class="row">
                            <div class="col-xs-12" *ngFor="let excessOption of pet.cover.excessOptions">
                               
                                <button type="button" class="btn radio-btn col-xs-24 excessButton" tabindex="-1" 
                                    (click)="updateExcessSelect(excessOption)"
                                     [ngClass]="{ 'active': (pet.cover.excessSelected === excessOption) }">
                                ${{excessOption}}
                            </button>
                        </div>
                        </div>
                    </div>
                </div>
                <div class="form-group" *ngIf="pet.cover.hasRoutineCare()">
                    <label class="col-sm-8 control-label">
                        Please select if you require optional routine care
                        <div class="tooltip-xs">
                            <a href="javascript:void(0);" class="tooltip-link" tooltipPlacement="bottom" tabindex="999"
                               tooltip="As an optional extra, you can add Routine Care as part of your pet insurance policy, which can help cover the cost of vaccinations, health checks, worming treatments and more.">
                                <span class="help-icon"></span>
                            </a>
                        </div>
                    </label>
                    <div class="col-sm-10">
                        <div class="row">
                            <div class="col-xs-12">
                                <button type="button" class="btn radio-btn col-xs-24"
                                        (click)="updateRoutineCareSelect(true)"
                                        [ngClass]="{ 'active': pet.cover.routineCareSelected === true }">
                                    Yes
                                </button>
                            </div>
                            <div class="col-xs-12">
                                <button type="button" class="btn radio-btn col-xs-24"
                                        (click)="updateRoutineCareSelect(false)"
                                        [ngClass]="{ 'active': pet.cover.routineCareSelected === false }">
                                    No
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 hidden-xs">
                        <a href="javascript:void(0);" class="tooltip-link" tooltipPlacement="bottom" tabindex="999"
                           tooltip="As an optional extra, you can add Routine Care as part of your pet insurance policy, which can help cover the cost of vaccinations, health checks, worming treatments and more.">
                            <span class="help-icon"></span>
                        </a>
                    </div>
                </div>
                <div class="form-group" [ngClass]="{ 'last': !showPDDOQuestions }">
                    <label class="col-sm-8 control-label">{{pet.name | emptyValue:'Your pet'}} Insurance premium is</label>
                    <div class="col-sm-10">
                        <h2 class="pet-pricing-container">
                            {{ coverPrice | customCurrency:2:'$' }}
                            <div class="senior-discount-txt" *ngIf="pet.cover.seniorDiscount > 0 && user.eligibleForSenior">
                                <small>
                                    Includes {{pet.cover.seniorDiscount}}% Seniors Discount
                                </small>
                            </div>
                        </h2>
                    </div>
                    <div class="col-sm-5">
                        <div class="dropdown mobile-text-center price-freq-dropdown">
                            <div class="dropdown-toggle" [id]="'price-dropdown-pet-' + id" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                {{FREQUENCY[frequency]}}ly
                                <span class="glyphicon glyphicon-menu-down"></span>
                            </div>
                            <ul class="dropdown-menu price-dropdown-pet-menu" [attr.aria-labelledby]="'price-dropdown-pet-' + id">
                                <li (click)="frequency = FREQUENCY.Fortnight; changeFrequency();">
                                    Fortnightly
                                </li>
                                <li (click)="frequency = FREQUENCY.Month; changeFrequency();">
                                    Monthly
                                </li>
                                <li (click)="frequency = FREQUENCY.Year; changeFrequency();">
                                    Yearly
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>




                <div class="form-group pddo last" *ngIf="showPDDOQuestions">
                      <!--<label class="col-sm-8 control-label">{{pddoSelectedDetails.title}}</label>
                   <div class="col-sm-16">
                        <div class="checkbox no-padding-top">
                            <label class="control-label checkbox-double-size">
                                <input type="checkbox" name="petIsFirstTermsAccepted"
                                [(ngModel)]="pet.isFirstTermsAccepted"
                                #petIsFirstTermsAccepted="ngModel"
                                [fieldValidation]="pet"
                                [fieldName]="'isFirstTermsAccepted'"
                                [fieldGuard]="submitted"
                                [validationStep]="3"
                                />
                                {{pddoSelectedDetails.firstTerms}}
                            </label>
                        </div>
                        <div class="item-spacer-10 clearfix"></div>
                        <ul [innerHTML]="pddoSelectedDetails.benefits">
                        </ul>
                        <div class="item-spacer-10 clearfix"></div>
                        <p>{{pddoSelectedDetails.note}}</p>
                        <div class="checkbox">
                            <label class="control-label checkbox-double-size" >
                                <input type="checkbox" name="petIsSecondTermsAccepted"
                                [(ngModel)]="pet.isSecondTermsAccepted"
                                #petIsSecondTermsAccepted="ngModel"
                                [fieldValidation]="pet"
                                [fieldName]="'isSecondTermsAccepted'"
                                [fieldGuard]="submitted"
                                [validationStep]="3"
                                />
                                {{pddoSelectedDetails.secondTerms}}
                            </label>
                        </div>
                        <div class="row">
                            <div class="col-sm-15">
                                <div [hidden]="petIsFirstTermsAccepted.valid || petIsFirstTermsAccepted.pristine  || submitted" class="alert alert-danger">
                                    {{pddoSelectedDetails.errorMessage}}
                                </div>
                                <div [model-validated]="pet" [property-name]="'isFirstTermsAccepted'" model-validation-display></div>
                            </div>
                        </div>
                    </div> -->
                    <div class="pddo-title">
                        <p><b>{{pddoSelectedDetails.title}}</b></p>
                    </div>
                    <div class="pddo-content">

                        <ul [innerHTML]="pddoSelectedDetails.benefits">
                        </ul>   
                        <div>
                           <p><b>You are comfortable and agree that you can afford to pay:</b></p>
                       </div>                  
                          
                           <ul>
                           <li>the applicable premium, which will increase each year;</li>
                           <li>the vet invoice in full and upfront before submitting a claim for eligible vet expenses unless you use GapOnly®, in which case you will only need to pay the gap upfront;
                               <span class="tooltip-link">
                                   <span class="help-icon-excess"></span>
                                </span>
                                <span class="tool-tip">
                                   <p>The ‘gap’ means the difference between the vet’s invoice for eligible expenses and the claim benefit calculated under the policy terms and conditions, if any.</p>                               
                               </span>                          
                             
                           </li>
                           <li>any vet expenses above the accepted claim amount. This includes the per condition excess (if any) and any amount above the benefit percentage, annual benefit limit and any applicable sub limits that may apply for some conditions, treatments or benefits as outlined in the table.</li>
                       </ul>    
                                   
   
                       <div class="item-spacer-10 clearfix"></div>     
   
                       <p>
                           <b>Lastly, you acknowledge that this product does not provide cover for chronic pre-existing conditions or for general exclusions listed in the PDS</b>
                           <span class="tooltip-link">
                              <span class="help-icon-excess"></span>
                           </span>
                           <span class="tool-tip">
                           <span>Pre-Existing Conditions <br/></span><br/>
                           <span>A pre-existing condition is:<br/></span>
                           <span>A condition that existed or occurred prior to the commencement date of your first policy period or within any applicable waiting period.<br/></span>
                           <span>A condition that you or your vet were aware of, or a reasonable person in your circumstances would have been aware of.<br/></span>
                           <span>This is irrespective of whether the underlying or causative condition was diagnosed at the time.<br/></span>                       
                           <span>Whether it is a pre-existing condition will depend on its nature and experience. If your pet has a temporary condition that has not existed, occurred or shown noticeable signs, symptoms or an abnormality in the 18-month period immediately prior to your claim treatment date, it will no longer be excluded from cover as a pre-existing condition. Chronic Conditions and several other specified Conditions that are pre-existing conditions cannot fall within this category. For more information please refer to the relevant PDS.</span>
                       <br/> </span>
                           </p>    
                    </div>              
                 
                    <div class="row">
                        <div class="col-sm-offset-8 col-sm-8 text-center">
                            <button type="button" (click)="agreeDDOTerms(true)" class="btn submit-btn width-100">Yes</button>
                            <div>
                                <div class="item-spacer-10 clearfix"></div>
                                <button type="button" (click)="agreeDDOTerms(false)" class="btn submit-btn width-100" >No, let me review</button>
                            </div>
                        </div>
                        <div class="col-sm-offset-8 col-sm-8 text-center">
                            <div [hidden]="isDDOTermsAccepted" *ngIf="!isDDOTermsAccepted || isDDOTermsAccepted.pristine  || submitted">
                                {{pddoSelectedDetails.errorMessage}}
                            </div>
                            <div [model-validated]="pet" [property-name]="'isDDOTermsAccepted'" model-validation-display></div>
                        </div>        
                    </div>                    
                </div>
            </div>
        </div>
    </div>
</div>