import { Component, EventEmitter, OnInit, Input, Output  } from '@angular/core';
 
import { ProductQuestion } from '../model/productQuestion'; 
import { Loading } from '../model/loading'; 

@Component({
    selector: '[product-question-form]',
    templateUrl: 'product-question.component.html' 
})

export class ProductQuestionComponent implements OnInit {


    @Input('productQuestion') productQuestion: ProductQuestion;    
    @Input('id') id: number;
    @Input('loading') loading: Loading;
    @Input('loadingRoute') loadingRoute: boolean;
    @Output() onRoute = new EventEmitter<any>();
   

    public constructor() {
       
    }

    ngOnInit(): void {
        this.loadingRoute = false;
    }

    goToRoute(route: string) {
        this.onRoute.emit({ route: route, index: this.id });
    }

   

    

 

    

    

     
}