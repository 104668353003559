import { ModelValidator } from './model-validator';

import { State } from './state.enum';
import { AustralianPostCodes } from './australian-postcodes';

import { ToolsMethods } from '../shared/tools.methods';

export class Address extends ModelValidator {

    static getBlank(): Address {
        return new Address('', '', '', null, false);
    }

    static getCopy(address: Address): Address {
        let newAddress = Address.getBlank();
        newAddress.address = address.address;
        newAddress.suburb = address.suburb;
        newAddress.postcode = address.postcode;
        newAddress.state = address.state;
        newAddress.mismatch = address.mismatch;
        newAddress.invalidpostcode = address.invalidpostcode;
        return newAddress;
    }

    constructor(
        public address: string,
        public suburb: string,
        public postcode: string,
        public state: State,
        public mismatch: boolean = false,
        public invalidpostcode: boolean = false
    ) {
        super();
    }

    isPostCodeInRange(range: any): boolean {
        if (range.length === 2) {
            return ToolsMethods.between(+this.postcode, range[0], range[1]);
        } else {
            return ToolsMethods.between(+this.postcode, range[0], range[0]);
        }
    }

    isPostCodeInRangeList(rangeList: any): boolean {
        let test: boolean = false;
        for (let range of rangeList) {
            if (this.isPostCodeInRange(range)) {
                test = true;
                break;
            }
        }
        return test;
    }

    checkPostCodeAndStateCombination(): boolean {
        if (this.state !== null && this.postcode !== null && AustralianPostCodes.statesPostCodes[State[this.state]]) {
            if (!this.isPostCodeInRangeList(AustralianPostCodes.statesPostCodes[State[this.state]])) {
                return false;
            }
        }
        return true;
    }

    public checkValidPostCode(): boolean {
        let test: boolean = false;
        for (let state in AustralianPostCodes.statesPostCodes) {
            if (AustralianPostCodes.statesPostCodes.hasOwnProperty(state)) {
                if (this.isPostCodeInRangeList(AustralianPostCodes.statesPostCodes[state])) {
                    test = true;
                    break;
                }
            }

        }
        return test;
    }

    proceedValidation(step: number = 0): boolean {
        if (this.state !== null && this.state) {
            this.state = +this.state;
            if (this.state !== State.ACT
                && this.state !== State.NSW
                && this.state !== State.NT
                && this.state !== State.QLD
                && this.state !== State.SA
                && this.state !== State.TAS
                && this.state !== State.VIC
                && this.state !== State.WA) {
                this.setError('state', 'Please select the state in which you reside');
            }
        } else {
            this.setError('state', 'Please select the state in which you reside');
        }
        if (step !== 2 && step !== 20) {
            if (this.postcode === null || this.postcode === '') {
                this.setError('postcode', 'Please enter your postcode');
            }
            if (this.postcode !== null && this.postcode !== '' && !this.postcode.match(/^[0-9]{4}$/i)) {
                this.setError('postcode', 'Your postcode must be 4 digits and may only contain numbers');
            } else {
                if (!this.checkValidPostCode() || this.invalidpostcode) {
                    this.setError('postcode', 'Please enter a valid postcode');
                } else if (!this.checkPostCodeAndStateCombination()) {
                    this.setError('postcode', 'Postcode and State combination invalid');
                }
            }
        }
        if (step === 3) {
            if (this.mismatch) {
                this.setError('address', 'The Address does not match with the Postcode');
            }
            if (this.address === null || this.address === '') {
                this.setError('address', 'Please enter your street address');
            }
            if (this.suburb === null || this.suburb === '') {
                this.setError('suburb', 'Please select your suburb');
            }
        }
        return this.isValid();
    }
}
