import { Component } from '@angular/core';
import { style, animate, transition, trigger } from '@angular/animations';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';
import { CONFIGURATION } from './environments/environment';

import { Pet } from './model/pet';
import { User } from './model/user';
import { Cover } from './model/cover';
import { Theme } from './model/theme';
import { Loading } from './model/loading';
import { Insurance } from './model/insurance';
import { Species } from './model/species.enum';
import { InsuranceCovers } from './model/insurance-covers';

import { ThemeService } from './services/theme.service';
import { SubscriptionService } from './services/subscription.service';

import { ToolsMethods } from './shared/tools.methods';
import { ProductQuestion } from './model/productQuestion';
import { ReCAPTCHAService } from './services/recaptcha.service';

// declare let loadRecaptcha: any;
// declare let loadRecaptcha2: any;
declare let loadGmap: any;
declare let tag_fct: any;
declare let utag: any;

@Component({
    selector: 'pet-app',
    templateUrl: 'app.component.html',
    providers: [ThemeService],
    animations: [
        trigger('slideDown', [
            transition(':enter', [
                style({height: 0}),
                animate('0.7s ease-out', style({height: '*'}))
            ]),
            transition(':leave', [
                style({height: '*'}),
                animate('0.7s ease-out', style({height: 0}))
            ])
        ])
    ]
})
export class AppComponent {
    hideRecaptchaBadge = "<style> .grecaptcha-badge { display: none!important; }</style>";
    // model
    pet: Pet;
    pets: Pet[];
    user: User;
    theme: Theme;
    cover: Cover;
    insurance: Insurance;
    petCoverList: Insurance[];
    insuranceCovers: InsuranceCovers;
    productQuestions: ProductQuestion[];

    timeOutId: any;
    loading: Loading;
    haveUsCallOpen: boolean = false;
recaptchaPassed:boolean=false;
    subscriptionTheme: BehaviorSubject<Theme>;

    constructor(
        public router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private themeService: ThemeService,
        private subscriptionService: SubscriptionService,
        private recaptchaService: ReCAPTCHAService
    ) {
        this.loading = Loading.getBlank();
        this.loading.activated = true;
        this.loading.loadingText = 'Loading details';
        subscriptionService.newObservable<Loading>('loading', this.loading).subscribe(
            loading => {
                this.loading = loading;
            }
        );

        this.pets = [];
        subscriptionService.newObservable<Pet[]>('pets', this.pets).subscribe(
            pets => {
                this.pets = pets;
            }
        );

        this.pet = Pet.getBlank();
        subscriptionService.newObservable<Pet>('pet', this.pet).subscribe(
            pet => {
                this.pet = pet;
            }
        );

        this.user = User.getBlank();
        subscriptionService.newObservable<User>('user', this.user).subscribe(
            user => {
                this.user = user;
            }
        );

        this.cover = Cover.getBlank();
        subscriptionService.newObservable<Cover>('cover', this.cover).subscribe(
            cover => {
                this.cover = cover;
            }
        );

        this.insuranceCovers = InsuranceCovers.getBlank();
        subscriptionService.newObservable<InsuranceCovers>('insurance-covers', this.insuranceCovers).subscribe(
            insuranceCovers => {
                this.insuranceCovers = insuranceCovers;
            }
        );

        this.petCoverList = [];
        subscriptionService.newObservable<Insurance[]>('pet-covers', this.petCoverList).subscribe(
            petCoverList => {
                this.petCoverList = petCoverList;
            }
        );

        this.insurance = Insurance.getBlank();
        subscriptionService.newObservable<Insurance>('insurance', this.insurance).subscribe(
            insurance => {
                this.insurance = insurance;
            }
        );

        subscriptionService.newObservable<boolean>('haveUsCallOpen', this.haveUsCallOpen).subscribe(
            haveUsCallOpen => {
                this.haveUsCallOpen = haveUsCallOpen;
            }
        );
//
subscriptionService.newObservable<boolean>('recaptchaPassed', this.recaptchaPassed).subscribe(
    recaptchaPassed => {
        this.recaptchaPassed = recaptchaPassed;
    }
);
        subscriptionService.newObservable<ProductQuestion[]>('productQuestions', this.productQuestions).subscribe(
            productQuestionList => {
                this.productQuestions = productQuestionList;
            }
        );

        this.theme = Theme.getBlank();
        this.subscriptionTheme = subscriptionService.newObservable<Theme>('theme', this.theme);
        this.subscriptionTheme.subscribe(
            theme => {
                this.theme = theme;
            }
        );
        let userIdParamName = 'UserID';
        let sourceParamName = 'Source';
        let automatedParamName = 'automated'
        this.activatedRoute.queryParams.subscribe(
            (value) => {
                if (value.hasOwnProperty(userIdParamName)) {
                    this.theme.userId = value[userIdParamName];
                    this.subscriptionTheme.next(this.theme);
                }
                if (value.hasOwnProperty(sourceParamName)) {
                    this.theme.source = value[sourceParamName];
                    this.subscriptionTheme.next(this.theme);
                }
                if (value.hasOwnProperty(automatedParamName)) {
                    this.theme.automated = value[automatedParamName];
                    this.subscriptionTheme.next(this.theme);
                }
            }
        );

        setTimeout(() => this.initTag(), 100);
        this.getTheme();
        this.initGmap();
        // setTimeout(() =>  this.initRecaptcha(), 100);

        if (CONFIGURATION.environment === 'prod') {
            this.initTimeOutChecker();
        }
    }

    getTheme() {
        this.themeService
        .getTheme()
        .subscribe(
            data => {
                this.theme.setNewData(data);
                this.subscriptionTheme.next(this.theme);
                this.titleService.setTitle(data.companyName);
            },
            error => { console.error('error', error); }
        );
    }

    initGmap() {
        let key: string = CONFIGURATION.gmapApiKey;
        loadGmap(key);
    }

    // initRecaptcha() {
    //     if (this.theme.userId === 'WebUser' && this.theme.automated === 'false') {
    //     let key: string = this.recaptchaService.getSiteKey();
    //     // loadRecaptcha(key);
    //     // loadRecaptcha2(key);
    //     }
    // }

    initTag() {
        if (this.theme.userId === 'WebUser' && this.theme.automated === 'false') {
            this.executeTag();
            this.router.events.subscribe(
                (value) => {
                    if (value instanceof NavigationEnd) {
                        if (value.urlAfterRedirects.indexOf('/compare') >= 0) {
                            this.addTag('compare_quote', '/compare');
                        } else if (value.urlAfterRedirects.indexOf('/thank-you') >= 0) {
                            setTimeout(() => this.addTag('pet_sale', '/thank-you'), 2000);
                        } else if (value.urlAfterRedirects.indexOf('/buy') >= 0) {
                            this.addView('/buy');
                        } else if (value.urlAfterRedirects.indexOf('/payment') < 0) {
                            this.addView('/');
                        }
                    }
                });
        }
    }

    addTag (u1: string, pageView: string) {
        if (this.theme.userId === 'WebUser' && this.theme.automated === 'false' && typeof utag !== 'undefined') {
            let u2: string = CONFIGURATION.tracker_name;
            let ord: string = 'p' + this.user.petCriteriaID;

            let dataToSend = {
                virtual_page_name: pageView,
                cost: '',
                order_currency: '',
                order_value : '',
                u3: '',
                u1: u1,
                u2: u2,
                qty: '',
                ord: ord,
                insuranceType: this.getPetInsuranceType(),
                petBreed: this.getPetBreed()
            };
            if (CONFIGURATION.environment === 'dev') {
                console.log('TAGGING');
                console.log(JSON.stringify(dataToSend));
            }
            utag.view(dataToSend);
        }
    }

    getPetBreed(): string[] {
        let breeds: string[] = [];
        if (this.pets.length > 0) {
            for (let pet of this.pets) {
                breeds.push(pet.breed.breed);
            }
        } else {
            breeds.push(this.pet.breed.breed);
        }
        return breeds;
    }

    getPetInsuranceType(): string {
        let cat: boolean = false;
        let dog: boolean = false;
        let petSpecies: string = '';
        if (this.pets.length > 0) {
            for (let pet of this.pets) {
                if (pet.petType === Species.CANINE) {
                    dog = true;
                } else {
                    cat = true;
                }
            }
        } else {
            dog = this.pet.petType === Species.CANINE;
            cat = this.pet.petType === Species.FELINE;
        }
        if (dog && cat) {
            petSpecies = 'dog and cat';
        } else if (dog) {
            petSpecies = 'dog';
        } else if (cat) {
            petSpecies = 'cat';
        }
        return petSpecies;
    }

    addView (u1: string) {
        if (this.theme.userId === 'WebUser' && this.theme.automated === 'false' && typeof utag !== 'undefined') {
            let dataToSend = {
                virtual_page_name: u1
            };
            if (CONFIGURATION.environment === 'dev') {
                console.log('TAGGING');
                console.log(JSON.stringify(dataToSend));
            }
            utag.view(dataToSend);
        }
    }

    executeTag() {
        if (this.theme.userId === 'WebUser' && this.theme.automated === 'false') {
            let url: string = CONFIGURATION.trackUrl;
            if (CONFIGURATION.environment === 'dev') {
                console.log('INIT TAG WITH URL: ' + url);
            }
            //tag_fct(url);
        }
    }

    initTimeOutChecker() {
        this.idlTimer();
        let that: any = this;
        document.addEventListener('keyup', function() {
            that.idlTimer();
        });
    }

    idlTimer() {
        if (this.timeOutId !== null) {
            clearTimeout(this.timeOutId);
        }
        // timer 30min
        this.timeOutId = setTimeout(
            () => {
                this.resetApp();
            },
            1000 * 60 * 30
        );
    }

    resetApp() {
        this.router.navigate(['']);
        setTimeout(() => { location.reload(); }, 5000);
    }

    closeHaveCall(close: boolean) {
        this.haveUsCallOpen = close;
    }

    goTop() {
        ToolsMethods.scrollToId('app-header', 1000);
    }

    goToRoute(route: string) {
        if (!this.loading.activated) {
            if (this.linkActivated(route)) {
                this.loading.activated = true;
                this.router.navigate([route]);
            }
        }
    }

    linkActivated(route: string): boolean {
        let step = this.getStep();
        let linkStep = this.getStep(route);
        return step > linkStep;
    }

    getStep(route: string = this.router.url): number {
        switch (route) {
            case '/compare':
                return 2;
            case '/buy':
                return 3;
            default:
                return 1;
        }
    }
    getRecaptchaTerms(): string{
return this.themeService.getRecaptchaTerms();
    }

}
