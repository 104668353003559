import { Pipe, PipeTransform } from '@angular/core';
import { BrandsSorting } from '../model/brands.enum';
import { Insurance } from '../model/insurance';
import { Frequency } from '../model/frequency.enum';

@Pipe({
    name: 'sortInsurances'
})
export class SortInsurancesPipe implements PipeTransform {
    transform(array: Array<Insurance>, type: string, partnerId: number): Array<Insurance> {
        if (array != null) {
            switch (type) {
                case 'price':
                    return array.sort((a: any, b: any) => {
                        return a.getPrice(Frequency.Year) - b.getPrice(Frequency.Year);
                    });
                case '-price':
                    return array.sort((a: any, b: any) => {
                        return b.getPrice(Frequency.Year) - a.getPrice(Frequency.Year);
                    });
                case 'brand':
                    if(partnerId === 1 || partnerId === 2 || partnerId === 3)
                    {
                        return array.sort((a: any, b: any) => {
                            if (a.brandId === b.brandId) {
                                return a.getPrice(Frequency.Year) - b.getPrice(Frequency.Year);
                            }
                            return BrandsSorting.getBrandOrder(a.brandId) - BrandsSorting.getBrandOrder(b.brandId);
                        });
                    }
                    else
                    {
                        return array.sort((a: any, b: any) => {
                            if (a.brandId === b.brandId) {
                                return a.displayOrder - b.displayOrder;
                            }
                            return BrandsSorting.getBrandOrder(a.brandId) - BrandsSorting.getBrandOrder(b.brandId);
                        });
                    }
                case 'offer':                    
                    if(partnerId === 1 || partnerId === 2  || partnerId === 3)
                    {
                        return array.sort((a: any, b: any) => {
                            if (a.insurancePromotion && b.insurancePromotion) {
                                return a.getPrice(Frequency.Year) - b.getPrice(Frequency.Year);
                            }
                            if (a.insurancePromotion) {
                                return -1;
                            }
                            if (b.insurancePromotion) {
                                return 1;
                            }
                            return a.getPrice(Frequency.Year) - b.getPrice(Frequency.Year);
                        });
                    }
                    else
                    {
                        return array.sort((a: any, b: any) => {
                            if (a.insurancePromotion && b.insurancePromotion) {
                                return a.displayOrder - b.displayOrder;
                            }
                            if (a.insurancePromotion) {
                                return -1;
                            }
                            if (b.insurancePromotion) {
                                return 1;
                            }
                            return a.displayOrder - b.displayOrder;
                        });
                    }
                default:
                    if(partnerId === 1 || partnerId === 2 || partnerId === 3)
                    {
                       // console.info('PARTNERID: ' + partnerId);
                        return array.sort((a: any, b: any) => {
                            return a.getPrice(Frequency.Year) - b.getPrice(Frequency.Year);
                        });
                    }
                    else
                    {
                       // console.info('PARTNERID: ' + partnerId);
                        return array.sort((a: any, b: any) => {
                            return a.displayOrder - b.displayOrder;
                        });
                    }
            }
        }
        return array;
    }
}

@Pipe({
    name: 'filterShortList',
    pure: false
})
export class FilterShortListPipe implements PipeTransform {
    transform(array: Array<Insurance>, shortListOnly: boolean): Array<Insurance> {
        if (array != null && shortListOnly) {
            return array.filter((a: any) => {
                return a.shortListed;
            });
        }
        return array;
    }
}


@Pipe({
    name: 'filterPromotionList'
})
export class FilterPromotionListPipe implements PipeTransform {
    transform(array: Array<Insurance>, promotionOnly: boolean): Array<Insurance> {
        if (array != null && promotionOnly) {
            return array.filter((a: any) => {
                return a.originalPriceText !== null && a.originalPriceText !== '';
            });
        }
        return array;
    }
}
