
export class Promotion {

    static getBlank(): Promotion {
        return new Promotion(
            0, 0, 0, 0,
            '', '', '',
            '', 0,
            '', '', ''
        );
    }

    static getCopy(promotion: Promotion): Promotion {
        return new Promotion(
            promotion.offerId,
            promotion.offerTypeId,
            promotion.offerVariantId,
            promotion.offerCriteriaId,

            promotion.offerTitle,
            promotion.offerTypeName,
            promotion.offerDescription,

            promotion.partnerCode,
            promotion.petsurePromotionId,

            promotion.productCode,
            promotion.promoCode,
            promotion.termsAndConditions
        );
    }

    constructor(
        public offerId: number,
        public offerTypeId: number,
        public offerVariantId: number,
        public offerCriteriaId: number,

        public offerTitle: string,
        public offerTypeName: string,
        public offerDescription: string,

        public partnerCode: string,
        public petsurePromotionId: number,

        public productCode: string,
        public promoCode: string,
        public termsAndConditions: string
    ) {
    }
}
