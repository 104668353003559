<div class="top-strap-resume-container">
    <div class="container">
        <div class="row row-height sm-row-height thank-you-header">
            <div class="col-xs-24 col-md-10 col-height col-top top-strap-title-container">
                <h2>
                    Thank you
                </h2>
                Your application has been completed successfully!
            </div>
            <div class="col-xs-24 col-md-9 padding-0 col-height col-top" *ngIf="pets.length > 0">
                <div class="col-xs-24 resume-container">
                    <div class="col-xs-12 resume-container-details">
                        <div class="resume-container-logo">
                            <img src="{{insurance.brandLogo}}" alt="{{insurance.insuranceName}}" />
                        </div>
                        <div class="pet-number">
                            {{pets.length}} Pet{{ (pets.length > 1)? 's' : ''}}
                        </div>
                        <div>
                            <button type="button" class="btn action-btn" (click)="topDetailsVisible = !topDetailsVisible">
                                {{ topDetailsVisible? 'Close Details' : 'Show Details' }}
                                <span class="glyphicon" [ngClass]="{ 'glyphicon-menu-up': topDetailsVisible, 'glyphicon-menu-down': !topDetailsVisible }"></span>
                            </button>
                        </div>
                    </div>
                    <div class="col-xs-12 resume-container-price-container">
                        <div>
                            <strong>Total price:</strong>
                        </div>
                        <h3>{{ totalPrice | customCurrency:2:'$' }}</h3>
                        <span>
                            {{FREQUENCY[cover.frequency].toLowerCase()}}ly
                        </span>
                    </div>
                </div>
                <ul class="col-xs-24 resume-container-details-dropdown" [hidden]="!topDetailsVisible">
                    <li *ngFor="let pet of pets; let i = index" class="pet-resume">
                        <div class="row align-items-start">
                            <div class="col-xs-11 col-lg-9"> 
                                <h4>Pet {{i+1}}:</h4>
                            </div>
                            <div class="col-xs-13 col-lg-15">
                                 <h4><strong>{{pet.name}}</strong></h4>
                            </div>
                        </div>
                        <div class="row align-items-start">
                            <div class="col-xs-11 col-lg-9">
                                Pet name: 
                            </div>
                            <div class="col-xs-13 col-lg-15">
                                <strong>{{pet.name}}</strong>
                            </div>
                        </div>
                        <div class="row align-items-start">
                            <div class="col-xs-11 col-lg-9">
                                {{FREQUENCY[cover.frequency]}} premium: 
                            </div>
                            <div class="col-xs-13 col-lg-15">
                                <strong><span>{{ pet.cover.getPrice(cover.frequency) | customCurrency:2:'$' }}</span></strong>  
                            </div>
                        </div>
                        <div class="row align-items-start">
                            <div class="col-xs-11 col-lg-9">
                                Excess:
                            </div>
                            <div class="col-xs-13 col-lg-15">
                                <strong>{{pet.cover.excessSelected  | customCurrency:2:'$'}}</strong>
                            </div>
                        </div>
                        <div class="row align-items-start">
                            <div class="col-xs-11 col-lg-9">
                                Routine care:
                            </div>
                            <div class="col-xs-13 col-lg-15">
                                <strong>{{ (pet.cover.routineCareSelected) ? 'Yes' : 'No' }}</strong>
                            </div>
                        </div>
                        <div class="row align-items-start">
                            <div class="col-xs-11 col-lg-9">
                                Species:
                            </div>
                            <div class="col-xs-13 col-lg-15">
                                <strong>{{ (pet.petType === SPECIES.FELINE) ? 'Cat' : 'Dog' }}</strong>
                            </div>
                        </div>
                        <div class="row align-items-start">
                            <div class="col-xs-11 col-lg-9">
                                Breed:
                            </div>
                            <div class="col-xs-13 col-lg-15">
                                <strong>{{pet.breed.breed}}</strong>
                            </div>
                        </div>
                        <div class="row align-items-start">
                            <div class="col-xs-11 col-lg-9">
                                Cover:
                            </div>
                            <div class="col-xs-13 col-lg-15">
                                <strong>{{pet.cover.insuranceName}} {{pet.cover.coverName}}</strong>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="hidden-xs hidden-sm col-md-5  col-height col-top top-resume-additional-information" *ngIf="pets.length > 0">
                <div>
                    <span class="icon-large calendar-light-blue"></span>
                    <p>
                        This cover has a <strong>30 days</strong> cooling off period
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="row">
        <div class="col-xs-24">
            <h2 *ngIf="!hasBefore">
                Congratulations!
            </h2>
            <div class="item-spacer-20 clearfix"></div>
            <div *ngIf="!hasBefore">
                <h4 class="colored-title">
                    Thank you for securing your pet’s insurance policy through {{theme.companyName}}.
                </h4>
                <p>
                    Your pet will be covered by {{insurance.insuranceName}} from 1 minute to midnight on your
                    selected policy commencement date.
                </p>
            </div>
            <div *ngIf="hasBefore">
                <h4 class="colored-title">
                    Thank you for applying for your pet insurance through {{theme.companyName}}. Your {{insurance.insuranceName}}
                    application has been received.
                </h4>
                <p>
                    We have referred your application to the underwriter who will call you within two working days to
                    finalise your application. Should you want to follow up on your application please ring
                    {{insurance.brandPhone}} during business hours of 8am-8pm Monday to Friday and quote your
                    reference number{{ (insuredPets.length > 1) ? 's' : '' }}.
                </p>
            </div>
            <div class="item-spacer-10 clearfix"></div>
            <ul class="insured-pet-list col-xs-24">
                <li>
                    <strong>Your application reference number{{ (insuredPets.length > 1) ? 's' : '' }}:</strong>
                </li>
                <li *ngFor="let insuredPet of insuredPets" class="col-xs-24 clearfix">
                    <strong>{{insuredPet.petDetails}}</strong>
                    - {{insuredPet.referenceNumber}}
                </li>
            </ul>
            <div class="item-spacer-20 clearfix"></div>
        </div>
    </div>
    <hr *ngIf="!hasBefore"/>
    <div class="row" *ngIf="!hasBefore">
        <div class="col-xs-24">
            <h2>
                What happens next?
            </h2>
            <div class="col-xs-24 col-sm-12">
                <p>
                    {{insurance.insuranceName}} will send you an email shortly to confirm your policy.
                    {{insurance.insuranceName}} will also send you a welcome pack which will include the
                    Combined Product Disclosure Statement, Policy Terms and Conditions, {{insurance.insuranceName}}
                    Financial Services Guide and your Certificate of Insurance.
                    This pack will also include  any relevant information or required forms, such as a claim form,
                    cruciate ligament examination form and a printed copy of the {{theme.companyName}} Financial Services Guide.
                </p>
            </div>
            <div class="col-xs-24 col-sm-12">
                <div class="icons-list">
                    <div class="icons-list-el">
                        <div class="icons-list-icon-container">
                            <span class="icon-medium gears-circle-light-blue"></span>
                        </div>
                        <div class="icons-list-txt">
                            <h4>Your application has been processed</h4>
                        </div>
                    </div>
                    <div class="icons-list-el">
                        <div class="icons-list-icon-container">
                            <span class="icon-medium file-circle-light-blue"></span>
                        </div>
                        <div class="icons-list-txt">
                            <h4>You will receive your policy documents</h4>
                        </div>
                    </div>
                    <div class="icons-list-el">
                        <div class="icons-list-icon-container">
                            <span class="icon-medium phone-circle-light-blue"></span>
                        </div>
                        <div class="icons-list-txt">
                            <h4>If you have any questions call us {{ theme.contactPhoneNumber }}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="item-spacer-20"></div>
    <hr *ngIf="theme.showInterestedIn" />
    <div class="row" *ngIf="theme.showInterestedIn">
        <div class="col-xs-24">
            <h2>You may also be interested in:</h2>
            <div class="item-spacer-20 clearfix"></div>
            <div class="product-thumbnails-container">
                <div class="product-thumbnails separate">
                    <div class="row row-height md-row-height">
                        <div class="col-xs-24 col-sm-8 col-height product-thumbnail-container"
                             *ngFor="let recommendedProduct of recommendedProducts">
                            <div class="product-thumbnail-img-container">
                                <img class="img-responsive" src="{{recommendedProduct.imgPath}}" />
                            </div>
                            <div class="product-thumbnail-title">
                                <h4>
                                    <span class="icon-xl {{recommendedProduct.getIconClass()}}"></span>
                                    {{recommendedProduct.title}}
                                </h4>
                            </div>
                            <div class="product-thumbnail-txt">
                                <p class="product-thumbnail-main-txt">
                                    {{recommendedProduct.mainText}}
                                </p>
                                <p class="product-thumbnail-sub-txt sm">
                                    {{recommendedProduct.subText}}
                                </p>
                                <div class="product-thumbnail-btn-container">
                                    <a href="{{recommendedProduct.quoteUrl}}" class="btn submit-btn">
                                        Request a Quote
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="item-spacer-20"></div>