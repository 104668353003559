import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';

//import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http'
//import { Observable } from 'rxjs'
//import { map } from 'rxjs/operators'
import { CONFIGURATION } from '../environments/environment';
    
export interface ReCAPTCHAResponse {
  success: boolean; 
  score: number,
  error_codes?: Array<string>;
}


    

@Injectable()
export class ReCAPTCHAService extends BaseService {
    constructor(private http: HttpClient) {
        super();
    }
   
//   public verifyUserResponse(userResponseToken: string): Observable<ReCAPTCHAResponse> {
//     return this.http.post<ReCAPTCHAResponse>('/auth/captcha', {'g-recaptcha-response': userResponseToken}, httpOptions).
//       pipe(map( (resp: HttpResponse) => { ... resp.body }))
//   } 
public checkExists(): void {
    const url = this.getServiceHost() + '/api/v1/recaptcha/verify';
  //  console.log('VERIFY URL STEM: ' + url);
}

public getSiteKey(): string {
    var serviceKey=CONFIGURATION.SITE_KEY;    
    return serviceKey;
}

public getServiceHost(): string {
    var serviceHost=CONFIGURATION.RECAPTCHA_HOST;    
    return serviceHost;
}
  
public apiVerifyRecaptcha  (token: string) : Promise<ReCAPTCHAResponse> {
	const url = this.getServiceHost() + '/api/v1/recaptcha/verify';
    const params = {
        token,
        siteKey: this.getSiteKey(),
        action: 'compare'
    }
    const response = this.http.post<ReCAPTCHAResponse>(url, JSON.stringify(params), {headers: this.headers})
    .toPromise()
    .then((response: ReCAPTCHAResponse) => {
        return response;
    })
    .catch(()=>{
        return {
			success: true,
			score: 1
		}
    });
   return response;
    // //  pipe(map( (resp: HttpResponse) => { ... resp.body }));
	// if(response.ok){
	// 	const data =  response.json();
	// 	return data;
	// } else {
	// 	//allow login to continue when there are outages with recaptcha.
	// 	return {
	// 		success: true,
	// 		score: 1
	// 	}
	// }
}


}