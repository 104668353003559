import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot, NavigationExtras } from '@angular/router';

import { ToolsMethods } from '../shared/tools.methods';


@Injectable()
export class PaymentGatewayResolve implements Resolve<boolean> {

    constructor(
        private router: Router
    ) {}

    redirectError(): boolean {
        this.router.navigate(['']);
        return false;
    }

    resolve(route: ActivatedRouteSnapshot): boolean {        
        try {
            let serviceReturnedUrlParam: string = 'serviceReturnURL';
            let ccNumberParam: string = 'no_credit_card';

            if (route.queryParams.hasOwnProperty(serviceReturnedUrlParam)
                && route.queryParams.hasOwnProperty(ccNumberParam)) {

                let serviceReturnedUrl: string = route.queryParams[serviceReturnedUrlParam];
                let ccNumber: string = route.queryParams[ccNumberParam];

                if (serviceReturnedUrl === null
                    && serviceReturnedUrl === ''
                    && ccNumber === null
                    && ccNumber === '') {
                    this.redirectError();
                }

                let queryParams: any = {
                    'TokenID': ToolsMethods.getQueryParamValueInUrl(serviceReturnedUrl, 'TokenID'),
                    'PetCriteriaID': ToolsMethods.getQueryParamValueInUrl(serviceReturnedUrl, 'PetCriteriaID'),
                    'action': 'Registered',
                    'cd_crn': 'PS4715860-89874',
                    'accountType': 'CC',
                    'nm_account_alias': '541354...413',
                    'cd_prerego': 'N026179255',
                    'nm_card_holder': 'John',
                    'dt_expiry': '1219',
                    'cd_summary': 0,
                    'cd_response': '00',
                    'tx_response': 'Account+Registered'
                };

                if (ccNumber !== '5413541354135413') {
                    queryParams.action = 'failed';
                }

                let navigationExtras: NavigationExtras = {
                    queryParams: queryParams
                };
                if (serviceReturnedUrl.indexOf('thank-you-s') >= 0) {
                    this.router.navigate(['thank-you-s'], navigationExtras);
                    return true;
                } else {
                    this.router.navigate(['thank-you'], navigationExtras);
                    return true;
                }
            }

        } catch (e) {
            console.error(e);
        }
        return this.redirectError();
    }

}
