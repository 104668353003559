import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { RecommendedProduct } from '../model/recommended-product';
import { Frequency } from '../model/frequency.enum';
import { InsuredPet } from '../model/insured-pet';
import { Species } from '../model/species.enum';
import { Insurance } from '../model/insurance';
import { Loading } from '../model/loading';
import { Theme } from '../model/theme';
import { Cover } from '../model/cover';
import { User } from '../model/user';
import { Pet } from '../model/pet';

import { SubscriptionService } from '../services/subscription.service';

@Component({
    selector: 'thank-you',
    templateUrl: 'thank-you.component.html'
})
export class ThankYouComponent implements OnInit {

    FREQUENCY = Frequency;
    SPECIES = Species;

    // model
    pets: Pet[] = [];
    user: User;
    cover: Cover;
    insurance: Insurance;

    // subscriptions
    subscriptionPets: BehaviorSubject<Pet[]>;
    subscriptionUser: BehaviorSubject<User>;
    subscriptionCover: BehaviorSubject<Cover>;
    subscriptionTheme: BehaviorSubject<Theme>;
    subscriptionLoading: BehaviorSubject<Loading>;
    subscriptionInsurance: BehaviorSubject<Insurance>;

    theme: Theme;
    loading: Loading;
    insuredPets: InsuredPet[] = [];
    hasBefore: boolean = false;
    recommendedProducts: RecommendedProduct[] = [];


    constructor(
        private route: ActivatedRoute,
        private subscriptionService: SubscriptionService,
    ) {
        this.loading = Loading.getBlank();
        this.loading.activated = true;
        this.subscriptionLoading = subscriptionService.getSubscriptions<Loading>('loading');
        this.subscriptionLoading.subscribe(
            loading => {
                this.loading = loading;
            });
        this.subscriptionTheme = subscriptionService.getSubscriptions<Theme>('theme');
        this.subscriptionTheme.subscribe(
            theme => {
                this.theme = theme;
        });
        this.subscriptionPets = subscriptionService.getSubscriptions<Pet[]>('pets');
        this.subscriptionPets.subscribe(
            pets => {
                this.pets = pets;
            }
        );

        this.subscriptionInsurance = subscriptionService.getSubscriptions<Insurance>('insurance');
        this.subscriptionInsurance.subscribe(
            insurance => {
                this.insurance = insurance;
            }
        );

        this.subscriptionUser = subscriptionService.getSubscriptions<User>('user');
        this.subscriptionUser.subscribe(
            user => {
                this.user = user;
            }
        );

        this.subscriptionCover = subscriptionService.getSubscriptions<Cover>('cover');
        this.subscriptionCover.subscribe(
            cover => {
                this.cover = cover;
            }
        );
    }

    ngOnInit(): void {
        this.route.data.forEach((data: { insuredPets: any[] }) => {
            // this.insuredPets = data.insuredPets; //somehow the api returns different property name
            this.insuredPets = data.insuredPets.map(pet => new InsuredPet(pet.Reference, pet.PetName));
        });
        this.route.data.forEach((data: { hasBefore: boolean }) => {
            this.hasBefore = data.hasBefore;
        });

        this.recommendedProducts = [
            new RecommendedProduct(
                1,
                'Life Insurance',
                'Help secure your family\'s financial future when you are no longer around.',
                'Compare, choose and apply for Life Insurance today!',
                'https://quote.choosi.com.au/?insurancetype=1',
                'assets/img/cross-sell/life.jpg'
            ),
            new RecommendedProduct(
                2,
                'Funeral Insurance',
                'Protect your family from your funeral costs or other end-of-life expenses.',
                'Compare, choose and apply for Funeral Insurance today!',
                'https://quote.choosi.com.au/?insurancetype=2',
                'assets/img/cross-sell/funeral.jpg'
            ),
            new RecommendedProduct(
                3,
                'Income Protection',
                'Bills don\'t stop coming if you are unable to work for a period of time.',
                'Compare, choose and apply for Income Insurance today!',
                'https://quote.choosi.com.au/?insurancetype=4',
                'assets/img/cross-sell/income.jpg'
            )
        ];
    }

    get totalPrice(): number {
        let price: number = 0;
        for (let pet of this.pets) {
            price += pet.cover.getPrice(this.cover.frequency);
        }
        return price;
    }
}
