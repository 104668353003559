import { ModelValidator } from './model-validator';
import { Address } from './address';
import { Gender } from './gender.enum';
import { Promotion } from './promotion';
import { ToolsMethods } from '../shared/tools.methods';

export class User extends ModelValidator {

    static getBlank(id: number = 0): User {
        return new User(
            id, '', null, '', Gender.Male, '', '',  // Mehedi
            null, null, null, '', '',
            Address.getBlank());
    }

    static getCopy(user: User): User {
        let newUser = User.getBlank();
        newUser.id = user.id;
        newUser.encryptedClientId = 'TestMehedi',       // Mehedi
        newUser.token = user.token;
        newUser.title = user.title;
        newUser.gender = user.gender;
        newUser.firstName = user.firstName;
        newUser.lastName = user.lastName;
        newUser.bornDay = user.bornDay;
        newUser.bornMonth = user.bornMonth;
        newUser.bornYear = user.bornYear;
        newUser.contactNumber = user.contactNumber;
        newUser.email = user.email;
        if (user.address) {
            newUser.address = Address.getCopy(user.address);
        }
        if (user.promotion) {
            newUser.promotion = Promotion.getCopy(user.promotion);
        }
        newUser.eligibleForSenior = user.eligibleForSenior;
        newUser.petCriteriaID = user.petCriteriaID;
        newUser.excessSelected = user.excessSelected;
        return newUser;
    }

    constructor(
        public id: number,
        public encryptedClientId: string,   // Mehedi
        public token: string,
        public title: string,
        public gender: Gender,
        public firstName: string,
        public lastName: string,
        public bornDay: number,
        public bornMonth: number,
        public bornYear: number,
        public contactNumber: string,
        public email: string,
        public address: Address,
        public promotion: Promotion = Promotion.getBlank(),
        public eligibleForSenior: boolean = false,
        public petCriteriaID: string = '',
        public excessSelected: number = 0
    ) {
        super();
    }

    getDoB(): Date {
        return new Date(this.bornYear, this.bornMonth - 1, this.bornDay);
    }

    isOver18(): boolean {
        let minDate =  new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate());
        return minDate >= this.getDoB();
    }

    isOver50(): boolean {
        let minDate =  new Date(new Date().getFullYear() - 50, new Date().getMonth(), new Date().getDate());
        return minDate >= this.getDoB();
    }

    isDateValid(): boolean {
        let d = this.getDoB();
        return (
               d.getFullYear() === Number(this.bornYear)
            && d.getMonth() === (this.bornMonth - 1)
            && d.getDate() === Number(this.bornDay)
        );
    }

    getFormattedPhoneNumber(): string {
        return ToolsMethods.cleanPhoneNumber(this.contactNumber);
    }

    proceedValidation(step: number = 0): boolean {

        if (this.firstName === null || this.firstName.trim().length < 1) {
            this.setError('firstName', 'Please enter your first name');
        }

        if (this.firstName !== null && this.firstName.length > 0 && this.firstName.length < 2) {
            this.setError('firstName', 'Your first name must be at least 2 characters');
        }

        if (this.firstName !== null && this.firstName.length > 30) {
            this.setError('firstName', 'Your first name may not be more than 30 characters');
        }

        if (this.firstName !== null && this.firstName.length > 0 && !this.firstName.match(/^[a-z'\-]+$/i)) {
            this.setError('firstName', 'Your first name may only contain letters');
        }

        if (this.lastName === null || this.lastName.trim().length < 1) {
            this.setError('lastName', 'Please enter your last name');
        }

        if (this.lastName !== null && this.lastName.length > 0 && this.lastName.length < 2) {
            this.setError('lastName', 'Your last name must be at least 2 characters');
        }

        if (this.lastName !== null && this.lastName.length > 30) {
            this.setError('lastName', 'Your last name may not be more than 30 characters');
        }

        if (this.lastName !== null && this.lastName.length > 0 && !this.lastName.match(/^[a-z'\-]+$/i)) {
            this.setError('lastName', 'Your last name may only contain letters');
        }

        if (step !== 20) {
            if (this.title === null || this.title.length === 0) {
                this.setError('title', 'Please select your title');
            }
            if (!this.bornDay) {
                this.setError('bornDay', 'Please enter your day of birth');
            }

            if (this.bornDay && this.bornDay < 1 || this.bornDay > 31) {
                this.setError('bornDay', 'Day of birth must be between 1-31');
            }

            if (!this.bornMonth) {
                this.setError('bornMonth', 'Please enter your month of birth');
            }

            if (this.bornMonth && this.bornMonth < 1 || this.bornMonth > 12) {
                this.setError('bornMonth', 'Month of birth must be between 1-12');
            }

            if (!this.bornYear) {
                this.setError('bornYear', 'Please enter your year of birth');
            }

            let nowDate: Date = new Date();
            if (this.bornYear && this.bornYear < (nowDate.getFullYear() - 112) || this.bornYear >= nowDate.getFullYear()) {
                this.setError(
                    'bornYear',
                    'Year of birth must be between ' + (nowDate.getFullYear() - 112) + '-' + (nowDate.getFullYear() - 18)
                );
            }

            if (this.isPropertyValid('bornDay')
                && this.isPropertyValid('bornMonth')
                && this.isPropertyValid('bornYear')
                && !this.isOver18()) {
                this.setError('bornDate', 'The policy holder must be over 18 years old');
            }

            if (this.isPropertyValid('bornDay')
                && this.isPropertyValid('bornMonth')
                && this.isPropertyValid('bornYear')
                && !this.isDateValid()) {
                this.setError('bornDate', 'The date is invalid');
            }
        }

        if (step !== 2) {
            if (!ToolsMethods.validatePhoneNumber(this.contactNumber)) {
                this.setError('contactNumber', 'Please enter a valid 10-digit Australian phone number including area code');
            }

            if (this.contactNumber !== null
                && ToolsMethods.cleanPhoneNumber(this.contactNumber).length === 10
                && ToolsMethods.cleanPhoneNumber(this.contactNumber).match(/[^0-9]+/i)
            ) {
                this.setError('contactNumber', 'Your phone number may only contain numbers');
            }
        }

        if (step !== 2 && step !== 20) {
            if (this.email === null || this.email.length < 5) {
                this.setError('email', 'Please enter a valid email address in the format name@address.com');
            }

            if (this.email !== null && this.email.length > 4
                && this.email !== null && !this.email.match(/^[^@\s]+@[^\.\s]+(\.[^\.\s]+)+/i)) {
                this.setError('email', 'Please enter a valid email address in the format name@address.com');
            }
        }

        // validation for final details
        if (step === 3 || step === 2) {
            if (this.gender === null) {
                this.setError('gender', 'Please select your gender');
            }

            if (this.gender !== null && this.gender !== Gender.Male && this.gender !== Gender.Female) {
                this.setError('gender', 'Please select male or female');
            }

            if (this.gender !== null && this.title !== null) {
                if (this.gender === Gender.Male) {
                    if (this.title !== 'Mr' && this.title !== 'Dr') {
                        this.setError('title', 'Please re-select your title');
                    }
                } else if (this.gender === Gender.Female) {
                    if (this.title !== 'Ms'
                        && this.title !== 'Mrs'
                        && this.title !== 'Miss'
                        && this.title !== 'Dr'
                    ) {
                        this.setError('title', 'Please re-select your title');
                    }
                }
            }
        }

        this.address.validate(step);
        return this.address.isValid() && this.isValid();
    }

    isValid() {
        return this.address.isValid() && super.isValid();
    }

}
