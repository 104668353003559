
export enum BrandsEnum {
    Real   = 1,
    Asia = 2,
    Guardian = 7,
    RSPCA = 9,
    Prime = 10,
    GuideDogs = 13
}

export class BrandsSorting {
    public static getBrandOrder(brand: BrandsEnum) {
        // order: Real, ASIA, GDPI, Guardian, RSPCA, Prime
        switch (brand) {
            case BrandsEnum.Real:
                return 1;
            case BrandsEnum.Asia:
                return 2;
            case BrandsEnum.GuideDogs:
                return 3;
            case BrandsEnum.Guardian:
                return 4;
            case BrandsEnum.RSPCA:
                return 5;
            case BrandsEnum.Prime:
                return 6;
            default:
                return 20;
        }
    }
}
