//User story - 31124 - Choosi PDDO Pet STP
import { getDebugNode, Injectable }    from '@angular/core';
import { BaseService } from './base.service';

import 'rxjs/add/operator/toPromise';

import { Insurance } from '../model/insurance';
import { PDDOSelectedDetails } from '../model/pddo-selected-details';

@Injectable()
export class PDDOService extends BaseService {

    getPDDOSelectedDetails(insurance: Insurance, petName: string): Promise<PDDOSelectedDetails> {
        return Promise.resolve(
            new PDDOSelectedDetails(
                `You have selected ${insurance.insuranceName} ${insurance.coverName} ${ insurance.routineCareSelected ? `+ Routine Care` : ``}` +' and acknowledge the level of protection and following benefits are right for you and your pet:', //title
              
                //`You agree and acknowledge the following benefits of ${insurance.coverName} ${ insurance.routineCareSelected ? `and Routine Care` : ``} are right for you and ${petName}:`, //firstTerms
                this.getBenefitsHtml(insurance), //benefits
               
               // `Please note, that you will have to cover the proportion of costs not covered by your policy (or policies) for the treatment of your pet(s).`, //notes
                //`You agree and acknowledge that chronic pre-existing conditions associated with your pet(s) are not covered.`, //secondTerms
                
                //`You must agree with the above statements to continue.` //errorMessage`
            )
        );
    }

    private getBenefitsHtml(insurance: Insurance): string {
        let htmlContent: string =  `
        <li>
            the benefit percentage of ${insurance.getBenefitPercentage()} and overall annual limit of ${insurance.getBenefitLimit()}.
        </li>`;
        

        // switch (insurance.coverName.toLowerCase().replace(/\s\s+/g, ' ')) {
        //     case 'ultimate plus accident & illness cover': //RSPCA
        //         //Dynamic bullet point appears if customer selects Routine Care
        //         if (insurance.routineCareSelected)
        //         {
        //             htmlContent += `
        //             <li>
        //                 Routine Care’s limited reimbursement for specified preventive treatments and ownership costs.
        //             </li>
        //             `;
        //         }
        //         break;
        //     case 'platinum accident & illness cover': //Guardian 
        //     case 'premium accident & illness cover': //Real
        //     case 'sovereign accident & illness cover': //Prime 
        //     case 'top accident & illness cover': //Seniors 
        //     case 'premium care': //Guide Dogs 
        //     case 'ultimate accident & illness cover': //RSPCA
        //         htmlContent += `
        //         <li>
        //             The sub-limits for conditions like tick paralysis and cruciate ligament conditions that apply.
        //         </li>
        //         `;

        //         //Dynamic bullet point appears if customer selects Routine Care
        //         if (insurance.routineCareSelected)
        //         {
        //             htmlContent += `
        //             <li>
        //                 Routine Care’s limited reimbursement for specified preventive treatments and ownership costs.
        //             </li>
        //             `;
        //         }
        //         //this is to ONLY appear for Prime Sovereign Accident & Illness Cover
        //         if (insurance.coverName.toLowerCase() === 'sovereign accident & illness cover')
        //         {
        //             htmlContent += `
        //             <li>
        //                 The limited reimbursement for specified dental treatment.
        //             </li>
        //             `;
        //         }
        //         break;
        //     case 'gold accident & illness cover': //Guardian 
        //     case 'standard accident & illness cover': //Real 
        //     case 'imperial accident & illness cover': //Prime 
        //     case 'essential accident & illness cover': //Seniors 
        //     case 'comprehensive care': //Guide Dogs 
        //     case 'basic care': //Guide Dogs 
        //     case 'economy accident & illness cover': //RSPCA
        //         htmlContent += `
        //         <li>
        //             The sub-limits for conditions like tick paralysis and cruciate ligament conditions that apply.
        //         </li>
        //         `;
        //         break;
        //     case 'bronze accident & illness cover': //Guardian 
        //     case 'classic accident & illness cover': //Real 
        //     case 'regal accident & illness cover': //Prime 
        //     case 'basic accident & illness cover': //Seniors 
        //     case 'general accident & illness cover': //RSPCA
        //         htmlContent += `
        //         <li>
        //             Annual condition limit of ${insurance.getAnnualLimit()}.
        //         </li>
        //         `;
        //         break;
        //     default:
        //         break;
        // }

       // htmlContent += `
       // <li>
           // The level of protection that this cover provides.
        //</li>
        //`;
        return htmlContent;
    }
}
