import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { ClientService } from './client.service';

import 'rxjs/add/operator/toPromise';

import { Frequency } from '../model/frequency.enum';
import { InsuredPet } from '../model/insured-pet';
import { Species } from '../model/species.enum';
import { Insurance } from '../model/insurance';
import { Gender } from '../model/gender.enum';
import { Cover } from '../model/cover';
import { Breed } from '../model/breed';
import { User } from '../model/user';
import { Pet } from '../model/pet';
import { Vet } from '../model/vet';
import Application from '../model/application';

@Injectable()
export class ApplicationService extends BaseService {

    private static mapApplicationRequest(
        quoteId: number,
        clientId: number,
        customerReferenceNumber: string,
        customerPreRegistrationCode: string,
        cardHolderName: string,
        accountAlias: string,
        action: string,
        expiryDate: string,
        errorContext: string
    ) {
        return {
            'QuoteId': quoteId,
            'ClientId':  clientId,
            'CustomerReferenceNumber': customerReferenceNumber,
            'CustomerPreRegistrationCode': customerPreRegistrationCode,
            'CardHolderName': cardHolderName,
            'AccountAlias': accountAlias,
            'Action': action,
            'ExpiryDate': expiryDate,
            'ErrorContext': errorContext
        };
    }

    private static mapGetApplicationResponse(data: any): Application {
        let extractedQuote: any = ApplicationService.extractQuote(data.Quote);
        let extractedUser: User = ClientService.mapGetClientResponse(data.Client);
        extractedUser.eligibleForSenior = extractedQuote.isSeniorDiscount;
        extractedUser.promotion.promoCode = extractedQuote.promoCode;
        return {
            pets: extractedQuote.pets,
            cover: extractedQuote.cover,
            user: extractedUser
        };
    }

    private static extractQuote(data: any): {pets: Pet[], cover: Cover, isSeniorDiscount: boolean, clientId: number, promoCode: string} {
        let pets: Pet[] = [];

        let cover: Cover = Cover.getBlank();

        cover.token = data.Token;
        cover.quoteId = data.PetQuoteId;

        switch (data.PaymentFrequency) {
            case 'FORTNIGHTLY':
            case 'F':
                cover.frequency = Frequency.Fortnight;
                break;
            case 'MONTHLY':
            case 'M':
                cover.frequency = Frequency.Month;
                break;
            case 'YEARLY':
            case 'Y':
                cover.frequency = Frequency.Year;
                break;
            default:
                cover.frequency = Frequency.Fortnight;
                break;
        }

        if (data.DateCreated !== null) {
            cover.createdDate = data.DateCreated;
        }
        if (data.CoverStartDate !== null) {
            cover.coverStartDate = new Date(data.CoverStartDate);
        }
        if (data.PremiumStartDate !== null) {
            cover.premiumStartDate = new Date(data.PremiumStartDate);
        }

        let productCode: string = '';
        if (data.PetQuoteHeader) {
            productCode = data.PetQuoteHeader.ProductCode;
            cover.eDeliveryMethod = data.PetQuoteHeader.Efulfillment;
        }

        data.QuoteDetails.forEach((quoteDetails, index) => {

            let petDoB: Date = new Date(quoteDetails.DateOfBirth);

            let hasFreeMonthOffer: boolean = data.PromoCode === "1CFREEMONTH" && 
                (data.PartnerCode == "RS456" || data.PartnerCode == "GU456");

            let premiumPrice = quoteDetails.PetPremium;
            let routinePriceFortnight = premiumPrice.FortnightlyAmountWithRoutineCare;
            let routinePriceMonth = premiumPrice.MonthlyAmountWithRoutineCare;
            let routinePriceYear = premiumPrice.AnnualAmountWithRoutineCare;

            let originalPriceFortnight = premiumPrice.FortnightlyAmountDiscount
                                            + premiumPrice.FortnightlyAmount;
            let originalPriceMonth = premiumPrice.MonthlyAmountDiscount
                                            + premiumPrice.MonthlyAmount;
            let originalPriceYear = premiumPrice.AnnualAmountDiscount
                                            + premiumPrice.AnnualAmount;

            let originalRoutinePriceFortnight = routinePriceFortnight
                                            + premiumPrice.FortnightlyAmountWithRoutineCareDiscount;
            let originalRoutinePriceMonth = routinePriceMonth
                                            + premiumPrice.MonthlyAmountWithRoutineCareDiscount;
            let originalRoutinePriceYear = routinePriceYear
                                            + premiumPrice.AnnualAmountWithRoutineCareDiscount;


            let insurance: Insurance = Insurance.getBlank();
            insurance.id = index;
            insurance.quoteDetailsId = quoteDetails.Id;
            insurance.productBenefitId = quoteDetails.ProductBenefitId;
            insurance.productCode = productCode;
            insurance.petSureProductCode = quoteDetails.PetsureProductCode;
            insurance.petSurePartnerCode = data.PartnerCode;
            insurance.coverName = premiumPrice.ProductName;
            insurance.routineCareAvailable = quoteDetails.HasRoutineCare;

            insurance.accidentBenefitCode = premiumPrice.AccidentBenefitCode;
            insurance.displayOrder = premiumPrice.DisplayOrder;
            insurance.minimumPetAgeForAccidentOnly = premiumPrice.MinimumPetAgeForAccidentOnly;
            insurance.coolingOffDays = premiumPrice.CoolingOffDays;

            insurance.brandId = 0;
            insurance.brandCode = '';
            insurance.insuranceName = '';
            insurance.brandLogo = '';
            insurance.brandLogoLarge = '';
            insurance.aboutBrand = '';
            insurance.brandPhone = '';

            insurance.priceFortnight = hasFreeMonthOffer ? originalPriceFortnight : premiumPrice.FortnightlyAmount;
            insurance.priceMonth = hasFreeMonthOffer ? originalPriceMonth : premiumPrice.MonthlyAmount;
            insurance.priceYear = premiumPrice.AnnualAmount;

            insurance.originalPriceFortnight = originalPriceFortnight;
            insurance.originalPriceMonth = originalPriceMonth;
            insurance.originalPriceYear = originalPriceYear;
            insurance.originalPriceText = '';

            insurance.routineCarePriceFortnight = hasFreeMonthOffer ? originalRoutinePriceFortnight : routinePriceFortnight;
            insurance.routineCarePriceMonth = hasFreeMonthOffer ? originalRoutinePriceMonth : routinePriceMonth;
            insurance.routineCarePriceYear = routinePriceYear;

            insurance.originalRoutineCarePriceFortnight = originalRoutinePriceFortnight;
            insurance.originalRoutineCarePriceMonth = originalRoutinePriceMonth;
            insurance.originalRoutineCarePriceYear = originalRoutinePriceYear;

            insurance.routineCareSelected = quoteDetails.HasRoutineCare;

            insurance.seniorDiscountAmount = premiumPrice.AnnualAmountSeniorsDiscount;
            insurance.seniorDiscountAmountRoutineCare = premiumPrice.AnnualAmountSeniorsWithRoutineCareDiscount;

            insurance.multiPetDiscountCount = premiumPrice.MultiPetDiscountCount;
            insurance.multiPetDiscount = premiumPrice.AnnualAmountMultipetDiscount;
            insurance.multiPetDiscountRoutineCare = premiumPrice.AnnualAmountMultipetWithRoutineCareDiscount;

            insurance.createdDate = quoteDetails.DateCreated;
            if (quoteDetails.ExcessOption) {
                insurance.excessSelected = quoteDetails.ExcessOption;
            }

            let gender: Gender = null;
            if (quoteDetails.Gender !== null) {
                if (typeof quoteDetails.Gender === 'number') {
                    gender = quoteDetails.Gender;
                } else {
                    gender = ((quoteDetails.Gender === 'female') ? Gender.Female : Gender.Male);
                }
            }
            let vet: Vet = new Vet(quoteDetails.VetPracticeCode, quoteDetails.VetPractice);

            let species: Species = Species.CANINE;
            if (quoteDetails.Species && (quoteDetails.Species === '1' || quoteDetails.Species === Species.FELINE)) {
                species = Species.FELINE;
            }

            pets.push(
                new Pet(
                    index,
                    quoteDetails.Name,
                    null,
                    species,
                    new Breed(
                        quoteDetails.BreedCode,
                        '',
                        false
                    ),
                    gender,
                    quoteDetails.Desexed,
                    petDoB.getMonth() + 1,
                    petDoB.getFullYear(),
                    quoteDetails.PetAgeConfirmed,
                    insurance,
                    vet,
                    false,
                    (quoteDetails.VetPractice !== null && quoteDetails.VetPractice !== ''),
                    (quoteDetails.IsGuideDog !== null ? quoteDetails.IsGuideDog : false),
                    (quoteDetails.GuideDogRegisteredNumber !== null ?
                            quoteDetails.GuideDogRegisteredNumber : '')
                )
            );
        });
        return {
            pets: pets,
            cover: cover,
            isSeniorDiscount: data.IsEligibleForSeniorsDiscount,
            clientId: data.ClientId,
            promoCode: data.PromoCode
        };
    }

    constructor(private http: HttpClient) {
        super();
    }

    submitApplication(
        quoteId: number,
        clientId: number,
        customerReferenceNumber: string,
        customerPreRegistrationCode: string,
        cardHolderName: string,
        accountAlias: string,
        action: string,
        expiryDate: string,
        errorContext: string,
        userId: string
    ): Promise<InsuredPet[]> {
        let url = this.baseUrl + 'api/Petsure/SaveApplication/' + userId;
        let clientRequestObject = ApplicationService
            .mapApplicationRequest(
                quoteId,
                clientId,
                customerReferenceNumber,
                customerPreRegistrationCode,
                cardHolderName,
                accountAlias,
                action,
                expiryDate,
                errorContext
            );

        return this.http.post<InsuredPet[]>(url, JSON.stringify(clientRequestObject), {headers: this.headers})
            .toPromise()
            .then((response: InsuredPet[]) => {
                return response
            })
            .catch(this.handleError);
    }

    getApplication(token: string): Promise<Application> {
        let url = this.baseUrl + 'api/Petsure/GetApplication?token=' + token;
        return this.http.get(url, {headers: this.headers})
            .toPromise()
            .then((response) => {
                return ApplicationService.mapGetApplicationResponse(response);
            })
            .catch(this.handleError);
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }

    saveToken(accoutType:string,
        no_account:string,
        no_bsb:string,
        nm_card_holder : string,
        nm_account_alias : string,
        cd_prerego : string,
        action : string,
        cd_crn : string,
        dt_expiry : string,
        nm_account : string,
        tx_response : string):Promise<boolean>
    {
        let url = this.baseUrl + 'api/Petsure/SaveToken';
        let token: any = {
            "cd_community" : "PETSURE",
            "nm_card_holder" : nm_card_holder,
            "nm_account_alias" : nm_account_alias,
            "cd_prerego" : cd_prerego,
            "action" : action,
            "cd_crn" : cd_crn,
            "dt_expiry" : dt_expiry,            
            "tx_response" : tx_response,
            "CreatedBy" : "Petsure Request"
            };

        if(accoutType.toLowerCase() == "dd")
        {
            token = {
            "cd_community" : "PETSURE",
            "nm_account_alias" : nm_account_alias,
            "no_account": no_account,
            "no_bsb": no_bsb,
            "cd_prerego" : cd_prerego,
            "action" : action,
            "cd_crn" : cd_crn,            
            "nm_account" : nm_account,            
            "tx_response" : tx_response,
            "dt_expiry" : "0000",
            "CreatedBy" : "Petsure Request"
            };
        }
        
        return this.http.post(url, JSON.stringify(token), {headers: this.headers})
        .toPromise()
        .then((response) => {
            return response.toString() != "";
        })
        .catch(this.handleError);
    }
}
