import { Injectable } from '@angular/core';
import { Router, Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { Pet } from '../model/pet';
import { User } from '../model/user';
import { Theme } from '../model/theme';
import { Breed } from '../model/breed';
import { Cover } from '../model/cover';
import { Loading } from '../model/loading';
import { Insurance } from '../model/insurance';

import { PremiumsHelper } from '../helpers/premiums.helper';

import { SubscriptionService } from '../services/subscription.service';
import { ApplicationService } from '../services/application.service';
import { PremiumService } from '../services/premium.service';
import { ClientService } from '../services/client.service';
import { BreedsService } from '../services/breeds.service';
import { QuoteService } from '../services/quote.service';
import { ProductQuestions } from '../model/productQuestions';
import { InsuranceCovers } from '../model/insurance-covers';

@Injectable()
export class AboutYouResolve implements Resolve<boolean> {

    subscriptionLoading: BehaviorSubject<Loading>;
    loading: Loading;

    constructor(
        private subscriptionService: SubscriptionService,
        private applicationService: ApplicationService,
        private premiumService: PremiumService,
        private clientService: ClientService,
        private breedsService: BreedsService,
        private quoteService: QuoteService,
        private router: Router
    ) {
        this.subscriptionLoading = subscriptionService.getSubscriptions<Loading>('loading');
        this.subscriptionLoading.subscribe(
            loading => {
                this.loading = loading;
            });
    }

    resolve(route: ActivatedRouteSnapshot): Promise<boolean> {
        try {
            this.initTheme(route);
            let clientParam = 'ClientToken';
            let errorParam = 'error';
            let tokenParam = 'TokenID';

            if (route.queryParams.hasOwnProperty(errorParam)) {
                let errorCode: number = Number(route.queryParams[errorParam]);
                this.handleErrorCode(errorCode);
            }

            if (route.queryParams.hasOwnProperty(tokenParam)) {
                return this.updateCover(route.queryParams[tokenParam])
                    .then(() => { return true; });
            } else if (route.queryParams.hasOwnProperty(clientParam)) {
                this.loading.activated = true;
                this.loading.loadingText = 'Retrieving user data';
                this.subscriptionLoading.next(this.loading);
                return this
                    .updateClient(route.queryParams[clientParam])
                    .then(() => { return true; });
            } else {
                this.loading.activated = false;
                this.subscriptionLoading.next(this.loading);
                return Promise.resolve(false);
            }
        } catch (e) {
            console.error(e.toString());
        }
        return Promise.resolve(false);
    }

    private initTheme(route: ActivatedRouteSnapshot) {
        let theme: Theme = this.subscriptionService.getSubscriptions<Theme>('theme').getValue();

        let userIdParamName = 'UserID';
        if (route.queryParams.hasOwnProperty(userIdParamName)) {
            theme.userId = route.queryParams[userIdParamName];
        }

        let sourceParamName = 'Source';
        if (route.queryParams.hasOwnProperty(sourceParamName)) {
            theme.source = route.queryParams[sourceParamName];
        }
    }

    private updateClient(clientToken: string): Promise<boolean> {
        return this.clientService
            .getClient(clientToken)
            .then(
                user => {
                    this.subscriptionService.getSubscriptions<User>('user').next(user);
                    return true;
                },
                error => {
                    return this.handleError('Error while retrieving the client', error);
                }
            );
    }

    private updateCover(quoteToken: string): Promise<boolean> {
        this.loading.loadingText = 'Retrieving quote data';
        this.subscriptionLoading.next(this.loading);
        return this.applicationService
            .getApplication(quoteToken)
            .then(
                (result) => {
                    return this.handleUpdateCoverResponse(result);
                },
                (error) => {
                    return this.handleError('Error while retrieving the quote. This link has expired.', error);
                }
            );
    }

    private handleUpdateCoverResponse(result: any): Promise<boolean> {
        this.subscriptionService.getSubscriptions<Pet[]>('pets').next(result.pets);
        let insurance: Insurance = Insurance.getBlank();
        if (result.pets.length > 0) {
            insurance = Insurance.getCopy(result.pets[0].cover);
            insurance.createdDate = '';
            this.subscriptionService.getSubscriptions<Pet>('pet').next(result.pets[0]);
        }
        this.subscriptionService.getSubscriptions<Insurance>('insurance').next(insurance);
        this.subscriptionService.getSubscriptions<Cover>('cover').next(result.cover);
        this.subscriptionService.getSubscriptions<User>('user').next(result.user);
        return this.findProducts(result.pets, insurance, result.user);
    }


    private findProducts(pets: Pet[], ins: Insurance, user: User): Promise<boolean> {
        return this.quoteService
            .findProduct(ins)
            .then(
                (insurance) => {
                    ins.brandId = insurance.brandId;
                    ins.brandCode = insurance.brandCode;
                    ins.insuranceName = insurance.insuranceName;
                    ins.aboutBrand = insurance.aboutBrand;
                    ins.brandLogo = insurance.brandLogo;
                    ins.brandPhone = insurance.brandPhone;
                    for (let pet of pets) {
                        this.findBreed(pet);
                        pet.cover.brandId = insurance.brandId;
                        pet.cover.brandCode = insurance.brandCode;
                        pet.cover.fields = insurance.fields;
                        pet.cover.insuranceName = insurance.insuranceName;
                        pet.cover.aboutBrand = insurance.aboutBrand;
                        pet.cover.brandLogo = insurance.brandLogo;
                        pet.cover.brandPhone = insurance.brandPhone;
                        pet.cover.coolingOffDays = insurance.coolingOffDays;
                    }
                    this.subscriptionService.getSubscriptions<Pet[]>('pets').next(pets);
                    this.subscriptionService.getSubscriptions<Pet>('pet').next(pets[0]);
                    return this.findPremiums(user, pets);
                },
                (error) => {
                    return this.handleError('Error while retrieving the brand', error);
                }
            );
    }


    private findBreed(pet: Pet) {
        this.breedsService
            .getBreeds(pet.petType)
            .subscribe(
                breeds => {
                    let breedsFiltered: Breed[] = breeds.filter(
                        breed => {
                            return breed.breedCode === pet.breed.breedCode;
                        }
                    );
                    pet.breed.breed = 'UNKNOWN';
                    if (breedsFiltered.length > 0) {
                        pet.breed.breed = breedsFiltered[0].breed;
                    }
                },
                error => {
                    pet.breed.breed = 'UNKNOWN';
                    this.handleError('Error while retrieving the breed', error);
                }
            );
    }

    private findPremiums(user: User, pets: Pet[]): Promise<boolean> {
        if (pets.length > 0) {
            let theme: Theme = this.subscriptionService.getSubscriptions<Theme>('theme').getValue();
           
        return this.premiumService.getAllBenefitsAndPremiums(1, theme.id, user, pets[0]).then( (insuranceCover) => {
                
            return this.loadExtraPremiums(user, pets, theme, insuranceCover);
                    
        },
        (error) => {
            return this.handleError('Error while retrieving the premiums', error);
        });

        
        } else {
            return Promise.resolve(false);
        }
    }

    private loadExtraPremiums(user: User, pets: Pet[], theme: Theme, insuranceCover: InsuranceCovers): Promise<boolean> {
        let products: string[] = PremiumsHelper.getProductCodes(2, user, pets[0]);
        let partners: string[] = PremiumsHelper.getPartnerCodes(2, user, pets[0]);

        return this.premiumService.getAllPremiums(products, partners, theme.id, user, pets[0], insuranceCover.coverFields).then(
            (secondStageInsurances) => {
                insuranceCover.getInsurances().forEach(
                    (insurance)=>{
                        secondStageInsurances.insurances.push(insurance);
                });

                return this.loadRoutineCarePremiums(user, pets, theme, insuranceCover, secondStageInsurances);
            });

    }

    private loadRoutineCarePremiums(user: User, pets: Pet[], theme: Theme, insuranceCover: InsuranceCovers, secondStageInsurances: InsuranceCovers): Promise<boolean>{
        let products = PremiumsHelper.getProductCodes(3, user, pets[0]);
        let partners = PremiumsHelper.getPartnerCodes(3, user, pets[0]);
        return this.premiumService.getAllPremiums(products, partners, theme.id, user, pets[0], insuranceCover.coverFields).then(
            (rcInsurances) => {
                rcInsurances.getInsurances().forEach(
                    (insurance) => {
                       this.updateRoutineCarePricesForProduct(secondStageInsurances, insurance);
                    
                });
                return this.updatePetInsurance(secondStageInsurances, pets)
        });
    }

    private updatePetInsurance(insuranceCover: InsuranceCovers, pets: Pet[]){
        let valid: boolean = insuranceCover !== null;
        let brandInsuranceList: Insurance[] = [];
        for (let insurance of insuranceCover.getInsurances()) {
            if (pets[0].cover.brandId === insurance.brandId) {
                brandInsuranceList.push(insurance);
            }
            for (let pet of pets) {
                if (pet.cover.productBenefitId === insurance.productBenefitId) {
                    let quoteDetailsId: number = pet.cover.quoteDetailsId;
                    let routineCareSelected: boolean = pet.cover.routineCareSelected;
                    let excessSelected: number = pet.cover.excessSelected;
                    let createdDate: string = pet.cover.createdDate;
                    pet.cover = Insurance.getCopy(insurance);
                    pet.cover.quoteDetailsId = quoteDetailsId;
                    pet.cover.createdDate = createdDate;
                    pet.cover.setRoutineCare(routineCareSelected);
                    pet.cover.setExcess(excessSelected);
                }
            }
        }
        if (valid) {
            let currentInsurance: Insurance;
            this.subscriptionService.getSubscriptions<Insurance>('insurance').subscribe(insurance => {
                currentInsurance = insurance;
            });
            currentInsurance = brandInsuranceList.find(x => x.productBenefitId == currentInsurance.productBenefitId && x.petSurePartnerCode == currentInsurance.petSurePartnerCode);
            this.subscriptionService.getSubscriptions<Insurance>('insurance').next(currentInsurance);
            this.subscriptionService.getSubscriptions<Insurance[]>('pet-covers').next(brandInsuranceList);
            this.subscriptionService.getSubscriptions<Pet[]>('pets').next(pets);
            this.subscriptionService.getSubscriptions<ProductQuestions>('productQuestions').next(ProductQuestions.getFromPets(pets, insuranceCover));
            this.loading.activated = false;
            this.subscriptionLoading.next(this.loading);
            this.router.navigate(['buy']);
            return false;
        } else {
            this.subscriptionService.getSubscriptions<Insurance[]>('pets').next([]);
            this.loading.activated = false;
            this.subscriptionLoading.next(this.loading);
            return true;
        }
    }

    private updateRoutineCarePricesForProduct(insuranceCovers: InsuranceCovers, insurance: Insurance) {
        // update the routine care price for all the insurances
        for (let i = 0; i < insuranceCovers.getInsurances().length; i++) {
            if (insuranceCovers.insurances[i].productBenefitId !== insurance.productBenefitId) {
                continue;
            }
            insuranceCovers.insurances[i]
                    .mergeInsurancePricings(insurance.cloneInsurancePricings());
            insuranceCovers.insurances[i]
                    .applyCurrentInsurancePricing();
        }
    }

    private disableLoading(error: string = '') {
        this.loading.activated = false;
        // to clear the errors
        this.loading.validate();
        if (error !== '') {
            this.loading.setError('searchingPremiumError', error);
        }
        this.subscriptionLoading.next(this.loading);
    }

    private handleErrorCode(code: number) {
        let message: string = '';
        switch (code) {
            case 1:
                message = 'An error occurred while processing the payment, please try again';
                break;
            case 2:
                message = 'An error has occurred, please try again';
                break;
            default:
                message = 'An error has occurred, please try again';
                break;
        }
        this.loading.setError('paymentProcessError', message);
    }

    private handleError(message: string, error: any): boolean {
        this.disableLoading(message);
        console.error(message);
        console.error(error);
        return true;
    }

}
