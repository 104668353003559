export abstract class ModelValidator {

    constructor(
        public errors: Map<string, string> = new Map<string, string>()
    ) {}

    protected abstract proceedValidation(step: number): boolean;

    getErrorNb(): number {
        return this.errors.size;
    }

    getError(key: string): string {
        if (this.errors.has(key)) {
            return this.errors.get(key);
        }
        return '';
    }

    getErrors(keys: string[]): string[] {
        let errors = [];
        for (let key of keys) {
            if (this.errors.has(key)) {
                errors.push(this.errors.get(key));
            }
        }
        return errors;
    }

    isValid(): boolean {
        return this.getErrorNb() === 0;
    }

    isPropertyValid(key: string) {
        return !this.errors.has(key);
    }

    setError(key: string, value: string): this {
        this.errors.set(key, value);
        return this;
    }

    clearErrors(): void {
        return this.errors.clear();
    }


    validate(step: number = 0): boolean {
        this.clearErrors();
        return this.proceedValidation(step) && this.isValid();
    }

}
