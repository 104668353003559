import { Injectable }    from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';

import 'rxjs/add/operator/toPromise';
import { ToolsMethods } from '../shared/tools.methods';

import { User } from '../model/user';
import { State } from '../model/state.enum';

@Injectable()
export class DiscountService extends BaseService {

    constructor(private http: HttpClient) {
        super();
    }

    isCustomerEligibleForSeniorsDiscount(user: User): Promise<boolean> {
        let state: string = State[user.address.state];
        let dob: string = ToolsMethods.getDateAsString(user.getDoB());

        let url = this.baseUrl + 'api/Petsure/IsCustomerEligibleForSeniorsDiscount/' + state + '/' + dob;
        return this.http.get<boolean>(url, {headers: this.headers})
            .toPromise()
            .then((response: boolean) => {
                return response;
            })
            .catch(this.handleError);
    }


    getDiscount(numberOfPet: number, productCode: string): Promise<number> {
        let url = this.baseUrl + 'api/Petsure/GetDiscount/' + numberOfPet + '/' + productCode;
        return this.http.get<number>(url, {headers: this.headers})
            .toPromise()
            .then((response: number) => {
                return response;
            })
            .catch(this.handleError);
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }
}
