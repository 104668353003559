
declare var $: any;

export class ToolsMethods {

    public static running: boolean = false;

    public static between(val: number, x: number, y: number, include: boolean = true): boolean {
        if (include) {
            return val >= x && val <= y;
        } else {
            return val > x && val < y ;
        }
    }

    public static getElWidth(selector: string): number {
        return $(selector).width();
    }

    public static scrollIsBottom(selector: string): boolean {
        return ($(selector).scrollTop() + $(selector).innerHeight() >= $(selector)[0].scrollHeight);
    }

    public static findPos(obj): number {
        let curtop = 0;
        if (obj.offsetParent) {
            do {
                curtop += obj.offsetTop;
                obj = obj.offsetParent;
            } while (obj);
            return curtop;
        }
    }

    public static scrollToId(id: string, animate: number = 0, marginTop: number = 0) {
        if (ToolsMethods.running) {
            return;
        }
        if (animate !== 0) {
            ToolsMethods.running = true;
            $('html, body').animate(
                {
                    scrollTop: ToolsMethods.findPos(document.getElementById(id)) + marginTop
                },
                animate,
                function() {
                    ToolsMethods.running = false;
                }
            );
        } else {
            window.scroll(
                0,
                (ToolsMethods.findPos(document.getElementById(id)) + marginTop)
            );
        }
    }

    public static getScrollBarWidth(): number {
        let outer = document.createElement('div');
        outer.style.visibility = 'hidden';
        outer.style.width = '100px';
        document.body.appendChild(outer);

        let widthNoScroll = outer.offsetWidth;
        // force scrollbars
        outer.style.overflow = 'scroll';

        // add innerdiv
        let inner = document.createElement('div');
        inner.style.width = '100%';
        outer.appendChild(inner);

        let widthWithScroll = inner.offsetWidth;

        // remove divs
        outer.parentNode.removeChild(outer);

        return widthNoScroll - widthWithScroll;
    }

    /**
     * Return the base url base on the currentRoute, add or remove trailing slash depending on the trailingSlash value
     *
     * @param currentRoute
     * @param trailingSlash if true it add a trailing slash, otherwise remove the trailing slash
     * @returns {string}
     */
    public static getBaseAbsoluteUrl(currentRoute: string, trailingSlash: boolean = true): string {
        let result = window.location.href;
        if (currentRoute !== '/') {
            let absoluteUrl = window.location.href;
            let lastIndex = absoluteUrl.lastIndexOf(currentRoute);
            if (lastIndex !== -1) {
                absoluteUrl = absoluteUrl.substring(0, lastIndex);
            }
            result = absoluteUrl;
        }
        if (trailingSlash && result.charAt(result.length - 1) !== '/') {
            result += '/';
        } else if (!trailingSlash && result.charAt(result.length - 1) === '/') {
            result = result.substring(0, result.length - 2);
        }
        return result;
    }

    public static simulateClickOn(selector: string) {
        $(selector).click();
    }

    /**
     * Get query parameter value in url
     *
     * @param url The url as a string
     * @param name The query parameter name as string
     * @returns {any} null if the url is empty or if there is not the query parameter in it. Or the query parameter value as string.
     */
    public static getQueryParamValueInUrl(url: string, name: string): string {
        if (!url || !name) {
            return null;
        }
        name = name.replace(/[\[\]]/g, '\\$&');
        let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) {
            return null;
        }
        if (!results[2]) {
            return '';
        }
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    /**
     * To get the date as string with the format yyyy-mm-dd
     *
     * @param date
     * @returns {string}
     */
    public static getDateAsString(date: Date) {
        return date.getFullYear()
            + '-' + (((date.getMonth() + 1) < 10) ? '0' : '') + (date.getMonth() + 1)
            + '-' + ((date.getDate() < 10) ? '0' : '') + date.getDate();
    }

    /**
     * Format the phone number to match the australian standards
     *
     * @param phoneNumber
     * @returns {string}
     */
    public static formatPhoneNumber(phoneNumber: string): string {
        if (phoneNumber === null || phoneNumber.length < 2) {
            return phoneNumber;
        }
        let cleanPhoneNumber = ToolsMethods.cleanPhoneNumber(phoneNumber);
        let formattedPhoneNumber = '';
        if (new RegExp('^0[023578]').test(cleanPhoneNumber)) {
            formattedPhoneNumber = '(' + cleanPhoneNumber.slice(0, 2) + ') ';
            if (cleanPhoneNumber.length > 5) {
                formattedPhoneNumber += cleanPhoneNumber.slice(2, 6) + ' ' + cleanPhoneNumber.slice(6);
            } else {
                formattedPhoneNumber += cleanPhoneNumber.slice(2);
            }
        } else if (cleanPhoneNumber.startsWith('04') && cleanPhoneNumber.length > 3) {
            if (cleanPhoneNumber.length > 6) {
                formattedPhoneNumber = cleanPhoneNumber.slice(0, 4)
                    + ' ' + cleanPhoneNumber.slice(4, 7)
                    + ' ' + cleanPhoneNumber.slice(7);
            } else {
                formattedPhoneNumber = cleanPhoneNumber.slice(0, 4) + ' ' + cleanPhoneNumber.slice(4);
            }
        } else {
            return phoneNumber;
        }
        return formattedPhoneNumber;
    }

    /**
     * Clean the phone number to contain only numbers (remove space and brackets)
     *
     * @param phoneNumber
     * @returns {string}
     */
    public static cleanPhoneNumber(phoneNumber: string): string {
        return phoneNumber.replace(/^\+61/, '0').replace(/[^0-9]/g, '');
    }

    /**
     * Get the maximum phone length
     *
     * @param phoneNumber
     * @returns {number}
     */
    public static maxPhoneLength(phoneNumber: string): number {
        if (phoneNumber !== null && ToolsMethods.cleanPhoneNumber(phoneNumber).startsWith('04')) {
            return 12;
        }
        return 14;
    }

    /**
     * Validate australian phone number
     *
     * @param phoneNumber
     * @returns {boolean}
     */
    public static validatePhoneNumber(phoneNumber: string): boolean {
        if (phoneNumber === null) {
            return false;
        }
        let cleanPhoneNumber = ToolsMethods.cleanPhoneNumber(phoneNumber);
        return (cleanPhoneNumber.length === 10
            && /(0[02-578])(?!([\d])\2{7})([0-9]{8})/.test(cleanPhoneNumber));
    }

    /**
     * Get caret position for the given Input type="text" element
     *
     * @param id The input id
     * @returns {number} The caret position, or -1 if the input does not exist
     */
    public static getCaretPosition(id: string): number {
        let input = <HTMLInputElement>document.getElementById(id);
        let selectionStartString: string = 'selectionStart';
        if (input && selectionStartString in input) {
            return input.selectionStart;
        }
        return -1;
    }

    /**
     * Set caret position for the given Input type="text" element
     *
     * @param id The input id
     * @param pos The position to move the cursor
     */
    public static setCaretPosition(id: string, pos: number) {
        let ctrl = <HTMLInputElement>document.getElementById(id);
        if (ctrl.setSelectionRange) {
            ctrl.focus();
            ctrl.setSelectionRange(pos, pos);
        }
    }

    public static smartJoinTexts(texts: string[]): string {
        if (!texts || texts.length <= 0) {
            return '';
        }
        if (texts.length === 1) {
            return texts[0];
        }
        if (texts.length === 2) {
            return texts.join(' and ');
        }
        return texts.slice(0, texts.length - 1).join(', ')
            + ' and '
            + texts[texts.length - 1];
    }

    //Capture digital Lead Source
    public static getCookie(cookies: any) {          
        var result = {};
        try {
           for(var cookie of cookies.split(";"))
           {
                var splitCookie = cookie.split('=');
                result[splitCookie[0].replace(/ /g, "")] = splitCookie[1].replace(/ /g, "");
           }  
        }
        catch (exception) {
            // Ignore any Exception
        }
        return result;
    }
}
