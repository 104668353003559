import { ModelValidator } from './model-validator';
import { Frequency } from './frequency.enum';

export class Cover extends ModelValidator {

    static getBlank(): Cover {
        return new Cover(
            null, '', Frequency.Fortnight, '',
            new Date(), new Date(), false, false, false
        );
    }

    static getMinCoverStartDate(): Date {
        let now = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
        return new Date(now.getTime());
    }

    static getMaxCoverStartDate(): Date {
        let now = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
        let then = new Date(now.getTime());
        then.setDate(then.getDate() + 14);

        return then;
    }

    static getInvalidCoverStartDates(): Array<Date> {
        let dates: Array<Date> = [];
        let i: Date = Cover.getMinCoverStartDate();

        while (i.getTime() <= Cover.getMaxCoverStartDate().getTime()) {
            if (i.getDay() === 0 || i.getDay() === 6) {
                dates.push(new Date(i.getTime()));
            }
            i.setDate(i.getDate() + 1);
        }

        return dates;
    }

    static getMinPremiumStartDate(coverStartDate: Date): Date {
        let then = new Date(coverStartDate.getTime());
        then.setDate(then.getDate() + 2);

        return then;
    }

    static getMaxPremiumStartDate(coverStartDate: Date): Date {
        let then = new Date(coverStartDate.getTime());
        then.setDate(then.getDate() + 13);

        return then;
    }

    constructor(
        public quoteId: number,
        public token: string,
        public frequency: Frequency,
        public createdDate: string,
        public coverStartDate: Date,
        public premiumStartDate: Date,
        public preExistingConditionsDeclaration: boolean,
        public hadPriorPolicyCancelled: boolean,
        public eDeliveryMethod: boolean
    ) {
        super();
    }

    proceedValidation(step: number = 0): boolean {
        if (step === 2 || step === 3) {
            if (this.frequency === null) {
                this.setError('frequency', 'Please select a payment frequency');
            }

            if (this.coverStartDate.getTime() < Cover.getMinCoverStartDate().getTime()) {
                this.setError('coverStartDate', 'Cover start date must be in the next 15 days');
            }

            if (this.coverStartDate.getTime() > Cover.getMaxCoverStartDate().getTime()) {
                this.setError('coverStartDate', 'Cover must start in the next 15 days');
            }

            if (this.premiumStartDate.getTime() < Cover.getMinPremiumStartDate(this.coverStartDate).getTime()) {
                this.setError('premiumStartDate', 'Premium start date should be a least 2 days after cover start date');
            }

           // if (this.coverStartDate.getDay() === 0 || this.coverStartDate.getDay() === 6) {
           //     this.setError('coverStartDate', 'Cover start date mast be a week day');
           // }

            if (this.premiumStartDate.getTime() > Cover.getMaxPremiumStartDate(this.coverStartDate).getTime()) {
                this.setError('premiumStartDate', 'Premium start date must be within 13 days of cover start date');
            }

            if (this.premiumStartDate.getDay() === 0 || this.premiumStartDate.getDay() === 6) {
                this.setError('premiumStartDate', 'Premium start date mast be a week day');
            }
        }

        if ( step === 3 || step === 4) {
            if (this.preExistingConditionsDeclaration === null || !this.preExistingConditionsDeclaration) {
                this.setError('preExistingConditionsDeclaration', 'Please indicate you understand and accept the statement');
            }

            if (this.hadPriorPolicyCancelled === null) {
                this.setError('hadPriorPolicyCancelled', 'Please indicate if you have ever had an insurance policy cancelled');
            }
        }
        return this.isValid();
    }
}
