import { Component, Input } from '@angular/core';
import { ModelValidator } from '../model/model-validator';

@Component({
        selector: '[model-validation-display]',
        template: `
            <div class="alert alert-danger" 
                [hidden]="model.isPropertyValid(modelPropertyName)">
              {{model.getError(modelPropertyName)}}
            </div>
        `
})
export class ModelValidationDisplayComponent {
    @Input('model-validated') model: ModelValidator;
    @Input('property-name') modelPropertyName: string;
}
