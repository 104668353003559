import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'payment-gateway',
    templateUrl: 'payment-gateway.component.html'    
})
export class PaymentGatewayComponent {

    constructor(
        private route: ActivatedRoute
    ) {}

}
