import { ModelValidator } from './model-validator';

export class ProductQuestion extends ModelValidator {
     
   static getBlank(): ProductQuestion {
        return new ProductQuestion(
            '', [], '', '','',false,false
        );
    }
    
    static getCopy(productQuestion: ProductQuestion): ProductQuestion {
        let newProductQuestion = ProductQuestion.getBlank();
        newProductQuestion.cover = productQuestion.cover;
        newProductQuestion.petNames = productQuestion.petNames;
        newProductQuestion.petNamesString = productQuestion.petNamesString;
        newProductQuestion.productQuestionsDeclaration = productQuestion.productQuestionsDeclaration;
        newProductQuestion.benefitLimit = productQuestion.benefitLimit;
        newProductQuestion.benefitPercentage = productQuestion.benefitPercentage;
        newProductQuestion.declarationSubmit = productQuestion.declarationSubmit;
        return newProductQuestion;
    }

    constructor(
        public cover: string,
        public petNames: string[] = [] ,
        public petNamesString: string,
        public benefitLimit: string,
        public benefitPercentage: string,
        public productQuestionsDeclaration: boolean = false,
        public declarationSubmit: boolean = false
    ) {
        super();
    }
 
 
    proceedValidation(step: number = 0): boolean {
         
 
        if (   step === 4) {
            if (this.productQuestionsDeclaration === null || !this.productQuestionsDeclaration) {
                this.setError('productQuestionsDeclaration', 'Please indicate you understand and accept the statement');
            }

            
        }
        return this.isValid();
    }
};