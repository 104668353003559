import { Injectable }    from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';

import 'rxjs/add/operator/toPromise';
import { ToolsMethods } from '../shared/tools.methods';
import { PremiumsHelper } from '../helpers/premiums.helper';

import { Pet } from '../model/pet';
import { User } from '../model/user';
import { Cover } from '../model/cover';
import { Theme } from '../model/theme';
import { State } from '../model/state.enum';
import { Insurance } from '../model/insurance';
import { PremiumService } from './premium.service';
import { Frequency } from '../model/frequency.enum';
import { PetResponse } from '../model/petresponse';

@Injectable()
export class QuoteService extends BaseService {

    private static mapSaveQuoteRequest(user: User, pets: Pet[], insurance: Insurance, cover: Cover, newQuote: boolean, cookies: any) {
        let quoteDetails = [];
        for (let pet of pets) {
            quoteDetails.push(
                {
                    'EncryptedClientId': user.encryptedClientId,
                    'AnnualPremiumAmount': pet.cover.priceYear,
                    'BreedCode': pet.breed.breedCode,
                    'Breed': pet.breed.breed,
                    'DateCreated': insurance.createdDate,
                    'PetsureProductCode': PremiumsHelper.getProductCode(
                                            pet.cover.petSureProductCode,
                                            pet.cover.routineCareSelected),
                    'DateOfBirth': ToolsMethods.getDateAsString(pet.getDoB()),
                    'DiscountAmount': pet.cover.getDiscountAmount(cover.frequency),
                    'DiscountPercent': pet.cover.seniorDiscount,
                    'Gender': pet.gender,
                    'HasRoutineCare': pet.cover.hasRoutineCare() ? pet.cover.routineCareSelected : false,
                    'Id': pet.cover.quoteDetailsId,
                    'IsEligibleForSeniorsDiscount': user.eligibleForSenior,
                    'IsValid': true,
                    'Desexed': pet.isDesexed,
                    'Name': pet.name,
                    'PaymentFrequency': Frequency[cover.frequency].toUpperCase().substring(0, 1),
                    'PetAgeConfirmed': pet.isOver8WeeksOldDeclaration,
                    'PetPremium': {
                        'AnnualAmount': pet.cover.priceYear,
                        'AnnualAmountDiscount': pet.cover.getDiscountAmount(Frequency.Year, false),
                        'AnnualAmountWithRoutineCare': pet.cover.routineCarePriceYear,
                        'AnnualAmountWithRoutineCareDiscount': pet.cover.getDiscountAmount(Frequency.Year, true),
                        'ProductBenefitId': pet.cover.productBenefitId
                    },
                    'PremiumAmount': pet.cover.getPrice(cover.frequency),
                    'ProductBenefitId': pet.cover.productBenefitId,
                    'QuoteId': (newQuote ? '' : cover.quoteId),
                    'Species': pet.petType,
                    'State': State[user.address.state],
                    'VetPractice': pet.vet.vet,
                    'VetPracticeCode': pet.vet.practiceCode,
                    'ExcessOption': pet.cover.excessSelected,
                    'IsGuideDog': pet.isGuideDog,
                    'GuideDogRegisteredNumber': pet.microchipNumber
                }
            );
        }
        return {
            'AddressBarcode': null,
            'AddressDpid': null,
            'ClientId': user.id,
            'EncryptedClientId': user.encryptedClientId,   // Mehedi
            'ClientToken': user.token,
            'PromoCode': user.promotion.promoCode,
            'PromotionId': user.promotion.petsurePromotionId,
            'PetsurePromotionId': user.promotion.petsurePromotionId,
            'PetsureProductCode': PremiumsHelper.getProductCode(
                                        insurance.petSureProductCode,
                                        insurance.routineCareSelected),
            'PartnerCode': insurance.petSurePartnerCode,
            'CoverStartDate': ToolsMethods.getDateAsString(cover.coverStartDate),
            'DateCreated': cover.createdDate,
            'Id': null, // todo ??
            'IsEligibleForSeniorsDiscount': user.eligibleForSenior,
            'PaymentFrequency': Frequency[cover.frequency].toUpperCase().substring(0, 1),
            'PremiumStartDate': ToolsMethods.getDateAsString(cover.premiumStartDate),
            'ProductCode': insurance.productCode,
            'QuoteDetails': quoteDetails,
            'ReferToUnderwriting': cover.hadPriorPolicyCancelled,
            'State': State[user.address.state],
            'Token': (newQuote ? '' : cover.token),
            'PetQuoteHeader' : {
                'Efulfillment': cover.eDeliveryMethod
            },
            'Cookies' : cookies
        };
    }

    private static mapSaveQuoteResponse(data: any): {quoteDetails: PetResponse[], quoteId: number, token: string, createdDate: string } {
        let quoteDetails: PetResponse[] = [];
        if (data.Pets && data.Pets.length > 0) {
            //Store an array of all pets to return
            data.Pets.forEach(element => {
                var detail : PetResponse = 
                {
                    PetName: element.PetName,
                    QuoteDetailId: element.PetQuoteDetailId,
                    PetDateOfBirth: new Date(Date.parse(element.PetDateOfBirth)),
                    BreedCode: element.BreedCode
                };
                quoteDetails.push(detail);
            });
        }
        return {
            quoteDetails: quoteDetails,
            quoteId: data.QuoteId,
            token: data.Token,
            createdDate: data.CreatedDate
        };
    }

    private static mapFindProductResponse(data: any): Insurance {
        let insurance = Insurance.getBlank();
        insurance.brandId = data.BrandId;
        insurance.brandCode = data.Code;
        insurance.insuranceName = data.Name;
        insurance.aboutBrand = data.Description;
        insurance.brandPhone = data.PhoneNumber;
        insurance.brandLogo = PremiumService.getLogoPath(data.BrandId);
        insurance.brandLogoLarge = PremiumService.getLargeLogoPath(data.BrandId);
        return insurance;
    }

    private static mapSaveQuoteDetailsRequest(user: User, pet: Pet, cover: Cover) {
        return {
            'EncryptedClientId': user.encryptedClientId,    // Mehedi
            //Azure 54853: Call the getPrice method using the yearly frequency to calculate the correct annual amount
            'AnnualPremiumAmount': pet.cover.getPrice(Frequency.Year), 
            'BreedCode': pet.breed.breedCode,
            'Breed': pet.breed.breed,
            'DateCreated': pet.cover.createdDate,
            'DateOfBirth': ToolsMethods.getDateAsString(pet.getDoB()),
            'DiscountAmount': pet.cover.getDiscountAmount(cover.frequency),
            'DiscountPercent': pet.cover.multiPetDiscount,
            'Gender': pet.gender,
            'HasRoutineCare': pet.cover.hasRoutineCare() ? pet.cover.routineCareSelected : false,
            'Id': pet.cover.quoteDetailsId,
            'IsEligibleForSeniorsDiscount': user.eligibleForSenior,
            'IsValid': pet.isValid(),
            'Name': pet.name,
            'PaymentFrequency': Frequency[cover.frequency].toUpperCase().substring(0, 1),
            'PetAgeConfirmed': pet.isOver8WeeksOldDeclaration,
            'PetPremium': {
                'AnnualAmount': pet.cover.priceYear,
                'AnnualAmountDiscount': pet.cover.getDiscountAmount(Frequency.Year, false),
                'AnnualAmountWithRoutineCare': pet.cover.routineCarePriceYear,
                'AnnualAmountWithRoutineCareDiscount': pet.cover.getDiscountAmount(Frequency.Year, true),
                'ProductBenefitId': pet.cover.productBenefitId
            },
            'PremiumAmount': pet.cover.getPrice(cover.frequency),
            'ProductBenefitId': pet.cover.productBenefitId,
            'QuoteId': cover.quoteId,
            'QuoteToken': cover.token,
            'Species': pet.petType,
            'State': State[user.address.state],
            'VetPractice': pet.vet.vet,
            'VetPracticeCode': pet.vet.practiceCode,
            'PetsureProductCode': PremiumsHelper.getProductCode(
                                        pet.cover.petSureProductCode,
                                        pet.cover.routineCareSelected),
            'PartnerCode': pet.cover.petSurePartnerCode,
            'PetsurePromotionId': user.promotion.petsurePromotionId,
            'ExcessOption': pet.cover.excessSelected,
            'IsGuideDog': pet.isGuideDog,
            'GuideDogRegisteredNumber': pet.microchipNumber
        };
    }

    private static mapSaveQuoteDetailsResponse(data: any, pet: Pet) {
        pet.cover.quoteDetailsId = data.PetQuoteDetailID ? data.PetQuoteDetailID : data.PetQuoteDetailId;
        pet.cover.createdDate = data.DateCreated;
    }

    constructor(private http: HttpClient) {
        super();
    }

    saveQuote(
        user: User,
        insurance: Insurance,
        pets: Pet[],
        cover: Cover,
        theme: Theme,
        cookies: any,
        newQuote: boolean = false
    ): Promise<{ quoteDetails: PetResponse[], quoteId: number, token: string, createdDate: string }> {
        let url = this.baseUrl + 'api/Petsure/SaveQuote/' + theme.userId;

        let clientRequestObject = QuoteService.mapSaveQuoteRequest(user, pets, insurance, cover, newQuote, cookies);

        // console.log(JSON.stringify(clientRequestObject));
        return this.http.post(url, JSON.stringify(clientRequestObject), {headers: this.headers})
            .toPromise()
            .then((response) => {
                return QuoteService.mapSaveQuoteResponse(response);
            })
            .catch(this.handleError);
    }

    findProduct(insurance: Insurance): Promise<Insurance> {
        let url = this.baseUrl + 'api/Petsure/FindProduct/' + insurance.productCode;
        return this.http.get(url, {headers: this.headers})
            .toPromise()
            .then((response) => {
                return QuoteService.mapFindProductResponse(response);
            })
            .catch(this.handleError);
    }

    saveQuoteDetails(user: User, pet: Pet, cover: Cover, petNumber: number): Promise<void> {
        let url = this.baseUrl + 'api/Petsure/SaveQuoteDetail/' + petNumber + '/' + pet.cover.productCode;

        let clientRequestObject = QuoteService.mapSaveQuoteDetailsRequest(user, pet, cover);

        return this.http.post(url, JSON.stringify(clientRequestObject), {headers: this.headers})
            .toPromise()
            .then((response) => {
                QuoteService.mapSaveQuoteDetailsResponse(response, pet);
                return;
            })
            .catch(this.handleError);
    }

    invalidateQuoteDetail(quoteId: number, quoteDetailsId: number): Promise<boolean> {
        let url = this.baseUrl + 'api/Petsure/InvalidateQuoteDetail/' + quoteId + '/' + quoteDetailsId;
        return this.http.get<boolean>(url, {headers: this.headers})
            .toPromise()
            .then((response: boolean) => {
                return response;
            })
            .catch(this.handleError);
    }


    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        console.error(error.toString()); // for demo purposes only
        return Promise.reject(error.message || error);
    }
}
