import { Insurance } from './insurance';
import { CoverField } from './cover-field';

export class InsuranceCovers {

    static getBlank(): InsuranceCovers {
        return new InsuranceCovers(new Map<string, CoverField>(), []);
    }

    constructor(
        public coverFields: Map<string, CoverField>,
        public insurances: Insurance[],
        public petToUpdate: number = null,
        public brandIdFilter: number = null
    ) {}

    getCoverFields() {
        return this.coverFields;
    }

    getInsurances() {
        return this.insurances;
    }

}
