export class CoverField {

    constructor(
        public id: number,
        public order: number,
        public name: string,
        public helpText: string,
        public isVisible: boolean
    ) {}
}
