// The environment variables should be configured in the octopus project along with environment variable injection

export const CONFIGURATION = {
    'paymentSubmitMode': '#{paymentSubmitMode}',
    'baseUrl': '#{baseUrl}',
    'environment': '#{environment}',
    'gmapApiKey': '#{gmapApiKey}',
    'trackUrl': '#{trackUrl}',
    'tracker_name': '#{tracker_name}',
    'SITE_KEY':'#{SITE_KEY}',
    'RECAPTCHA_HOST':'#{RECAPTCHA_HOST}',    
};

