import { Component, EventEmitter, OnInit, Input, Output  } from '@angular/core';
import { Observable } from 'rxjs';


import { Pet } from '../model/pet';
import { Vet } from '../model/vet';
import { User } from '../model/user';
import { Cover } from '../model/cover';
import { Breed } from '../model/breed';
import { Loading } from '../model/loading';
import { Gender } from '../model/gender.enum';
import { Insurance } from '../model/insurance';
import { Desexed } from '../model/desexed.enum';
import { Species } from '../model/species.enum';
import { BrandsEnum } from '../model/brands.enum';
import { Frequency } from '../model/frequency.enum';

import { PremiumService } from '../services/premium.service';
import { BreedsService } from '../services/breeds.service';
import { VetsService } from '../services/vets.service';

//User story - 31124 - Choosi PDDO Pet STP
import { PDDOSelectedDetails } from '../model/pddo-selected-details';
import { PDDOService } from '../services/pddo.service';
import { PremiumsHelper } from '../helpers/premiums.helper';


@Component({
    selector: '[pet-form]',
    templateUrl: 'pet-form.component.html',
    providers: [ PremiumService, PDDOService ]
})

export class PetFormComponent implements OnInit {

    GENDER = Gender;
    SPECIES = Species;
    DESEXED = Desexed;
    FREQUENCY = Frequency;
    BRANDS = BrandsEnum;

    @Input('pet') pet: Pet;
    @Input('user') user: User;
    @Input('pets') pets: Pet[];
    @Input('cover') cover: Cover;
    @Input('pet-covers') coverList: Insurance[];
    @Input('id') id: number;
    @Input('frequency') frequency: Frequency;
    @Input('breedsService') breedsService: BreedsService;
    @Input('vetsService') vetsService: VetsService;
    @Input('submitted') submitted: boolean;
    @Input('loading') loading: Loading;
    @Input('loadingRoute') loadingRoute: boolean;

    petNb: number;
    @Input('pet-nb')
    set petNbParent(petNb: number) {
        if (this.petNb > petNb) {
            this.updateInsurancesPricing(false);
        }
        this.petNb = petNb;
    }

    coverStartDate: Date;
    @Input('cover-start-date')
    set coverStartDateParent(coverStartDate: Date) {
        this.coverStartDate = coverStartDate;
    }

    coverStartDateChangeAge: boolean = false;
    @Input('cover-start-date-change-age')
    set coverStartDateChangeAgeParent(coverStartDateChangeAge: boolean) {
        if (this.coverStartDateChangeAge !== coverStartDateChangeAge) {
            this.coverStartDateChangeAge = coverStartDateChangeAge;
            if (coverStartDateChangeAge === false ||
                (this.pet.getAge() !== this.pet.getAge(this.coverStartDate))
            ) {
                this.updateInsurancesPricing(true);
            }
        }
    }

    @Output() onRemove = new EventEmitter<number>();
    @Output() changeFreq = new EventEmitter<Frequency>();
    @Output() onRoute = new EventEmitter<any>();

    petAgeControl: boolean = false;
    vetName = '';

    breedName: string = '';
    breedDataSource: Array<Breed>;
    breedTypeaheadLoading: boolean = false;
    breedTypeaheadNoResults: boolean = false;

    dataSource: Observable<any>;
    typeaheadLoading: boolean = false;
    typeaheadNoResults: boolean = false;
    petCoverList: Insurance[] = [];

    processRemove: boolean = false;
    processingPrice: boolean = false;

    quoteDetailsId: number = null;

    blankCover: Insurance = Insurance.getBlank();
    insurancesDisplayed: Insurance[] = [];

    selectDisabled: boolean = true;
    isGuideDogInsurance: boolean = true;

    timeoutProcess: any = null;

    selectedExcess: number = 0;

    //User story - 31124 - Choosi PDDO Pet STP
    //PDDO lightbox - validation
    pddoSelectedDetails: PDDOSelectedDetails = PDDOSelectedDetails.getBlank();
    showPDDOQuestions: boolean = false;

    public constructor(
        private premiumService: PremiumService,
        private pddoService: PDDOService
    ) {
        this.breedTypeaheadInit();
        this.dataSource = Observable
            .create((observer: any) => { observer.next(this.vetName); })
            .mergeMap((token: string) => this.getVetAsObservable(token));
    }

    ngOnInit(): void {
        this.loadingRoute = false;
        this.vetName = this.pet.vet.vet;
        for (let cover of this.coverList) {
            if (this.pet.cover.quoteDetailsId !== null && this.pet.cover.coverName === cover.coverName) {
                this.petCoverList.push(this.pet.cover);
            } else {
                this.petCoverList.push(Insurance.getCopy(cover));
            }
        }
        if (this.petCoverList.length > 0) {
            this.isGuideDogInsurance = this.petCoverList[0].brandId === this.BRANDS.GuideDogs;
        }
        if (this.pet.cover.quoteDetailsId !== null || this.pet.cover.productBenefitId !== null) {
            this.selectedExcess = this.pet.cover.excessSelected;
            this.blankCover.excessSelected = this.pet.cover.excessSelected;
            this.quoteDetailsId = this.pet.cover.quoteDetailsId;
            this.blankCover.quoteDetailsId = this.pet.cover.quoteDetailsId;
            this.updateRoutineCareSelect(this.pet.cover.routineCareSelected);
            this.selectDisabled = false;

            //User story - 31124 - Choosi PDDO Pet STP
           // this.pet.isFirstTermsAccepted = true;
            //this.pet.isSecondTermsAccepted = true;
            //this.pet.isDDOTermsAccepted=true
            this.showPDDOQuestions = true;
        } else {
            this.blankCover.routineCareSelected = this.pet.cover.routineCareSelected;
            this.pet.cover = this.blankCover;
            
            //User story - 31124 - Choosi PDDO Pet STP
           // this.pet.isFirstTermsAccepted = false;
            //this.pet.isSecondTermsAccepted = false;
            this.showPDDOQuestions = false;
        }
        this.updateInsurancesPricing(false);
        this.getBreeds();
        this.updatePetAgeControl();
    }

    updateInsuranceDisplayed() {
        this.insurancesDisplayed = this.petCoverList;
    }

    updateSpecies(species: Species) {
        if (species !== this.pet.petType) {
            this.pet.petType = species;
            this.pet.isGuideDog = false;
            this.pet.microchipNumber = '';
            this.pet.breed = Breed.getBlank();
            this.getBreeds();
            this.checkAndUpdatePricing();
        }
    }

    checkAndUpdatePricing() {
        if (this.timeoutProcess !== null) {
            clearTimeout(this.timeoutProcess);
            this.timeoutProcess = null;
        }
        this.timeoutProcess = setTimeout(
            () => {
                let quoteDetailsId: number = this.pet.cover.quoteDetailsId;
                this.pet.cover = this.blankCover;

                //User story - 31124 - Choosi PDDO Pet STP
                //this.pet.isFirstTermsAccepted = false;
               //this.pet.isSecondTermsAccepted = false;
               this.pet.isDDOTermsAccepted=false;
                this.showPDDOQuestions = false;

                if (quoteDetailsId !== null && this.blankCover.quoteDetailsId === null) {
                    this.blankCover.quoteDetailsId = quoteDetailsId;
                    this.pet.cover.quoteDetailsId = quoteDetailsId;
                }
                if (this.isPetFullAndUpdated()) {
                    this.updateInsurancesPricing(true);
                } else {
                    this.selectDisabled = true;
                }
                this.timeoutProcess = null;
               
            },
            500
        );
    }

    updatePetAgeControl(): void {
        if (this.pet.isPropertyValid('bornMonth') && this.pet.isPropertyValid('bornYear')) {
            this.petAgeControl = this.pet.getMonthsOld() === 2;
        }
    }

    isPetFullAndUpdated(): boolean {
        if (this.pet.petType !== null
            && this.pet.breed !== null
            && this.pet.breed.breedCode !== ''
            && this.pet.bornMonth !== null
            && this.pet.bornYear !== null
            && this.pet.name !== null
            && this.pet.name !== ''
            && (this.pet.bornMonth + '').match(/([1-9]|[1][0-2]|0[1-9])/i)
            && (this.pet.bornYear + '').match(/(19[0-9]{2}|20[0-2][0-9])/i)
        ) {
            return this.pet.validate();
        }
        return false;
    }

    changeBreedTypeaheadLoading(e: boolean): void {
        this.pet.breed = Breed.getBlank();
        this.breedTypeaheadLoading = e;
    }

    changeBreedTypeaheadNoResults(e: boolean): void {
        this.breedTypeaheadNoResults = e;
    }

    breedTypeaheadOnSelect(e: any): void {
        this.pet.breed = e.item;
    }

    breedTypeaheadInit(): void {
        this.breedName = (this.pet && this.pet.breed !== null)
            ? this.pet.breed.breed
            : '';
    }

    resetPetBreed() {
        this.pet.breed = Breed.getBlank();
    }

    getVetAsObservable(token: string): Promise<Vet[]> {
        return this.vetsService
            .getVets(token)
            .then(
                data => {
                    if (data.length === 0) {
                        return Promise.resolve([new Vet('', 'Unknown')]);
                    } else {
                        return data;
                    }
                },
                () => {
                    return Promise.resolve([]);
                }
            );
    }

    changeTypeaheadLoading(e: boolean): void {
        this.pet.vet = Vet.getBlank();
        this.typeaheadLoading = e;
    }

    changeTypeaheadNoResults(e: boolean): void {
        this.typeaheadNoResults = e;
    }

    typeaheadOnSelect(e: any): void {
        this.pet.vet = e.item;
    }

    resetPetVet() {
        this.pet.vet = Vet.getBlank();
    }

    getBreeds(): void {
        this.breedsService
            .getBreeds(this.pet.petType)
            .subscribe(
                data => {
                    this.breedDataSource = data;
                    this.breedTypeaheadInit();
                },
                error => { console.error('Error fetching breeds', error);
            })
        ;
    }

    get coverPrice() {
        let price = this.pet.cover.getPrice(this.frequency);
        if (price !== null) {
            return price;
        }
        return 0;
    }

    updateRoutineCareSelect(routine: boolean) {
        for (let insurance of this.petCoverList) {
            insurance.setRoutineCare(routine);
        }
        this.pet.cover.routineCareSelected = routine;

        //User story - 31124 - Choosi PDDO Pet STP
        this.pddoService
            .getPDDOSelectedDetails(this.pet.cover, this.pet.name)
            .then(
                (result) => {
                    this.pddoSelectedDetails = result;
                }
            );
    }
    
    updateExcessSelect(excess: number) {
        for (let insurance of this.petCoverList) {    
            insurance.setExcess(excess);
        }
        this.pet.cover.excessSelected = excess;
        this.selectedExcess = excess;
        this.updateInsurancesPricing(false);

        //User story - 31124 - Choosi PDDO Pet STP
        this.pddoService
            .getPDDOSelectedDetails(this.pet.cover, this.pet.name)
            .then(
                (result) => {
                    this.pddoSelectedDetails = result;
                }
            );
    }

    handleCoverChange(cover: Insurance) {
        let prevQuoteDetailId = -1;
        if(this.pet.cover.quoteDetailsId != null) {
            prevQuoteDetailId = this.pet.cover.quoteDetailsId;
        }

        this.pet.cover = cover;
        this.pet.cover.excessSelected = cover.excessDefault;        

        if(prevQuoteDetailId != -1) {
            this.pet.cover.quoteDetailsId = prevQuoteDetailId;
        }
        
        this.setPDDOTermsAccepted();
    }

    updateInsurancesPricing(setExcessToDefault: boolean = false) {
        
        this.selectDisabled = true;
        if (this.quoteDetailsId === null && this.pet.cover.quoteDetailsId !== null) {
            this.quoteDetailsId = this.pet.cover.quoteDetailsId;
        }
        if (!this.processingPrice && this.pet.name && this.pet.validate()) {
            this.processingPrice = true;
            let selectedCover: Insurance = this.petCoverList[0];
            
            this.premiumService
            .getPremiums(this.user, this.pet, this.pets, this.petCoverList, selectedCover, this.cover)
            .then(
                (insurances) => {
                    this.petCoverList = insurances;
                    for (let i = 0; i < this.petCoverList.length; i++) {
                        
                        this.petCoverList[i].setRoutineCare(this.pet.cover.routineCareSelected);
                        this.petCoverList[i].setExcess(this.selectedExcess);

                        if (this.quoteDetailsId !== null) {
                            this.petCoverList[i].quoteDetailsId = this.quoteDetailsId;
                        }
                        if (this.pet.cover.productBenefitId === this.petCoverList[i].productBenefitId) {
                            this.pet.cover = this.petCoverList[i];
                        }
                        
                        this.pet.cover.excessSelected = this.selectedExcess;
                    }

                    this.premiumService
                    .getPremiums(this.user, this.pet, this.pets, this.petCoverList, selectedCover, this.cover)
                    .then(
                        (insurances) => {
                            this.petCoverList = insurances;
                            for (let i = 0; i < this.petCoverList.length; i++) {
                                
                                this.petCoverList[i].setRoutineCare(this.pet.cover.routineCareSelected);
                                
                                if (setExcessToDefault)
                                {
                                    let excessDefault: number = PremiumsHelper
                                    .getDefaultExcess(this.petCoverList[i].brandId, this.petCoverList[i].productBenefitId);

                                    this.petCoverList[i].setExcess(excessDefault);
                                    this.petCoverList[i].excessDefault = excessDefault;
                                    this.petCoverList[i].excessSelected = excessDefault;
                                    this.pet.cover.excessSelected = excessDefault;
                                    this.pet.cover.excessDefault = excessDefault;
                                    this.selectedExcess = excessDefault;
                                }
                                else {
                                    this.petCoverList[i].setExcess(this.selectedExcess);
                                }
                                if (this.quoteDetailsId !== null) {
                                    this.petCoverList[i].quoteDetailsId = this.quoteDetailsId;
                                }
                                if (this.pet.cover.productBenefitId === this.petCoverList[i].productBenefitId) {
                                    this.pet.cover = this.petCoverList[i];
                                }                              
                            }
                            this.processingPrice = false;                       
                            this.updateInsuranceDisplayed();
                            this.selectDisabled = false;
                        },
                        (error) => {
                            console.error(error);
                            this.processingPrice = false;
                        }
                    );
                    
                },
                (error) => {
                    console.error(error);
                    this.processingPrice = false;
                }
            );
        }
    }

 

    changeFrequency() {
        this.changeFreq.emit(this.frequency);
    }

    updateHasVet(hasVet: boolean) {
        this.pet.hasVet = hasVet;
        if (!hasVet) {
            this.pet.vet = Vet.getBlank();
            this.vetName = '';
        }
    }

    remove(event: any = null) {
        if (!this.processRemove) {
            this.processRemove = true;
            event.stopPropagation();
            this.onRemove.emit(this.id);
            setTimeout(() => { this.processRemove = false; }, 500);
        }
    }

    goToRoute(route: string) {
        this.onRoute.emit({ route: route, index: this.id });
    }

    get diagnostic() {
        return JSON.stringify({
            'frequency': Frequency[this.frequency],
            // 'pet': this.pet,
            'pet.cover': this.pet.cover,
            'petCoverList': this.petCoverList
        });
    }


    //User story - 31124 - Choosi PDDO Pet STP
    setPDDOTermsAccepted() {
        if (this.pet.cover.id == 0)
        {
            this.showPDDOQuestions = false;
        }
        else {
            this.showPDDOQuestions = true;
        }
        this.pddoService
            .getPDDOSelectedDetails(this.pet.cover, this.pet.name)
            .then(
                (result) => {
                    this.pddoSelectedDetails = result;
                    //this.pet.isFirstTermsAccepted = false;
                    //this.pet.isSecondTermsAccepted = false;
                    this.pet.isDDOTermsAccepted=false;
                }
            );
    } 
    //This will handle the ddo agreement button click and validate pet  
    agreeDDOTerms(isAccpted:boolean)
    {   this.pet.isDDOTermsAccepted=isAccpted;
       this.pet.validate(3)      
   
    } 
}
