export class AustralianPostCodes {
    static statesPostCodes: any = {
        'NSW': [
            [1001, 1902],
            [2000, 2599],
            [2611],
            [2618],
            [2619, 2899],
            [2921, 2999],
            [3644],
            [3691],
            [3707]
        ],
        'ACT': [
            [200, 221],
            [2600, 2618],
            [2620],
            [2900, 2920]
        ],
        'VIC': [
            [3000, 3999],
            [8000, 8627]
        ],
        'QLD': [
            [4000, 4999],
            [9000, 9944]
        ],
        'SA': [
            [5000, 5799]
        ],
        'WA': [
            [6000, 6999]
        ],
        'TAS': [
            [7000, 7923]
        ],
        'NT': [
            [800, 899],
            [906, 909],
            [4825]
        ]
    };

}
