import { Component, OnInit, DoCheck } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { Pet } from '../model/pet';
import { User } from '../model/user';
import { Theme } from '../model/theme';
import { Breed } from '../model/breed';
import { Loading } from '../model/loading';
import { State } from '../model/state.enum';
import { Gender } from '../model/gender.enum';
import { Promotion } from '../model/promotion';
import { Species } from '../model/species.enum';
import { Desexed } from '../model/desexed.enum';

import { SubscriptionService } from '../services/subscription.service';
import { BreedsService } from '../services/breeds.service';
import { ClientService } from '../services/client.service';

import { ToolsMethods } from '../shared/tools.methods';

import { LocationService } from '../services/location.service';
import { ReCAPTCHAResponse, ReCAPTCHAService } from '../services/recaptcha.service'
import { CONFIGURATION } from 'app/environments/environment';

declare var grecaptcha: any;

@Component({
    selector: 'about-you',
    templateUrl: './about-you.component.html',
    providers: [BreedsService, ClientService, LocationService, ReCAPTCHAService]
})

export class AboutYouComponent implements OnInit, DoCheck {



    SPECIES = Species;
    DESEXED = Desexed;
    GENDER = Gender;
    STATES = State;

    // models
    pet: Pet;
    user: User;
    loading: Loading;
    theme: Theme;

    // subscriptions
    subscriptionLoading: BehaviorSubject<Loading>;
    subscriptionUser: BehaviorSubject<User>;
    subscriptionPet: BehaviorSubject<Pet>;
    subscriptionTheme: BehaviorSubject<Theme>;

    subscriptionHaveUsCallOpen: BehaviorSubject<boolean>;
    subscriptionRecaptchaPassed: BehaviorSubject<boolean>;
    petAgeControl: boolean = false;
    isTermsAccepted: boolean = false;
    submitted: boolean;
    oldPetType: number;
    recaptcha_sitekey: string = null;
    resultToken: string = null;

    breedName: string = '';
    breedDataSource: Array<Breed>;
    breedTypeaheadLoading: boolean = false;
    breedTypeaheadNoResults: boolean = false;

    nowDate = new Date();
    blankState = null;

    showClientConsent: boolean = false;
    showRecaptchaError: boolean = false;
    isProdEnv: boolean = true; //set false for local debugging diagnostic trace #117737

    constructor(
        private breedsService: BreedsService,
        private subscriptionService: SubscriptionService,
        private clientService: ClientService,
        private router: Router,
        private route: ActivatedRoute,
        private locationService: LocationService,
        private recaptchaService: ReCAPTCHAService
    ) {
        this.subscriptionLoading = subscriptionService.getSubscriptions<Loading>('loading');
        this.subscriptionLoading.subscribe(
            loading => {
                this.loading = loading;
            });

        this.subscriptionUser = subscriptionService.getSubscriptions<User>('user');
        this.subscriptionUser.subscribe(
            user => {
                this.user = user;
            });

        this.subscriptionPet = subscriptionService.getSubscriptions<Pet>('pet');
        this.subscriptionPet.subscribe(
            pet => {
                this.pet = pet;
                this.getMarketingParameter();
                this.breedTypeaheadInit();
            });

        this.subscriptionTheme = subscriptionService.getSubscriptions<Theme>('theme');
        this.subscriptionTheme.subscribe(
            theme => {
                this.theme = theme;
            });
        this.subscriptionHaveUsCallOpen = subscriptionService.getSubscriptions<boolean>('haveUsCallOpen');
        this.subscriptionRecaptchaPassed = subscriptionService.getSubscriptions<boolean>('recaptchaPassed');
        this.whenGrecaptchaReady = this.whenGrecaptchaReady.bind(this);
        this.afterRecaptcha = this.afterRecaptcha.bind(this);
        this.recaptcha_sitekey = this.recaptchaService.getSiteKey();
        
    }

    ngOnInit(): void {
        this.submitted = false;

        //US79543 - do not pre-populating details before anything has been selected
        if (this.pet.name === '' && this.pet.breed.breed === '') {
            this.pet.petType = null;
            this.pet.gender = null;
            this.pet.isDesexed = null;
        }

        this.oldPetType = this.pet.petType;
        this.loading.activated = false;
        this.getBreeds();
        this.user.promotion = Promotion.getBlank();
        this.recaptchaService.checkExists();
    }

    ngDoCheck(): void {
        if (this.pet.petType !== this.oldPetType) {
            this.oldPetType = this.pet.petType;
            this.pet.breed = Breed.getBlank();
            this.getBreeds();
        }
    }

    updateTitle(title: string) {
        switch (title) {
            case 'Dr':
                break;
            case 'Mr':
                this.user.gender = Gender.Male;
                break;
            default:
                this.user.gender = Gender.Female;
        }
    }

    updatePetAgeControl(): void {
        if (this.pet.isPropertyValid('bornMonth') && this.pet.isPropertyValid('bornYear')) {
            this.petAgeControl = this.pet.getMonthsOld() === 2;
        }
    }

    changeBreedTypeaheadLoading(e: boolean): void {
        this.pet.breed = Breed.getBlank();
        this.breedTypeaheadLoading = e;
    }

    changeBreedTypeaheadNoResults(e: boolean): void {
        this.breedTypeaheadNoResults = e;
    }

    breedTypeaheadOnSelect(e: any): void {
        this.pet.breed = e.item;
    }

    breedTypeaheadInit(): void {
        this.breedName = (this.pet.breed !== null)
            ? this.pet.breed.breed
            : '';
    }

    resetPetBreed() {
        this.pet.breed = Breed.getBlank();
    }

    getBreeds(): void {
        this.breedsService
            .getBreeds(this.pet.petType)
            .subscribe(
                data => {
                    this.breedDataSource = data;
                    this.breedTypeaheadInit();
                },
                error => {
                    console.log('error', error);
                }
            );
    }

    updatePhoneNumber($event: any) {
        let currentPosition: number = ToolsMethods.getCaretPosition('userContactNumber');
        let currentSize: number = this.user.contactNumber.length;
        this.user.contactNumber = ToolsMethods.formatPhoneNumber($event);
        let finalSize: number = this.user.contactNumber.length;
        let diff: number = finalSize - currentSize;
        setTimeout(() => { ToolsMethods.setCaretPosition('userContactNumber', currentPosition + diff); }, 5);
    }

    updateNumberOnDel($event: any) {
        if ($event.keyCode === 8 || $event.keyCode === 46) {
            this.user.contactNumber = ToolsMethods.formatPhoneNumber($event.target.value);
        }
    }

    updatePostCode() {
        this.user.address.address = '';
        this.user.address.suburb = '';

        let postcode = this.user.address.postcode;
        if (postcode !== null && postcode !== '' && postcode.match(/^[0-9]{4}$/i)) {
            this.locationService
                .getSuburbs(postcode)
                .then(
                    (suburbs) => {
                        this.user.address.invalidpostcode = suburbs.length === 0;
                    }, (error) => {
                        console.log(error);
                    });
        }
    }

    /**
     * This is made to fix the Autofill problem, Chrome does not trigger event on autofilling so the model won't be updated.
     * Therefore we need to manually check by hand the value to be sure that it hasn't been autofilled and if it is
     * update the model accordingly.
     */
    preCheckAutoFill() {
        let userFirstNameField: HTMLInputElement = <HTMLInputElement>document.getElementById('userFirstName');
        if (userFirstNameField.value !== this.user.firstName) {
            this.user.firstName = userFirstNameField.value;
        }
        let userLastNameField: HTMLInputElement = <HTMLInputElement>document.getElementById('userLastName');
        if (userLastNameField.value !== this.user.lastName) {
            this.user.lastName = userLastNameField.value;
        }
        let userBornDayField: HTMLInputElement = <HTMLInputElement>document.getElementById('userBornDay');
        if (+userBornDayField.value !== this.user.bornDay) {
            this.user.bornDay = +userBornDayField.value;
        }
        let userBornMonthField: HTMLInputElement = <HTMLInputElement>document.getElementById('userBornMonth');
        if (+userBornMonthField.value !== this.user.bornMonth) {
            this.user.bornMonth = +userBornMonthField.value;
        }
        let userBornYearField: HTMLInputElement = <HTMLInputElement>document.getElementById('userBornYear');
        if (+userBornYearField.value !== this.user.bornYear) {
            this.user.bornYear = +userBornYearField.value;
        }
        let userPhoneField: HTMLInputElement = <HTMLInputElement>document.getElementById('userContactNumber');
        if (userPhoneField.value !== this.user.contactNumber) {
            this.user.contactNumber = ToolsMethods.formatPhoneNumber(userPhoneField.value);
        }
        let userMailField: HTMLInputElement = <HTMLInputElement>document.getElementById('userMail');
        if (userMailField.value !== this.user.email) {
            this.user.email = userMailField.value;
        }
        let userPostcodeField: HTMLInputElement = <HTMLInputElement>document.getElementById('userPostcode');
        if (userPostcodeField.value !== this.user.address.postcode) {
            this.user.address.postcode = userPostcodeField.value;
        }
    }

    resolved(recaptchaResponse: string) {
        this.resultToken = recaptchaResponse;
        this.showRecaptchaError = (this.resultToken === null || this.resultToken === undefined);
    }

    whenGrecaptchaReady(): void {
        let _th = this;
        if (this.resultToken !== null && this.resultToken !== undefined) {
            _th.afterRecaptcha(_th, this.resultToken);
        } else {
            this.loading.activated = false;
        }
    }

    afterRecaptcha(_th: AboutYouComponent, token: string): any {
        if (token == null || token.length === 0) {
            //attempt to reset the control if there is no token coming through 
            //- this would indicate some error in communicating with Google recaptcha itself
            this.scrollBack('about-you-form');
        } else {
            _th.recaptchaService.checkExists();
            _th.recaptchaService.apiVerifyRecaptcha(token).then(
                (r: ReCAPTCHAResponse) => {
                    if (r.success) {
                        //proceed with the rest of the comparison as normal
                        //if the gfs recaptcha service indicates we are human
                        _th.DoIfRecaptchaSucceeded(_th);
                    } else {
                        //we only fail / block if service call succeeds with a low score / success=false
                        //this part may still be up for debate - what to do when a low score is given to a human
                        //currently recaptcha enterprise does not support showing challenge if the site key is configured for using score
                        //and using a challenge / checkbox site key means it gets shown to every user
                        this.scrollBack('about-you-form');
                    }
                },
                (error: any) => {
                    //fail safe - if gfs recaptcha service call fails then revert to existing behaviour
                    //which is to proceed as normal with the comparison workflow
                    _th.DoIfRecaptchaSucceeded(_th);
                }
            );
        }
    }

    DoIfRecaptchaSucceeded(_th: AboutYouComponent) {
        //this is the normal comparison workflow - which happens after recaptcha succeeds or is skipped
        _th.subscriptionRecaptchaPassed.next(true);
        _th.loading.validate();
        _th.loading.activated = true;
        _th.loading.loadingText = 'Comparing...';
        _th.clientService.saveClient(_th.theme, _th.user, _th.pet, 1, ToolsMethods.getCookie(document.cookie))
            .then(
                () => {
                    // validate response
                    if (_th.validateForm()) {
                        _th.subscriptionUser.next(_th.user);
                        _th.subscriptionPet.next(_th.pet);
                        _th.subscriptionHaveUsCallOpen.next(false);
                        _th.router.navigate(['compare']);
                    }
                },
                (error: any) => {
                    _th.loading.setError('searchingPremiumError', 'An error occurred while processing the request, please retry.');
                    _th.loading.activated = false;
                }
            );
    }

    onSubmit(): void {
        this.preCheckAutoFill();
        this.submitted = true;
        // validate form
        if (this.validateForm()) {
            //here we request a token from google recaptcha, but only if the site is being used externally
            //skip recpatcha if the site is being used by an agent in evolve
            this.loading.activated = true;
            if (this.theme.userId === 'WebUser' && this.theme.automated === 'false') {
                this.whenGrecaptchaReady()
            }
            else {
                this.DoIfRecaptchaSucceeded(this);
            }

        } else {
            if (!this.pet.isValid()) {
                this.scrollBack('about-your-pet-form');
            } else if (!this.user.isValid()) {
                this.scrollBack('about-you-form');
            }
        }
    }

    validateForm(): boolean {
        this.pet.validate(1);
        this.user.validate(1);
        let termsAccepted: boolean = this.theme.firstStepTermsCheckbox ? this.isTermsAccepted : true;
        return this.pet.isValid() && this.user.isValid() && this.recaptchaValidate() && termsAccepted;
    }

    recaptchaValidate(): boolean{
        this.showRecaptchaError = (this.resultToken === null || this.resultToken.length === 0 );
        if (this.theme.automated === 'true' || this.theme.userId !== 'WebUser') {
            this.showRecaptchaError = false;
        }
        return !this.showRecaptchaError;
    }

    get maxPhoneLength(): number {
        return ToolsMethods.maxPhoneLength(this.user.contactNumber);
    }

    get diagnostic() {
        return JSON.stringify(
            {
                'pet': this.pet,
                'petValid': this.pet.isValid(),
                // 'breeds': this.breedDataSource,
                'user': this.user,
                'submitted': this.submitted,
                'loading': this.loading,
                'isTermsAccepted': this.isTermsAccepted
            },
            undefined,
            4
        );
    }

    private scrollBack(id: string): void {
        this.loading.activated = false;
        ToolsMethods.scrollToId(id, 500);
    }

    private getMarketingParameter() {
        if (this.pet.clientConsent != null) {
            this.showClientConsent = true;
        } else {
            const clientConsent = this.route.snapshot.queryParams['client_consent'];

            if (clientConsent && clientConsent === "1") {
                this.showClientConsent = true;
                this.pet.clientConsent = false;
            }
        }
    }
}
