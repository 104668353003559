import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'keys'})
export class KeysPipe implements PipeTransform {

    transform(value, args: string[]): any {
        let keys = [];
        for (let enumMember in value) {
            if (value.hasOwnProperty(enumMember)) {
                let isValueProperty = parseInt(enumMember, 10) >= 0;
                if (isValueProperty) {
                    keys.push({key: enumMember, value: value[enumMember]});
                }
            }
        }
        return keys;
    }
}

@Pipe({name: 'removeSpaces'})
export class RemoveSpacesPipe implements PipeTransform {

    transform(value, args: string[]): any {
        return value.replace(/ /g, '');
    }
}

/**
 * The default angular one is not compatible with IE9 due to Intl library.
 */
@Pipe({name: 'customCurrency'})
export class CustomCurrencyPipe implements PipeTransform {
    transform(value, decimals: number, currencySymbol: string): any {
        if (decimals === 0) {
            // this rounds to zero decimals, adds two decimals, formats the number with the commas, then removes the
            // two empty decimals and the dot.
            let zeroDecimalsRoundedValue: string =
                Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals)
                .toFixed(decimals) + '.00';
            return currencySymbol + zeroDecimalsRoundedValue.replace(/(\d)(?=(\d{3})+\.)/g, '$1,').replace('.00', '');
        } else {
            return currencySymbol
                + Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals)
                    .toFixed(decimals).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
        }

    }
}

/**
 * The default angular one is not compatible with IE9 due to Intl library.
 */
@Pipe({name: 'emptyValue'})
export class EmptyValuePipe implements PipeTransform {
    transform(value, defaultValue: string): any {
        return (value === '') ? defaultValue : value;
    }
}

/**
 * Set the first letter to capital
 */
@Pipe({name: 'capitalize'})
export class CapitalizePipe implements PipeTransform {
    transform(value: any) {
        if (value) {
            return value.charAt(0).toUpperCase() + value.slice(1);
        }
        return value;
    }
}

/**
 * Set the first letter to capital
 */
@Pipe({name: 'protocol'})
export class ProtocolPipe implements PipeTransform {
    transform(value: any) {
        if (value) {
            let tmpValue = value.toLowerCase();
            if (!(tmpValue.startsWith('http://')
                && tmpValue.startsWith('https://')
                && tmpValue.startsWith('//'))) {
                return '//' + value;
            }
            return value;
        }
        return value;
    }
}
