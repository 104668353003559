export class StringHelper {
    public static ReplaceCaseInsensitive(findIn: string, find: string, replacement: string):string{
        var searchMask = find;
        var regEx = new RegExp(searchMask, "gi");
        var replaceMask = replacement;
        var result = findIn.replace(regEx, replaceMask);
        return result;
    }
    public static stripHtml(html:string):string
{
    var tmp = document.implementation.createHTMLDocument("New").body;
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
}

public static FormatCurrency(value:string):string{
    let nf = new Intl.NumberFormat('en-US');
    return "$" +  nf.format(Number(value.replace(/[^0-9\.]+/g,"")));
    
}

}