import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import 'rxjs/add/operator/toPromise';

import { ToolsMethods } from '../shared/tools.methods';

import { Pet } from '../model/pet';
import { User } from '../model/user';
import { Theme } from '../model/theme';
import { Address } from '../model/address';
import { State } from '../model/state.enum';

@Injectable()
export class ClientService extends BaseService {

    public static mapGetClientResponse(data: any): User {
        let state: State = State[data.State + ''];
        let postCode = '';
        if (data.PostCode !== null) {
            if (data.PostCode > 999) {
                postCode = data.PostCode.toString();
            } else if (data.PostCode !== 0) {
                postCode = '0' + data.PostCode.toString();
            }
        }
        let address: Address = new Address(
            data.Address,
            (data.Suburb !== null) ? data.Suburb.toUpperCase() : '',
            postCode,
            state
        );
        let dob: Date = new Date(data.DateOfBirth);
        return new User(
            data.ClientId,
            data.EncryptedClientId,
            data.Token,
            data.Title,
            data.Gender,
            data.FirstName,
            data.LastName,
            dob.getDate(),
            dob.getMonth() + 1,
            dob.getFullYear(),
            ToolsMethods.formatPhoneNumber(data.PrimaryContactNumber),
            data.Email,
            address
        );
    }

    private static mapSaveClientRequest(theme: Theme, user: User, pet: Pet, pageId: number, cookies: any) {
        let postCode = '';
        if (user.address.postcode !== null) {
            let userPostcode: number = +user.address.postcode;
            if (userPostcode > 999) {
                postCode = user.address.postcode;
            } else if (userPostcode !== 0) {
                postCode = '0' + userPostcode;
            }
        }
        return {
            'Address': user.address.address,
            'AustralianResident': true, // todo default true ?
            'ClientId': (user.id !== 0) ? user.id : null,
            'EncryptedClientId': user.encryptedClientId,
            'CreatedBy': theme.userId,
            'DateOfBirth': ToolsMethods.getDateAsString(user.getDoB()),
            'Email': user.email,
            'FirstName': user.firstName,
            'Gender': user.gender,
            'HomePhone': '', // todo we do not know if it's home or mobile phone
            'IsLead': true,  // todo ??
            'Lastname': user.lastName,
            'Mobile': '', // todo we do not know if it's home or mobile phone
            'PartnerId': theme.id,
            'PartnerCode': theme.partnerCode,
            'PetCriteria': {
                'Id': user.petCriteriaID,
                'PageId': pageId,
                'Name': pet.name,
                'ClientConsent': pet.clientConsent,
                'Species': pet.petType,
                'Breed': {
                    'Breed': pet.breed.breed,
                    'BreedCode': pet.breed.breedCode
                },
                'DateOfBirth': ToolsMethods.getDateAsString(pet.getDoB()),
                'PetAgeConfirmed': pet.isOver8WeeksOldDeclaration
            },
            'PostCode': postCode,
            'PostalAddressLine': user.address.address,
            'PostalPostCode': user.address.postcode,
            'PostalState': ((user.address.state === null) ? null : State[user.address.state]),
            'PostalSuburb': user.address.suburb,
            'PrimaryContactNumber': user.getFormattedPhoneNumber(),
            'State': ((user.address.state === null) ? null : State[user.address.state]),
            'Suburb': user.address.suburb,  // todo we don't have this at step 1
            'SuburbId': null, // todo we don't have this
            'Title': user.title,
            'Token': user.token,
            'Cookies': cookies
        };
    }

    private static mapSaveClientResponse(user: User, data: any) {
        user.id = data.ClientId;
        user.encryptedClientId = data.EncryptedClientId;    // Mehedi
        user.token = data.Token;
        if (data.State !== null) {
            user.address.state = ClientService.getStateByName(data.State);
        }
        if (data.petCriteriaID !== null && data.PetCriteria.Id !== null) {
            user.petCriteriaID = data.PetCriteria.Id;
        }
    }

    /**
     * Due to a typescript bug we need to do this by hand State[string] is not valid for the tests....
     *
     * @param stateString
     */
    private static getStateByName(stateString: string): State {
        switch (stateString) {
            case State[State.ACT]:
                return State.ACT;

            case State[State.NSW]:
                return State.NSW;

            case State[State.VIC]:
                return State.VIC;

            case State[State.QLD]:
                return State.QLD;

            case State[State.TAS]:
                return State.TAS;

            case State[State.SA]:
                return State.SA;

            case State[State.WA]:
                return State.WA;

            case State[State.NT]:
                return State.NT;

            default:
                return null;
        }
    }

    private static mapUpdatePageForClientRequest(theme: Theme, user: User, pet: Pet, pageId: number) {
        return {
            'Address': user.address.address,
            'AustralianResident': true, // todo default true ?
            'ClientId': (user.id !== 0) ? user.id : null,
            'CreatedBy': theme.userId,
            'DateOfBirth': ToolsMethods.getDateAsString(user.getDoB()),
            'Email': user.email,
            'FirstName': user.firstName,
            'Gender': user.gender,
            'HomePhone': '', // todo we do not know if it's home or mobile phone
            'IsLead': true,  // todo ??
            'Lastname': user.lastName,
            'Mobile': '', // todo we do not know if it's home or mobile phone
            'PartnerId': theme.id,
            'PetCriteria': {
                'Id': user.petCriteriaID,
                'PageId': pageId,
                'Name': pet.name,
                'Species': pet.petType,
                'Breed': {
                    'Name': pet.breed.breed,
                    'Id': pet.breed.breedCode
                },
                'DateOfBirth': ToolsMethods.getDateAsString(pet.getDoB())
            }
        };
    }

    constructor(private http: HttpClient) {
        super();
    }

    saveClient(theme: Theme, user: User, pet: Pet, pageId: number, cookies: any = null): Promise<void> {
        let url = this.baseUrl + 'api/Petsure/SaveClient/' + theme.userId;

        let clientRequestObject = ClientService.mapSaveClientRequest(theme, user, pet, pageId, cookies);

        return this.http.post(url, JSON.stringify(clientRequestObject), { headers: this.headers })
            .toPromise()
            .then((response) => {
                ClientService.mapSaveClientResponse(user, response);
                return;
            })
            .catch(this.handleError);
    }

    getClient(clientToken: string): Promise<User> {
        let url = this.baseUrl + 'api/Petsure/GetClient?token=' + clientToken;
        return this.http.get(url, { headers: this.headers })
            .toPromise()
            .then((response) => {
                return ClientService.mapGetClientResponse(response);
            })
            .catch(this.handleError);
    }

    updatePageForClient(theme: Theme, user: User, pet: Pet, pageId: number): Promise<void> {
        let url = this.baseUrl + 'api/Petsure/UpdatePageForClient/' + pageId;

        let clientRequestObject = ClientService.mapUpdatePageForClientRequest(theme, user, pet, pageId);

        return this.http.post(url, JSON.stringify(clientRequestObject), { headers: this.headers })
            .toPromise()
            .then((response) => {
                if (response === 0) {
                    console.error('Error while saving the user page');
                }
                return;
            })
            .catch(this.handleError);
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        console.error(error.toString()); // for demo purposes only
        return Promise.reject(error.message || error);
    }
}
