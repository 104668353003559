import { NgModule, CUSTOM_ELEMENTS_SCHEMA }      from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { FormsModule }   from '@angular/forms';
import { HttpClientModule }  from '@angular/common/http';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from './shared/typeahead/index';
import { DatepickerModule } from 'ngx-bootstrap/datepicker';
import { RecaptchaModule } from 'ng-recaptcha'
import { RecaptchaLoaderService } from 'ng-recaptcha'


import { SubscriptionService } from './services/subscription.service';
import { FileService } from './services/file.service';

import { AppComponent }  from './app.component';
import { BuyComponent }  from './buy/buy.component';
import { PetFormComponent }  from './buy/pet-form.component';
import { CompareComponent }  from './compare/compare.component';
import { HaveCallComponent }  from './have-call/have-call.component';
import { AboutYouComponent }  from './about-you/about-you.component';
import { ThankYouComponent }  from './thank-you/thank-you.component';
import { PaymentGatewayComponent } from './payment-gateway/payment-gateway.component';
import { ModelValidationDisplayComponent } from './shared/model-validation-display.component';

import { AddressTypeaheadDirective } from './shared/address.directive';
import { ValidationDirective } from './shared/model-field-validation.directive';

import { routing, appRoutingProviders }  from './app.routing';

import {
    KeysPipe,
    ProtocolPipe,
    CapitalizePipe,
    EmptyValuePipe,
    RemoveSpacesPipe,
    CustomCurrencyPipe
} from './shared/tools.pipe';
import {
    SortInsurancesPipe,
    FilterShortListPipe,
    FilterPromotionListPipe
} from './compare/insurances.pipe';
import { ProductQuestionComponent } from './buy/product-question.component';


@NgModule({
    imports: [
        BrowserModule,
        routing,
        FormsModule,
        HttpClientModule,
        TooltipModule.forRoot(),
        TypeaheadModule,
        DatepickerModule.forRoot(),
        RecaptchaModule,
        BrowserAnimationsModule
    ],
    declarations: [
        AppComponent,
        BuyComponent,
        PetFormComponent,
        CompareComponent,
        ThankYouComponent,
        AboutYouComponent,
        HaveCallComponent,
        PaymentGatewayComponent,
        ModelValidationDisplayComponent,
        SortInsurancesPipe,
        FilterShortListPipe,
        FilterPromotionListPipe,
        KeysPipe,
        ProtocolPipe,
        EmptyValuePipe,
        CapitalizePipe,
        RemoveSpacesPipe,
        CustomCurrencyPipe,
        ValidationDirective,
        AddressTypeaheadDirective,
        ProductQuestionComponent
    ],
    providers: [
        appRoutingProviders,
        SubscriptionService,
        RecaptchaLoaderService,
        FileService
    ],    
    bootstrap: [
        AppComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ]
})
export class AppModule { }
