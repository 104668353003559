import { Injectable }    from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';

import 'rxjs/add/operator/toPromise';

import { User } from '../model/user';
import { Cover } from '../model/cover';
import { Theme } from '../model/theme';
import { Payment } from '../model/payment';
import { Insurance } from '../model/insurance';
import {ExtendedAuthToken} from '../model/extended-auth-token';

@Injectable()
export class PaymentService extends BaseService {

    private static mapGetPaymentTokenRequest(user: User, cover: Cover, insurance: Insurance, theme: Theme) {
        return {
            'ClientId': user.id,
            'QuoteId': cover.quoteId,
            'BrandId': insurance.brandId,
            'Source': theme.source
        };
    }

    private static mapGetPaymentTokenResponse(data: any, payment: Payment): Payment {
        payment.token = data.Token;
        payment.community = data.Community;
        payment.supplierBusiness = data.SupplierBusiness;
        payment.crn = data.Crn;
        payment.responseType = (data.ResponseType !== null) ? data.ResponseType : '';
        payment.postUrl = data.PostUrl;
        return payment;
    }

    constructor(private http: HttpClient) {
        super();
    }

    getPaymentToken(user: User, cover: Cover, insurance: Insurance, payment: Payment, theme: Theme): Promise<Payment> {
        let url = this.baseUrl + 'api/Petsure/GetPaymentToken/';

        let clientRequestObject = PaymentService.mapGetPaymentTokenRequest(user, cover, insurance, theme);

        return this.http.post(url, JSON.stringify(clientRequestObject), {headers: this.headers})
            .toPromise()
            .then((response) => {
                return PaymentService.mapGetPaymentTokenResponse(response, payment);
            })
            .catch(this.handleError);
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }

    getAuthToken(): Promise<ExtendedAuthToken>{
        let url = this.baseUrl + 'api/Petsure/GetAuthToken';
        return this.http.get(url, {headers: this.headers})
        .toPromise()
        .then((response)=>{            
            return response;            
        })
        .catch(this.handleError);
    }
}
