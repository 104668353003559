import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import 'rxjs/add/operator/toPromise';
import { State } from '../model/state.enum';

@Injectable()
export class LocationService extends BaseService {

    public static mapGetPublicHolidayResponse(data: any): Date[] {
        let holidays: Date[] = [];
        data.forEach(
            (holidayData) => {
                holidays.push(new Date(holidayData.HolidayDate));
            }
        );
        return holidays;
    }

    public static mapGetSuburbResponse(data: any): string[] {
        let suburbs: string[] = [];
        data.forEach(
            (suburbData) => {
                suburbs.push(suburbData.Suburb.toUpperCase());
            }
        );
        return suburbs;
    }

    constructor(private http: HttpClient) {
        super();
    }

    getPublicHoliday(state: State): Promise<Date[]> {
        let url = this.baseUrl + 'api/Petsure/GetPublicHoliday/' + State[state];
        return this.http.get(url)
            .toPromise()
            .then((response) => {
                return LocationService.mapGetPublicHolidayResponse(response);
            })
            .catch(this.handleError);
    }

    getSuburbs(postcode: string): Promise<string[]> {
        let url = this.baseUrl + 'api/Petsure/GetSuburb/' + postcode;
        return this.http.get(url)
            .toPromise()
            .then((response) => {
                return LocationService.mapGetSuburbResponse(response);
            })
            .catch(this.handleError);
    }
     
    getAuPostToken(): Promise<string> {
        var url = this.baseUrl + 'api/Petsure/GetAuPostToken';
        return this.http.get<string>(url)
            .toPromise()
            .then((response: string) => {
                return response;
            })
            .catch(this.handleError);
    }

    findAllAddresses(locality: string, addressFragment: string, auPostToken: string): Promise<string[]> {
        let url = this.baseUrl + 'api/Petsure/SearchAddress?postcode=' + locality.toString() + "&address=" + encodeURIComponent(addressFragment) + '&auPostToken=' + auPostToken;
        return this.http.get<string[]>(url)
            .toPromise()
            .then((response: string[]) => {
                return response;
            })
            .catch(this.handleError);
    }

    getAddress(address: any, auPostToken: string): Promise<any> {
        let url = this.baseUrl + 'api/Petsure/FormatAddress?address=' + encodeURIComponent(address) + '&auPostToken=' + auPostToken;
        return this.http.get(url)
            .toPromise()
            .then((response) => {
                return response;
            })
            .catch(this.handleError);
    }

    private handleError(error: any): Promise<any> {
        console.error('An error occurred', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }
}
