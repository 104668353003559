
 <div class="checkbox form-group product-questionForm">
     <label class="control-label pet-cover-terms"    [ngClass]="{ 'check-required': !productQuestion.productQuestionsDeclaration && productQuestion.declarationSubmit}">
    <input type="checkbox"  
    id="productQuestionsDeclaration{{id}}"  
    name="productQuestionsDeclaration{{id}}" 
    #productQuestionsDeclaration{{i}}="ngModel"
    [(ngModel)]="productQuestion.productQuestionsDeclaration" 
    [fieldValidation]="productQuestion"
    [fieldName]="'productQuestionsDeclaration'"
    [fieldGuard]="productQuestion.declarationSubmit"
    [validationStep]="4"
    />
    You agree and acknowledge the following benefits of <b>{{productQuestion.cover}}</b> are right for {{productQuestion.petNamesString}}:
</label>
<div class="item-spacer-10 clearfix"></div>
<div class="col-xs-24 col-sm-offset-6 col-sm-12">
    <div [model-validated]="productQuestion" [property-name]="'productQuestionsDeclaration'" model-validation-display></div>
</div>
<div class="col-xs-24 product-questionList">
<ul>
    <li>The benefit percentage of {{productQuestion.benefitPercentage}} and overall limit of {{productQuestion.benefitLimit}}.</li>
    <li>The sub-limits for conditions like tick paralysis and cruciate ligament surgery that apply.</li>
    <li>The level of protection that this cover provides.</li>
</ul>
</div>
</div>

