import { ModelValidator } from './model-validator';

export class Vet extends ModelValidator {

    static getBlank(): Vet {
        return new Vet('', '');
    }

    static getCopy(vet: Vet): Vet {
        let newVet = Vet.getBlank();
        newVet.practiceCode = vet.practiceCode;
        newVet.vet = vet.vet;
        return newVet;
    }

    constructor(
        public practiceCode: string,
        public vet: string
    ) {
        super();
    }


    proceedValidation(step: number = 0): boolean {
        if (step === 3) {
            if (this.practiceCode === null || this.practiceCode.trim() === '') {
                this.setError('practiceCode', 'Please enter your pet’s vet');
            }
        }
        return this.isValid();
    }
}
