<form class="form-horizontal" novalidate #aboutYouForm="ngForm" (ngSubmit)="onSubmit()">
    <div id="about-your-pet-form" class="container">
        <div class="row">
            <div class="col-sm-offset-8 col-sm-10 text-center">
                <div class="item-spacer-10 clearfix"></div>
                <div [model-validated]="loading" [property-name]="'searchingPremiumError'" model-validation-display></div>
            </div>
            <div id="antiHawking" class="antiHawking-about-you col-height col-middle text-center col-sm-24"  [innerHTML]="theme.antiHawking"></div>
               
            <div class="col-sm-10 col-sm-offset-8 text-center">
                <h2 class="about-your-pet-h2">About your pet</h2>
            </div>
        
            <div class="col-sm-24 m-t-1">
                <div class="form-group">
                    <label for="petName" class="col-sm-8 control-label">
                        Your pet's name
                        <div class="tooltip-xs">
                            <a href="javascript:void(0);" class="tooltip-link" tooltipPlacement="bottom" tabindex="999"
                                  [tooltip]="'Let\'s start with your youngest pet. If you would like to add another pet to your policy, '+theme.companyName +' let\'s you do this at a later stage of the quote process.'">
                                <span class="help-icon"></span>
                            </a>
                        </div>
                    </label>
                    <div class="col-xs-24 col-sm-10">
                        <input type="text" id="petName" class="form-control" name="petName" placeholder="Pet Name"
                               [(ngModel)]="pet.name" #petName="ngModel" maxlength="50"
                               [fieldValidation]="pet"
                               [fieldName]="'name'"
                               [fieldGuard]="submitted"
                               (keyup)="(($event.key == 8 || $event.key == 46)? (pet.name=$event.target.value):'')" required />
                         <div [hidden]="petName.valid || petName.pristine || submitted" class="alert alert-danger">
                             Please enter your pet’s name
                        </div>
                        <div [model-validated]="pet" [property-name]="'name'" model-validation-display></div>
                    </div>
                    <div class="hidden-xs col-sm-6">
                        <a href="javascript:void(0);" class="tooltip-link" tooltipPlacement="bottom" tabindex="999"
                              [tooltip]="'Let\'s start with your youngest pet. If you would like to add another pet to your policy, ' + theme.companyName + ' let\'s you do this at a later stage of the quote process.'">
                            <span class="help-icon"></span>
                        </a>
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-sm-8 control-label">{{pet.name | emptyValue:'Your pet'}} is a</label>
                    <div class="col-sm-10">
                        <div id="petType" class="row">
                            <div class="col-xs-12">
                                <button type="button" class="btn radio-btn col-xs-24"
                                        (click)="pet.petType = SPECIES.FELINE; pet.isGuideDog = false;"
                                        [ngClass]="{ 'active': pet.petType === SPECIES.FELINE }">
                                    Cat
                                </button>
                            </div>
                            <div class="col-xs-12">
                                <button type="button" class="btn radio-btn col-xs-24"
                                        (click)="pet.petType = SPECIES.CANINE; pet.isGuideDog = null;"
                                        [ngClass]="{ 'active': pet.petType === SPECIES.CANINE }">
                                    Dog
                                </button>
                            </div>
                            <div class="col-xs-24" [model-validated]="pet" [property-name]="'petType'" model-validation-display></div>
                        </div>
                    </div>
                </div>
                <div class="form-group" *ngIf="pet.petType == SPECIES.CANINE">
                    <label class="col-sm-8 control-label">
                        Is {{pet.name | emptyValue:'Your pet'}} a Registered Guide Dog
                        <div class="tooltip-xs">
                            <a href="javascript:void(0);" class="tooltip-link" tooltipPlacement="bottom" tabindex="999"
                               [tooltip]="'Registered Guide Dog means a dog supplied by a Guide Dogs Australia state member organisation to a person for the purpose of mobility support.'">
                                <span class="help-icon"></span>
                            </a>
                        </div>
                    </label>
                    <div class="col-sm-10">
                        <div id="isGuideDog" class="row">
                            <div class="col-xs-12">
                                <button type="button" class="btn radio-btn col-xs-24"
                                        (click)="pet.isGuideDog = true"
                                        [ngClass]="{ 'active': pet.isGuideDog }">
                                    Yes
                                </button>
                            </div>
                            <div class="col-xs-12">
                                <button type="button" class="btn radio-btn col-xs-24"
                                        (click)="pet.isGuideDog = false"
                                        [ngClass]="{ 'active': pet.isGuideDog !== null && !pet.isGuideDog }">
                                    No
                                </button>
                            </div>
                            <div class="col-xs-24" [model-validated]="pet" [property-name]="'isGuideDog'" model-validation-display></div>
                        </div>
                    </div>
                    <div class="hidden-xs col-sm-6">
                        <a href="javascript:void(0);" class="tooltip-link" tooltipPlacement="bottom" tabindex="999"
                           [tooltip]="'Registered Guide Dog means a dog supplied by a Guide Dogs Australia state member organisation to a person for the purpose of mobility support.'">
                            <span class="help-icon"></span>
                        </a>
                    </div>
                </div>
                <div class="form-group">
                    <label for="petBreed" class="col-sm-8 control-label">
                        What breed is {{pet.name | emptyValue:'your pet'}}?
                        <div class="tooltip-xs">
                            <a href="javascript:void(0);" class="tooltip-link" tooltipPlacement="bottom" tabindex="999"
                                  tooltip="If your pet is a cross breed that is not listed, please choose the dominant breed plus the word 'cross'. e.g. If the dominant breed is Beagle, choose 'Beagle Cross'. If the breed is not known, please select 'Unknown'.">
                                <span class="help-icon"></span>
                            </a>
                        </div>
                    </label>
                    <div class="col-xs-24 col-sm-10 typeahead-container">
                        <input
                                id="petBreed" name="petBreed"
                                placeholder="Start typing to begin search"
                                class="form-control"
                                type="text"
                                autocomplete="off"
                                #petBreed="ngModel"
                                [ngClass]="{ 'form-invalid': ((!petBreed.pristine || submitted) && pet.breed.breedCode === '') }"
                                [(ngModel)]="breedName"
                                [typeahead]="breedDataSource"
                                (typeaheadLoading)="changeBreedTypeaheadLoading($event)"
                                (typeaheadNoResults)="changeBreedTypeaheadNoResults($event)"
                                (typeaheadOnSelect)="breedTypeaheadOnSelect($event)"
                                [typeaheadOptionsLimit]="7"
                                [typeaheadMinLength]="1"
                                [typeaheadWaitMs]="50"
                                [typeaheadOptionField]="'breed'"
                                (keyup)="(($event.key == 8 || $event.key == 46)? (resetPetBreed()):'')"
                                required>
                        <div *ngIf="breedTypeaheadLoading">
                            <i class="glyphicon glyphicon-refresh ng-hide rotating"></i>
                        </div>
                        <div *ngIf="breedTypeaheadNoResults" class="">
                            <i class="glyphicon glyphicon-remove"></i> No Results Found
                        </div>
                        <div [hidden]="(petBreed.pristine && !submitted) || pet.breed.breed !== ''" class="alert alert-danger">
                            Please select the breed of your pet
                        </div>
                    </div>
                    <div class="col-sm-6 hidden-xs">
                        <a href="javascript:void(0);" class="tooltip-link" tooltipPlacement="bottom" tabindex="999"
                              tooltip="If your pet is a cross breed that is not listed, please choose the dominant breed plus the word 'cross'. e.g. If the dominant breed is Beagle, choose 'Beagle Cross'. If the breed is not known, please select 'Unknown'.">
                            <span class="help-icon"></span>
                        </a>
                    </div>
                </div>
                <div class="form-group">
                    <label for="petBornMonth" class="col-sm-8 control-label">
                        {{pet.name | emptyValue:'Your pet'}} was born in
                        <div class="tooltip-xs">
                            <a href="javascript:void(0);" class="tooltip-link" tooltipPlacement="bottom" tabindex="999"
                                  tooltip="In order to qualify for any of the pet insurance policies, your pet needs to be older than 8 weeks of age. Please enter the month and year your pet was born.">
                                <span class="help-icon"></span>
                            </a>
                        </div>
                    </label>
                    <div class="col-xs-24 col-sm-10">
                        <div class="row">
                            <div class="col-xs-12">
                                <input type="tel" class="form-control text-center" id="petBornMonth" name="petBornMonth" placeholder="MM"
                                       [(ngModel)]="pet.bornMonth" #petBornMonth="ngModel"
                                       [fieldValidation]="pet"
                                       [fieldName]="'bornMonth'"
                                       [fieldGuard]="submitted"
                                       (change)="updatePetAgeControl()"
                                       (keyup)="(($event.key == 8 || $event.key == 46)? (pet.bornMonth=$event.target.value):'')"
                                       maxlength="2" pattern="([1-9]|[1][0-2]|0[1-9])"
                                       required/>
                                <div [hidden]="petBornMonth.valid || petBornMonth.pristine || submitted" class="alert alert-danger">
                                    Please enter a month
                                </div>
                                <div [model-validated]="pet" [property-name]="'bornMonth'" model-validation-display></div>
                            </div>
                            <div class="col-xs-12">
                                <input type="tel" class="form-control text-center" id="petBornYear" name="petBornYear" placeholder="YYYY"
                                       [(ngModel)]="pet.bornYear" #petBornYear="ngModel"
                                       [fieldValidation]="pet"
                                       [fieldName]="'bornYear'"
                                       [fieldGuard]="submitted"
                                       (change)="updatePetAgeControl()"
                                       (keyup)="(($event.key == 8 || $event.key == 46)? (pet.bornYear=$event.target.value):'')"
                                       maxlength="4" pattern="(19[0-9]{2}|20[0-2][0-9])"
                                       required/>
                                <div [hidden]="petBornYear.valid || petBornYear.pristine || submitted" class="alert alert-danger">
                                    Please enter a year
                                </div>
                                <div [model-validated]="pet" [property-name]="'bornYear'" model-validation-display></div>
                            </div>
                            <div class="col-xs-24">
                                <div [model-validated]="pet" [property-name]="'bornDate'" model-validation-display></div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6 hidden-xs">
                        <a href="javascript:void(0);" class="tooltip-link" tooltipPlacement="bottom" tabindex="999"
                              tooltip="In order to qualify for any of the pet insurance policies, your pet needs to be older than 8 weeks of age. Please enter the month and year your pet was born.">
                            <span class="help-icon"></span>
                        </a>
                    </div>
                    <div class="col-xs-24 col-sm-10 col-sm-offset-8 checkbox-confirm-container" *ngIf="petAgeControl">
                        <div class="item-spacer-10 clearfix"></div>
                        <div class="checkbox checkbox-confirm">
                            <label>
                                <input type="checkbox"
                                       name="isOver8Weeks"
                                       [(ngModel)]="pet.isOver8WeeksOldDeclaration"
                                       [fieldValidation]="pet"
                                       [fieldName]="'isOver8WeeksOldDeclaration'"
                                       [fieldGuard]="submitted"
                                       #isOver8Weeks="ngModel"
                                       required/>
                                I confirm that <strong>{{pet.name | emptyValue:'my pet'}}</strong> is older than 8 weeks
                            </label>
                        </div>
                        <div [model-validated]="pet" [property-name]="'isOver8WeeksOldDeclaration'" model-validation-display></div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-sm-8 control-label">Is {{pet.name | emptyValue:'your pet'}} male or female?</label>
                    <div class="col-sm-10">
                        <div id="petGender" class="row">
                            <div class="col-xs-12">
                                <button type="button" class="btn radio-btn col-xs-24"
                                        (click)="pet.gender = GENDER.Male"
                                        [ngClass]="{ 'active': pet.gender === GENDER.Male }">
                                    Male
                                </button>
                            </div>
                            <div class="col-xs-12">
                                <button type="button" class="btn radio-btn col-xs-24"
                                        (click)="pet.gender = GENDER.Female"
                                        [ngClass]="{ 'active': pet.gender === GENDER.Female }">
                                    Female
                                </button>
                            </div>
                            <div class="col-xs-24" [model-validated]="pet" [property-name]="'gender'" model-validation-display></div>
                        </div>
                    </div>
                </div>
                <div class="form-group last">
                    <label class="col-sm-8 control-label">
                        Is {{pet.name | emptyValue:'your pet'}} desexed?
                    </label>
                    <div class="col-xs-24 col-sm-10">
                        <div id="petDesexed" class="row">
                            <div class="col-xs-8">
                                <button type="button" class="btn radio-btn col-xs-24"
                                        (click)="pet.isDesexed = DESEXED.Yes"
                                        [ngClass]="{ 'active': pet.isDesexed === DESEXED.Yes }">
                                    Yes
                                </button>
                            </div>
                            <div class="col-xs-8">
                                <button type="button" class="btn radio-btn col-xs-24"
                                        (click)="pet.isDesexed = DESEXED.No"
                                        [ngClass]="{ 'active': pet.isDesexed === DESEXED.No }">
                                    No
                                </button>
                            </div>
                            <div class="col-xs-8">
                                <button type="button" class="btn radio-btn col-xs-24"
                                        (click)="pet.isDesexed = DESEXED.Unknown"
                                        [ngClass]="{ 'active': pet.isDesexed === DESEXED.Unknown }">
                                    Unknown
                                </button>
                            </div>
                            <div class="col-xs-24" [model-validated]="pet" [property-name]="'isDesexed'" model-validation-display></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
   <div class="item-spacer-30 clearfix"></div>
   <hr/>
    <div id="about-you-form" class="container">
        <div class="row">
            <div class="col-sm-10 col-sm-offset-8 text-center">
                <h2>About you</h2>
            </div>
            <div class="col-sm-24 m-t-1">
                <div class="form-group">
                    <label for="userTitle" class="col-xs-24 col-sm-8 control-label">Title</label>
                    <div class="col-xs-12 col-sm-3">
                        <select id="userTitle" class="form-control" name="petCover"
                                [(ngModel)]="user.title"
                                [fieldValidation]="user"
                                [fieldName]="'title'"
                                [fieldGuard]="submitted"
                                (change)="updateTitle($event.target.value)"
                                #userTitle="ngModel"
                                required>
                            <option value="">Select</option>
                            <option value="Mr">Mr</option>
                            <option value="Ms">Ms</option>
                            <option value="Mrs">Mrs</option>
                            <option value="Miss">Miss</option>
                            <option value="Dr">Dr</option>
                        </select>
                        <div [model-validated]="user" [property-name]="'title'" model-validation-display></div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="userFirstName" class="col-sm-8 control-label">First name</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" id="userFirstName" name="userFirstName" placeholder="First name"
                               [(ngModel)]="user.firstName" #userFirstName="ngModel"
                               [fieldValidation]="user"
                               [fieldName]="'firstName'"
                               [fieldGuard]="submitted"
                               (keyup)="(($event.key == 8 || $event.key == 46)? (user.firstName=$event.target.value):'')"
                               required />
                        <div [hidden]="userFirstName.valid || userFirstName.pristine || submitted" class="alert alert-danger">
                            Please enter your first name
                        </div>
                        <div [model-validated]="user" [property-name]="'firstName'" model-validation-display></div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="userLastName" class="col-sm-8 control-label">Last name</label>
                    <div class="col-sm-10">
                        <input type="text" class="form-control" id="userLastName" name="userLastName" placeholder="Last name"
                               [(ngModel)]="user.lastName" #userLastName="ngModel"
                               [fieldValidation]="user"
                               [fieldName]="'lastName'"
                               [fieldGuard]="submitted"
                               (keyup)="(($event.key == 8 || $event.key == 46)? (user.lastName=$event.target.value):'')"
                               required />
                        <div [hidden]="userLastName.valid || userLastName.pristine || submitted" class="alert alert-danger">
                            Please enter you last name
                        </div>
                        <div [model-validated]="user" [property-name]="'lastName'" model-validation-display></div>
                    </div>
                </div>

                <div class="form-group">
                    <label for="userBornDay" class="col-sm-8 control-label">Your date of birth</label>
                    <div class="col-sm-10">
                        <div class="row">
                            <div class="col-xs-8">
                                <input type="tel" class="form-control text-center" id="userBornDay" name="userBornDay" placeholder="DD"
                                       [(ngModel)]="user.bornDay" #userBornDay="ngModel"
                                       [disabled]="theme.userId !== 'WebUser'"
                                       [fieldValidation]="user"
                                       [fieldName]="'bornDay'"
                                       [fieldGuard]="submitted"
                                       (keyup)="(($event.key == 8 || $event.key == 46)? (user.bornDay=$event.target.value):'')"
                                       maxlength="2" pattern="([1-9]|[12][0-9]|3[01]|0[1-9])"
                                       required/>
                                <div [hidden]="userBornDay.valid || userBornDay.pristine || submitted" class="alert alert-danger">
                                    Please enter a day
                                </div>
                                <div [model-validated]="user" [property-name]="'bornDay'" model-validation-display></div>
                            </div>
                            <div class="col-xs-8">
                                <input type="tel" class="form-control text-center" id="userBornMonth" name="userBornMonth" placeholder="MM"
                                       [(ngModel)]="user.bornMonth" #userBornMonth="ngModel"
                                       [disabled]="theme.userId !== 'WebUser'"
                                       [fieldValidation]="user"
                                       [fieldName]="'bornMonth'"
                                       [fieldGuard]="submitted"
                                       (keyup)="(($event.key == 8 || $event.key == 46)? (user.bornMonth=$event.target.value):'')"
                                       maxlength="2" pattern="([1-9]|[1][0-2]|0[1-9])"
                                       required/>
                                <div [hidden]="userBornMonth.valid || userBornMonth.pristine || submitted" class="alert alert-danger">
                                    Please enter a month
                                </div>
                                <div [model-validated]="user" [property-name]="'bornMonth'" model-validation-display></div>
                            </div>
                            <div class="col-xs-8">
                                <input type="tel" class="form-control text-center" id="userBornYear" name="userBornYear" placeholder="YYYY"
                                       [(ngModel)]="user.bornYear" #userBornYear="ngModel"
                                       [disabled]="theme.userId !== 'WebUser'"
                                       [fieldValidation]="user"
                                       [fieldName]="'bornYear'"
                                       [fieldGuard]="submitted"
                                       (keyup)="(($event.key == 8 || $event.key == 46)? (user.bornYear=$event.target.value):'')"
                                       maxlength="4" pattern="(19[0-9]{2}|20[0-2][0-9])"
                                       required/>
                                <div [hidden]="userBornYear.valid || userBornYear.pristine || submitted" class="alert alert-danger">
                                    Year of birth must be between {{nowDate.getFullYear() - 112}}-{{nowDate.getFullYear() - 18}}
                                </div>
                                <div [model-validated]="user" [property-name]="'bornYear'" model-validation-display></div>
                            </div>
                            <div class="col-xs-24">
                                <div [model-validated]="user" [property-name]="'bornDate'" model-validation-display></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="userContactNumber" class="col-sm-8 control-label">
                        Primary contact number
                        <div class="tooltip-xs">
                            <a href="javascript:void(0);" class="tooltip-link" tooltipPlacement="bottom" tabindex="999"
                                  [tooltip]="'This information allows ' + theme.companyName + ' to contact you quickly if you need help at any stage of this process.'">
                                <span class="help-icon"></span>
                            </a>
                        </div>
                    </label>
                    <div class="col-sm-10 col-xs-24">
                        <input type="tel" class="form-control" id="userContactNumber" name="userContactNumber" placeholder="Mobile or landline number with area code"
                               [(ngModel)]="user.contactNumber" #userContactNumber="ngModel"
                               [fieldValidation]="user"
                               [fieldName]="'contactNumber'"
                               [fieldGuard]="submitted"
                               (ngModelChange)="updatePhoneNumber($event)"
                               (keyup)="updateNumberOnDel($event)"
                               maxlength="{{maxPhoneLength}}" pattern="(04)|(\(0[023578]\)\s)([0-9\s]{9,10})"
                               required />
                        <div [hidden]="userContactNumber.valid || userContactNumber.pristine || submitted" class="alert alert-danger">
                            Please enter a valid Australian phone number including the area code
                        </div>
                        <div [model-validated]="user" [property-name]="'contactNumber'" model-validation-display></div>
                    </div>
                    <div class="col-sm-6 hidden-xs">
                        <a href="javascript:void(0);" class="tooltip-link" tooltipPlacement="bottom" tabindex="999"
                              [tooltip]="'This information allows ' + theme.companyName + ' to contact you quickly if you need help at any stage of this process.'">
                            <span class="help-icon"></span>
                        </a>
                    </div>
                </div>
                <!-- client consent -->
                <div class="form-group" *ngIf="showClientConsent">                                               
                    <div class="col-sm-24">
                        <div class="col-sm-8 col-xs-0"></div>
                        <div class="col-sm-10 col-xs-24">
                            <div class="checkbox col-md-2 col-sm-3 col-xs-2">
                                <label class="control-label separated-txt-checkbox">
                                    <input type="checkbox" id="clientConsent" name="clientConsent" [(ngModel)]="pet.clientConsent" #clientConsent="ngModel">
                                </label>
                            </div>
                            <div class="col-md-22 col-sm-21 col-xs-22">
                            You agree to us calling you so that we can provide pet insurance quotes from the brands we compare
                            </div>
                            </div>
                        <div class="col-sm-6"></div>
                    </div>                    
                </div>
                <!--- end client consent -->
                <div class="form-group">
                    <label for="userMail" class="col-sm-8 control-label">
                        Your email address
                        <div class="tooltip-xs">
                            <a href="javascript:void(0);" class="tooltip-link" tooltipPlacement="bottom" tabindex="999"
                                  [tooltip]="'This information allows '+ theme.companyName + ' to email you your quotes on request, so you can decide in your own time.'">
                                <span class="help-icon"></span>
                            </a>
                        </div>
                    </label>
                    <div class="col-sm-10 col-xs-24">
                        <input type="email" class="form-control" id="userMail" name="userMail" placeholder="Your email address"
                               [(ngModel)]="user.email" #userMail="ngModel"
                               [fieldValidation]="user"
                               [fieldName]="'email'"
                               [fieldGuard]="submitted"
                               (keyup)="(($event.key == 8 || $event.key == 46)? (user.email=$event.target.value):'')" required />
                        <div [hidden]="userMail.valid || userMail.pristine || submitted" class="alert alert-danger">
                            Please enter a valid email address
                        </div>
                        <div [model-validated]="user" [property-name]="'email'" model-validation-display></div>
                    </div>
                    <div class="col-sm-6 hidden-xs">
                        <a href="javascript:void(0);" class="tooltip-link" tooltipPlacement="bottom" tabindex="999"
                              [tooltip]="'This information allows ' + theme.companyName + ' to email you your quotes on request, so you can decide in your own time.'">
                            <span class="help-icon"></span>
                        </a>
                    </div>
                </div>
                <div class="form-group">
                    <label for="userState" class="col-xs-24 col-sm-8 control-label">
                        State you live in
                    </label>
                    <div class="col-xs-12 col-sm-5">
                        <select id="userState" class="form-control" name="userState"
                                [(ngModel)]="user.address.state" #userState="ngModel"
                                [fieldValidation]="user.address"
                                [fieldName]="'state'"
                                [fieldGuard]="submitted"
                                required>
                            <option [value]="null">Select State</option>
                            <option *ngFor="let state of STATES | keys" [value]="STATES[state.value]">{{state.value}}</option>
                        </select>
                        <div [model-validated]="user.address" [property-name]="'state'" model-validation-display></div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="userPostcode" class="col-sm-8 col-xs-24 control-label">What is your postcode?</label>
                    <div class="col-xs-12 col-sm-5">
                        <input type="tel" class="form-control" id="userPostcode" name="userPostcode"  placeholder="Postcode"
                               maxlength="4"
                               [(ngModel)]="user.address.postcode" #userPostcode="ngModel"
                               [fieldValidation]="user.address"
                               [fieldName]="'postcode'"
                               [fieldGuard]="submitted"
                               (ngModelChange)="updatePostCode()"
                               (keyup)="(($event.key == 8 || $event.key == 46)? (user.address.postcode=$event.target.value):'')" required />
                        <div [hidden]="userPostcode.valid || userPostcode.pristine || submitted" class="alert alert-danger">
                            Please enter your postcode
                        </div>
                        <div [model-validated]="user.address" [property-name]="'postcode'" model-validation-display></div>
                    </div>
                </div>
            </div>
            <div class="item-spacer-30 clearfix"></div>
            <div class="col-sm-24">
                <div class="row row-height xs-row-height" *ngIf="theme.automated === 'false' && theme.userId === 'WebUser'">    
                    <div class="col-sm-offset-8 col-sm-8 text-center">
                        <re-captcha (resolved)="resolved($event)" siteKey="{{recaptcha_sitekey}}"></re-captcha>
                    </div>
                </div>
                <div [hidden]="!showRecaptchaError" class="alert alert-danger col-sm-offset-8 col-sm-5" *ngIf="theme.automated === 'false' && theme.userId === 'WebUser'">
                    Please verify that you are not a robot
                </div>
                <div class="row row-height xs-row-height">
                    <div class="col-xs-2 col-height col-middle" *ngIf="theme.firstStepTermsCheckbox">
                        <div class="checkbox text-center">
                            <label class="control-label separated-txt-checkbox" [ngClass]="{ 'check-required': theme.firstStepTermsCheckbox && (!isTermsAccepted && submitted) }">
                                <input type="checkbox" [checked]="isTermsAccepted" (change)="isTermsAccepted = !isTermsAccepted"/>
                            </label>
                        </div>
                    </div>
                    
                    <div id="about-you-terms" class="col-height col-middle text-center" [ngClass]="{ 'col-xs-24': !theme.firstStepTermsCheckbox, 'col-xs-22': theme.firstStepTermsCheckbox }" [innerHTML]="theme.firstStepTerms"></div>
                </div>
                <div class="row" *ngIf="theme.firstStepTermsCheckbox && (!isTermsAccepted && submitted)">
                    <div class="col-xs-24">
                        <div class="item-spacer-10 clearfix"></div>
                        <div class="alert alert-danger">
                            Please agree to the terms &amp; conditions and privacy consent
                        </div>
                    </div>
                </div>
                <div class="item-spacer-20 clearfix"></div>
                <div class="col-sm-offset-8 col-sm-8 text-center">
                    <button id="about-you-sbm-btn" type="submit" class="btn submit-btn btn-lg width-100 mobile-not-full" *ngIf="!loading.activated">Compare &amp; Choose</button>
                    <div *ngIf="loading.activated" class="loading-container">
                        <div class="loading-container-table">
                            <div>
                                <h3>
                                    <span class="glyphicon glyphicon-refresh rotating"></span>
                                </h3>
                            </div>
                            <div>
                                <h3>
                                    {{loading.loadingText}}
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div class="item-spacer-10 clearfix"></div>
                    
                </div>

                <div class="item-spacer-30 clearfix"></div>
            </div>
        </div>
    </div>
</form>
<!-- build:dev -->
<div *ngIf="!isProdEnv" class="container" style="word-wrap: break-word;">
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
    <textarea id="diagnostic" cols="50" rows="25" style="width:100%" [innerHTML]="diagnostic"></textarea>
    <br/>
    <br/>
    <br/>
    <br/>
    <br/>
</div>
<!-- endbuild -->



