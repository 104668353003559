import { Component, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CONFIGURATION } from '../environments/environment';

import { User } from '../model/user';
import { Theme } from '../model/theme';
import { State } from '../model/state.enum';

import { SubscriptionService } from '../services/subscription.service';
import { CallService } from '../services/call.service';

import { ToolsMethods } from '../shared/tools.methods';

declare let utag: any;

@Component({
    selector: '[have-us-call]',
    templateUrl: 'have-call.component.html',
    providers: [CallService]
})
export class HaveCallComponent {

    STATES = State;
    errorMsg: string = '';

    submitted: boolean = false;
    finalSubmit: boolean = false;
    theme: Theme;
    user: User;

    subscriptionUser: BehaviorSubject<User>;
    subscriptionTheme: BehaviorSubject<Theme>;

    @Output() closeHaveCall = new EventEmitter<boolean>();

    public constructor(
        private callService: CallService,
        private subscriptionService: SubscriptionService
    ) {
        this.user = User.getBlank();
        this.subscriptionUser = subscriptionService.getSubscriptions<User>('user');
        this.subscriptionUser.subscribe(
            user => {
                this.updateUserDetails(user);
            }
        );
        this.subscriptionTheme = subscriptionService.getSubscriptions<Theme>('theme');
        this.subscriptionTheme.subscribe(
            theme => {
                this.theme = theme;
            }
        );
    }

    updateUserDetails(user: User) {
        this.user.firstName = user.firstName;
        this.user.contactNumber = user.contactNumber;
        this.user.lastName = user.lastName;
        if (user.address !== null && user.address.state !== null) {
            this.user.address.state = user.address.state;
        }
    }

    updatePhoneNumber($event: any) {
        let currentPosition: number = ToolsMethods.getCaretPosition('haveCallContactNumber');
        let currentSize: number = this.user.contactNumber.length;
        this.user.contactNumber = ToolsMethods.formatPhoneNumber($event);
        let finalSize: number = this.user.contactNumber.length;
        let diff: number = finalSize - currentSize;
        setTimeout(() => { ToolsMethods.setCaretPosition('haveCallContactNumber', currentPosition + diff); }, 5);
    }

    updateNumberOnDel($event: any) {
        if ($event.keyCode === 8 || $event.keyCode === 46) {
            this.user.contactNumber = ToolsMethods.formatPhoneNumber($event.target.value);
        }
    }

    onSubmit() {
        this.submitted = true;
        this.errorMsg = '';
        if (!this.user.validate(20)) {
            return;
        }
        this.callService
            .haveUsCall(this.theme.id, this.user, ToolsMethods.getCookie(document.cookie))
            .then(
                response => {
                    this.finalSubmit = response !== '0';
                    this.addTag(response);
                },
                error => {
                    this.errorMsg = 'An error occurred while submitting the form please try again';
                }
            );
    }

    addTag (id: string) {
        if (typeof utag !== 'undefined') {
            let u2: string = CONFIGURATION.tracker_name;
            let ord: string = 'ccmn' + id;
            let dataToSend = {
                event_category: 'Forms',
                event_action: 'Have us call you',
                event_label: 'submitted',
                cost: '',
                order_value: '',
                qty: 1,
                u1: 'call_request',
                u2: u2,
                value_currency: '',
                ord: ord
            };
            if (CONFIGURATION.environment === 'dev') {
                console.log('TAGGING');
                console.log(JSON.stringify(dataToSend));
            }
            utag.link(dataToSend);
        }
    }

    close() {
        this.closeHaveCall.emit(false);
    }

    get maxPhoneLength(): number {
        return ToolsMethods.maxPhoneLength(this.user.contactNumber);
    }
}
